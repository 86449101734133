import { sum } from "lodash";
import { createSelector } from "reselect";

import {
  getBusinesses,
  getConsignments,
  getDeployments,
  getNominations,
  getProperties,
  selectActiveNominationTermByIdLookup,
  selectAgencyByDeploymentIdLookup,
  selectConsignmentIdsByNominationIdLookup,
  selectDisplayedAgentByVendorId,
  selectResolvedCommentCountByNominationIdLookup,
  selectUnresolvedCommentCountByNominationIdLookup,
} from "selectors";

import { selectFilteredNominationIds } from "selectors/globalSearch/nominationFilters";

export const selectNominationData = createSelector(
  [
    getNominations,
    selectActiveNominationTermByIdLookup,
    getBusinesses,
    getProperties,
    getDeployments,
    selectResolvedCommentCountByNominationIdLookup,
    selectUnresolvedCommentCountByNominationIdLookup,
    selectDisplayedAgentByVendorId,
    selectAgencyByDeploymentIdLookup,
    selectFilteredNominationIds,
    selectConsignmentIdsByNominationIdLookup,
    getConsignments,
  ],
  (
    nominations,
    nominationTerms,
    businesses,
    properties,
    deployments,
    resolvedCommentCountByNominationIdLookup,
    unresolvedCommentCountByNominationIdLookup,
    displayedAgentByVendorIdLookup,
    agencyByDeploymentIdLookup,
    filteredNominationIds,
    consignmentIdsByNominationIdLookup,
    consignmentByIdLookup,
  ) => {
    return filteredNominationIds.reduce((acc, nominationId) => {
      const nomination = nominations[nominationId];

      const consignmentIds =
        consignmentIdsByNominationIdLookup[nominationId] || [];

      const consignmentsQuantity = sum(
        consignmentIds.map(
          consignmentId => consignmentByIdLookup[consignmentId].quantity,
        ),
      );
      const nominationQuantity = sum(
        nomination.nominationDetails.map(nomDetail => nomDetail.quantity),
      );

      nomination.nominationDetails.forEach(nominationDetail => {
        acc.push({
          agency: agencyByDeploymentIdLookup[nomination.deploymentId],
          nomination,
          vendor: businesses[nomination.vendorId],
          vendorProperty: properties[nomination.propertyId],
          consigningDeployment: deployments[nomination.consigningDeploymentId],
          nominationDetail,
          resolvedCommentCount:
            resolvedCommentCountByNominationIdLookup[nomination.id],
          unresolvedCommentCount:
            unresolvedCommentCountByNominationIdLookup[nomination.id],
          agentRelationship:
            displayedAgentByVendorIdLookup[nomination.vendorId],
          consignmentDiff: nominationQuantity - consignmentsQuantity,
        });
      });

      return acc;
    }, []);
  },
);
