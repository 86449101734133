import React from "react";

import { TextArea } from "components/Form/FormikControls";

// This tooltip is formatted in this way so it will render example html tags for the user.
// &lt; = < and &gt; = >
export const detailsTooltip = (
  <div>
    Text entered here will shown on billing documents to provide any extra
    information to the recipient. HTML can be used in this field. e.g:
    <br />
    &lt;strong&gt; <strong>This text will be bold </strong> &lt;/strong&gt;
    <br />
    &lt;small&gt; <small> This text will be small </small> &lt;/small&gt; <br />
    &lt;mark&gt;
    <mark> This text will be highlighted</mark>
    &lt;/mark&gt;
    <br />
    &lt;ul&gt; &lt;li&gt;
    <ul>
      <li>This text will be in a list</li>
    </ul>
    &lt;/li&gt; &lt;/ul&gt;
  </div>
);

export const HTMLTemplateTextField = ({ name, disabled }) => {
  return (
    <TextArea
      label="Details Text"
      name={name}
      tooltip={detailsTooltip}
      maxLength={500}
      rows={4}
      disabled={disabled}
    />
  );
};
