import React from "react";

import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { useField, useFormikContext } from "formik";
import isArray from "lodash/isArray";

import IconTextButton from "components/Button/IconTextButton";
import { DataTour } from "components/DataTour";
import { Warning } from "components/ErrorMessage";
import { Column, Row } from "components/Layout";
import { CombinedFormikMissingFieldIndicator } from "components/MissingFieldsIndicator";
import { ReactSelect } from "components/SearchableSelector";

import { EMPTY_ARRAY } from "lib";

import { simpleOptionComparer } from "lib/compare";

import { Error } from "./Error";
import { Label } from "./Label";
import { useSuggestedValue } from "./lib";

export const getNullEmptyValue = () => null;
export const getUndefinedEmptyValue = () => undefined;
export const getFirstOnlyOption = field => (value, index, array) =>
  array.length === 1 ? value : simpleOptionComparer(field)(value, index, array);

const getValueFromSelectedOption = selectedOption => {
  if (Array.isArray(selectedOption)) {
    return selectedOption.map(o => o.value);
  } else {
    return selectedOption.value;
  }
};

export const flattenOptions = options => {
  return options.reduce((acc, option) => {
    if (isArray(option.options)) {
      option.options.forEach(option => acc.push(option));
    } else {
      acc.push(option);
    }
    return acc;
  }, []);
};

export const SelectField = ({
  comparer = simpleOptionComparer,
  menuPortalTarget = undefined,
  disabled = false,
  getEmptyValue = getNullEmptyValue,
  isClearable = false,
  isOptionDisabled = undefined,
  label = undefined,
  maxMenuHeight = undefined,
  name,
  onChangeExtra = null,
  options,
  tooltip = undefined,
  required = false,
  suggestedValueFieldName = undefined,
  isMulti = false,
  components = {},
  altTooltip = null,
  altColor = null,
  warningText = null,
  placeholder = "Select...",
  filterOption = undefined,
  autoFocus = false,
  missingMLAFieldsWarningEnabled = false,
  missingNASFieldWarningEnabled = false,
  overrideMissingFieldName = undefined,
  modelIds = EMPTY_ARRAY,
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const suggestedValue = useSuggestedValue(
    suggestedValueFieldName,
    field.value,
  );
  const suggestedLabel =
    suggestedValue === undefined
      ? ""
      : options.find(o => o.value === suggestedValue)?.label;

  const onChange = (e, { action }) => {
    // useField onChange doesnt work with a select, so use the form.setFieldValue.
    const newValue =
      action === "clear" ? getEmptyValue() : getValueFromSelectedOption(e);
    setFieldTouched(name, true, false).then(() =>
      setFieldValue(name, newValue, true),
    );
    onChangeExtra && onChangeExtra(newValue);
  };

  const handleClickUseSuggestedValue = () => {
    onChange({ value: suggestedValue }, {});
  };

  const flattenedOptions = flattenOptions(options);

  const comparerFunc = comparer(field);
  let selectedValue =
    flattenedOptions && field.value !== null
      ? flattenedOptions.filter(comparerFunc)
      : getEmptyValue();

  if (isMulti && Array.isArray(selectedValue)) {
    selectedValue =
      field.value?.map(v => flattenedOptions.find(o => o.value === v)) || [];
  }

  const error = meta.touched && meta.error;
  const hasLabel = !!label;
  // We need to put the menu in a portal when we need the menu options to be able to overflow outside of the parent.
  // Eg in a modal, we dont want to scroll the content of the modal when the menu is open.

  return (
    <Column fullWidth>
      <DataTour dataTour={name}>
        <Row justifyBetween>
          <Label
            htmlFor={name}
            required={hasLabel && required}
            error={!!error}
            tooltip={tooltip}
            altTooltip={altTooltip}
            altColor={altColor}
          >
            {label}
          </Label>
          <CombinedFormikMissingFieldIndicator
            missingMLAFieldsWarningEnabled={missingMLAFieldsWarningEnabled}
            missingNASFieldWarningEnabled={missingNASFieldWarningEnabled}
            name={name}
            overrideMissingFieldName={overrideMissingFieldName}
            modelIds={modelIds}
          />
        </Row>
        <ReactSelect
          // TODO: Modify the below so it works better as per the comment above.
          isClearable={isClearable}
          isOptionDisabled={isOptionDisabled}
          data-tour={name}
          maxMenuHeight={maxMenuHeight || 300}
          value={selectedValue}
          options={options}
          labelledBy={name}
          onChange={onChange}
          error={!!error}
          isDisabled={disabled}
          menuPortalTarget={menuPortalTarget}
          isMulti={isMulti}
          components={components}
          placeholder={placeholder}
          filterOption={filterOption}
          autoFocus={autoFocus}
        />
        {suggestedLabel ? (
          <IconTextButton
            icon={faQuestionCircle}
            color="success"
            onClick={handleClickUseSuggestedValue}
          >
            Use Suggested: {suggestedLabel}
          </IconTextButton>
        ) : null}
        {error && <Error>{error}</Error>}
        {warningText && <Warning>{warningText}</Warning>}
      </DataTour>
    </Column>
  );
};
