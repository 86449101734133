import React, { useEffect, useMemo, useRef, useState } from "react";

import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import Big from "big.js";
import { useFormikContext, useField } from "formik";
import styled from "styled-components/macro";

import IconTextButton from "components/Button/IconTextButton";
import { Column, Row } from "components/Layout";
import { CombinedFormikMissingFieldIndicator } from "components/MissingFieldsIndicator";
import { OptionToggler } from "components/OptionToggler";

import { EMPTY_ARRAY } from "lib";

import { useIsMobile } from "hooks";

import { Error } from "./Error";
import { HelpText } from "./HelpText";
import { Label } from "./Label";
import { LabelText, Required, StyledInput } from "./layout";
import { useSuggestedValue } from "./lib";

const CurrencySign = styled.span`
  color: #666666;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  ${({ after, theme }) =>
    after ? `right: ${theme.space[2]}px;` : `left: ${theme.space[2]}px`}
`;

const InputWrapper = styled.div`
  position: relative;
`;
const PREFILL_OPTION_OTHER_VALUE = "__OTHER__";
const PREFILL_OPTION_OTHER = {
  value: PREFILL_OPTION_OTHER_VALUE,
  label: "Other",
};
function PrefillSelector(props) {
  const { options, disabled, value, selectValue, dataTour } = props;

  const togglerOptions = useMemo(() => {
    return [
      ...options.map(o => ({ value: o, label: o })),
      PREFILL_OPTION_OTHER,
    ];
  }, [options]);

  return (
    <OptionToggler
      dataTour={dataTour}
      name=""
      options={togglerOptions}
      disabled={disabled}
      value={value}
      onChange={selectValue}
    />
  );
}

function defaultGetPrefillOptions() {
  return null;
}
export function BaseInput(props) {
  const {
    afterSymbol = undefined,
    align = undefined,
    autoFocus = false,
    beforeSymbol = undefined,
    bold = false,
    disabled,
    error,
    helpText, // The help text to display underneath the input, when present is always visible
    hideSpinner = true,
    label,
    maxLength = undefined,
    name,
    dataTour,
    onBlur,
    onChange,
    placeholder = "",
    required,
    selectOnFocus = true,
    tooltip = undefined,
    type = "text",
    value,
    width = "100%",
    columnProps = { fullWidth: true },
    suggestedValueFieldName = undefined,
    disableAutoComplete = false,
    altTooltip = undefined,
    altColor = undefined,
    getPrefillOptions = defaultGetPrefillOptions,
    modelIds = EMPTY_ARRAY,
    missingMLAFieldsWarningEnabled = false,
    missingNASFieldWarningEnabled = false,
    overrideMissingFieldName = undefined,
    hideMissingFieldLabel = false,
    innerRef = null,
    ...inputProps
  } = props;
  const ownRef = useRef(null);
  const inputRef = innerRef || ownRef;
  const onFocus = e => selectOnFocus && e.target.select();
  const isMobile = useIsMobile();

  const { setValue } = useField(name)[2];

  const suggestedValue = useSuggestedValue(suggestedValueFieldName, value);
  const handleClickUseSuggestedValue = () => {
    setValue(suggestedValue);
  };

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus, inputRef]);

  const hasLabel = !!label;

  const prefillOptions =
    typeof getPrefillOptions === "function" ? getPrefillOptions(props) : null;
  const [showMainInput, setShowMainInput] = useState(
    prefillOptions === null || (value && !prefillOptions.includes(value)),
  );
  const prefillSelectValue = value => {
    if (value === PREFILL_OPTION_OTHER_VALUE) {
      setValue("");
      setShowMainInput(true);
    } else {
      setValue(value);
      setShowMainInput(false);
    }
  };
  const prefillValue =
    !value || (prefillOptions && prefillOptions.includes(value))
      ? value
      : PREFILL_OPTION_OTHER_VALUE;

  return (
    <Column {...columnProps}>
      <Row justifyBetween alignCenter>
        <Label
          htmlFor={name}
          required={hasLabel && required}
          error={!!error}
          tooltip={tooltip}
          altTooltip={altTooltip}
          altColor={altColor}
        >
          {label}
        </Label>
        <CombinedFormikMissingFieldIndicator
          missingMLAFieldsWarningEnabled={missingMLAFieldsWarningEnabled}
          missingNASFieldWarningEnabled={missingNASFieldWarningEnabled}
          name={name}
          overrideMissingFieldName={overrideMissingFieldName}
          modelIds={modelIds}
          hideLabel={hideMissingFieldLabel || isMobile}
        />
      </Row>

      {prefillOptions ? (
        <>
          <PrefillSelector
            dataTour={dataTour}
            options={prefillOptions}
            disabled={disabled}
            value={prefillValue}
            selectValue={prefillSelectValue}
          />
          {showMainInput ? (
            <LabelText className="pt-1">Enter Other {label}</LabelText>
          ) : null}
        </>
      ) : null}
      {showMainInput ? (
        <InputWrapper style={{ width }}>
          {beforeSymbol && <CurrencySign>{beforeSymbol}</CurrencySign>}
          <StyledInput
            data-tour={dataTour || name}
            value={value}
            labelledBy={name}
            onBlur={onBlur}
            name={name}
            onChange={onChange}
            disabled={disabled}
            onFocus={onFocus}
            type={type}
            // Setting `required` attribute and on the DOM _input_ element will prevent a form from being
            // submitted when there is a nested form inside it with a field which is set as `required`
            // required={required}
            ref={inputRef}
            maxLength={maxLength}
            placeholder={placeholder}
            beforeSymbol={beforeSymbol}
            afterSymbol={afterSymbol}
            hideSpinner={hideSpinner}
            align={align}
            bold={bold}
            autoComplete={disableAutoComplete ? "off" : "on"}
            {...inputProps}
          />
          {required && !hasLabel && <Required />}
          {afterSymbol && <CurrencySign after>{afterSymbol}</CurrencySign>}
        </InputWrapper>
      ) : null}
      {suggestedValue !== undefined ? (
        <IconTextButton
          icon={faQuestionCircle}
          color="success"
          onClick={handleClickUseSuggestedValue}
        >
          Use Suggested: {suggestedValue}
        </IconTextButton>
      ) : null}
      {error && <Error>{error}</Error>}
      {helpText && <HelpText>{helpText}</HelpText>}
    </Column>
  );
}

const defaultValueFormatter = ({ value, decimal, decimalPlaces }) => {
  // If a decimal input, show the full rounding amount (eg 78.90)
  return decimal && value ? parseFloat(value).toFixed(decimalPlaces) : value;
};

const defaultValueGetter = ({ overrideValue, value, multiplier }) =>
  overrideValue ||
  (multiplier ? value / multiplier : value) ||
  (value === undefined || value === null ? "" : value);

const defaultValueRenderer = ({ value, rawText }) => rawText || value;

const defaultValueParser = props => {
  const {
    setTouched,
    e,
    setValue,
    setRawText,
    type,
    decimalPattern,
    maxLength,
    multiplier,
    decimal,
    decimalPlaces,
    field,
    name,
    onChangeExtra,
    valueTransformer,
    round,
    emptyValue,
  } = props;
  setTouched(true, false);

  if (type === "number" && maxLength) {
    e.target.value = Math.max(0, parseFloat(e.target.value, 10))
      .toString()
      .slice(0, maxLength);
  }

  e.target.value = e.target.value === "NaN" ? "" : e.target.value;
  if (decimalPattern && !decimalPattern.test(e.target.value)) {
    // If the value doesnt match the regex for decimal, dont let it be set.
    return;
  }
  if (valueTransformer) {
    e.target.value = valueTransformer(e.target.value);
  }
  setRawText(e.target.value);
  if (
    multiplier &&
    // Prevent the input value from being scaled when a user has cleared the input.
    // Otherwise `""` becomes `"0"` every time the user attempts to clear the input.
    e.target.value !== ""
  ) {
    // Floating point numbers on weights.
    // 4161.48 * 1000 = 4161479.9999999995
    // We know the precision we need - let's make it happen.  And wipe away tears as we do so.
    if (decimal) {
      e.target.value = parseFloat(e.target.value * multiplier).toFixed(
        decimalPlaces,
      );
    } else {
      e.target.value *= multiplier;
    }
  }
  if (type === "number" && e.target.value === "") {
    setValue(emptyValue);
    return;
  }

  if (round) {
    setValue(Math.round(e.target.value));
  } else {
    field.onChange(name)(e);
  }

  typeof onChangeExtra === "function" && onChangeExtra(e);
};

export const Input = props => {
  const {
    name,
    dataTour,
    label,
    type = "text",
    maxLength = undefined,
    decimal = false,
    decimalPlaces = 2,
    multiplier = undefined,
    overrideValue = undefined,
    align = undefined,
    required = false,
    valueGetter = defaultValueGetter, // Internal Value -> Stored Value
    valueFormatter = defaultValueFormatter, // Stored Value -> Displayed Value
    valueRenderer = defaultValueRenderer, // Internal Value || Displayed Value -> Displayed Value
    valueParser = defaultValueParser, // Displayed Value -> Stored Value
    disableAutoComplete,
    getPrefillOptions = defaultGetPrefillOptions,
    innerRef = null,
    onChangeExtra: ignored, // Referenced in defaultValueParser, but raises warning when passed through to the input.
    modelIds = EMPTY_ARRAY,
    missingMLAFieldsWarningEnabled = false,
    missingNASFieldWarningEnabled = false,
    overrideMissingFieldName = undefined,
    hideMissingFieldLabel = false,
    ...inputProps
  } = props;
  const [field, meta, helpers] = useField(name);
  const { setValue, setTouched } = helpers;

  const decimalPattern = React.useMemo(
    () =>
      decimal
        ? new RegExp(`^-?\\d*(\\.\\d{0,${decimalPlaces}})?$`)
        : type === "number"
          ? new RegExp(`^[0-9]*$`)
          : undefined,
    [decimal, decimalPlaces, type],
  );

  const value = valueGetter(
    { overrideValue, value: field.value, multiplier, decimalPattern },
    props,
  );

  const [rawText, setRawText] = useState(
    valueFormatter({ decimal, value, decimalPlaces }, props),
  );

  useEffect(() => {
    setRawText(value);
  }, [value]);

  const error = meta.touched && meta.error;

  const displayValue = valueRenderer({ rawText, value }, props);

  const alignInput = React.useMemo(() => {
    if (align) {
      return align;
    } else if (decimal) {
      return "right";
    } else {
      return undefined;
    }
  }, [align, decimal]);

  const onBlur = e => {
    // Format the output if needed.
    setRawText(valueFormatter({ value, decimal, decimalPlaces }, props));
    field.onBlur(name)(e);
  };

  // Chrome desktop: input type=text allows text and numbers. ✅
  // Chrome desktop: input type=number doesnt allow text. ❌
  // Safari mobile: input type=text allows text and numbers but doesnt show the numbers on the keyboard by default. ❌
  // Safari mobile: inputMode="numeric" only shows numeric keypad, cant enter text. ❌
  // Safari mobile: input type=number allows text and numbers and shows the numbers on the keyboard by default. ✅
  // Android mobile: input type=number doesnt allow text. ❌
  // Android mobile: type=text doesnt show numbers on the keyboard.❌ So we need to override inputmode=numeric  ✅

  // So 🤠
  // For mobile safari, use type="number", for everything else use type="text" ✅

  // https://css-tricks.com/everything-you-ever-wanted-to-know-about-inputmode/ Gives testable examples for inputs.
  // IOS has option for number and text by default, but android is either number or text with the option to change to number.
  // Users can turn on number row in android keyboard (Gboard) settings to achieve similar result to IOS

  return (
    <BaseInput
      dataTour={dataTour}
      label={label}
      name={name}
      required={required}
      maxLength={maxLength}
      type={type === "number" && decimal ? "text" : type}
      inputmode={type === "number" && decimal ? "numeric" : undefined}
      value={displayValue}
      error={error}
      onChange={e =>
        valueParser({
          ...props,
          value,
          setTouched,
          e,
          setValue,
          setRawText,
          decimalPattern,
          field,
        })
      }
      onBlur={onBlur}
      align={alignInput}
      disableAutoComplete={disableAutoComplete}
      getPrefillOptions={getPrefillOptions}
      innerRef={innerRef}
      modelIds={modelIds}
      missingMLAFieldsWarningEnabled={missingMLAFieldsWarningEnabled}
      missingNASFieldWarningEnabled={missingNASFieldWarningEnabled}
      overrideMissingFieldName={overrideMissingFieldName}
      hideMissingFieldLabel={hideMissingFieldLabel}
      {...inputProps}
    />
  );
};

export const IntegerPriceInput = ({ name, ...props }) => {
  return (
    <Input
      name={name}
      type="number"
      beforeSymbol="$"
      decimal
      multiplier={100}
      round
      {...props}
    />
  );
};

export const PercentageInput = ({ name, ...props }) => {
  const [field, meta, helpers] = useField(name);

  const { setValue, setTouched } = helpers;

  const { value } = field;

  const [displayedValue, setDisplayedValue] = useState(
    Big(value || 0).times(100),
  );

  const onChange = e => {
    setTouched(true, false);
    setValue(
      Big(e.target.value || 0)
        .div(100)
        .toString(),
    );
    setDisplayedValue(e.target.value);
  };

  useEffect(() => {
    // If the form value changes again (ie re-initialize is on), update the displayed value.
    setDisplayedValue(Big(value || 0).times(100));
  }, [value]);

  const error = meta.touched && meta.error;
  return (
    <BaseInput
      name={name}
      type="number"
      afterSymbol="%"
      value={displayedValue}
      error={error}
      onChange={onChange}
      {...props}
    />
  );
};

export const MutuallyExclusiveInput = props => {
  const {
    mutuallyExclusiveFalseValue = "0",
    mutuallyExclusiveWithName = "",
    mutuallyExclusiveWithFalseValue = false,
  } = props;
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const onChangeExtra = ({ target }) => {
    if (
      mutuallyExclusiveWithName &&
      target.value !== mutuallyExclusiveFalseValue
    ) {
      setFieldValue(mutuallyExclusiveWithName, mutuallyExclusiveWithFalseValue);
      setFieldTouched(mutuallyExclusiveWithName);
    }
  };
  return <Input {...props} onChangeExtra={onChangeExtra} />;
};
