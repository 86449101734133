import { ColumnType } from "components/AgGrid/constants";

import { ACTION_COLUMN_NAME } from "constants/aggrid";

import { datetimeFormatter } from "lib/agGrid/columns/datetime";
import {
  GLCodeRenderer,
  EditManualAdjustmentRenderer,
  SundryTemplateRenderer,
} from "lib/agGrid/renderers";

export const ManualAdjustmentColId = {
  ACTIONS: "c72ee1b9-0e47-4d71-a40a-fa90405fa8f8",
  CREATED: "acb207dd-54e5-4a8f-9e82-f2d4548372b7",
  FROM_BUSINESS: "9cf2213e-7d8c-4af4-ac49-4ab1e74595fe",
  GL_CODE: "583d9e29-754a-4af2-b83c-9b49951cc34e",
  GST_CENTS: "132ff995-19d9-433c-9708-d8a43df9fd62",
  GST_METHOD: "8de9b652-20dd-4b28-9f0a-73c666f16d81",
  LABELS: "c9d6eda4-0f32-41b1-8e77-10cb71c9f1ec",
  NOTE: "f447b90f-9f2a-4b33-b06b-fbb23d03500c",
  SUBTOTAL_CENTS: "be516b65-1f41-4882-93ca-8d717946f9e1",
  TAX_TYPE: "95246417-88c3-41d3-affd-025143227e0b",
  TOTAL_CENTS: "9ad3a324-3a18-4576-b6e0-1356b9887ffa",
  TO_BUSINESS: "3785146b-f119-4bb1-9cd9-77d0d4590112",
  TEMPLATE: "a967f125-f46d-4d42-9ee7-ca97b03ce248",
};

export const ManualAdjustmentColumnDef = {
  [ManualAdjustmentColId.CREATED]: {
    colId: ManualAdjustmentColId.CREATED,
    field: "manualAdjustment.created",
    headerName: "Created",
    valueFormatter: datetimeFormatter,
  },
  [ManualAdjustmentColId.SUBTOTAL_CENTS]: {
    colId: ManualAdjustmentColId.SUBTOTAL_CENTS,
    field: "manualAdjustment.subtotalCents",
    headerName: "Amount ($)",
    aggFunc: "sum",
    valueFormatterParams: {
      decimalPlaces: 2,
      formatToMinTwoDecimalPlaces: false,
    },
    type: ColumnType.CURRENCY_FROM_CENTS,
  },
  [ManualAdjustmentColId.GST_CENTS]: {
    colId: ManualAdjustmentColId.GST_CENTS,
    field: "manualAdjustment.gstCents",
    headerName: "GST ($)",
    aggFunc: "sum",
    valueFormatterParams: {
      decimalPlaces: 2,
      formatToMinTwoDecimalPlaces: false,
    },
    type: ColumnType.CURRENCY_FROM_CENTS,
  },
  [ManualAdjustmentColId.TOTAL_CENTS]: {
    colId: ManualAdjustmentColId.TOTAL_CENTS,
    field: "manualAdjustment.totalCents",
    headerName: "Total ($)",
    aggFunc: "sum",
    valueFormatterParams: {
      decimalPlaces: 2,
      formatToMinTwoDecimalPlaces: false,
    },
    type: ColumnType.CURRENCY_FROM_CENTS,
  },
  [ManualAdjustmentColId.LABELS]: {
    colId: ManualAdjustmentColId.LABELS,
    field: "labels",
    headerName: "Labels",
  },
  [ManualAdjustmentColId.GST_METHOD]: {
    colId: ManualAdjustmentColId.GST_METHOD,
    field: "manualAdjustment.gstMethod",
    headerName: "GST Method",
  },
  [ManualAdjustmentColId.TAX_TYPE]: {
    colId: ManualAdjustmentColId.TAX_TYPE,
    field: "manualAdjustment.taxType",
    headerName: "Tax Type",
  },
  [ManualAdjustmentColId.GL_CODE]: {
    colId: ManualAdjustmentColId.GL_CODE,
    field: "manualAdjustment.glCode",
    headerName: "GL Code",
    cellRenderer: GLCodeRenderer,
  },
  [ManualAdjustmentColId.NOTE]: {
    colId: ManualAdjustmentColId.NOTE,
    field: "manualAdjustment.note",
    headerName: "Note",
  },
  [ManualAdjustmentColId.ACTIONS]: {
    colId: ManualAdjustmentColId.ACTIONS,
    field: "edit",
    headerName: ACTION_COLUMN_NAME,
    pinned: "right",
    cellRenderer: EditManualAdjustmentRenderer,
  },
  [ManualAdjustmentColId.TEMPLATE]: {
    colId: ManualAdjustmentColId.TEMPLATE,
    field: "manualAdjustment.sundryTemplateId",
    headerName: "Template",
    cellRenderer: SundryTemplateRenderer,
  },
};
