import React from "react";

import { useSelector } from "react-redux";

import AgGridContainer from "components/AgGrid/AgGridContainer";
import { Column, Row } from "components/Layout";
import { Paper } from "components/Paper";

import { AgGridTables } from "constants/aggrid";
import { SundryTemplateColumnDef } from "constants/columnDefinitions/sundryTemplates";
import { ModalTypes } from "constants/navigation";
import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";

import { openModalLink } from "lib/navigation";

import { selectSundryTemplatesAggridData } from "selectors/aggrid/sundryTemplates";

import { useHasSaleyardOrDeploymentPermission } from "hooks";

export const columnDefs = Object.values(SundryTemplateColumnDef);

export const SundryTemplatesTable = () => {
  const rowData = useSelector(selectSundryTemplatesAggridData);
  const hasCanAddSundryTemplatePermission =
    useHasSaleyardOrDeploymentPermission(
      DeploymentPermissions.canAddSundryTemplate,
      SaleyardPermissions.canAddSundryTemplate,
    );

  const openCreateSundryTemplate = () =>
    openModalLink(ModalTypes.SundryTemplate);

  const additionalActions = [
    {
      title: "Create Sundry Template",
      dataTour: "createSundryTemplate",
      onClick: openCreateSundryTemplate,
      disabled: !hasCanAddSundryTemplatePermission,
    },
  ];

  return (
    <AgGridContainer
      rowData={rowData}
      columnDefs={columnDefs}
      tableName={AgGridTables.SUNDRY_TEMPLATES}
      additionalActions={additionalActions}
      headerJustifyContent="space-between"
      downloadFilename="sundryTemplates.csv"
    />
  );
};

export const SundryTemplates = () => {
  return (
    <Row flexGrow full>
      <Column margin={2} gridGap={2} full>
        <Paper square>
          <SundryTemplatesTable />
        </Paper>
      </Column>
    </Row>
  );
};
