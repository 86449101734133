import React from "react";

import { PropTypes } from "prop-types";
import styled from "styled-components/macro";

import { ExclamationIcon } from "components/Icons";
import { Row } from "components/Layout";

const Wrapper = styled(Row)`
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  padding: 12px 18px;
  border: 1px solid ${({ theme }) => theme.colors.error};
  background-color: #fff;
  color: ${({ theme }) => theme.colors.error};
  font-weight: bold;
`;

const IconWrapper = styled.div`
  flex-grow: 0;
  padding-right: 18px;
  padding-top: 6px;
`;

const LargeErrorMessage = ({ children, visible = true }) => (
  <Wrapper visible={visible}>
    <IconWrapper>
      <ExclamationIcon />
    </IconWrapper>
    <div>{children}</div>
  </Wrapper>
);

LargeErrorMessage.propTypes = {
  children: PropTypes.any,
  visible: PropTypes.bool,
};

export default LargeErrorMessage;
