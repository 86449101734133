import React, { useState } from "react";

import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import styled from "styled-components/macro";

import AnimalListItem from "components/AnimalListItem";
import ExpandIcon from "components/ExpandIcon";
import { Button, SecondaryButton } from "components/Form";
import { AlertIcon } from "components/Icons";
import { Row, Column } from "components/Layout";
import { ModalBg, ModalHeader } from "components/Modal";

const getExceptions = (exceptionsArray, type) => {
  if (exceptionsArray.length < 1) {
    return (
      <>
        <CheckIcon /> No {type} exceptions
      </>
    );
  }
  if (exceptionsArray[0].exceptions) {
    return exceptionsArray[0].exceptions.map(e => (
      <React.Fragment key={e}>
        <AlertIcon /> {e}
      </React.Fragment>
    ));
  }
  return `There are ${type} exceptions`;
};

const SaleConfirmationModal = ({
  startPen,
  animals,
  buyerName,
  destinationProperty,
  vendorName,
  exceptions,
  closeSelf,
  handleSellClick,
}) => {
  const [isAnimalExpanded, setAnimalExpanded] = useState(false);
  const toggleAnimalExpanded = () => setAnimalExpanded(!isAnimalExpanded);

  const handleSell = () => {
    closeSelf();
    handleSellClick();
  };

  return (
    <ModalBgStyled>
      <ModalHeaderStyled title="Sale Confirmation" closeSelf={closeSelf} />
      <Wrapper>
        <SubHeading>Lot #{startPen}</SubHeading>
        <ColumnWrapper>
          <Column>
            <Text label="true">Vendor/s</Text>
            <Text>{vendorName}</Text>
          </Column>
          <IconText>{getExceptions(exceptions.vendors, "Vendor")}</IconText>
        </ColumnWrapper>
        <ColumnWrapper>
          <Column>
            <Text label="true">Buyer</Text>
            <Text>
              {buyerName} - {destinationProperty}
            </Text>
          </Column>
          <IconText>{getExceptions(exceptions.buyers, "Buyer")}</IconText>
        </ColumnWrapper>
        <ColumnWrapper>
          <StyledColumn>
            <Text label="true">Animals</Text>
            <Text>{animals.length}</Text>
            <StyledExpandIcon
              expanded={isAnimalExpanded}
              onClick={toggleAnimalExpanded}
            />
          </StyledColumn>
          {isAnimalExpanded && animals.length ? (
            <AnimalList>
              {animals.map(animal => (
                <AnimalListItemStyled key={animal.EID} {...animal} />
              ))}
            </AnimalList>
          ) : null}
        </ColumnWrapper>
        <Column flexGrow>
          <Small>
            Upon confirming the sale the records of the sold animals will be
            updated in the NLIS.
          </Small>
        </Column>
        <ButtonWrapper>
          <SecondaryButton onClick={closeSelf} data-tour="cancel">
            Cancel
          </SecondaryButton>
          <ButtonStyled
            type="submit"
            onClick={handleSell}
            data-tour="confirm-sale"
          >
            Confirm Sale
          </ButtonStyled>
        </ButtonWrapper>
      </Wrapper>
    </ModalBgStyled>
  );
};

SaleConfirmationModal.propTypes = {
  startPen: PropTypes.string,
  animals: PropTypes.array,
  buyerName: PropTypes.string,
  destinationProperty: PropTypes.string,
  vendorName: PropTypes.string,
  closeSelf: PropTypes.func.isRequired,
  exceptions: PropTypes.object,
  handleSellClick: PropTypes.func,
};

export default SaleConfirmationModal;

const ModalHeaderStyled = styled(ModalHeader)`
  margin-bottom: 0;
`;

const ModalBgStyled = styled(ModalBg).attrs(({ theme }) => ({
  backgroundStyle: {
    backgroundColor: "rgba(102,102,102,0.5)",
  },
  contentStyle: {
    height: "100%",
    maxWidth: "100%",
    padding: `${theme.space[2]}px`,
    backgroundColor: theme.colors.white,
    overflowY: "auto",
  },
}))``;

const StyledColumn = styled(Column).attrs({
  flexGrow: true,
})`
  position: relative;
  margin-bottom: ${({ theme }) => theme.space[2]}px;
`;

const ColumnWrapper = styled(Column).attrs({})`
  position: relative;
  margin: ${({ theme }) => theme.space[2]}px ${({ theme }) => theme.space[0]}px;
`;

const Small = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.beta}px;
  color: ${({ theme }) => theme.colors.gray9C};
  margin-left: ${({ theme }) => theme.space[2]}px;
`;

const IconText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.beta}px;
  color: ${({ theme }) => theme.colors.gray44};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  margin-left: ${({ theme }) => theme.space[2]}px;
`;

const Text = styled.div`
  font-size: ${({ theme, label }) =>
    label ? theme.fontSizes.beta : theme.fontSizes.gamma}px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme, label }) =>
    label ? theme.colors.gray9C : theme.colors.gray44};
  line-height: ${({ label }) => (label ? 18 : 22)}px;
  text-transform: ${({ label }) => (label ? "uppercase" : "")};
  margin-left: ${({ theme }) => theme.space[2]}px;
`;

const Wrapper = styled(Column).attrs({
  flexGrow: true,
})`
  height: 90%;
`;

const ButtonWrapper = styled(Row)`
  margin-top: auto;
  padding-left: ${({ theme }) => theme.space[2]}px;
`;

const ButtonStyled = styled(Button)`
  margin-left: ${({ theme }) => theme.space[2]}px;
`;

const SubHeading = styled.div`
  color: ${({ theme }) => theme.colors.grayAE};
  font-size: ${({ theme }) => theme.fontSizes.delta}px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-left: ${({ theme }) => theme.space[2]}px;
  margin-bottom: 25px;
`;

const AnimalList = styled(Column).attrs({
  flexGrow: true,
})`
  margin-left: ${({ theme }) => theme.space[2]}px;
  max-height: 100%;
  overflow-y: scroll;
`;

const StyledExpandIcon = styled(ExpandIcon)`
  position: absolute;
  right: 0;
  ${({ top }) => (top ? "top: 5px" : "bottom: 20px")};
`;

const CheckIcon = styled(FontAwesomeIcon).attrs({
  icon: faCheck,
})`
  color: ${({ theme }) => theme.colors.success};
`;

const AnimalListItemStyled = styled(AnimalListItem)`
  padding: ${({ theme }) => theme.space[2]}px;
`;
