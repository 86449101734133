import { ColumnType } from "components/AgGrid/constants";

import { ACTION_COLUMN_NAME } from "constants/aggrid";
import {
  CommentColId,
  CommentColumnDef,
  PenScanLotColumnDefinitions,
  PenScanLotColumnId,
} from "constants/columnDefinitions";
import { CommentTypes } from "constants/comments";

import { getters } from "lib/agGrid";
import { naturalSortStringComparator } from "lib/agGrid/columns/comparators";
import { booleanRenderer } from "lib/agGrid/renderers";
import { scanLotSaleLotLotNumbersRenderer } from "lib/agGrid/renderers/scanLotrenderers";

import {
  PenScanLotActionsColumnRenderer,
  PenScanLotRenderer,
} from "selectors/aggrid/penScanLots";

export const penScanLotColumns = [
  {
    colId: "status",
    enablePivot: true,
    enableRowGroup: true,
    field: "status",
    pinned: "left",
    cellRenderer: PenScanLotRenderer,
  },
  {
    colId: "sellingPen",
    enablePivot: true,
    enableRowGroup: true,
    field: "sellingPen",
    headerName: "Pen",
    width: 90,
    pinned: "left",
    comparator: naturalSortStringComparator,
  },
  {
    colId: "marks",
    enablePivot: true,
    enableRowGroup: true,
    field: "marks",
    valueGetter: getters.marksGetter,
  },
  {
    colId: "hd",
    field: "penScanLot.quantity",
    enablePivot: true,
    enableRowGroup: true,
  },
  {
    colId: "scanHd",
    field: "scanHd",
    headerName: "Scan Hd",
    width: 100,
    enablePivot: true,
    enableRowGroup: true,
  },
  {
    colId: "6e2291e5-cb71-42b2-b1bc-96bfc533f732",
    field: "deployment",
    headerName: "Agency",
    width: 100,
    enablePivot: true,
    enableRowGroup: true,
  },
  {
    colId: "fa51df95-cd2f-4893-82a9-69d10ba858f6",
    field: "round",
    headerName: "Round",
    width: 100,
    enablePivot: true,
    enableRowGroup: true,
  },
  {
    cellRenderer: booleanRenderer,
    colId: "isLocked",
    enablePivot: true,
    enableRowGroup: true,
    field: "penScanLot.isLocked",
    headerName: "Is Locked",
    width: 90,
  },
  PenScanLotColumnDefinitions[PenScanLotColumnId.CREATED],
  PenScanLotColumnDefinitions[PenScanLotColumnId.LAST_MODIFIED],
  {
    colId: "receivalLot",
    headerName: "Receival Lot",
    children: [
      {
        colId: "receivalLot.count",
        headerName: "Receival Lot Count",
        field: "receivalLot.count",
        width: 100,
      },
      {
        colId: "receivalLot.vendors",
        headerName: "Vendors",
        field: "receivalLot.vendors",
        width: 100,
      },
      {
        colId: "receivalLot.pens",
        headerName: "Pens",
        field: "receivalLot.pens",
        width: 100,
      },
      {
        colId: "receivalLot.marks",
        headerName: "Marks",
        field: "receivalLot.marks",
        width: 100,
      },
      {
        colId: "receivalLot.quantity",
        headerName: "Hd",
        field: "receivalLot.quantity",
        width: 100,
      },
      {
        colId: "receivalLot.scanHd",
        headerName: "Scan Hd",
        field: "receivalLot.scanHd",
        width: 100,
      },
    ],
  },
  {
    colId: "9a10d1e9-d973-48f9-90f5-25cd7ea7d365",
    headerName: "Warning",
    field: "rowWarning",
    type: ColumnType.STRING,
    width: 500,
  },
  {
    colId: "7e2525d6-cd6e-48b7-b21f-0147e3354ea8",
    headerName: "Sale Lots",
    field: "saleLots",
    cellRenderer: scanLotSaleLotLotNumbersRenderer,
    width: 100,
  },
  {
    headerName: ACTION_COLUMN_NAME,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: PenScanLotActionsColumnRenderer,
    colId: "actions",
    suppressCellFlash: true,
    width: 100,
    pinned: "right",
  },
  {
    ...CommentColumnDef[CommentColId.COMMENTS],
    pinned: "right",
    valueGetter: params => {
      if (!params.data) {
        return null;
      }

      const { unresolvedCommentCount, resolvedCommentCount, penScanLot } =
        params.data;

      return {
        commentType: CommentTypes.PEN_SCAN_LOT,
        commentTypeId: penScanLot.id,
        unresolvedCommentCount,
        resolvedCommentCount,
      };
    },
  },
];
