import React from "react";

import { faBrush as faBrushSolid } from "@fortawesome/pro-duotone-svg-icons";
import { faBrush as faBrushOutlined } from "@fortawesome/pro-light-svg-icons";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";

import { Chip } from "./BaseChip";

export const MarkChips = ({ marks, showNoMarks }) => {
  if (!Array.isArray(marks) || marks.length === 0) {
    return showNoMarks ? <Chip>No Marks</Chip> : null;
  }
  return marks.map(({ color, location }, index) => (
    <Chip key={`mark${index} `}>
      <span className="text-lg">{location}</span> &nbsp;
      <FaIcon icon={color ? faBrushSolid : faBrushOutlined} color={color} />
    </Chip>
  ));
};
