import React from "react";

import { faBalanceScaleRight } from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@material-ui/core";
import range from "lodash/range";

import { Input } from "components/Form/FormikControls";
import { Row } from "components/Layout";
import { Paper } from "components/Paper";
import { MediumText as Heading } from "components/Text";

import { Error, Icon } from "./components";

export const FatScoreSection = ({ errorMessage, maxScore, readOnly }) => (
  <Grid item xs={12}>
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Row flexGrow>
            <Icon icon={faBalanceScaleRight} color="gray44" />
            <Heading>Fat Score (hd)</Heading>
            {errorMessage && <Error>{errorMessage}</Error>}
          </Row>
        </Grid>
        {/* range in inclusive of the lower bound and exclusive of the upper bound, add 1 to compensate */}
        {range(1, maxScore + 1).map(i => (
          <Grid item xs key={i}>
            <Input
              label={`Score ${i}`}
              name={`draftingAttributes.fatScore${i}`}
              type="number"
              emptyValue={null}
              disabled={readOnly}
              missingMLAFieldsWarningEnabled
              overrideMissingFieldName="fatScore"
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  </Grid>
);
