import React, { useCallback, useMemo, useRef, useState } from "react";

import { faMobile } from "@fortawesome/free-solid-svg-icons";
import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { openDispatchingModal, patchSaleLot, screenResize } from "actions";

import AgGridTable from "components/AgGrid/AgGridContainer";
import {
  QuickCreateClearingSaleSaleLotPanel,
  QuickCreateLivestockSaleLotPanel,
  QuickEditSaleLotPanel,
} from "components/AgGrid/panels";
import { BulkUpdateMLASupplementaryDataModal } from "components/BulkUpdateOptionalFieldsModal/MLASupplementaryData";
import BulkUpdateClearingSaleSaleLotsModal from "components/BulkUpdateSaleLots";
import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { AgGridPanels, AgGridTables } from "constants/aggrid";
import { Column } from "constants/columns";
import { CommentTypes } from "constants/comments";
import { ApiModel } from "constants/loading";
import { ModalTypes, SaleLotModalSection } from "constants/navigation";
import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { saleLotStatuses, SaleTypes } from "constants/sale";
import { userTypes } from "constants/users";

import { roundAgGridNumber } from "lib";

import {
  getLivestockSaleId,
  getSaleRoute,
  getSaleyardName,
  openCommentsModal,
  openDeliverSaleLotModal,
  openEditConsignmentModal,
  openEditSaleLotModal,
  openInlineCreateSaleLotModal,
} from "lib/navigation";
import { pluralize } from "lib/pluralize";

import {
  currentSaleSelector,
  getActiveLivestockAgentDeployment,
  getCurrentSaleyard,
  getHasWriteAccessInCurrentSale,
  selectAreStudFeaturesEnabled,
  selectGeneralSaleLotsAggridData,
  selectIsUserOfType,
  selectRoleDeployments,
  selectVendorSplitByKeyLookup,
} from "selectors";

import { useBoolean, useDebounceSelector, useIsMobile, useTheme } from "hooks";
import {
  useHasPermission,
  useHasSaleyardOrDeploymentPermission,
  useHasSaleyardPermission,
  useSomeHasPermission,
} from "hooks/useHasPermission";

import { saleLotColumns } from "./columnDefs";
import SaleOverviewStatusBar from "./StatusBar";

const getRowId = params => params.data.id;

const statusBar = {
  statusPanels: [{ align: "left", statusPanel: SaleOverviewStatusBar }],
};

const getWeightRangeFromParams = params => {
  // Get the weight range from the first lot
  let weightRange = params.values[0] || {};
  // If the row is grouped - grab the weight range from the first of its children
  if (params.rowNode.group) {
    weightRange = params.rowNode.allLeafChildren[0]?.data?.weightRange || {};
  }
  return weightRange;
};

const onFilterChangedExtra = event => {
  const { api } = event;
  api.expireValueCache();
  api.refreshCells({
    columns: [
      Column.WEIGHT_OUTLIER,
      Column.SINGLE_WEIGH_SUM_OUTLIER,
      Column.AVERAGE_WEIGHT_OUTLIER,
    ],
  });
};

const onOpenDeliverSaleLotModal = saleLotId =>
  openDeliverSaleLotModal(saleLotId);

const onOpenEditConsignmentModal = consignmentId =>
  openEditConsignmentModal(consignmentId, undefined, true);

const averageCentKg = params => {
  // Don't show any value if grouped by sale round
  if (params.rowNode.field === Column.SALE_ROUND_NAME) {
    return "-";
  }
  const weightRange = getWeightRangeFromParams(params);
  if (Object.keys(weightRange).length) {
    const quantityTotal = params.rowNode.aggData?.quantity;
    const centsTotal = params.rowNode.aggData?.total_price_cents;
    const minWeightRange = parseFloat(weightRange.weightRangeMinKg) || 0;
    const maxWeightRange = parseFloat(weightRange.weightRangeMaxKg) || 0;
    if (minWeightRange === 0 || quantityTotal === 0) {
      return "-";
    }
    const value =
      centsTotal / (quantityTotal * ((minWeightRange + maxWeightRange) / 2));
    return Number.isNaN(value) ? "-" : roundAgGridNumber(value);
  } else {
    // IF there is no weight range we can't calculate this, return 0
    return "-";
  }
};

const lowCentKg = params => {
  // Don't show any value if grouped by sale round
  if (params.rowNode.field === Column.SALE_ROUND_NAME) {
    return "-";
  }
  const weightRange = getWeightRangeFromParams(params);
  if (Object.keys(weightRange).length) {
    const quantityTotal = params.rowNode.aggData?.quantity;
    const centsTotal = params.rowNode.aggData?.total_price_cents;
    const minWeightRange = parseFloat(weightRange.weightRangeMinKg) || 0;
    if (minWeightRange === 0 || quantityTotal === 0) {
      return "-";
    }
    const value = centsTotal / (quantityTotal * minWeightRange);
    return Number.isNaN(value) ? "-" : roundAgGridNumber(value);
  } else {
    // IF there is no weight range we can't calculate this, return 0
    return "-";
  }
};

const highCentKg = params => {
  // Don't show any value if grouped by sale round
  if (params.rowNode.field === Column.SALE_ROUND_NAME) {
    return "-";
  }
  const weightRange = getWeightRangeFromParams(params);
  if (Object.keys(weightRange).length) {
    const quantityTotal = params.rowNode.aggData?.quantity;
    const centsTotal = params.rowNode.aggData?.total_price_cents;

    const maxWeightRange = parseFloat(weightRange.weightRangeMaxKg) || 0;
    if (maxWeightRange === 0 || quantityTotal === 0) {
      return "-";
    }
    const value = centsTotal / (quantityTotal * maxWeightRange);
    return Number.isNaN(value) ? "-" : roundAgGridNumber(value);
  } else {
    // IF there is no weight range we can't calculate this, return 0
    return "-";
  }
};

const averageDollarHead = params => {
  const quantityTotal = params.rowNode.aggData?.quantity;
  if (!quantityTotal) {
    return "-";
  }
  const centsTotal = params.rowNode.aggData?.total_price_cents;
  return centsTotal / quantityTotal / 100;
};

const minNotZero = params => {
  const values = params.values.filter(Boolean);
  if (values.length > 0) {
    return Math.min(...params.values.filter(Boolean));
  } else {
    return 0;
  }
};

const aggFuncs = {
  averageCentKg,
  averageDollarHead,
  minNotZero,
  lowCentKg,
  highCentKg,
};

function SaleLotsTable() {
  const saleLots = useDebounceSelector(selectGeneralSaleLotsAggridData);
  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );
  const areStudFeaturesEnabled = useSelector(selectAreStudFeaturesEnabled);

  const arePenScanLotFeaturesEnabled = useHasSaleyardPermission(
    SaleyardPermissions.featurePenScanLots,
  );

  const sale = useSelector(currentSaleSelector);
  const theme = useTheme();

  const hasPenScanLotPermission = useHasPermission(
    getCurrentSaleyard,
    SaleyardPermissions.featurePenScanLots,
  );

  const allowQuickSell = useSomeHasPermission(
    selectRoleDeployments,
    DeploymentPermissions.featureQuickSell,
  );

  const allowLivestockQuickCreate =
    useHasPermission(
      getCurrentSaleyard,
      SaleyardPermissions.featureQuickCreateLivestock,
    ) && hasWriteAccessInCurrentSale;

  const readOnly = !hasWriteAccessInCurrentSale;

  const hasAutoIncrementPermission = useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.featureAutoIncrement,
    SaleyardPermissions.featureAutoIncrement,
  );

  const hasMLASupplementaryDataFeatrure = useHasSaleyardPermission(
    SaleyardPermissions.featureMLASupplementaryData,
  );

  const [editBulkEditClearingSaleOpen, setBulkEditClearingSaleOpen] =
    useState(false);
  const [selectedSaleLots, setSelectedSaleLots] = useState([]);

  const [
    isBulkUpdateMLASupplementaryShowModalOpen,
    openBulkUpdateMLASupplementaryModal,
    closeBulkUpdateMLASupplementaryModal,
  ] = useBoolean(false);

  const onCloseClearingSaleBulkEdit = () => {
    setBulkEditClearingSaleOpen(false);
  };

  // Use a ref for this, rather than state, as the callback that uses it gets "frozen" down in
  // aggrid, so the referenced state value is unchanged when we come back up here.
  const saleLotsForEditRef = useRef([]);

  const dispatch = useDispatch();

  const {
    date: saleDate,
    saleyard_name: saleyard,
    sale_type: saleType,
    species_id: speciesId,
  } = sale;

  const onOpenEditSaleLotModal = useCallback(
    saleLotId => {
      const saleLot = saleLots.find(saleLot => saleLot.id === saleLotId);
      openEditSaleLotModal(
        saleLotId,
        saleLot?.status === saleLotStatuses.SOLD
          ? SaleLotModalSection.SELLING
          : SaleLotModalSection.GENERAL,
      );
    },
    [saleLots],
  );

  const onClearingSaleBulkEdit =
    saleType === SaleTypes.CLEARING
      ? () => {
          saleLotsForEditRef.current = selectedSaleLots;
          setBulkEditClearingSaleOpen(true);
        }
      : null;

  const onOpenBulkUpdateSaleLotsModal = useCallback(() => {
    saleLotsForEditRef.current = selectedSaleLots;
    dispatch(
      openDispatchingModal(ModalTypes.BulkUpdateSaleLot, window.location.hash, {
        saleLotIds: saleLotsForEditRef.current,
      }),
    );
  }, [dispatch, selectedSaleLots]);

  const onOpenBulkUpdateMLASupplementaryDataModal = useCallback(() => {
    saleLotsForEditRef.current = selectedSaleLots;
    openBulkUpdateMLASupplementaryModal();
  }, [openBulkUpdateMLASupplementaryModal, selectedSaleLots]);

  const onUndeliverSaleLot = useCallback(
    saleLotId => {
      dispatch(
        patchSaleLot(
          { quantity_delivered: null, id: saleLotId },
          { actionText: "un-delivered" },
        ),
      );
    },
    [dispatch],
  );

  const onClickSaleLotComments = ({ id }) => {
    openCommentsModal(CommentTypes.SALE_LOT, id);
  };

  const columnDefs = useMemo(
    () =>
      saleLotColumns(
        saleType,
        areStudFeaturesEnabled,
        arePenScanLotFeaturesEnabled,
        speciesId,
      ),
    [saleType, areStudFeaturesEnabled, arePenScanLotFeaturesEnabled, speciesId],
  );

  const onRowSelectionChange = useCallback(rows => {
    setSelectedSaleLots(rows.map(r => r.id));
  }, []);

  const hasWriteAccess =
    useSelector(
      selectIsUserOfType([userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN]),
    ) && hasWriteAccessInCurrentSale;

  const showCurrentAgencyFilter = useSelector(
    selectIsUserOfType([userTypes.BUSINESS_USER, userTypes.SALEYARD_ADMIN]),
  );

  const deployment = useSelector(getActiveLivestockAgentDeployment);

  const vendorSplitByKeyLookup = useSelector(selectVendorSplitByKeyLookup);

  const context = useMemo(() => {
    const context = {
      deployment,
      showCurrentAgencyFilter,
      hasWriteAccess,
      handleEdit: onOpenEditSaleLotModal,
      handleEditConsignment: onOpenEditConsignmentModal,
      handleClickSaleLotComments: onClickSaleLotComments,
      vendorSplitByKeyLookup,
    };
    if (saleType !== SaleTypes.CLEARING && hasWriteAccess) {
      context.showDeliveryActions = true;
      context.handleDeliver = onOpenDeliverSaleLotModal;
      context.handleUndeliver = onUndeliverSaleLot;
    }
    return context;
  }, [
    deployment,
    saleType,
    hasWriteAccess,
    onOpenEditSaleLotModal,
    onUndeliverSaleLot,
    showCurrentAgencyFilter,
    vendorSplitByKeyLookup,
  ]);

  const tableName =
    saleType === SaleTypes.CLEARING
      ? AgGridTables.CLEARING_SALE_SALELOT
      : AgGridTables.SALE_LOT;

  const isMobile = useIsMobile();

  const panels = React.useMemo(() => {
    return [
      allowQuickSell &&
        saleType === SaleTypes.CLEARING && {
          id: AgGridPanels.QUICK_CREATE,
          labelDefault: "Quick Create",
          iconKey: "save",
          toolPanel: QuickCreateClearingSaleSaleLotPanel,
          width: !isMobile && 300,
        },
      allowQuickSell && {
        id: AgGridPanels.QUICK_EDIT,
        labelDefault: "Quick Sell",
        iconKey: "save",
        toolPanel: QuickEditSaleLotPanel,
        width: !isMobile && 300,
      },
      allowLivestockQuickCreate &&
        saleType !== SaleTypes.CLEARING && {
          id: AgGridPanels.QUICK_CREATE,
          labelDefault: "Quick Create",
          iconKey: "save",
          toolPanel: QuickCreateLivestockSaleLotPanel,
          width: !isMobile && 600,
        },
    ].filter(Boolean);
  }, [allowQuickSell, allowLivestockQuickCreate, saleType, isMobile]);

  const getContextMenuItems = useCallback(
    params => {
      const contextMenu = [...params.defaultItems];
      allowQuickSell &&
        saleType !== SaleTypes.CLEARING &&
        contextMenu.push({
          name: "Quick Sell",
          action: () => params.api.openToolPanel(AgGridPanels.QUICK_EDIT),
        });

      contextMenu.push({
        name: "Edit Sale Lot",
        action: () => openEditSaleLotModal(params.node.data.id),
      });

      return contextMenu;
    },
    [allowQuickSell, saleType],
  );

  const history = useHistory();

  const openCreateSaleLotModal = useCallback(() => {
    openInlineCreateSaleLotModal(
      {},
      {
        showDeliveryPen: false,
        showAuctionPenAtTop: true,
        includeCreateUnknownConsignment: true,
        autoPopulateNextPen: !!hasAutoIncrementPermission,
        includeStoredValues: true,
      },
    );
  }, [hasAutoIncrementPermission]);

  const additionalActions = useMemo(
    () =>
      saleType !== SaleTypes.CLEARING
        ? [
            {
              dataTour: "addPen",
              title: "Lot",
              isDisabled: readOnly,
              onClick: openCreateSaleLotModal,
              default: !readOnly,
              icon: faPlus,
            },
            {
              title: "Mobile View",
              icon: faMobile,
              onClick: () => {
                dispatch(screenResize(theme.breakpoints[2] - 1));
                history.push(
                  `${getSaleRoute(
                    getSaleyardName(),
                    getLivestockSaleId(),
                  )}/auction-pen-cards`,
                );
              },
              default: !!readOnly,
              dataTour: "mobileView",
            },
            {
              dataTour: "bulkUpdate",
              title: `Bulk Update (${selectedSaleLots.length}) ${pluralize("Lot", selectedSaleLots.length)}`,
              isDisabled: readOnly || selectedSaleLots.length === 0,
              onClick: () => onOpenBulkUpdateSaleLotsModal(),
              default: !readOnly,
              icon: faPencil,
            },
            hasMLASupplementaryDataFeatrure && {
              dataTour: "bulkUpdateMLA",
              title: `Bulk Update MLA Data For (${selectedSaleLots.length}) Lots`,
              isDisabled: selectedSaleLots.length === 0,
              onClick: onOpenBulkUpdateMLASupplementaryDataModal,
              default: !readOnly,
              icon: faPencil,
            },
          ].filter(Boolean)
        : [],
    [
      saleType,
      readOnly,
      openCreateSaleLotModal,
      selectedSaleLots.length,
      onOpenBulkUpdateSaleLotsModal,
      hasMLASupplementaryDataFeatrure,
      onOpenBulkUpdateMLASupplementaryDataModal,
      dispatch,
      theme.breakpoints,
      history,
    ],
  );

  // Highlight rows that sale lot hd count does not equal the sale lot scans count
  const rowClassRules = useMemo(
    () => ({
      "row-warning": params => {
        const { api, node } = params;
        const saleLotQuantity = api.getValue(Column.QUANTITY, node);
        const saleLotScansCount = api.getValue(Column.SCANNED_COUNT, node);
        return hasPenScanLotPermission && saleLotQuantity !== saleLotScansCount;
      },
    }),
    [hasPenScanLotPermission],
  );

  return (
    <>
      <AgGridTable
        downloadFilename={`${saleDate}-${saleyard}-SaleLotReport.csv`}
        columnDefs={columnDefs}
        aggFuncs={aggFuncs}
        rowData={saleLots}
        tableName={tableName}
        context={context}
        rowSelection="multiple"
        onRowSelectionChanged={onRowSelectionChange}
        editSelected={onClearingSaleBulkEdit}
        getRowId={getRowId}
        onFilterChangedExtra={onFilterChangedExtra}
        getContextMenuItems={getContextMenuItems}
        panels={panels}
        statusBar={statusBar}
        additionalActions={additionalActions}
        rowClassRules={rowClassRules}
      />
      {editBulkEditClearingSaleOpen && (
        <BulkUpdateClearingSaleSaleLotsModal
          saleLotIds={saleLotsForEditRef.current}
          onClose={onCloseClearingSaleBulkEdit}
        />
      )}
      {isBulkUpdateMLASupplementaryShowModalOpen && (
        <BulkUpdateMLASupplementaryDataModal
          saleLotIds={saleLotsForEditRef.current}
          onClose={closeBulkUpdateMLASupplementaryModal}
        />
      )}
    </>
  );
}

const LoadingWrapper = props => (
  <WaitForSync
    requiredData={[
      ApiModel.SALE_LOTS,
      ApiModel.CONSIGNMENTS,
      ApiModel.DEPLOYMENTS,
      ApiModel.SALEYARDS,
      ApiModel.ROUNDS,
      ApiModel.SPECIES,
    ]}
    subjectName="sale lots"
  >
    <SaleLotsTable {...props} />
  </WaitForSync>
);

export default LoadingWrapper;
