import isNil from "lodash/isNil";
import styled from "styled-components/macro";

import Card from "components/Card";
import { SmHeading } from "components/Text";

import { isNumeric } from "lib";

export const CardItemTitle = styled(SmHeading)`
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  padding-top: 3px;
  padding-left: 5px;
`;

export const ExpandedCardStack = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.space[4]}px;
  margin-left: ${props => props.theme.space[3]}px;
`;

export const CompactCardStack = styled(Card)`
  padding: 0px;
`;

export function formatCardValue(value) {
  if (isNil(value)) {
    return "-";
  }
  if (typeof value === "number") {
    return parseInt(value, 10).toLocaleString();
  }
  return value;
}

export function createCardFromSummaryData(summaryData, cardTitles) {
  return cardTitles.map(title => {
    const currentCardData = { title: title.replace(/_/g, " ") };
    const rawData = summaryData[title];

    if (isNumeric(rawData)) {
      currentCardData.value = rawData;
    } else if (Array.isArray(rawData)) {
      // TODO - this can be Null.  Why?
      currentCardData.value = rawData.reduce(
        (sum, item) => sum + item.count,
        0,
      );
      currentCardData.additionalInfo = rawData.map(({ round_name, count }) => ({
        title: round_name,
        value: count,
      }));
    } else {
      currentCardData.value = null;
    }

    return currentCardData;
  });
}
