import { difference, sortBy } from "lodash";
import flatten from "lodash/flatten";
import { createSelector } from "reselect";

import { ValueSource } from "constants/ruleBooks";

import {
  getLedgerAccounts,
  getMasterLedgerAccounts,
  getRules,
} from "selectors/root";

export const getLedgerAccountById = id => state =>
  getLedgerAccounts(state)[id] || null;

export const selectMasterLedgerAccountOptionsForRuleBuilder = createSelector(
  [getMasterLedgerAccounts],
  masterLedgerAccountsLookup => {
    const options = Object.values(masterLedgerAccountsLookup).map(
      ledgerAccount => ({
        value: ledgerAccount.code,
        label: `${ledgerAccount.code} (${ledgerAccount.name})`,
      }),
    );
    return sortBy(options, "label");
  },
);

export const selectMasterLedgerAccountOptions = createSelector(
  [getMasterLedgerAccounts, getLedgerAccounts],
  (masterLedgerAccounts, ledgerAccounts) => {
    const ledgerAccountsByMasterLedgerAccountIdLookup = Object.values(
      ledgerAccounts,
    ).reduce((acc, ledgerAccount) => {
      ledgerAccount.masterLedgerAccountIds.forEach(masterLedgerAccountId => {
        if (acc[masterLedgerAccountId]) {
          acc[masterLedgerAccountId].push(ledgerAccount);
        } else {
          acc[masterLedgerAccountId] = [ledgerAccount];
        }
      });
      return acc;
    }, {});

    return Object.values(masterLedgerAccounts).map(masterAccount => {
      let label = `${masterAccount.code} ${masterAccount.name}`;
      const usedIn =
        ledgerAccountsByMasterLedgerAccountIdLookup[masterAccount.id]?.map(
          account => account.code,
        ) || [];
      if (usedIn.length > 0) {
        label += ` -> ${usedIn.join(", ")}`;
      }

      return {
        label,
        value: masterAccount.id,
        isDisabled: usedIn.length > 0,
      };
    });
  },
);

export const selectUnmappedMasterLedgerAccountIds = createSelector(
  [getLedgerAccounts, getMasterLedgerAccounts],
  (ledgerAccounts, masterLedgerAccounts) => {
    const mapped = flatten(
      Object.values(ledgerAccounts).map(
        ledgerAccount => ledgerAccount.masterLedgerAccountIds,
      ),
    );
    return difference(Object.keys(masterLedgerAccounts), mapped);
  },
);

const selectMyLedgerAccountOptionsForRuleBuilder = createSelector(
  [getLedgerAccounts, getMasterLedgerAccounts],
  (ledgerAccounts, masterLedgerAccounts) => {
    return Object.values(ledgerAccounts).reduce((acc, ledgerAccount) => {
      const myLabel = `${ledgerAccount.code} (${ledgerAccount.name})`;

      ledgerAccount.masterLedgerAccountIds.forEach(masterLedgerAccountId => {
        const masterCode = masterLedgerAccounts[masterLedgerAccountId].code;
        acc.push({
          label: `${masterCode} -> ${myLabel}`,
          value: masterCode,
        });
      });

      acc.push({
        label: myLabel,
        value: ledgerAccount.code,
      });
      return acc;
    }, []);
  },
);

export const selectLedgerAccountByCodeLookup = createSelector(
  [getLedgerAccounts],
  ledgerAccountsLookup => {
    return Object.values(ledgerAccountsLookup).reduce((acc, cur) => {
      acc[cur.code] = cur;
      return acc;
    }, {});
  },
);

export const getLedgerAccountByCode = code => state =>
  selectLedgerAccountByCodeLookup(state)[code];

export const selectMyLedgerAccountOptionsForLedgerEntryForm = createSelector(
  [getLedgerAccounts],
  ledgerAccounts =>
    Object.values(ledgerAccounts).map(ledgerAccount => ({
      label: `${ledgerAccount.code} (${ledgerAccount.name})`,
      value: ledgerAccount.code,
    })),
);

const selectUnMappedMasterLedgerAccountOptions = createSelector(
  [getLedgerAccounts, getMasterLedgerAccounts],
  (ledgerAccounts, masterLedgerAccounts) => {
    const mappedMasterLedgerAccounts = flatten(
      Object.values(ledgerAccounts).map(
        ledgerAccount => ledgerAccount.masterLedgerAccountIds,
      ),
    );

    const unlinkedMasterLedgerAccounts = Object.values(
      masterLedgerAccounts,
    ).filter(
      masterLedgerAccount =>
        !mappedMasterLedgerAccounts.includes(masterLedgerAccount.id),
    );

    return unlinkedMasterLedgerAccounts.map(masterLedgerAccount => ({
      label: `${masterLedgerAccount.code} (${masterLedgerAccount.name})`,
      value: masterLedgerAccount.code,
    }));
  },
);

// Find any codes referenced in rules that we haven't seen.
// Just in case the account hasn't been created, make sure that all used codes are available as options.
const getImplicitAccountsOptions = (rules, explicitAccountCodes) => {
  return Object.values(rules).reduce((acc, rule) => {
    if (
      rule.gl_code &&
      rule.gl_code?.source === ValueSource.CONSTANT &&
      rule.gl_code.value &&
      !acc.includes(rule.gl_code.value) &&
      !explicitAccountCodes.includes(rule.gl_code.value)
    ) {
      const glCode = rule.gl_code.value;
      acc.push({
        label: `${glCode} (Missing Ledger Account)`,
        value: glCode,
      });
    }
    return acc;
  }, []);
};

export const selectLedgerAccountOptionsForRuleBuilder = createSelector(
  [
    getRules,
    selectMyLedgerAccountOptionsForRuleBuilder,
    selectUnMappedMasterLedgerAccountOptions,
  ],
  (rules, myLedgerAccountOptions, masterLedgerAccountOptions) => {
    const explicitLedgerAccountOptions = myLedgerAccountOptions.concat(
      masterLedgerAccountOptions,
    );

    const explicitAccountCodes = explicitLedgerAccountOptions.map(
      option => option.value,
    );

    const implicitAccountsOptions = getImplicitAccountsOptions(
      rules,
      explicitAccountCodes,
    );

    return sortBy(
      explicitLedgerAccountOptions.concat(implicitAccountsOptions),
      "label",
    );
  },
);

export const selectLedgerAccountOptionsForLedgerEntryForm = createSelector(
  [
    getRules,
    selectMyLedgerAccountOptionsForLedgerEntryForm,
    selectUnMappedMasterLedgerAccountOptions,
  ],
  (rules, myLedgerAccountOptions, masterLedgerAccountOptions) => {
    const explicitLedgerAccountOptions = myLedgerAccountOptions.concat(
      masterLedgerAccountOptions,
    );

    const explicitAccountCodes = explicitLedgerAccountOptions.map(
      option => option.value,
    );

    const implicitAccountsOptions = getImplicitAccountsOptions(
      rules,
      explicitAccountCodes,
    );

    return sortBy(
      explicitLedgerAccountOptions.concat(implicitAccountsOptions),
      "label",
    );
  },
);

export const selectMappedLedgerAccountByCodeLookup = createSelector(
  [getLedgerAccounts, getMasterLedgerAccounts],
  (ledgerAccountLookup, masterLedgerAccountLookup) => {
    const lookup = {};

    Object.values(ledgerAccountLookup).forEach(ledgerAccount => {
      lookup[ledgerAccount.code] = ledgerAccount;
      ledgerAccount.masterLedgerAccountIds.forEach(masterLedgerAccountId => {
        lookup[masterLedgerAccountLookup[masterLedgerAccountId].code] =
          ledgerAccount;
      });
    });
    return lookup;
  },
);
