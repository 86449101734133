import React from "react";

import { faCheckCircle, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isNumber from "lodash/isNumber";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import Clickable from "components/Button/Clickable";
import { Column } from "components/Layout";
import ProgressBar from "components/ProgressBar";

const Wrapper = styled(Clickable)`
  width: 60px;
  position: relative;
  flex-shrink: 0;
  margin: ${({ isMobile }) => (isMobile ? "6px" : "0 12px 18px 12px")};
`;

const SelectedBar = styled.div`
  position: absolute;
  ${({ isMobile }) => {
    if (isMobile) {
      return `height: 4px;
              bottom: -6px;
              left: 0;
              right: 0;`;
    } else {
      return `left: -12px;
              top: 0;
              bottom: 0;
              width: 4px;`;
    }
  }};
  background-color: ${({ theme }) => theme.colors.primary};
  z-index: 9;
  opacity: ${({ selected }) => (selected ? 1 : 0)};
  transition: opacity 200ms ease-in-out;
`;

const FaIcon = styled(FontAwesomeIcon)`
  font-size: 36px;
  color: #c8c8c8;
  margin: 12px auto;
`;

const ImageWrapper = styled.div`
  width: 60px;
  height: 60px;
  overflow-y: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const CompleteMark = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.success};
  font-size: 24px;
  position: absolute;
  bottom: 0;
  right: -6px;
`;

const EnvdOverlay = styled.div(
  ({ theme }) => ` 
  color: ${theme.colors.lightCoral};
  font-size: ${theme.fontSizes.gamma}px;
  font-weight:   ${theme.fontWeights.bold};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  `,
);

const AttachmentThumb = ({
  id = undefined,
  thumbUrl = "",
  progress = undefined,
  selected = false,
  setSelected = null,
  isComplete = false,
  isMobile = false,
  isEnvd = false,
}) => {
  const progressPercent = progress ? progress * 100 : 0;

  let progressBarOpacity = 0;
  if (!thumbUrl && (isNumber(progressPercent) || progressPercent === 100)) {
    progressBarOpacity = 1;
  }

  let imageOrIcon = <FaIcon icon={faFileAlt} />;
  if (thumbUrl) {
    imageOrIcon = (
      <ImageWrapper>
        <Image src={thumbUrl} alt="Attachment thumbnail" />
      </ImageWrapper>
    );
  }

  return (
    <Wrapper isMobile={isMobile} onClick={() => setSelected(id)}>
      <Column>
        {isComplete && <CompleteMark icon={faCheckCircle} />}
        {isEnvd && <EnvdOverlay>eNVD</EnvdOverlay>}
        <SelectedBar selected={selected} isMobile={isMobile} />

        {imageOrIcon}
        <ProgressBar
          progress={progressPercent}
          style={{ height: 5, maxWidth: "100%", opacity: progressBarOpacity }}
          color="primary"
        />
      </Column>
    </Wrapper>
  );
};

AttachmentThumb.propTypes = {
  id: PropTypes.number,
  thumbUrl: PropTypes.string,
  progress: PropTypes.number,
  selected: PropTypes.bool,
  setSelected: PropTypes.func,
  isComplete: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default AttachmentThumb;
