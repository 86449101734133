import React from "react";

import { useSelector } from "react-redux";

import { SaleyardPermissions } from "constants/permissions";
import { MissingFieldsIndicatorTypes } from "constants/saleLots";

import { selectMLAFieldWarningsBySaleLotId } from "selectors";

import { useHasSaleyardPermission, useGetMissingFieldWarnings } from "hooks";
import { MissingFieldsIndicatorBase } from "./MissingFieldsIndicator";

const generalMLAFields = [
  "Age Id",
  "Sex Id",
  "Breed Id",
  "Dressing Range Id",
  "Product Id",
];

const filterFields = (
  fields: string[],
  onlyGeneral: boolean,
  onlyAdvanced: boolean,
) => {
  if (onlyGeneral) {
    return fields.filter(field => generalMLAFields.includes(field));
  }
  if (onlyAdvanced) {
    return fields.filter(field => !generalMLAFields.includes(field));
  }
  return fields;
};

// For use in non formik locations where you just want to show all MLA warnings e.g. on lot cards
// Use CombinedFormikMissingFieldIndicator if you want to show the warnigns on a formik input.
export const MLAMissingFieldsIndicator: React.FC<{
  keyValue?: string | string[];
  onlyGeneralFields?: boolean;
  onlyAdvancedFields?: boolean;
}> = ({
  keyValue = null,
  onlyGeneralFields = false,
  onlyAdvancedFields = false,
}) => {
  const hasMlaPermission = useHasSaleyardPermission(
    SaleyardPermissions.featureMLASupplementaryData,
  );
  const missingMLAFieldsLookup = useSelector(selectMLAFieldWarningsBySaleLotId);

  const filteredMissingFields = Object.entries(missingMLAFieldsLookup).reduce(
    (acc: Record<string, string[]>, [key, value]: [string, string[]]) => {
      const filteredFields = filterFields(
        value,
        onlyGeneralFields,
        onlyAdvancedFields,
      );
      if (filteredFields.length > 0) {
        acc[key] = filteredFields;
      }
      return acc;
    },
    {},
  );

  const keyValues = Array.isArray(keyValue) ? keyValue : [keyValue];
  const warnings = useGetMissingFieldWarnings({
    modelIds: keyValues,
    lookup: filteredMissingFields,
  });

  if (!hasMlaPermission) {
    return null;
  }

  return (
    <MissingFieldsIndicatorBase
      label={MissingFieldsIndicatorTypes.MLA}
      warnings={warnings}
    />
  );
};
