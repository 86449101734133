import React from "react";

import { useSelector } from "react-redux";

import { PenTypes } from "constants/auctionPens";
import { Settings } from "constants/settings";

import { sortPenByOrder } from "lib/auctionPens";

import {
  getAuctionPens,
  getPenIdsByRoundId,
  getSetting,
  selectFilteredAuctionPenIds,
  selectSaleLotIdsByAuctionPenIdLookup,
} from "selectors";

export const useGetVisibleAndTempAuctionPenIds = extraFilteredPenIds => {
  const saleLotIdsByAuctionPenId = useSelector(
    selectSaleLotIdsByAuctionPenIdLookup,
  );

  const globalFilteredAuctionPenIds = useSelector(selectFilteredAuctionPenIds);

  const selectedRoundId = useSelector(getSetting(Settings.round));

  const auctionPenIds = useSelector(getPenIdsByRoundId(selectedRoundId));

  const auctionPens = useSelector(getAuctionPens);

  const visibleAndTempAuctionPenIds = React.useMemo(() => {
    const tempPensIds = [];

    // Apply filters to the full set - we then get a list of ids that should be included.
    const unsortedAuctionPens = auctionPenIds
      .filter(
        // That have sale lots.
        auctionPenId =>
          saleLotIdsByAuctionPenId[auctionPenId]?.length > 0 &&
          // Global filter
          globalFilteredAuctionPenIds.includes(auctionPenId) &&
          // Satisfies search criteria
          (extraFilteredPenIds === null ||
            extraFilteredPenIds.has(auctionPenId)),
      )
      // Map to the object for sorting.
      .map(auctionPenId => auctionPens[auctionPenId]);
    const unsortedAuctionPensWithRemovedTempPens = unsortedAuctionPens.filter(
      pen => {
        if (pen.penType === PenTypes.TEMP) {
          tempPensIds.push(pen.id);
          return false;
        } else {
          return true;
        }
      },
    );
    return [
      sortPenByOrder(unsortedAuctionPensWithRemovedTempPens).map(
        auctionPen => auctionPen.id,
      ),
      tempPensIds,
    ];
  }, [
    auctionPenIds,
    saleLotIdsByAuctionPenId,
    extraFilteredPenIds,
    auctionPens,
    globalFilteredAuctionPenIds,
  ]);

  return visibleAndTempAuctionPenIds;
};
