export const checkboxFieldNameSuffix = "_includeInChangeSet";

export function getCheckboxFieldName(fieldName: string): string {
  // Stop the checkbox getting namespaced with the . so we can *always* reference it on the root.
  return `${fieldName.replace(".", "__")}${checkboxFieldNameSuffix}`;
}

export const CollapseTitle = {
  GENERAL: "General",
  ADVANCED_DRAFTING: "Advanced Drafting",
  DRAFTING_ATTRIBUTES: "Drafting Attributes",
  BUYER: "Buyer",
  SHEEP_ONLY: "Sheep Only",
};
