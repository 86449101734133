import { UpdateInformationProps } from "components/BulkUpdateOptionalFieldsModal/types";
import React from "react";
import isEqual from "lodash/isEqual";
import { UpdateInformationWrapper } from "components/BulkUpdateOptionalFieldsModal/UpdateInformationComponents";
import { getMLASupplementaryData } from "selectors";
import { useSelector } from "react-redux";
import { getIn, useFormikContext } from "formik";
import { getCheckboxFieldName } from "components/BulkUpdateOptionalFieldsModal/util";
import { pluralize } from "lib/pluralize";
import {
  EXCLUDE_FROM_UPDATE_COUNT,
  resolveValue,
} from "lib/mlaSupplementaryData";

// This bulk update modal is a bit different - it updates MLA Supplementary data, but
// references underlying sale lot data.
// Hence - the messaging is a little different to the bulk update lots - it will just tell you
// how many values are going to be different after the change - this is likely more meaningful to a user than
// something more complex involving explaining the two data sets.

// if the user has a selected value.
//   Compare those values to the selected value.
//   Display how many are different to that value.
// else
//   Display how many different values are within the dataset.

// Null/empty is valid - this CLEARS the MLA data.

export function UpdateInformation(
  props: UpdateInformationProps,
): React.JSX.Element | null {
  const { field, modelIds } = props;
  const { fieldName } = field;
  const { values } = useFormikContext();

  const selectedValue = getIn(values, fieldName) || field.emptyValue;

  const isSelected = getIn(values, getCheckboxFieldName(fieldName));

  // Go through and find a resolved value for each entry.
  const mlaSupplementaryDatas = useSelector(getMLASupplementaryData) as Record<
    string,
    MLASupplementaryData
  >;

  const resolvedValues = modelIds.map(modelId =>
    resolveValue(
      mlaSupplementaryDatas[modelId],
      field.fieldName,
      field.emptyValue,
    ),
  );
  const howManyDifferent = resolvedValues.filter(
    value =>
      value !== EXCLUDE_FROM_UPDATE_COUNT && !isEqual(selectedValue, value),
  ).length;

  if (isSelected) {
    return (
      <UpdateInformationWrapper>
        This will apply the value on {howManyDifferent}{" "}
        {pluralize("Sale Lot", howManyDifferent)}.
      </UpdateInformationWrapper>
    );
  }
  if (resolvedValues.length > 1) {
    return (
      <UpdateInformationWrapper>
        {resolvedValues.length} different values in {modelIds.length}{" "}
        {pluralize("Sale Lot", modelIds.length)}
      </UpdateInformationWrapper>
    );
  }
  return (
    <UpdateInformationWrapper>
      All Sale Lots share the same value.
    </UpdateInformationWrapper>
  );
}
