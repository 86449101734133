import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { EMPTY_OBJECT } from "lib";

const Bar = styled.div`
  background-color: ${({ theme }) => theme.colors.gray95};
  height: 20px;
  width: 350px;
`;

const Filler = styled.div`
  height: 100%;
  transition: width 0.2s ease-in;
  ${({ theme, progress, color }) => `
    background-color: ${theme.colors[color]};
    width: ${progress}%;
  `};
`;

const ProgressBar = ({
  progress = 0,
  style = EMPTY_OBJECT,
  color = "success",
}) => {
  let fixedProgress = progress;

  if (fixedProgress <= 0) {
    fixedProgress = 0;
  } else if (fixedProgress >= 100) {
    fixedProgress = 100;
  } else {
    fixedProgress = progress;
  }
  return (
    <Bar style={style}>
      <Filler progress={fixedProgress} color={color} />
    </Bar>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  style: PropTypes.object,
  color: PropTypes.string,
};

export default ProgressBar;
