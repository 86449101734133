import React from "react";
import { useFieldValue, useGetMissingFieldWarnings } from "hooks";
import { useSelector } from "react-redux";
import {
  selectMLAFieldWarningsBySaleLotId,
  selectNASFieldWarningsBySaleLotId,
} from "selectors";
import { MissingFieldsIndicatorTypes } from "constants/saleLots";
import { startCase } from "lodash";
import { CombinedFormikMissingFieldIndicatorProps } from "./types";
import { MissingFieldsIndicatorBase } from "./MissingFieldsIndicator";

export const CombinedFormikMissingFieldIndicator: React.FC<
  CombinedFormikMissingFieldIndicatorProps
> = ({
  modelIds,
  missingMLAFieldsWarningEnabled,
  missingNASFieldWarningEnabled,
  overrideMissingFieldName,
  name,
  hideLabel = false,
}) => {
  const id = useFieldValue("id");

  const calculatedModelIds = modelIds.length > 0 ? modelIds : [id];

  const missingMLAFieldLookup = useSelector(selectMLAFieldWarningsBySaleLotId);
  const missingNASFieldsLookup = useSelector(selectNASFieldWarningsBySaleLotId);

  const missingMLAFieldWarnings = useGetMissingFieldWarnings({
    modelIds: calculatedModelIds,
    lookup: missingMLAFieldLookup,
  });

  const missingNASFieldWarnings = useGetMissingFieldWarnings({
    modelIds: calculatedModelIds,
    lookup: missingNASFieldsLookup,
  });

  return (
    <>
      {missingMLAFieldsWarningEnabled && (
        <MissingFieldsIndicatorBase
          warnings={missingMLAFieldWarnings}
          label={MissingFieldsIndicatorTypes.MLA}
          includeLabel={!hideLabel}
          overrideNoMissingField={
            !missingMLAFieldWarnings.includes(
              startCase(overrideMissingFieldName || name),
            )
          }
        />
      )}
      {missingNASFieldWarningEnabled && (
        <MissingFieldsIndicatorBase
          warnings={missingNASFieldWarnings}
          label={MissingFieldsIndicatorTypes.NAS}
          includeLabel={!hideLabel}
          overrideNoMissingField={
            !missingNASFieldWarnings.includes(startCase(name))
          }
        />
      )}
    </>
  );
};
