const containsString = (searchable, searchText) =>
  String(searchable).toLowerCase().includes(String(searchText.toLowerCase()));

export const equalsString = (searchable, searchText) =>
  String(searchable).toLowerCase() === String(searchText.toLowerCase());

const nestedObjectCompare = (
  object,
  searchText,
  { keys, nested },
  comparisonFn = containsString,
) => {
  if (keys.some(key => comparisonFn(object[key], searchText))) {
    // Don't continue  traversing the whole tree if we already have a positive result
    return true;
  }

  if (nested) {
    const nestedItem = object[nested.name];
    if (typeof nestedItem === "object") {
      if (Array.isArray(nestedItem)) {
        // handle nested item is an Array
        return nestedItem.some(object =>
          nestedObjectCompare(object, searchText, nested, comparisonFn),
        );
      } else if (nestedItem !== null) {
        // handle nested item is a hashmap (instanceof Object)
        return nestedObjectCompare(
          nestedItem,
          searchText,
          nested,
          comparisonFn,
        );
      }
    }
  }
  return false;
};

/**
 * Filters an array of objects by searching for specified keys at specified depths
 * returns unfiltered objects input if invalid search is passed in
 *
 * @param {array} objects Array of object to filter by
 * @param {(string|number)} searchText
 * @param {object} fields Object specifying keys to search for at each level
 * @param {function} comparisonFunction Optional comparison function
 *
 * Example of valid fields object:
 * const FIELDS = {
 *   keys: ["vendorName", "receivingPen"],
 *     nested: {
 *       name: "consignments",
 *       keys: ["receivingReference"],
 *       nested: {
 *         name: "saleLots",
 *         keys: ["startPen", "endPen", "destinationProperty"],
 *       },
 *    },
 * };
 */
export const filterNestedArrayOfObjects = (
  objects,
  searchText,
  fields,
  comparisonFunction = undefined,
) => {
  try {
    return objects.filter(object =>
      nestedObjectCompare(object, searchText, fields, comparisonFunction),
    );
  } catch (e) {
    if (process.env.NODE_ENV !== "test") {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    return objects;
  }
};
