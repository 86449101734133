import React from "react";

import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

import { Label } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";
import { CombinedFormikMissingFieldIndicator } from "components/MissingFieldsIndicator";
import { BoldText } from "components/Text";

import { EMPTY_ARRAY } from "lib";

import { getCurrentSaleSubType, getWeightRangeBySaleRoundId } from "selectors";

const { OptionTogglerField } = require("components/Form/FormikControls");

export const EstAvgWeightRangeField = ({
  name,
  disabled,
  showNeedsRoundMessage = false,
  showNoValuesMessage = false,
  missingRoundMessage = "Select a Sale Round to see available Est Avg Weights.",
  modelIds = EMPTY_ARRAY,
}) => {
  const formik = useFormikContext();
  const { setFieldValue, values } = formik;

  const { sale_round_id: saleRoundId } = values;

  const weightRanges = useSelector(getWeightRangeBySaleRoundId(saleRoundId));

  const saleSubTypeId = useSelector(getCurrentSaleSubType)?.id;

  const mappedWeightRanges = weightRanges.map(range => {
    return {
      label: range.shortCode,
      value: range.id,
    };
  });

  const weightRangeToolTip = (
    <div>
      {weightRanges.map(range => {
        let toolTipText = `${range.shortCode} - ${range.details} - ${range.weightRangeMinKg}- ${range.weightRangeMaxKg}Kg`;
        if (!range.weightRangeMaxKg) {
          toolTipText = `${range.shortCode} - ${range.details} - ${range.weightRangeMinKg}+ Kg`;
        }
        return (
          <BoldText>
            {toolTipText}
            <br />
          </BoldText>
        );
      })}
    </div>
  );

  const currentWeightRange = weightRanges.find(
    range => range.id === values?.estimatedAverageWeightId,
  );

  const currentWeightRangeMatchesRound =
    currentWeightRange?.saleRoundId === values.sale_round_id;

  const currentWeightRangeMatchesSubType =
    currentWeightRange?.saleSubType === saleSubTypeId;

  React.useEffect(() => {
    if (!currentWeightRangeMatchesRound) {
      setFieldValue("estimatedAverageWeightId", null);
    }
    if (!currentWeightRangeMatchesSubType) {
      setFieldValue("estimatedAverageWeightId", null);
    }
  }, [
    setFieldValue,
    currentWeightRangeMatchesRound,
    currentWeightRangeMatchesSubType,
  ]);

  if (mappedWeightRanges.length > 0) {
    return (
      <OptionTogglerField
        labelPosition="top"
        name={name}
        label="Est Avg Weight (+/- 2Kg)"
        options={mappedWeightRanges}
        disabled={disabled}
        tooltip={weightRangeToolTip}
        showMissingFieldWarnings
        missingNASFieldWarningEnabled
        modelIds={modelIds}
        overrideMissingFieldName="Weight Range"
      />
    );
  } else if (
    (mappedWeightRanges.length === 0 && showNoValuesMessage) ||
    (showNeedsRoundMessage && !saleRoundId)
  ) {
    return (
      <Column>
        <Row justifyBetween>
          <Label>Est Avg Weight (+/- 2Kg)</Label>
          <CombinedFormikMissingFieldIndicator
            missingMLAFieldsWarningEnabled={false}
            missingNASFieldWarningEnabled
            overrideMissingFieldName="Weight Range"
            name={name}
            modelIds={modelIds}
          />
        </Row>
        <div>
          {showNeedsRoundMessage && !saleRoundId
            ? missingRoundMessage
            : "No Weight Range Options are configured."}
        </div>
      </Column>
    );
  } else {
    return null;
  }
};
