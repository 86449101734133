export enum ActionPriority {
  // Reserved for actions that need to be done before other offline actions will work (e.g. refresh JWT, log out)
  HIGH = 30,

  // Used for actions that modify data - e.g. create, update, delete. Make sure they are done before getting new data.
  // A fallback value for actions that haven't specified their priority. Safer to do before updating.
  // Used for retrieving core data and other actions that will affect the user experience affecting. i.e. think 'Loading screen'
  MEDIUM = 20,

  // Used for Changes Since and other background updates that should not affect the user experience.
  LOW = 10,
}
