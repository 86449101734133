import React, { useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import { updateFileRoundMapping } from "actions";

import {
  AutoCompleteV2,
  contextOptionCellEditorParamsGetter,
  contextOptionFormatter,
} from "components/AgGrid/AutoComplete";
import { BaseTable } from "components/Importer/BaseTable";

import { toSelectOption } from "lib";

import { valueExistsRenderer } from "lib/agGrid/renderers";

import { getCurrentRoundsList, getRounds } from "selectors";

import { getRowId, onCellEditRequestFactory } from "./genericImport";

const ColumnId = {
  FileRoundId: "0771171c-519d-4103-97d1-377a8d061715",
  FileRoundIsMapped: "61832dae-03b4-4cb3-99ec-f6ef7c775d6f",
};

export const RoundColumns = [
  {
    headerName: "Round in File",
    children: [
      {
        colId: ColumnId.FileRoundIsMapped,
        headerName: "Is Mapped",
        field: "fileData.roundId",
        cellRenderer: valueExistsRenderer,
        sortable: true,
      },
      {
        headerName: "Round Name",
        field: "fileData.roundName",
        sortable: true,
      },
    ],
  },
  {
    headerName: "Round in AgriNous",
    children: [
      {
        cellEditor: AutoCompleteV2,
        cellEditorParams: contextOptionCellEditorParamsGetter("rounds"),
        colId: ColumnId.FileRoundId,
        editable: true,
        field: "fileData.roundId",
        headerName: "Name",
        valueFormatter: contextOptionFormatter("rounds"),
      },
    ],
  },
];

const initialRoundTableColumnState = {
  state: [{ colId: ColumnId.FileRoundIsMapped, sort: "asc" }],
  applyOrder: true,
};

function onRoundGridReady(params) {
  const { columnApi } = params;
  columnApi.applyColumnState(initialRoundTableColumnState);
}

export function MapRoundTable({ importName, type }) {
  const rounds = useSelector(state => state.importers?.[importName].rounds);
  const currentRounds = useSelector(getCurrentRoundsList);
  const roundByIdLookup = useSelector(getRounds);
  const dispatch = useDispatch();
  const context = {
    rounds: currentRounds.map(roundId =>
      toSelectOption(roundByIdLookup[roundId]),
    ),
  };

  const data = useMemo(
    () =>
      rounds.map(fileData => ({
        fileData,
        agrinousRound: roundByIdLookup[fileData.roundId],
      })),
    [rounds, roundByIdLookup],
  );
  return (
    <BaseTable
      columns={RoundColumns}
      data={data}
      context={context}
      getRowId={getRowId}
      immutableData
      onCellEditRequest={onCellEditRequestFactory(
        ColumnId.FileRoundId,
        updateFileRoundMapping,
        "roundId",
        dispatch,
        type,
      )}
      readOnlyEdit
      onGridReady={onRoundGridReady}
      defaultColDefOverride={{}}
    />
  );
}
