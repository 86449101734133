import {
  Autocomplete,
  getOptionAsValue,
  withNamespace,
} from "components/Form/FormikControls";
import React, { useMemo } from "react";
import { sortBy } from "lodash";
import { useSelector } from "react-redux";
import { getSaleSubTypes, getSpecies } from "selectors";
import { useField } from "formik";

interface SaleSubTypeFieldProps {
  namespace?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

// An autocomplete with a list of Sale Sub Types filtered by Species and Sale Type.
// These fields must also exist in the same form.
export const SaleSubTypeField = ({
  namespace: ns,
  label,
  name = "saleSubTypeId",
  required = false,
}: SaleSubTypeFieldProps) => {
  const saleSubTypeByIdLookup = useSelector(getSaleSubTypes);
  const speciesByIdLookup = useSelector(getSpecies);
  const [{ value: speciesId }] = useField(withNamespace(ns, "speciesId"));
  const [{ value: saleType }] = useField(withNamespace(ns, "saleType"));

  const options = useMemo(
    () =>
      [
        {
          value: null,
          label: "All",
        },
      ].concat(
        sortBy(
          Object.values(saleSubTypeByIdLookup)
            .filter((saleSubType: SaleSubType) => {
              if (speciesId && saleSubType.speciesId !== speciesId) {
                return false;
              }
              if (saleType && saleSubType.saleType !== saleType) {
                return false;
              }
              return true;
            })
            .map((saleSubType: SaleSubType) => ({
              label: `${speciesByIdLookup[saleSubType.speciesId].name} - ${saleSubType.name}`,
              value: saleSubType.id,
            })),
          "label",
        ),
      ),
    [saleSubTypeByIdLookup, speciesId, saleType, speciesByIdLookup],
  );

  return (
    <Autocomplete
      name={withNamespace(ns, name)}
      options={options}
      required={required}
      getOptionValue={getOptionAsValue}
      disableClearable
      label={label}
    />
  );
};
