import { intersection, uniq } from "lodash";
import { createSelector } from "reselect";

import { ExportSites } from "constants/exportSites";
import { GlobalSearchFields } from "constants/globalSearch";
import { ScanStatus } from "constants/scanner";

import { EMPTY_ARRAY } from "lib";

import { getConsignmentCode } from "lib/consignments";
import {
  doesSaleLotHaveOverflowPen,
  getBuyerHashFromSaleLot,
  getSaleLotScannedStatus,
  getScanStatusThreshold,
  isSaleLotBalanced,
  isSaleLotCounted,
  isSaleLotDelivered,
  isSaleLotInProgress,
  isSold,
} from "lib/saleLot";

import {
  getCurrentDeploymentSalesList,
  getGlobalSearchBySearchField,
  getWeighLots,
  selectDeploymentByDeploymentSaleIdLookup,
  getWeighLotScans,
  getScans,
  selectEidsByDeploymentIdLookup,
  selectSaleLotIdsByAuctionPenIdLookup,
  selectEidsBySaleLotIdLookup,
  getSaleLots,
  selectSaleLotIdsByDeliveryPenIdLookup,
  selectWeighLotIdsByEidLookup,
  selectConsignmentIdBySaleLotIdLookup,
  getConsignments,
  selectExceptionsBySaleLotIdLookup,
  getSaleyardScanSaleLots,
  getSexes,
  createLookupSelectors,
  createLookupCombiner,
  selectExceptionsByConsignmentIdLookup,
  selectScannedCountBySaleLotIdLookup,
  getCurrentSaleyard,
  getCurrentSale,
  createIdByKeySelector,
  selectVendorIdBySaleLotIdLookup,
  selectIsPreSaleBalancedByConsignmentIdLookup,
  selectIsPostSaleBalancedByConsignmentIdLookup,
  selectEidsByWeighLotIdLookup,
  selectReceivalLotIdByEidLookup,
  getReceivalLots,
  selectAuctionPenIdBySaleLotIdLookup,
  selectConsignmentHasVideoLookup,
  selectConsignmentHasImageLookup,
  selectSaleLotHasVideoLookup,
  selectSaleLotHasImageLookup,
  selectNASFieldWarningsBySaleLotId,
} from "selectors";

import { selectIsIntegrationCompliantBySaleLotIdLookup } from "selectors/integrations";
import { selectSaleLotIdsByWeighLotIdLookup } from "selectors/weighLots";

import { isObjectChangedAfter } from "./lib";

// sitting here instead of selectors/weighLots.js because of circular dependancies ;'(
const selectWeighLotIdsBySaleLotIdLookup = createSelector(
  [selectWeighLotIdsByEidLookup, selectEidsBySaleLotIdLookup],
  (weighLotIdsByEidLookup, eidsBySaleLotIdLookup) => {
    return Object.entries(eidsBySaleLotIdLookup).reduce(
      (acc, [saleLotId, saleLotEids]) => {
        acc[saleLotId] = uniq(
          saleLotEids.map(eid => weighLotIdsByEidLookup[eid]).flat(),
        );
        return acc;
      },
      {},
    );
  },
);

// sitting here instead of selectors/weighLots.js because of circular dependancies ;'(
export const selectWeighLotIdsByDeliveryPenIdLookup = createSelector(
  [selectSaleLotIdsByDeliveryPenIdLookup, selectWeighLotIdsBySaleLotIdLookup],
  (saleLotIdsByDeliveryPenIdLookup, weighLotIdsBySaleLotIdLookup) => {
    return Object.entries(saleLotIdsByDeliveryPenIdLookup).reduce(
      (acc, [deliveryPenId, saleLotIds]) => {
        acc[deliveryPenId] = uniq(
          saleLotIds
            .map(saleLotId => weighLotIdsBySaleLotIdLookup[saleLotId])
            .flat(),
        );
        return acc;
      },
      {},
    );
  },
);

export const selectUnfilteredWeighLotIds = createSelector(
  [getWeighLots],
  weighLots => Object.keys(weighLots).map(weighLotId => weighLotId),
);

/**
 * Returns a lookup for weigh lot ids keyed by deployment sale ids
 * based on the linked scans
 */
export const selectWeighlotIdsByDeploymentSaleIdLookup = createSelector(
  [
    getCurrentDeploymentSalesList,
    selectDeploymentByDeploymentSaleIdLookup,
    getWeighLotScans,
    getScans,
    selectEidsByDeploymentIdLookup,
    selectWeighLotIdsByEidLookup,
  ],
  (
    deploymentSales,
    deploymentByDeploymentSaleIdLookup,
    weighLotScansByIdLookup,
    scansByEidLookup,
    eidsByDeploymentIdLookup,
    weighLotIdsByEid,
  ) =>
    Object.values(deploymentSales).reduce((acc, deploymentSale) => {
      const deploymentSaleId = deploymentSale.deployment_sale_id;
      const deployment =
        deploymentByDeploymentSaleIdLookup[deploymentSaleId] || {};
      const eids = eidsByDeploymentIdLookup[deployment.id] || [];
      acc[deploymentSaleId] =
        eids
          .map(eid => weighLotIdsByEid[eid])
          .flat()
          .filter(Boolean) || EMPTY_ARRAY;
      return acc;
    }, {}),
);

/**
 * Return all weigh lot ids associated with deployment sales that are linked
 * to the filtered agency ids
 */
const selectAgencyFilteredWeighLotIds = createSelector(
  [
    getCurrentDeploymentSalesList,
    getGlobalSearchBySearchField(GlobalSearchFields.Agency),
    selectUnfilteredWeighLotIds,
    selectWeighlotIdsByDeploymentSaleIdLookup,
  ],
  (
    deploymentSales,
    agencyIds,
    unfilteredWeighLotIds,
    WeighLotIdsByDeploymentSaleIdLookup,
  ) => {
    return agencyIds === null
      ? unfilteredWeighLotIds
      : // filter deployment sales based on if the associated agency
        // is in the global search field
        deploymentSales
          .filter(deploymentSale =>
            agencyIds.includes(deploymentSale.livestock_agency_id),
          )
          // return weigh lot ids from the filtered deployment sales
          .map(
            deploymentSale =>
              WeighLotIdsByDeploymentSaleIdLookup[
                deploymentSale.deployment_sale_id
              ],
          )
          .flat();
  },
);

/**
 * Return weigh lot ids for filtered auction pens based on their linked scans
 * in their associated sale lots
 */
const selectAuctionPenFilteredWeighLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.AuctionPen),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByAuctionPenIdLookup,
    selectEidsBySaleLotIdLookup,
    getScans,
    getWeighLotScans,
  ],
  (
    auctionPenIds,
    unfilteredWeighLotIds,
    saleLotIdsByAuctionPenIdLookup,
    eidsBySaleLotIdLookup,
    scansByEidLookup,
    weighLotScanByIdLookup,
  ) => {
    // get pen scan ids based on the sale lots linked to
    // the auction pens in the global search field
    const filteredWeighLotIds = auctionPenIds
      ?.map(auctionPenId => {
        const saleLotIds = saleLotIdsByAuctionPenIdLookup[auctionPenId];

        const eids = saleLotIds
          .map(saleLotId => eidsBySaleLotIdLookup[saleLotId])
          .flat();

        const penScanLotIds = eids
          .map(
            eid =>
              weighLotScanByIdLookup[scansByEidLookup[eid]?.weigh_lot_scan_id]
                ?.weighLotId,
          )
          .flat();

        return penScanLotIds;
      })
      .flat();

    return auctionPenIds === null ? unfilteredWeighLotIds : filteredWeighLotIds;
  },
);

/**
 * filter and return weigh lot ids that have the filtered buyer ids
 * associated to them
 */
const selectBuyerFilteredWeighLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Buyer),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (saleLots, buyerIds, unfilteredValues, saleLotIdsByWeighLotIdLookup) =>
    buyerIds === null
      ? unfilteredValues
      : // filter sale lot ids based on if the buyer is included
        // in the global search field
        Object.entries(saleLotIdsByWeighLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId =>
              buyerIds.includes(saleLots[saleLotId].buyer_id),
            ),
          )
          // return the weigh lot ids based off the filtered sale lots
          .map(([weighLotId, ignored]) => weighLotId),
);

/**
 * filter and return weigh lot ids that have and linked sale lots with the
 * global filtered buyer and buyer way combinations (buyer hash) associated
 * to them
 */
const selectBuyerAndBuyerWayFilteredWeighLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.BuyerAndBuyerWay),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (saleLots, buyerHashes, unfilteredValues, saleLotIdsByWeighLotIdLookup) =>
    buyerHashes === null
      ? unfilteredValues
      : // filter sale lots based on if their buyer and buyer way combination
        // matches the global search field
        uniq(
          Object.entries(saleLotIdsByWeighLotIdLookup)
            .filter(([ignored, saleLotIds]) =>
              saleLotIds?.some(saleLotId =>
                buyerHashes.includes(
                  getBuyerHashFromSaleLot(saleLots[saleLotId]),
                ),
              ),
            )
            // return weigh lot ids from filtered sale lots
            .map(([weighLotId, ignored]) => weighLotId),
        ),
);

/**
 * Filter and return weigh lot ids that have the filtered delivery pen ids
 * associated to them
 */
const selectDeliveryPenFilteredWeighLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.DeliveryPen),
    selectUnfilteredWeighLotIds,
    selectWeighLotIdsByDeliveryPenIdLookup,
  ],
  (deliveryPenIds, unfilteredWeighLotIds, weighLotIdsByDeliveryPenIdLookup) => {
    const filteredWeighLotIds = [];
    // filter through delivery pens linked to weigh lots returning
    // delivery pens that are present in the global search field
    Object.entries(weighLotIdsByDeliveryPenIdLookup)
      .filter(([deliveryPenId, ignored]) =>
        deliveryPenIds?.includes(deliveryPenId),
      )
      // populate a list of filtered weigh lot ids
      .forEach(([ignored, weighLotIds]) =>
        weighLotIds.forEach(weighLotId => {
          filteredWeighLotIds.push(weighLotId);
        }),
      );
    return deliveryPenIds === null
      ? unfilteredWeighLotIds
      : filteredWeighLotIds;
  },
);

/**
 * Returns a lookup for weigh lot ids keyed by consignment ids
 * based on the linked sale lots
 */
const selectWeighLotIdsByConsignmentIdLookup = createSelector(
  [selectWeighLotIdsBySaleLotIdLookup, selectConsignmentIdBySaleLotIdLookup],
  (weighLotIdsBySaleLotIdLookup, consignmentIdBySaleLotIdLookup) =>
    Object.entries(weighLotIdsBySaleLotIdLookup).reduce(
      (acc, [saleLotId, weighLotIds]) => {
        const consignmentId = consignmentIdBySaleLotIdLookup[saleLotId];
        if (!acc[consignmentId]) {
          acc[consignmentId] = [...weighLotIds];
        } else {
          acc[consignmentId].push([...weighLotIds]);
        }
        return acc;
      },
      {},
    ),
);

/**
 * Filter and return weigh lot ids based on their linked consignments thats
 * has arrived boolean matches the global search
 */
const selectHasArrivedFilteredWeighLotIds = createSelector(
  [
    getConsignments,
    getGlobalSearchBySearchField(GlobalSearchFields.HasArrived),
    selectUnfilteredWeighLotIds,
    selectWeighLotIdsByConsignmentIdLookup,
  ],
  (
    consignments,
    hasArrived,
    unfilteredweighLotIds,
    weighLotIdsByConsignmentIdLookup,
  ) =>
    hasArrived === null
      ? unfilteredweighLotIds
      : // filter consignments based on if the has arrived boolean
        // matches the global search boolean
        Object.values(consignments)
          .filter(consignment => consignment.hasArrived === hasArrived[0])
          // return weigh lot ids associated to the filtered consignments
          .reduce(
            (acc, consignment) =>
              acc
                .concat(
                  weighLotIdsByConsignmentIdLookup[consignment.id]?.map(
                    weighLotId => weighLotId,
                  ),
                )
                .filter(Boolean),
            [],
          ),
);

/**
 * Filter and return weigh lot ids based on their linked sale lots
 * that have buyer exceptions equal to the global search boolean
 */
const selectHasBuyerExceptionsFilteredWeighLotIds = createSelector(
  [
    selectExceptionsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasBuyerExceptions),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByWeighLotIdLookup,
    selectWeighLotIdsBySaleLotIdLookup,
  ],
  (
    exceptionsBySaleLotIdLookup,
    hasBuyerExceptions,
    unfilteredWeighLotIds,
    saleLotIdsByWeighLotIdLookup,
    weighLotIdsBySaleLotIdLookup,
  ) => {
    // go through sale lot ids linked to weigh lots
    // filter them based on if they have exceptions
    // equal to the global search boolean
    const filteredWeighLotIds = Object.entries(saleLotIdsByWeighLotIdLookup)
      .map(([ignored, saleLotIds]) => saleLotIds)
      .flat()
      .filter(saleLotId => {
        return (
          (exceptionsBySaleLotIdLookup[saleLotId]?.length === 0) ===
          hasBuyerExceptions?.[0]
        );
      })
      // return weigh lot ids linked to the filtered sale lots
      .map(saleLotId => weighLotIdsBySaleLotIdLookup[saleLotId])
      .flat()
      .filter(Boolean);

    return hasBuyerExceptions === null
      ? unfilteredWeighLotIds
      : filteredWeighLotIds;
  },
);

/**
 * Filter and return weigh lot ids based on if their scans are
 * consignment scanned
 */
const selectHasConsignmentScansFilteredWeighLotIds = createSelector(
  [
    getSaleyardScanSaleLots,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasConsignmentScans),
    selectUnfilteredWeighLotIds,
    getScans,
    getWeighLotScans,
  ],
  (
    saleyardScanSaleLots,
    eidsBySaleLotIdLookup,
    hasConsignmentScans,
    unfilteredWeighLotIds,
    scanByEidLookup,
    weighLotScanByIdLookup,
  ) =>
    hasConsignmentScans === null
      ? unfilteredWeighLotIds
      : // go through eids linked to sale lots
        // filter for saleyard scans exist based on
        // the global search boolean
        Object.entries(eidsBySaleLotIdLookup)
          .filter(
            ([saleLotId, ignored]) =>
              Boolean(saleyardScanSaleLots[saleLotId]) ===
              hasConsignmentScans[0],
          )
          // return weigh lot ids from the filtered eids
          .reduce(
            (acc, [ignored, eids]) =>
              acc.concat([
                ...eids.map(
                  eid =>
                    weighLotScanByIdLookup[
                      scanByEidLookup[eid]?.weigh_lot_scan_id
                    ]?.weighLotId,
                ),
              ]),
            [],
          ),
);

/**
 * Filter and return weigh lot ids based on if their linked
 * sale lots have overflow pens
 */
const selectHasOverflowFilteredWeighLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.HasOverflow),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (saleLots, hasOverflow, unfilteredValues, saleLotIdsByWeighLotIdLookup) =>
    hasOverflow === null
      ? unfilteredValues
      : // go through sale lots linked to weigh lots and filter
        // on if any of the linked sale lots have overflow pens
        Object.entries(saleLotIdsByWeighLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(
              saleLotId =>
                doesSaleLotHaveOverflowPen(saleLots[saleLotId]) ===
                hasOverflow[0],
            ),
          )
          // return the weigh lot ids
          .map(([weighLotId, ignored]) => weighLotId),
);

const canSaleLotsHaveProgenyByWeighLotIdLookup = (
  saleLotIds,
  saleLots,
  sexes,
) => {
  return saleLotIds.some(
    saleLotId => sexes?.[saleLots[saleLotId]?.sex_id]?.hasProgeny || false,
  );
};

export const [
  selectCanSaleLotsHaveProgenyByWeighLotIdLookup,
  getCanSaleLotsHaveProgenyByWeighLotId,
] = createLookupSelectors(
  [selectSaleLotIdsByWeighLotIdLookup, getSaleLots, getSexes],
  createLookupCombiner(canSaleLotsHaveProgenyByWeighLotIdLookup),
);

/**
 * Filter and return weigh lot ids based on if their linked
 * sale lots have progeny
 */
const selectHasProgenyFilteredWeighLotIds = createSelector(
  [
    getWeighLots,
    selectCanSaleLotsHaveProgenyByWeighLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasProgeny),
    selectUnfilteredWeighLotIds,
  ],
  (
    weighLots,
    saleLotsCanHaveProgenyByWeighLotIdLookup,
    hasProgeny,
    unfilteredWeighLotIds,
  ) =>
    hasProgeny === null
      ? unfilteredWeighLotIds
      : // go through each weigh lot and filter according to
        // the has progeny global filter boolean
        Object.values(weighLots)
          .filter(
            weighLot =>
              saleLotsCanHaveProgenyByWeighLotIdLookup[weighLot.id] ===
              hasProgeny[0],
          )
          // return weigh lot ids
          .map(weighLot => weighLot.id),
);

/**
 * Filter and return weigh lot ids based on if their linked
 * sale lots have vendor exceptions
 */
const selectHasVendorExceptionsFilteredWeighLotIds = createSelector(
  [
    getSaleLots,
    selectExceptionsByConsignmentIdLookup,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasVendorExceptions),
    selectUnfilteredWeighLotIds,
    getScans,
    getWeighLotScans,
  ],
  (
    saleLots,
    exceptionsByConsignmentIdLookup,
    eidsBySaleLotIdLookup,
    hasVendorExceptions,
    unfilteredWeighLotIds,
    scanByEidLookup,
    weighLotScanByIdLookup,
  ) =>
    hasVendorExceptions === null
      ? unfilteredWeighLotIds
      : // go through each sale lot and check if the linked consignment
        // has vendor exceptions equal to the global search boolean
        Object.values(saleLots)
          .filter(
            saleLot =>
              (exceptionsByConsignmentIdLookup[saleLot.consignment_id]
                .length !==
                0) ===
              hasVendorExceptions[0],
          )
          // return weigh lot ids on filtered sale lots eids
          .reduce(
            (acc, saleLot) =>
              acc.concat([
                ...(eidsBySaleLotIdLookup[saleLot.id]
                  ?.map(
                    eid =>
                      weighLotScanByIdLookup[
                        scanByEidLookup[eid].weigh_lot_scan_id
                      ]?.weighLotId,
                  )
                  .flat() || []),
              ]),
            [],
          ),
);

/**
 * Filter and return weigh lot ids based on if their linked
 * sale lots that have NAS Warnings
 */
const selectHasMissingNASFieldsFilteredWeighLotIds = createSelector(
  [
    getSaleLots,
    selectNASFieldWarningsBySaleLotId,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasMissingNasFields),
    selectUnfilteredWeighLotIds,
    getScans,
    getWeighLotScans,
  ],
  (
    saleLots,
    hasMissingNASFieldsBySaleLotIdLookup,
    eidsBySaleLotIdLookup,
    hasMissingNASFields,
    unfilteredWeighLotIds,
    scanByEidLookup,
    weighLotScanByIdLookup,
  ) =>
    hasMissingNASFields === null
      ? unfilteredWeighLotIds
      : // go through each sale lot and check if the linked consignment
        // has missing NAS Fields equal to the global search boolean (has or has no)
        Object.values(saleLots)
          .filter(
            saleLot =>
              hasMissingNASFieldsBySaleLotIdLookup[saleLot.id].length > 0 ===
              hasMissingNASFields[0],
          )
          // return weigh lot ids on filtered sale lots eids
          .reduce(
            (acc, saleLot) =>
              acc.concat([
                ...(eidsBySaleLotIdLookup[saleLot.id]
                  ?.map(
                    eid =>
                      weighLotScanByIdLookup[
                        scanByEidLookup[eid].weigh_lot_scan_id
                      ]?.weighLotId,
                  )
                  .flat() || []),
              ]),
            [],
          ),
);

/**
 * Filter and return weigh lot ids based on if they have
 * a weight
 */
const selectHasWeightFilteredWeighLotIds = createSelector(
  [
    getWeighLots,
    getGlobalSearchBySearchField(GlobalSearchFields.HasWeight),
    selectUnfilteredWeighLotIds,
  ],
  (weighLotByIdLookup, hasWeight, unfilteredWeighLotIds) => {
    return hasWeight === null
      ? unfilteredWeighLotIds
      : // filter weigh lots based on if they have a weight (They should always have one - but can exist without one)
        Object.values(weighLotByIdLookup)
          .filter(weighLot =>
            hasWeight[0] ? !!weighLot.totalMassGrams : !weighLot.totalMassGrams,
          )
          .map(weighLot => weighLot.id)
          .filter(Boolean);
  },
);

/**
 * Filter and return weigh lot ids based on if their linked
 * sale lots are balanced
 */
const selectIsBuyerBalancedFilteredWeighLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsBuyerBalanced),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (
    saleLots,
    isBuyerBalanced,
    unfilteredValues,
    saleLotIdsByWeighLotIdLookup,
  ) =>
    isBuyerBalanced === null
      ? unfilteredValues
      : // filter sale lots on whether they are balanced based on
        // the global search field
        Object.entries(saleLotIdsByWeighLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(
              saleLotId =>
                isSaleLotBalanced(saleLots[saleLotId]) === isBuyerBalanced[0],
            ),
          )
          // return weigh lot ids from the filtered sale lots
          .map(([weighLotId, ignored]) => weighLotId),
);

/**
 * Filter and return weigh lot ids based on if their linked
 * sale lots are counted
 */
const selectIsCountedFilteredWeighLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsCounted),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (saleLots, isCounted, unfilteredValues, saleLotIdsByWeighLotIdLookup) =>
    isCounted === null
      ? unfilteredValues
      : // filter sale lots based on if they are counted according to
        // the global search boolean
        Object.entries(saleLotIdsByWeighLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(
              saleLotId =>
                isSaleLotCounted(saleLots[saleLotId]) === isCounted[0],
            ),
          )
          // return weigh lot ids from the filtered sale lots
          .map(([weighLotId, ignored]) => weighLotId),
);

/**
 * Filter and return weigh lot ids based on if their linked
 * sale lots are delivered
 */
const selectIsDeliveredFilteredWeighLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsDelivered),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (saleLots, isDelivered, unfilteredValues, saleLotIdsByWeighLotIdLookup) =>
    isDelivered === null
      ? unfilteredValues
      : // filter sale lots by weigh lot, checking if any linked sale lots
        // are delivered according the the global search boolean
        Object.entries(saleLotIdsByWeighLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(
              saleLotId =>
                isSaleLotDelivered(saleLots[saleLotId]) === isDelivered[0],
            ),
          )
          // return the weigh lot ids
          .map(([weighLotId, ignored]) => weighLotId),
);

function saleLotsScanStatusByWeighLotId(
  penScanLot,
  scannedCountBySaleLotIdLookup,
  saleLotIdsByWeighLotIdLookup,
  saleLotLookup,
  speciesId,
  currentSaleYard,
) {
  const saleLotIds = saleLotIdsByWeighLotIdLookup[penScanLot.id] || [];

  return saleLotIds.map(saleLotId => {
    const saleLot = saleLotLookup[saleLotId];
    return getSaleLotScannedStatus(
      saleLot,
      scannedCountBySaleLotIdLookup[saleLotId],
      getScanStatusThreshold(speciesId, currentSaleYard),
    );
  });
}

export const [
  selectSaleLotsScanStatusesByWeighLotIdLookup,
  getSaleLotsScanStatusesByWeighLotId,
] = createLookupSelectors(
  [
    getWeighLots,
    selectScannedCountBySaleLotIdLookup,
    selectSaleLotIdsByWeighLotIdLookup,
    getSaleLots,
    state => getCurrentSale(state).species_id,
    getCurrentSaleyard,
  ],
  createLookupCombiner(saleLotsScanStatusByWeighLotId),
);

/**
 * Filter and return weigh lot ids based on if their linked
 * sale lots are counted
 */
const selectIsEidCompliantFilteredWeighLotIds = createSelector(
  [
    selectSaleLotsScanStatusesByWeighLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsEidCompliant),
    selectUnfilteredWeighLotIds,
    getWeighLots,
  ],
  (
    saleLotScanStatusesByWeighLotIdLookup,
    isEidCompliant,
    unfilteredWeighLotIds,
    weighLots,
  ) =>
    isEidCompliant === null
      ? unfilteredWeighLotIds
      : // filter weigh lots based on if their sale lot scan status is equal to
        // the global search boolean
        Object.values(weighLots)
          .filter(
            weighLot =>
              saleLotScanStatusesByWeighLotIdLookup[weighLot.id].includes(
                ScanStatus.PASS,
              ) === isEidCompliant[0],
          )
          // return weigh lot ids
          .map(weighLot => weighLot.id),
);

/**
 * Filter and return weigh lot ids based on if their linked
 * sale lots are in progress
 */
const selectIsInProgressFilteredWeighLotIds = createSelector(
  [
    getSaleLots,
    getWeighLots,
    getConsignments,
    getGlobalSearchBySearchField(GlobalSearchFields.IsInProgress),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (
    saleLots,
    weighLots,
    consignments,
    isInProgress,
    unfilteredWeighLotIds,
    saleLotIdsByWeighLotIdLookup,
  ) => {
    return isInProgress === null
      ? unfilteredWeighLotIds
      : // filter weigh lots based on if any of their associated sale lots
        // are in progress
        Object.values(weighLots)
          .filter(weighLot =>
            saleLotIdsByWeighLotIdLookup[weighLot.id]
              ?.map(saleLotId =>
                isSaleLotInProgress(
                  saleLots[saleLotId],
                  consignments[saleLots[saleLotId]?.consignment_id],
                ),
              )
              .includes(isInProgress[0]),
          )
          // return weigh lot ids
          .map(weighLot => weighLot.id);
  },
);

export const selectAreSomeSaleLotsSoldByWeighLotIdLookup =
  createIdByKeySelector(
    createSelector(
      [
        getWeighLots,
        getSaleLots,
        selectSaleLotIdsByWeighLotIdLookup,
        selectVendorIdBySaleLotIdLookup,
      ],
      (
        weighLots,
        saleLots,
        saleLotIdsByWeighLotIdLookup,
        vendorIdBySaleLotId,
      ) =>
        Object.keys(weighLots).reduce((acc, weighLotId) => {
          acc[weighLotId] = {
            weighLotId,
            isSomeSold: saleLotIdsByWeighLotIdLookup[weighLotId]?.some(
              saleLotId =>
                isSold(
                  saleLots[saleLotId]?.buyer_id,
                  vendorIdBySaleLotId[saleLotId],
                ),
            ),
          };
          return acc;
        }, {}),
    ),
    "weighLotId",
    "isSomeSold",
  );

/**
 * Filter and return weigh lot ids based on if any of their linked
 * sale lots are sold
 */
const selectIsSoldFilteredWeighLotIds = createSelector(
  [
    getWeighLots,
    selectAreSomeSaleLotsSoldByWeighLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSold),
    selectUnfilteredWeighLotIds,
  ],
  (weighLots, isSomeSoldByWeighLotIdLookup, isSold, unfilteredWeighLotIds) =>
    isSold === null
      ? unfilteredWeighLotIds
      : // filter weigh lots by if any of their associated sale lots are
        // sold based on the global search boolean
        Object.values(weighLots)
          .filter(
            weighLot => isSomeSoldByWeighLotIdLookup[weighLot.id] === isSold[0],
          )
          // return weigh lot ids
          .map(weighLot => weighLot.id),
);

/**
 * Returns a lookup for weigh lot ids keyed by consignment ids
 * based on the linked scans
 */
const selectConsignmentIdsByWeighLotIdLookup = createSelector(
  [
    getWeighLots,
    selectEidsByWeighLotIdLookup,
    selectReceivalLotIdByEidLookup,
    getScans,
    getReceivalLots,
  ],
  (
    weighLots,
    eidsByWeighLotIdLookup,
    receivalLotIdLookupByEidLookup,
    scansLookup,
    receivalLotLookup,
  ) =>
    Object.values(weighLots).reduce((acc, weighLot) => {
      const eids = eidsByWeighLotIdLookup[weighLot.id] || [];
      const eidsWithReceivalLots = eids.filter(
        eid => !!scansLookup[eid]?.receival_lot_id,
      );
      const consignmentIds = uniq(
        eidsWithReceivalLots.map(eid => {
          const receivalLotId = receivalLotIdLookupByEidLookup[eid];
          return receivalLotLookup[receivalLotId]?.consignmentId;
        }),
      );
      acc[weighLot.id] = consignmentIds || [];
      return acc;
    }, {}),
);

/**
 * Filter and return weigh lot ids based on if any of their linked
 * consignments are pre sale balanced
 */
const selectIsVendorPreSaleBalancedFilteredWeighLotIds = createSelector(
  [
    getWeighLots,
    selectIsPreSaleBalancedByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsPreSaleVendorBalanced),
    selectUnfilteredWeighLotIds,
    selectConsignmentIdsByWeighLotIdLookup,
  ],
  (
    weighLots,
    isPreSaleBalancedByConsignmentId,
    isVendorPresaleBalanced,
    unfilteredWeighLotIds,
    consignmentIdsByWeighLotIdLookup,
  ) =>
    isVendorPresaleBalanced === null
      ? unfilteredWeighLotIds
      : // filter weigh lots based on if the linked consignment is pre sale balanced
        // in accordance with the global search boolean
        Object.values(weighLots)
          .filter(
            WeighLot =>
              isPreSaleBalancedByConsignmentId[
                consignmentIdsByWeighLotIdLookup[WeighLot.id]?.[0]
              ] === isVendorPresaleBalanced[0],
          )
          // return filtered weigh lot ids
          .map(WeighLot => WeighLot.id),
);

/**
 * Filter and return weigh lot ids based on if any of their linked
 * consignments are post sale balanced
 */
const selectIsVendorPostSaleBalancedFilteredWeighLotIds = createSelector(
  [
    getWeighLots,
    selectIsPostSaleBalancedByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsPostSaleVendorBalanced),
    selectUnfilteredWeighLotIds,
    selectConsignmentIdsByWeighLotIdLookup,
  ],
  (
    weighLots,
    isPostSaleBalancedByConsignmentIdLookup,
    isVendorBalanced,
    unfilteredWeighLotIds,
    consignmentIdsByWeighLotIdLookup,
  ) => {
    return isVendorBalanced === null
      ? unfilteredWeighLotIds
      : // filter weigh lots based on if the linked consignment is post sale balanced
        // in accordance with the global search boolean
        Object.values(weighLots)
          .filter(
            WeighLot =>
              isPostSaleBalancedByConsignmentIdLookup[
                consignmentIdsByWeighLotIdLookup[WeighLot.id]?.[0]
              ] === isVendorBalanced[0],
          )
          // return filtered weigh lot ids
          .map(WeighLot => WeighLot.id);
  },
);

/**
 * Filter and return weigh lot ids based on if any of their linked
 * sale lot's labels are included in the global search
 */
const selectLabelFilteredWeighLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Label),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (saleLots, labels, unfilteredValues, saleLotIdsByWeighLotIdLookup) =>
    labels === null
      ? unfilteredValues
      : // filter sale lots based on if they have labels and if the labels
        // are in the global search field
        Object.entries(saleLotIdsByWeighLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(
              saleLotId =>
                saleLots[saleLotId].labels?.length > 0 &&
                labels.some(
                  labelIds =>
                    intersection(saleLots[saleLotId].labels, labelIds).length,
                ),
            ),
          )
          // return weigh lot ids from filtered sale lots
          .map(([weighLotId, ignored]) => weighLotId),
);

/**
 * Filter and return weigh lot ids based on if any of their linked
 * sale lot's auction pen ids are included in the global search
 * lanes auction pen ids
 */
const selectLaneFilteredWeighLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.Lane),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByWeighLotIdLookup,
    selectAuctionPenIdBySaleLotIdLookup,
  ],
  (
    lanes,
    unfilteredValues,
    saleLotIdsByWeighLotIdLookup,
    auctionPenIdBySaleLotIdLookup,
  ) =>
    lanes === null
      ? unfilteredValues
      : // filter sale lots for auction pen ids available in the
        // lanes global search field
        Object.entries(saleLotIdsByWeighLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId =>
              lanes.some(lane =>
                lane.includes(auctionPenIdBySaleLotIdLookup[saleLotId]),
              ),
            ),
          )
          // return weigh lot ids from the filtered sale lots
          .map(([weighLotId, ignored]) => weighLotId),
);

/**
 * Filter and return weigh lot ids based on if any of their linked
 * sale lot's overflow pens are included in the overflow pen global
 * search
 */
const selectOverflowPenFilteredWeighLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.OverflowPen),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (overFlowPens, unfilteredValues, saleLotIdsByWeighLotIdLookup) =>
    overFlowPens === null
      ? unfilteredValues
      : // filter for any sale lots linked to each weigh lot that
        // has an overflow pen
        Object.entries(saleLotIdsByWeighLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId => overFlowPens.includes(saleLotId)),
          )
          // return the weigh lot ids
          .map(([weighLotId, ignored]) => weighLotId),
);

/**
 * Filter and return weigh lot ids based on if any of their linked
 * sale lot's pricing type is in the global search's pricing types
 */
const selectPricingTypeFilteredWeighLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.PricingType),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (saleLots, pricingTypes, unfilteredValues, saleLotIdsByWeighLotIdLookup) =>
    pricingTypes === null
      ? unfilteredValues
      : // filter for any sale lots linked to each weigh lot thats
        // pricing type is included in the global search field
        Object.entries(saleLotIdsByWeighLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId =>
              pricingTypes.includes(saleLots[saleLotId].pricing_type_id),
            ),
          )
          // return the weigh lot ids
          .map(([weighLotId, ignored]) => weighLotId),
);

/**
 * Filter and return weigh lot ids based on if their sale round
 * is in the global search's sale rounds
 */
export const selectSaleRoundFilteredWeighLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.SaleRound),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByWeighLotIdLookup,
    getSaleLots,
  ],
  (
    saleRoundIds,
    unfilteredWeighLotIds,
    saleLotIdsByWeighLotIdLookup,
    saleLotByIdLookup,
  ) => {
    return saleRoundIds === null
      ? unfilteredWeighLotIds
      : // filter sale lots linked to weigh lots based on if their sale round
        // ids exist in the global search
        uniq(
          Object.entries(saleLotIdsByWeighLotIdLookup)
            .filter(
              ([_ignored, saleLotIds]) =>
                intersection(
                  saleRoundIds,
                  saleLotIds.map(
                    saleLotId => saleLotByIdLookup[saleLotId].sale_round_id,
                  ),
                ).length,
            )
            // return weigh lot ids
            .map(([weighLotId, _ignored]) => weighLotId),
        );
  },
);

/**
 * Filter and return weigh lot ids based on if their eids
 * is in the global search's eids
 */
const selectScanFilteredWeighLotIds = createSelector(
  [
    selectWeighLotIdsByEidLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Scan),
    selectUnfilteredWeighLotIds,
  ],
  (weighLotIdsByEidLookup, eids, unfilteredWeighLotIds) =>
    eids === null
      ? unfilteredWeighLotIds
      : // return weigh lot ids based on the eids in the global search field
        uniq(
          eids
            .map(eid =>
              // conditionally check selector as there may not be any weigh lots
              // that exist with the filtered EID
              weighLotIdsByEidLookup[eid]?.map(weighLotId => weighLotId),
            )
            .flat(),
        ).filter(Boolean),
);

/**
 * Filter and return weigh lot ids based on if their linked
 * sale lot's third party is in the global search third parties
 */
const selectThirdPartyFilteredWeighLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.ThirdParty),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (saleLots, thirdPartyIds, unfilteredValues, saleLotIdsByWeighLotIdLookup) =>
    thirdPartyIds === null
      ? unfilteredValues
      : // filter sale lots associated to weigh lot ids for any sale lots
        // include the third parties in the global search field
        Object.entries(saleLotIdsByWeighLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId =>
              thirdPartyIds.includes(saleLots[saleLotId].thirdPartyId),
            ),
          )
          .map(([weighLotId, ignored]) => weighLotId),
);

/**
 * Returns a lookup for weigh lot ids keyed by vendor numbers
 * based on the linked consignments
 */
export const selectVendorIdsByWeighLotIdLookup = createSelector(
  [selectConsignmentIdsByWeighLotIdLookup, getConsignments],
  (consignmentIdsByWeighLotIdLookup, consignmentByIdLookup) => {
    return Object.entries(consignmentIdsByWeighLotIdLookup).reduce(
      (acc, [weighLotId, consignmentIds]) => {
        acc[weighLotId] = consignmentIds.map(
          consignmentId => consignmentByIdLookup[consignmentId]?.vendor_id,
        );
        return acc;
      },
      {},
    );
  },
);

/**
 * Filter and return weigh lot ids based on if their linked
 * vendor is in the global search's vendors
 */
const selectVendorFilteredWeighLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.Vendor),
    selectUnfilteredWeighLotIds,
    selectVendorIdsByWeighLotIdLookup,
  ],
  (vendorIds, unfilteredWeighLotIds, vendorIdsByWeighLotIdLookup) =>
    vendorIds === null
      ? unfilteredWeighLotIds
      : // filter vendor ids linked to weigh lots that are present
        // in the global search field
        Object.entries(vendorIdsByWeighLotIdLookup)
          .filter(
            ([ignored, weighLotVendorIds]) =>
              intersection(vendorIds, weighLotVendorIds).length,
          )
          // return weigh lot ids
          .map(([weighLotId, ignored]) => weighLotId),
);

/**
 * Create a lookup for weigh lot ids associated to vendor
 * numbers on consignments
 */
const selectVendorNumbersByWeighLotIdLookup = createSelector(
  [selectConsignmentIdsByWeighLotIdLookup, getConsignments],
  (consignmentIdsByWeighLotIdLookup, consignmentByIdLookup) => {
    return Object.entries(consignmentIdsByWeighLotIdLookup).reduce(
      (acc, [weighLotId, consignmentIds]) => {
        acc[weighLotId] = consignmentIds.map(consignmentId =>
          getConsignmentCode(consignmentByIdLookup[consignmentId]),
        );
        return acc;
      },
      {},
    );
  },
);

/**
 * Filter and return weigh lot ids based on if their linked
 * consignments vendor number is in the global search's vendor
 * numbers
 */
const selectVendorNumberFilteredWeighLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.VendorNumber),
    selectUnfilteredWeighLotIds,
    selectVendorNumbersByWeighLotIdLookup,
  ],
  (vendorNumbers, unfilteredWeighLotIds, vendorNumbersByWeighLotIdLookup) =>
    vendorNumbers === null
      ? unfilteredWeighLotIds
      : // filter vendor numbers linked to weigh lots that are present
        // in the global search field
        Object.entries(vendorNumbersByWeighLotIdLookup)
          .filter(
            ([_ignored, weighLotVendorNumbers]) =>
              intersection(vendorNumbers, weighLotVendorNumbers).length,
          )
          // return weigh lot ids
          .map(([weighLot, _ignored]) => weighLot),
);

/**
 * Create a lookup for weigh lot ids associated to vendor
 * PICs on consignments
 */
const selectVendorPicsByWeighLotIdLookup = createSelector(
  [selectConsignmentIdsByWeighLotIdLookup, getConsignments],
  (consignmentIdsByWeighLotIdLookup, consignmentByIdLookup) => {
    return Object.entries(consignmentIdsByWeighLotIdLookup).reduce(
      (acc, [weighLotId, consignmentIds]) => {
        acc[weighLotId] = consignmentIds.map(
          consignmentId =>
            consignmentByIdLookup[consignmentId]?.vendor_property_id,
        );
        return acc;
      },
      {},
    );
  },
);

/**
 * Filter and return weigh lot ids based on if their linked
 * consignments vendor PICs are in the global search's vendor
 * PICs
 */
const selectVendorPicFilteredWeighLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.VendorPic),
    selectUnfilteredWeighLotIds,
    selectVendorPicsByWeighLotIdLookup,
  ],
  (vendorPics, unfilteredWeighLotIds, vendorPicsByWeighLotId) =>
    vendorPics === null
      ? unfilteredWeighLotIds
      : // filter vendor PICs linked to weigh lots that are present
        // in the global search field
        Object.entries(vendorPicsByWeighLotId)
          .filter(
            ([_ignored, weighLotVendorPics]) =>
              intersection(vendorPics, weighLotVendorPics).length,
          )
          // return weigh lot ids
          .map(([weighLot, _ignored]) => weighLot),
);

/**
 * Filter and return weigh lot ids based on if their linked
 * sale lots buyer PIC is in the global search's buyer PIC
 */
const selectBuyerPicFilteredWeighLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.BuyerPic),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (saleLots, propertyIds, unfilteredValues, saleLotIdsByWeighLotIdLookup) =>
    propertyIds === null
      ? unfilteredValues
      : // filter sale lots associated to weigh lots thats destination properties
        // are also in the global search field
        Object.entries(saleLotIdsByWeighLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId =>
              propertyIds.includes(saleLots[saleLotId].destination_property_id),
            ),
          )
          // return the associated weigh lot ids
          .map(([weighLotId, ignored]) => weighLotId),
);

/**
 * Filter and return weigh lot ids based on if they were
 * created or modified after the global filtered checkpoints
 */
const selectCheckpointFilteredWeighLotIds = createSelector(
  [
    getWeighLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Checkpoint),
    selectUnfilteredWeighLotIds,
  ],
  (weighLots, checkpoints, unfilteredValues) =>
    checkpoints === null
      ? unfilteredValues
      : // filter weigh lots that have been updated or created after
        // the selected checkpoint
        Object.values(weighLots)
          .filter(weighLot => isObjectChangedAfter(weighLot, checkpoints[0]))
          // return the weigh lot ids
          .map(weighLot => weighLot.id),
);

/**
 * Filter and return weigh lot ids based on if any of their
 * linked sale lots are auctions plus compliant
 */
const selectIsAuctionsPlusCompliantFilteredWeighLotIds = createSelector(
  [
    getWeighLots,
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredWeighLotIds,
    getGlobalSearchBySearchField(GlobalSearchFields.IsAuctionsPlusCompliant),
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (
    weighLots,
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredWeighLotIds,
    searchField,
    saleLotIdsByWeighLotIdLookup,
  ) => {
    return searchField === null
      ? unfilteredWeighLotIds
      : // filter weigh lots based on their linked sale lots being
        // auction plus compliant in line with the global search value
        Object.values(weighLots)
          .filter(weighLot =>
            saleLotIdsByWeighLotIdLookup[weighLot.id]?.some(
              saleLotId =>
                isIntegrationCompliantBySaleLotIdLookup[saleLotId][
                  ExportSites.AUCTIONS_PLUS
                ] === searchField[0],
            ),
          )
          .map(weighLot => weighLot.id);
  },
);

/**
 * Filter and return weigh lot ids based on if any of their
 * linked sale lots are stock live compliant
 */
const selectIsStockLiveCompliantFilteredWeighLotIds = createSelector(
  [
    getWeighLots,
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredWeighLotIds,
    getGlobalSearchBySearchField(GlobalSearchFields.IsStockLiveCompliant),
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (
    penScanLots,
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredWeighLotIds,
    searchField,
    saleLotIdsByWeighLotIdLookup,
  ) => {
    return searchField === null
      ? unfilteredWeighLotIds
      : // filter weigh lots based on their linked sale lots being
        // stock live compliant in line with the global search value
        Object.values(penScanLots)
          .filter(receivalLot =>
            saleLotIdsByWeighLotIdLookup[receivalLot.id]?.some(
              saleLotId =>
                isIntegrationCompliantBySaleLotIdLookup[saleLotId][
                  ExportSites.STOCK_LIVE
                ] === searchField[0],
            ),
          )
          .map(receivalLot => receivalLot.id);
  },
);

/**
 * Filter and return weigh lot ids based on if any of their
 * linked sale lots have images uploaded
 */
const selectIsSaleLotImageUploadedWeighLotIds = createSelector(
  [
    getWeighLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSaleLotImageUploaded),
    selectUnfilteredWeighLotIds,
    selectSaleLotHasImageLookup,
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (
    weighLots,
    IsSaleLotImageUploaded,
    unfilteredWeighLotIds,
    SaleLotHasImageLookup,
    saleLotIdsByWeighLotIdLookup,
  ) =>
    IsSaleLotImageUploaded === null
      ? unfilteredWeighLotIds
      : // filter weigh lots based on if their linked sale lot has an image uploaded
        // in line with the global search boolean
        Object.values(weighLots)
          .filter(weighLot =>
            saleLotIdsByWeighLotIdLookup[weighLot.id]?.some(
              saleLotId =>
                SaleLotHasImageLookup[saleLotId] === IsSaleLotImageUploaded[0],
            ),
          )
          // return weigh lot ids
          .map(weighLot => weighLot.id),
);

/**
 * Filter and return weigh lot ids based on if any of their
 * linked sale lots have videos uploaded
 */
const selectIsSaleLotVideoUploadedWeighLotIds = createSelector(
  [
    getWeighLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSaleLotVideoUploaded),
    selectUnfilteredWeighLotIds,
    selectSaleLotHasVideoLookup,
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (
    weighLots,
    isSaleLotVideoUploaded,
    unfilteredWeighLotIds,
    saleLotHasVideoLookup,
    saleLotIdsByWeighLotIdLookup,
  ) =>
    isSaleLotVideoUploaded === null
      ? unfilteredWeighLotIds
      : // filter weigh lots based on if their linked sale lot has a video uploaded
        // in line with the global search boolean
        Object.values(weighLots)
          .filter(weighLot =>
            saleLotIdsByWeighLotIdLookup[weighLot.id]?.some(
              saleLotId =>
                saleLotHasVideoLookup[saleLotId] === isSaleLotVideoUploaded[0],
            ),
          )
          // return the weigh lot ids
          .map(weighLot => weighLot.id),
);

/**
 * Filter and return weigh lot ids based on if any of their
 * linked consignments have images uploaded
 */
const selectIsConsignmentImageUploadedWeighLotIds = createSelector(
  [
    getWeighLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsConsignmentImageUploaded),
    selectUnfilteredWeighLotIds,
    selectConsignmentHasImageLookup,
    selectConsignmentIdsByWeighLotIdLookup,
  ],
  (
    weighLots,
    isConsignmentImageUploaded,
    unfilteredWeighLotIds,
    consignmentHasImageLookup,
    consignmentIdsByWeighLotIdLookup,
  ) =>
    isConsignmentImageUploaded === null
      ? unfilteredWeighLotIds
      : // filter weigh lots based on if their linked consignment has an image uploaded
        // in line with the global search boolean
        Object.values(weighLots)
          .filter(weighLot =>
            consignmentIdsByWeighLotIdLookup[weighLot.id]?.some(
              consignmentId =>
                consignmentHasImageLookup[consignmentId] ===
                isConsignmentImageUploaded[0],
            ),
          )
          // return the weigh lot ids
          .map(weighLot => weighLot.id),
);

/**
 * Filter and return weigh lot ids based on if any of their
 * linked consignments have videos uploaded
 */
const selectIsConsignmentVideoUploadedWeighLotIds = createSelector(
  [
    getWeighLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsConsignmentVideoUploaded),
    selectUnfilteredWeighLotIds,
    selectConsignmentHasVideoLookup,
    selectConsignmentIdsByWeighLotIdLookup,
  ],
  (
    weighLots,
    isConsignmentVideoUploaded,
    unfilteredWeighLotIds,
    consignmentHasVideoLookup,
    consignmentIdsByWeighLotIdLookup,
  ) =>
    isConsignmentVideoUploaded === null
      ? unfilteredWeighLotIds
      : // filter weigh lots based on if their linked consignment has an image uploaded
        // in line with the global search boolean
        Object.values(weighLots)
          .filter(weighLot =>
            consignmentIdsByWeighLotIdLookup[weighLot.id]?.some(
              consignmentId =>
                consignmentHasVideoLookup[consignmentId] ===
                isConsignmentVideoUploaded[0],
            ),
          )
          // return the weigh lot ids
          .map(weighLot => weighLot.id),
);

/**
 * Filter and return weigh lot ids based on if any of their
 * linked sale lots have marks included in the global search's
 * marks filter
 */
const selectMarksFilteredWeighLotIds = createSelector(
  [
    getSaleLots,
    getWeighLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Marks),
    selectUnfilteredWeighLotIds,
    selectSaleLotIdsByWeighLotIdLookup,
  ],
  (
    saleLots,
    weighLots,
    marks,
    unfilteredWeighLotIds,
    saleLotIdsByWeighLotIdLookup,
  ) =>
    marks === null
      ? unfilteredWeighLotIds
      : // filter weigh lots based on if any of their associated sale lots
        // have marks that are included in the global search field
        Object.values(weighLots)
          .filter(
            weighLot =>
              intersection(
                marks,
                saleLotIdsByWeighLotIdLookup[weighLot.id]
                  .map(saleLotId =>
                    saleLots[saleLotId].marks.map(mark => mark.location),
                  )
                  .flat(),
              ).length > 0,
          ) // return the weigh lot ids
          ?.map(weighLot => weighLot.id),
);

export const selectFilteredWeighLotIds = createSelector(
  [
    selectAgencyFilteredWeighLotIds,
    selectAuctionPenFilteredWeighLotIds,
    selectBuyerFilteredWeighLotIds,
    selectBuyerAndBuyerWayFilteredWeighLotIds,
    selectDeliveryPenFilteredWeighLotIds,
    selectHasArrivedFilteredWeighLotIds,
    selectHasBuyerExceptionsFilteredWeighLotIds,
    selectHasConsignmentScansFilteredWeighLotIds,
    selectHasMissingNASFieldsFilteredWeighLotIds,
    selectHasOverflowFilteredWeighLotIds,
    selectHasProgenyFilteredWeighLotIds,
    selectHasVendorExceptionsFilteredWeighLotIds,
    selectHasWeightFilteredWeighLotIds,
    selectIsBuyerBalancedFilteredWeighLotIds,
    selectIsCountedFilteredWeighLotIds,
    selectIsDeliveredFilteredWeighLotIds,
    selectIsEidCompliantFilteredWeighLotIds,
    selectIsInProgressFilteredWeighLotIds,
    selectIsSoldFilteredWeighLotIds,
    selectIsVendorPreSaleBalancedFilteredWeighLotIds,
    selectIsVendorPostSaleBalancedFilteredWeighLotIds,
    selectLabelFilteredWeighLotIds,
    selectLaneFilteredWeighLotIds,
    selectOverflowPenFilteredWeighLotIds,
    selectPricingTypeFilteredWeighLotIds,
    selectSaleRoundFilteredWeighLotIds,
    selectScanFilteredWeighLotIds,
    selectThirdPartyFilteredWeighLotIds,
    selectVendorFilteredWeighLotIds,
    selectVendorNumberFilteredWeighLotIds,
    selectVendorPicFilteredWeighLotIds,
    selectBuyerPicFilteredWeighLotIds,
    selectCheckpointFilteredWeighLotIds,
    selectIsAuctionsPlusCompliantFilteredWeighLotIds,
    selectIsStockLiveCompliantFilteredWeighLotIds,
    selectIsSaleLotImageUploadedWeighLotIds,
    selectIsSaleLotVideoUploadedWeighLotIds,
    selectIsConsignmentImageUploadedWeighLotIds,
    selectIsConsignmentVideoUploadedWeighLotIds,
    selectMarksFilteredWeighLotIds,
  ],
  (
    agencyFilteredWeighLotIds,
    auctionPenFilteredWeighLotIds,
    buyerFilteredWeighLotIds,
    buyerAndBuyerWayFilteredWeighLotIds,
    deliveryPenFilteredWeighLotIds,
    hasArrivedFilteredWeighLotIds,
    hasBuyerExceptionsFilteredWeighLotIds,
    hasConsignmentScansFilteredWeighLotIds,
    hasMissingNASFieldsFilteredWeighLotIds,
    hasOverflowFilteredWeighLotIds,
    hasProgenyFilteredWeighLotIds,
    hasVendorExceptionsFilteredWeighLotIds,
    hasWeightFilteredWeighLotIds,
    isBuyerBalancedFilteredWeighLotIds,
    isCountedFilteredWeighLotIds,
    isDeliveredFilteredWeighLotIds,
    isEidCompliantFilteredWeighLotIds,
    isInProgressFilteredWeighLotIds,
    isSoldFilteredWeighLotIds,
    isVendorPreSaleBalancedFilteredWeighLotIds,
    isVendorPostSaleBalancedFilteredWeighLotIds,
    labelFilteredWeighLotIds,
    laneFilteredWeighLotIds,
    overflowPenFilteredWeighLotIds,
    pricingTypeFilteredWeighLotIds,
    saleRoundFilteredWeighLotIds,
    scanFilteredWeighLotIds,
    thirdPartyFilteredWeighLotIds,
    vendorFilteredWeighLotIds,
    vendorNumberFilteredWeighLotIds,
    vendorPicFilteredWeighLotIds,
    buyerPicFilteredWeighLotIds,
    checkpointFilteredWeighLotIds,
    isAuctionsPlusCompliantFilteredWeighLotIds,
    isStockLiveCompliantFilteredWeighLotIds,
    isSaleLotImageUploadedWeighLotIds,
    isSaleLotVideoUploadedWeighLotIds,
    isConsignmentImageUploadedWeighLotIds,
    isConsignmentVideoUploadedWeighLotIds,
    marksFilteredWeighLotIds,
  ) => {
    return intersection(
      agencyFilteredWeighLotIds,
      auctionPenFilteredWeighLotIds,
      buyerFilteredWeighLotIds,
      buyerAndBuyerWayFilteredWeighLotIds,
      deliveryPenFilteredWeighLotIds,
      hasArrivedFilteredWeighLotIds,
      hasBuyerExceptionsFilteredWeighLotIds,
      hasConsignmentScansFilteredWeighLotIds,
      hasMissingNASFieldsFilteredWeighLotIds,
      hasOverflowFilteredWeighLotIds,
      hasProgenyFilteredWeighLotIds,
      hasVendorExceptionsFilteredWeighLotIds,
      hasWeightFilteredWeighLotIds,
      isBuyerBalancedFilteredWeighLotIds,
      isCountedFilteredWeighLotIds,
      isDeliveredFilteredWeighLotIds,
      isEidCompliantFilteredWeighLotIds,
      isInProgressFilteredWeighLotIds,
      isSoldFilteredWeighLotIds,
      isVendorPreSaleBalancedFilteredWeighLotIds,
      isVendorPostSaleBalancedFilteredWeighLotIds,
      labelFilteredWeighLotIds,
      laneFilteredWeighLotIds,
      overflowPenFilteredWeighLotIds,
      pricingTypeFilteredWeighLotIds,
      saleRoundFilteredWeighLotIds,
      scanFilteredWeighLotIds,
      thirdPartyFilteredWeighLotIds,
      vendorFilteredWeighLotIds,
      vendorNumberFilteredWeighLotIds,
      vendorPicFilteredWeighLotIds,
      buyerPicFilteredWeighLotIds,
      checkpointFilteredWeighLotIds,
      isAuctionsPlusCompliantFilteredWeighLotIds,
      isStockLiveCompliantFilteredWeighLotIds,
      isSaleLotImageUploadedWeighLotIds,
      isSaleLotVideoUploadedWeighLotIds,
      isConsignmentImageUploadedWeighLotIds,
      isConsignmentVideoUploadedWeighLotIds,
      marksFilteredWeighLotIds,
    );
  },
);
