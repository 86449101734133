import { RequiredNutrienFieldOptions } from "constants/saleLots";
import { FormikContext } from "formik";
import { camelCase, flatten, isEmpty, snakeCase, uniq } from "lodash";
import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { getRequiredMLAFieldsBySubTypeAndSpecies } from "selectors";

interface UseGetMissingFieldWarningsProps {
  modelIds: string[];
  lookup: Object;
}

const checkField = (values, value) => {
  // Most fields are single fields, so we only need to check one of them. If it is set, we can return true.
  if (
    values[value] ||
    values[snakeCase(value)] ||
    (values?.draftingAttributes
      ? values.draftingAttributes[camelCase(value)]
      : null)
  ) {
    return true;
  }

  // If the fields we are checking are multi fields, we need to check all of them. They only need to enter one of them.
  // Multi fields are fields that have multiple values, e.g. muscleScore0, muscleScore1, muscleScore2, etc.
  return Array.from({ length: 7 }, (_, i) => `${value}${i}`).some(
    field =>
      values[field] ||
      values[snakeCase(field)] ||
      (values?.draftingAttributes
        ? values.draftingAttributes[camelCase(field)]
        : null),
  );
};

export const useGetMissingFieldWarnings = ({
  modelIds,
  lookup = {},
}: UseGetMissingFieldWarningsProps): string[] => {
  const formikContext = useContext(FormikContext);
  const uniqueMissingFields = useMemo(
    () => uniq(flatten(modelIds.map(modelId => lookup[modelId || null] || []))),
    [modelIds, lookup],
  );

  const requiredMLAOptions = useSelector(
    getRequiredMLAFieldsBySubTypeAndSpecies,
  );
  const filteredWarnings = useMemo(() => {
    const values = formikContext ? formikContext.values : {};
    if (isEmpty(values)) {
      return uniqueMissingFields;
    }
    return uniqueMissingFields.filter(warning => {
      const isFieldSet =
        RequiredNutrienFieldOptions.some(
          field => field.label === warning && checkField(values, field.value),
        ) ||
        requiredMLAOptions.some(
          field =>
            camelCase(field) === camelCase(warning) &&
            checkField(values, field),
        );

      return !isFieldSet;
    });
  }, [uniqueMissingFields, requiredMLAOptions, formikContext]);

  return filteredWarnings;
};
