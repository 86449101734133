import { sortBy } from "lodash";

export const BillingNumberPrefixes = {
  RCTI: "RCTI-",
  Invoice: "INV-",
};

export const BillingTypes = {
  RCTI: "Sales Credit Note",
  Invoice: "Sales Invoice",
};

export const BillingScreens = {
  BILLING: "billing-data",
  BILLING_RUNS: "billing-runs",
  SUNDRIES: "sundries",
  SEND: "send",
  EXPORT: "export",
  REVIEW: "review",
  OVERVIEW: "overview",
};

export const BaseTaxType = {
  INCOME: "Income",
  EXPENSES: "Expenses",
  THIRD_PARTY_TRANSACTIONS: "Third Party Transactions",
};

export const TaxType = {
  GST_ON_INCOME: "GST on Income",
  GST_FREE_INCOME: "GST Free Income",
  GST_ON_EXPENSES: "GST on Expenses",
  GST_FREE_EXPENSES: "GST Free Expenses",
  THIRD_PARTY_TRANSACTIONS_WITH_GST: "Third Party Transactions with GST",
  THIRD_PARTY_TRANSACTIONS_NO_GST: "Third Party Transactions no GST",
};

export const BaseTaxTypeOptions = sortBy(
  Object.values(BaseTaxType).map(option => ({
    value: option,
    label: option,
  })),
  "label",
);

export const TaxTypeOptions = sortBy(
  Object.values(TaxType).map(option => ({
    value: option,
    label: option,
  })),
  "label",
);

export const RuleTaxTypeOptions = BaseTaxTypeOptions.concat(
  TaxTypeOptions.map(o => ({ ...o, label: `(deprecated) ${o.label}` })),
);

export const GstMethod = {
  GST_EXCLUSIVE: "EXCLUSIVE",
  GST_EXEMPT: "EXEMPT",
  GST_INCLUSIVE: "INCLUSIVE",
  GST_FIXED: "FIXED",
};

export const ManualAdjustmentLabels = {
  INTEREST: "Interest",
};

export const groupedLineItemToolTipText =
  "When shown as Grouped Line Items, Ledger Entries with the same Line Item Group Title will be summed and shown together as a single Line Item on an Invoice. The value of this field will be used as the Line Item Title on the Invoice.";

export const InsuranceApplicationTypes = {
  SELL_ONLY: "Sell Only",
  BUY_ONLY: "Buy Only",
  BUY_AND_SELL: "Buy & Sell",
  N_A: "N/A",
};

export const InsuranceApplicationOptions = Object.values(
  InsuranceApplicationTypes,
).map(type => {
  return { value: type, label: type };
});

export const TRANSIT_INSURNACE = "Transit Insurance";

export const PricingMethod = {
  UNIT_PRICE: "UNIT_PRICE",
  GROSS_PRICE: "GROSS_PRICE",
};

export const PricingMethodOptions = [
  {
    label: "Unit Price",
    value: PricingMethod.UNIT_PRICE,
  },
  {
    label: "Gross Price",
    value: PricingMethod.GROSS_PRICE,
  },
];

export const GstMethodOptions = [
  {
    label: "GST Exclusive",
    value: GstMethod.GST_EXCLUSIVE,
  },
  {
    label: "GST Inclusive",
    value: GstMethod.GST_INCLUSIVE,
  },
  {
    label: "GST Exempt",
    value: GstMethod.GST_EXEMPT,
  },
];

export const BillingTags = {
  SaleLotTransaction: "SaleLotTransaction",
};
