import React from "react";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { Chip, ChipWrapper } from "components/Chip";

import {
  getHasUnregisteredEidsByAuctionPenId,
  getHasUnregisteredEidsByConsignmentId,
  getHasUnregisteredEidsBySaleLotId,
  getHasUnregisteredEidsByVendorId,
} from "selectors";

export const EidsNotRegisteredChip = props => {
  const { hasUnregisteredEids } = props;
  if (!hasUnregisteredEids) {
    return null;
  }
  return (
    <ChipWrapper>
      <Chip
        color
        backgroundColor="warning"
        tooltip="Not all EIDs are registered on the NLIS Database"
      >
        <FaIcon icon={faExclamationCircle} />
        &nbsp;Unregistered EIDs
      </Chip>
    </ChipWrapper>
  );
};
export const SaleLotEidsNotRegisteredChip = props => {
  const { saleLotId } = props;
  const hasUnregisteredEids = useSelector(
    getHasUnregisteredEidsBySaleLotId(saleLotId),
  );
  if (!hasUnregisteredEids) {
    return null;
  }
  return <EidsNotRegisteredChip hasUnregisteredEids={hasUnregisteredEids} />;
};
export const AuctionPenEidsNotRegisteredChip = props => {
  const { auctionPenId } = props;
  const hasUnregisteredEids = useSelector(
    getHasUnregisteredEidsByAuctionPenId(auctionPenId),
  );
  if (!hasUnregisteredEids) {
    return null;
  }
  return <EidsNotRegisteredChip hasUnregisteredEids={hasUnregisteredEids} />;
};
export const ConsignmentEidsNotRegisteredChip = props => {
  const { consignmentId } = props;
  const hasUnregisteredEids = useSelector(
    getHasUnregisteredEidsByConsignmentId(consignmentId),
  );
  if (!hasUnregisteredEids) {
    return null;
  }
  return <EidsNotRegisteredChip hasUnregisteredEids={hasUnregisteredEids} />;
};
export const VendorEidsNotRegisteredChip = props => {
  const { vendorId } = props;
  const hasUnregisteredEids = useSelector(
    getHasUnregisteredEidsByVendorId(vendorId),
  );
  if (!hasUnregisteredEids) {
    return null;
  }
  return <EidsNotRegisteredChip hasUnregisteredEids={hasUnregisteredEids} />;
};
