import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import styled from "styled-components/macro";

import { Row, HorizontalScrollBox } from "components/Layout";

import { EMPTY_OBJECT_FUNCTION } from "lib";

import TabSelector from ".";

const Wrapper = styled.div(
  ({ theme }) => `
  margin: 18px 0 0 0;
  border-bottom: 1px solid #c8c8c8;
  padding: 0 ${theme.space[3]}px;
  @media (max-width: ${theme.breakpoints[0]}px) {
    padding: 0 ${theme.space[1]}px;
  }
`,
);

const IconTab = styled(FontAwesomeIcon)`
  font-size: 1rem;
  position: relative;
  top: -1px;
`;

export const DefaultTab = props => {
  const {
    bold,
    color,
    disabled,
    id,
    onClick,
    selectedTab,
    setSelectedTab,
    title,
    icon,
  } = props;
  return (
    <TabSelector
      data-tour={id}
      selected={id === selectedTab}
      onClick={
        typeof onClick === "function"
          ? onClick
          : () => !disabled && setSelectedTab(id)
      }
      bold={bold}
      color={color}
    >
      {title || id} {icon && <IconTab icon={icon} />}
    </TabSelector>
  );
};

const TabRow = ({
  selectedTab,
  tabs,
  setSelectedTab = EMPTY_OBJECT_FUNCTION,
  bold,
  color,
  style,
  backgroundColor = "#fff",
}) => {
  const tabElements = tabs.map(tab => {
    const { component: TabComponent = DefaultTab, ...tabProps } = tab;
    return (
      <React.Fragment key={tab.id}>
        {tab.display === true || typeof tab.display === "undefined" ? (
          <TabComponent
            {...tabProps}
            bold={bold}
            color={color}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            icon={tab.icon ? tab.icon : null}
          />
        ) : null}
      </React.Fragment>
    );
  });

  if (!tabs?.length > 0) {
    return null;
  }

  return (
    <Wrapper data-tour={`tabrow.${selectedTab}`} style={style}>
      <HorizontalScrollBox backgroundColor={backgroundColor}>
        <Row>{tabElements}</Row>
      </HorizontalScrollBox>
    </Wrapper>
  );
};

TabRow.propTypes = {
  selectedTab: PropTypes.string,
  tabs: PropTypes.array,
  setSelectedTab: PropTypes.func,
  bold: PropTypes.bool,
  color: PropTypes.string,
  style: PropTypes.object,
  backgroundColor: PropTypes.string,
};

export default React.memo(TabRow);
