import React, { useEffect, useMemo } from "react";

import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, getIn } from "formik";
import { isEqual } from "lodash";
import uniqBy from "lodash/uniqBy";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { SaleAction } from "actions";

import { Row } from "components/Layout";

import { SaleyardPermissions } from "constants/permissions";
import { PricingTypes } from "constants/pricingTypes";
import {
  UnselectableSaleTypes,
  SaleRoundName,
  SaleTypes,
} from "constants/sale";
import { Species } from "constants/species";

import { toSelectOption } from "lib";

import {
  convertDateTimeToUTCDateTime,
  formatISO8601DateString,
  formatUTCToLocalDateTimeInputString,
} from "lib/timeFormats";

import {
  getActiveLivestockAgentDeployment,
  getDefaultRounds,
  getDeployments,
  getIsLivestockAgent,
  getRounds,
  getSaleById,
  getSaleSubTypes,
  getSaleTypes,
  selectCurrentSpecies,
} from "selectors";

import { useHasSaleyardPermission } from "hooks";

import BobbyCalfSaleForm from "./BobbyCalfSaleForm";
import SaleForm from "./SaleForm";
import { validationSchema } from "./SaleValidationSchema";

const DisabledLabel = ({ saleType }) => (
  <span style={{ color: "gray" }}>
    <FontAwesomeIcon icon={faLock} />
    {"   "}
    {saleType}
  </span>
);

function SaleModal({
  closeSelf,
  isScaleOperator,
  saleyards,
  buyers,
  initialValues,
  openConfirmModal,
  closeConfirmModal,
  saleId,
  validateSale,
}) {
  const defaultRounds = useSelector(getDefaultRounds);
  const saleTypes = useSelector(getSaleTypes);
  const rounds = useSelector(getRounds);
  const species = useSelector(selectCurrentSpecies);
  const selectedSale = useSelector(getSaleById(saleId));
  const deployments = useSelector(getDeployments);
  const currentDeployment = useSelector(getActiveLivestockAgentDeployment);
  const isLivestockAgent = useSelector(getIsLivestockAgent);

  const dispatch = useDispatch();
  const filterDefaultRounds = React.useCallback(
    props => {
      const { sale_type, saleyard_id, species_id, sale_sub_type_id } = props;
      const speciesRounds = Object.values(rounds).filter(
        r => r.species_id === species_id,
      );

      // Handle rounds for sale types that don't _really_ have rounds (BobbyCalf and ExternalAgentSale)
      const hardCoded = Object.values(speciesRounds).reduce((acc, round) => {
        if (
          sale_type === SaleTypes.BOBBYCALF &&
          round.name === SaleRoundName.BobbyCalf
        ) {
          acc.push(toSelectOption(round));
        }

        if (
          sale_type === SaleTypes.EXTERNAL_AGENT_SALE &&
          round.name === SaleRoundName.ExternalAgentSale
        ) {
          acc.push(toSelectOption(round));
        }
        return acc;
      }, []);
      if (hardCoded.length > 0) {
        return hardCoded;
      }
      const saleSubTypeFilteredDefaultRoundConfig = defaultRounds.filter(
        round => {
          if (round.sale_type !== sale_type) {
            return false;
          } else {
            return (
              round.sale_sub_type_id === sale_sub_type_id ||
              (!round.sale_sub_type_id && !sale_sub_type_id)
            );
          }
        },
      );

      // If any of the defaults are narrowed down explicity for this yard, we should use just that set.
      const yardFilteredDefaultRoundConfig =
        saleSubTypeFilteredDefaultRoundConfig.filter(
          round => round.saleyard_id === saleyard_id,
        );
      const generalYardDefaultRoundConfig =
        saleSubTypeFilteredDefaultRoundConfig.filter(
          round => !round.saleyard_id,
        );

      const defaultRoundConfig =
        yardFilteredDefaultRoundConfig.length > 0
          ? yardFilteredDefaultRoundConfig?.[0]
          : generalYardDefaultRoundConfig?.[0];

      // No default configured for this sale - nothing to do!
      if (!defaultRoundConfig) {
        return [];
      }

      const filteredRounds = defaultRoundConfig.rounds
        .filter(round => round.species_id === species_id)
        .map(round => toSelectOption(round));

      // Only return the unique default rounds from the saleyard or global defaults.
      return uniqBy(filteredRounds, "value");
    },
    [defaultRounds, rounds],
  );

  const subTypes = useSelector(getSaleSubTypes);
  const filterSaleSubTypes = React.useCallback(
    props => {
      const { sale_type, species_id } = props;
      return Object.values(subTypes).filter(
        type => type.saleType === sale_type && type.speciesId === species_id,
      );
    },
    [subTypes],
  );

  const isEditMode = !!saleId;

  const sale = React.useMemo(() => {
    return selectedSale
      ? {
          ...selectedSale,
          rounds: selectedSale?.rounds.map(roundId =>
            toSelectOption(rounds[roundId]),
          ),
        }
      : null;
  }, [rounds, selectedSale]);

  const defaultSpecies = isScaleOperator
    ? species.find(s => s.id === Species.CATTLE)
    : species[0] || {};

  const hasCanBlockNonAdminChangingWeightsPermission = useHasSaleyardPermission(
    SaleyardPermissions.canBlockNonAdminChangingWeights,
  );

  const defaults = {
    block_non_admin_changing_weights:
      !!hasCanBlockNonAdminChangingWeightsPermission,
    default_property_id: null,
    default_buyer_id: null,
    default_vendor_property_id: null,
    default_vendor_id: null,
    sale_type: saleTypes.includes(SaleTypes.SALEYARD_AUCTION)
      ? saleTypes[saleTypes.indexOf(SaleTypes.SALEYARD_AUCTION)]
      : saleTypes[0],
    saleyard_id: saleyards.length > 0 ? saleyards[0].value : undefined,
    date: formatISO8601DateString(new Date()),
    manual_vendor_numbers: false,
    species_id: defaultSpecies.id,
    pricing_type_id: defaultSpecies.defaultPricingType,
    default_buyers_premium: undefined,
    default_listing_fee_cents: undefined,
    default_vendor_commission: undefined,
    deployment_count: sale?.deployment_count,
    is_sale_locked:
      sale?.deployment_sales.some(obj => obj.is_sale_locked === true) || false,
    deploymentIds: Object.values(deployments).map(value => value.id),
    deployment_sales: Object.values(deployments).map(deployment => ({
      deployment_id: deployment.id,
    })),
  };

  const defaultFilteredSubTypes = filterSaleSubTypes(defaults);
  const [initial, setInitial] = React.useState({
    // Apply the defaults
    ...defaults,
    rounds: filterDefaultRounds(defaults),
    sale_sub_type_id:
      defaultFilteredSubTypes.length === 1
        ? defaultFilteredSubTypes[0]?.id
        : null,
    // Apply any initial values (eg currently selected saleyard)
    ...initialValues,
    // If its edit mode, apply the selected sale.
    ...sale,
  });

  const speciesRounds = useMemo(
    () =>
      Object.values(rounds).filter(r => r.species_id === initial.species_id),
    [rounds, initial.species_id],
  );

  const handleReInit = React.useCallback(
    values => {
      values.start_datetime = sale?.start_datetime
        ? formatUTCToLocalDateTimeInputString(sale.start_datetime)
        : "";
      values.end_datetime = sale?.end_datetime
        ? formatUTCToLocalDateTimeInputString(sale.end_datetime)
        : "";
      values.is_sale_locked =
        sale?.deployment_sales.some(obj => obj.is_sale_locked === true) ||
        false;

      const saleSubTypesForSale = filterSaleSubTypes(values);

      const isSingleDeploymentSaleType =
        values.sale_type !== SaleTypes.SALEYARD_AUCTION;

      const deploymentIds = isSingleDeploymentSaleType
        ? values.deploymentIds?.filter(id => id === currentDeployment.id)
        : values.deploymentIds;

      const deploymentSales = values.deployment_sales.filter(ds =>
        deploymentIds?.includes(ds.deployment_id),
      );

      if (saleSubTypesForSale.length === 1) {
        values.sale_sub_type_id = saleSubTypesForSale[0]?.id;
      } else if (
        !saleSubTypesForSale
          .map(obj => obj.id)
          .includes(values.sale_sub_type_id)
      ) {
        values.sale_sub_type_id = null;
      }

      // When certain fields are edited, other fields may need to be updated.
      // eg species -> pricing_type & rounds
      if (values.sale_type === SaleTypes.OVER_HOOKS) {
        setInitial({
          ...values,
          pricing_type_id: PricingTypes.PER_KILO,
          rounds: filterDefaultRounds(values),
          deploymentIds,
          deployment_sales: deploymentSales,
          ...(sale || {}),
        });
        if (values.sale_sub_type_id) {
          setInitial({
            ...values,
            rounds: filterDefaultRounds(values),
            deploymentIds,
            deployment_sales: deploymentSales,
          });
        }
      } else if (values.sale_type === SaleTypes.CLEARING) {
        setInitial({
          ...values,
          default_buyers_premium: getIn(
            values,
            "deployment_sales[0].default_buyers_premium",
          ),
          default_listing_fee_cents: getIn(
            values,
            "deployment_sales[0].default_listing_fee_cents",
          ),
          default_vendor_commission: getIn(
            values,
            "deployment_sales[0].default_vendor_commission",
          ),
          export_sites: getIn(values, "deployment_sales[0].export_sites"),
          rounds: filterDefaultRounds(values),
          deploymentIds,
          deployment_sales: deploymentSales,
          ...(sale || {}),
        });
      } else {
        setInitial({
          ...values,
          pricing_type_id: species.find(s => s.id === values.species_id)
            ?.defaultPricingType,
          rounds: filterDefaultRounds(values),
          deploymentIds,
          deployment_sales: deploymentSales,
          ...(sale || {}),
        });
      }
    },
    [sale, filterSaleSubTypes, currentDeployment, filterDefaultRounds, species],
  );

  const onDelete = () => {
    openConfirmModal({
      title: "Are you sure?",
      message: "Are you sure you want to delete this sale?",
      actions: [
        {
          label: "No",
          secondary: true,
          onClick: closeConfirmModal,
        },
        {
          label: "Yes",
          onClick: () => {
            dispatch(SaleAction.checkAndDeleteCurrent());
            closeConfirmModal();
            closeSelf();
          },
        },
      ],
    });
  };

  useEffect(() => {
    // If the underlying sale changes from somewhere else, update the form
    if (sale) {
      handleReInit(sale);
    }
  }, [sale, handleReInit]);

  // This is fired on bobbycalf when updating the default business, it only gets set, not cleared.
  useEffect(() => {
    const defaultBuyerChanged =
      initialValues.default_buyer_id &&
      initialValues.default_buyer_id !== initial.default_buyer_id;

    const defaultPropertyChanged =
      initialValues.default_property_id &&
      initialValues.default_property_id !== initial.default_property_id;

    if (defaultBuyerChanged || defaultPropertyChanged) {
      handleReInit({
        ...initial,
        default_buyer_id: initialValues.default_buyer_id,
        default_property_id: initialValues.default_property_id,
      });
    }
  }, [handleReInit, initialValues, initial]);

  const onSubmit = values => {
    const saleValues = {
      date: values.date,
      start_datetime: values.start_datetime
        ? convertDateTimeToUTCDateTime(values.start_datetime)
        : null,
      end_datetime: values.end_datetime
        ? convertDateTimeToUTCDateTime(values.end_datetime)
        : null,
      saleyard_id: values.saleyard_id,
      pricing_type_id: values.pricing_type_id,
      species_id: values.species_id,
      sale_type: values.sale_type,
      default_buyer_id: values.default_buyer_id,
      default_property_id: values.default_property_id,
      default_vendor_id: values.default_vendor_id,
      default_vendor_property_id: values.default_vendor_property_id,
      sale_sub_type_id: values.sale_sub_type_id,
      manual_vendor_numbers: !!values.manual_vendor_numbers,
      rounds: values.rounds.map(r => r.value),
      notes: values.notes,
      sale_title: values.sale_title,
      default_buyers_premium: values.default_buyers_premium,
      default_listing_fee_cents: values.default_listing_fee_cents,
      default_vendor_commission: values.default_vendor_commission,
      export_sites: getIn(values, "deployment_sales[0].export_sites"),
      using_registered_bidders: values.using_registered_bidders,
      deployment_count: values.deployment_count,
      deployment_sales: values.deployment_sales,
      deployment_ids: values.deploymentIds,
      location_property_id: values.location_property_id,
    };

    if (isScaleOperator) {
      // Ensure that these values haven't been changed, prevents it from being changed even if set in "initialValues"
      saleValues.species_id = Species.CATTLE;
      saleValues.sale_type = SaleTypes.BOBBYCALF;
    } else if (saleValues.sale_type === SaleTypes.CLEARING) {
      saleValues.species_id = Species.CLEARING_SALE;
      saleValues.pricing_type_id = PricingTypes.GROSS;
    } else if (saleValues.sale_type === SaleTypes.SUNDRY_SALE) {
      saleValues.species_id = Species.SUNDRY_SALE;
      saleValues.pricing_type_id = PricingTypes.GROSS;
    }

    const saleyard = saleyards.find(
      ({ value }) => value === values.saleyard_id,
    );

    if (validateSale(saleValues, isEditMode)) {
      if (isEditMode) {
        // If we are editing, we can only change the date, rounds, default pricing, title, or sale sub type.
        const payload = {};
        const deploymentSalePayload = {};
        const fields = [
          "rounds",
          "sale_title",
          "notes",
          "date",
          "start_datetime",
          "end_datetime",
          "pricing_type_id",
          "species_id",
          "using_registered_bidders",
          "sale_sub_type_id",
          "location_property_id",
        ];
        fields.forEach(field => {
          if (!isEqual(values[field], sale[field])) {
            // Use the saleValues value in the payload as it has been massaged (eg rounds is a array of ids)
            payload[field] = saleValues[field];
          }
        });

        if (values.sale_type === SaleTypes.CLEARING) {
          const fields = [
            "default_buyers_premium",
            "default_listing_fee_cents",
            "default_vendor_commission",
            "export_sites",
            "payment_due_date",
            "auctions_plus_url",
          ];
          fields.forEach(field => {
            if (!isEqual(values[field], sale[field])) {
              deploymentSalePayload[field] = saleValues[field];
            }
          });
        }

        if (values.sale_type === SaleTypes.PADDOCK) {
          const fields = [
            "default_vendor_id",
            "default_vendor_property_id",
            "default_buyer_id",
            "default_property_id",
          ];
          fields.forEach(field => {
            if (!isEqual(values[field], sale[field])) {
              payload[field] = saleValues[field];
            }
          });
        }

        if (values.sale_type === SaleTypes.OVER_HOOKS) {
          const fields = ["default_buyer_id", "default_property_id"];
          fields.forEach(field => {
            if (!isEqual(values[field], sale[field])) {
              payload[field] = saleValues[field];
            }
          });
        }

        if (hasCanBlockNonAdminChangingWeightsPermission) {
          payload.block_non_admin_changing_weights =
            values.block_non_admin_changing_weights;
        }

        // Deployment sales are not part of the sale creation request, so they need to be handled separately.
        const deploymentSales = values.deployment_sales.reduce((acc, ds) => {
          const original = sale.deployment_sales.find(
            originalDeploymentSale =>
              originalDeploymentSale.deployment_sale_id ===
              ds.deployment_sale_id,
          );

          if (values.sale_type === SaleTypes.CLEARING) {
            deploymentSalePayload.updateExistingSaleLotExportSites =
              ds.updateExistingSaleLotExportSites;
            deploymentSalePayload.auctions_plus_url = ds.auctions_plus_url;
            deploymentSalePayload.vendor_terms = ds.vendor_terms;
            deploymentSalePayload.buyer_terms = ds.buyer_terms;
            acc.push({
              payload: deploymentSalePayload,
              deploymentSaleId: ds.deployment_sale_id,
            });
          } else if (
            !isEqual(ds, original) ||
            values.is_sale_locked !== original.is_sale_locked
          ) {
            ds.is_sale_locked = values.is_sale_locked;
            deploymentSalePayload.market_report_notes = ds.market_report_notes;
            acc.push({
              payload: ds,
              deploymentSaleId: ds.deployment_sale_id,
            });
          }
          return acc;
        }, []);
        dispatch(
          SaleAction.updateWithDeploymentSales({
            salePayload: payload,
            livestockSaleId: values.livestocksale_id,
            deploymentSales,
          }),
        );
      } else {
        const payload = saleValues;
        payload.saleyard_name = saleyard?.name;
        dispatch(SaleAction.create(payload));
      }
      closeSelf();
    }
  };

  const title = isEditMode ? "Edit Sale" : <Row>New Sale</Row>;

  const selectableSaleTypes = React.useMemo(() => {
    // Listing all "visible" sale types - with lock icons next to unavailable sale types
    return (
      Object.values(SaleTypes)
        .map(saleType =>
          saleTypes.includes(saleType)
            ? {
                label: saleType,
                value: saleType,
              }
            : {
                label: <DisabledLabel saleType={saleType} />,
                isDisabled: true,
                value: saleType,
              },
        )
        // some sale types we don't want users to be able to select
        .filter(saleType => !UnselectableSaleTypes.includes(saleType.value))
    );
  }, [saleTypes]);

  const currentDeploymentSalesCount = defaults.deployment_count;

  const saleIsLocked = defaults.is_sale_locked;

  const readOnly =
    (saleIsLocked && isLivestockAgent) ||
    (currentDeploymentSalesCount > 1 && isLivestockAgent);

  const subTypesExist = filterSaleSubTypes(initial).length > 0;

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema(subTypesExist)}
      initialValues={initial}
      onSubmit={onSubmit}
    >
      {isScaleOperator ? (
        <BobbyCalfSaleForm
          buyers={buyers}
          saleyards={saleyards}
          handleClose={closeSelf}
          handleDelete={isEditMode ? onDelete : undefined}
          openConfirmModal={openConfirmModal}
          closeConfirmModal={closeConfirmModal}
          title={title}
        />
      ) : (
        <SaleForm
          isEditMode={isEditMode}
          saleyards={saleyards}
          handleClose={closeSelf}
          title={title}
          rounds={speciesRounds}
          saleTypes={selectableSaleTypes}
          setSpecies={setInitial}
          handleReInit={handleReInit}
          handleDelete={isEditMode ? onDelete : undefined}
          readOnly={readOnly}
        />
      )}
    </Formik>
  );
}

SaleModal.propTypes = {
  closeSelf: PropTypes.func,
  saleyards: PropTypes.array,
  buyers: PropTypes.array,
  initialValues: PropTypes.object,
  isScaleOperator: PropTypes.bool,
  openConfirmModal: PropTypes.func,
  closeConfirmModal: PropTypes.func,
  saleId: PropTypes.number,
  validateSale: PropTypes.func,
};

export default SaleModal;
