import React from "react";

import { useSelector } from "react-redux";

import {
  ACCREDITATION,
  ALL_ACCREDITATIONS,
  Accreditation,
} from "constants/draftingAttributes";

import { getSaleLotById } from "selectors";

import { Chip } from "./BaseChip";

function shouldShowAccreditation(value) {
  // A string of the ALL type, or a number specifically for jbas
  return (
    value &&
    ((typeof value === "string" && value === Accreditation.ALL) ||
      typeof value !== "string")
  );
}

export const AccreditationsChips = ({ saleLotId }) => {
  const draftingAttributes =
    useSelector(getSaleLotById(saleLotId))?.draftingAttributes || {};

  const getAccreditationChipText = (draftingAttributes, accreditationName) => {
    if (ACCREDITATION[accreditationName] === ACCREDITATION.accreditationJBAS) {
      return `JBAS: ${draftingAttributes?.[accreditationName]}`;
    } else {
      return ACCREDITATION[accreditationName];
    }
  };

  return (
    <>
      {ALL_ACCREDITATIONS.map(accreditationName =>
        shouldShowAccreditation(draftingAttributes?.[accreditationName]) ? (
          <Chip backgroundColor="successGreen" key={accreditationName}>
            {getAccreditationChipText(draftingAttributes, accreditationName)}
          </Chip>
        ) : null,
      )}
    </>
  );
};
