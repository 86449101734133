import React from "react";

import { Grid } from "@material-ui/core";

import { SubtleBadge } from "components/Badge";
import { CollapseLabel, FormCollapse } from "components/Form";
import { CombinedMissingFieldIndicator } from "components/MissingFieldsIndicator";

import { ForCattle, ForSheep } from "containers/ForSpecies";

import { sectionHasErrors } from "lib/form";

import { useFieldValue } from "hooks";

import { Cattle as AdvancedCattleDrafting } from "./Cattle";
import { Sheep as AdvancedSheepDrafting } from "./Sheep";

export const AdvancedDraftingCollapse = ({
  isOpen,
  onToggle,
  saleLot,
  setFieldValue,
  commonProps,
  onChangeBreed,
  readOnly,
}) => {
  const hasErrorBadge = sectionHasErrors(commonProps, ["advancedBreed"]);
  const id = useFieldValue("id");
  const header = (
    <Grid container>
      <SubtleBadge hasErrorBadge={hasErrorBadge}>Advanced Drafting</SubtleBadge>
      <CollapseLabel />
      <CombinedMissingFieldIndicator onlyAdvancedFields modelIds={id} />
    </Grid>
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header}
      id="collapse-advanced"
      dataTour={isOpen ? "hideAdvanced" : "showAdvanced"}
    >
      <Grid container spacing={2}>
        <ForSheep>
          <AdvancedSheepDrafting
            saleLot={saleLot}
            onChangeBreed={onChangeBreed}
            readOnly={readOnly}
          />
        </ForSheep>
        <ForCattle>
          <AdvancedCattleDrafting
            saleLot={saleLot}
            setFieldValue={setFieldValue}
            onChangeBreed={onChangeBreed}
            readOnly={readOnly}
          />
        </ForCattle>
      </Grid>
    </FormCollapse>
  );
};
