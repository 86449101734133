import React, { useCallback, useMemo } from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik, useFormikContext } from "formik";
import { isEmpty } from "lodash";
import partition from "lodash/partition";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import {
  addSaleLot,
  deleteAttachment,
  patchConsignment,
  patchSaleLot,
  uploadRequest,
} from "actions";

import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { Button, SecondaryButton } from "components/Form";
import { FileInput, Input, Label } from "components/Form/FormikControls";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { PricingTypes } from "constants/pricingTypes";
import { Settings } from "constants/settings";
import { Species } from "constants/species";

import {
  calculateTotalPriceCents,
  calculateUnitPrice,
  EMPTY_ARRAY,
  EMPTY_OBJECT_FUNCTION,
} from "lib";
import {
  getConsignmentById,
  getCurrentSale,
  getCurrentSpeciesId,
  getSaleLotById,
  getSaleLotIdsByConsignmentId,
  getSetting,
  selectAttachmentsByConsignmentId,
} from "selectors";

import { getSkinsSexId } from "selectors/sexes";

import { useBoolean } from "hooks";
import {
  DocumentTypes,
  NVDRelatedDocumentTypes,
} from "constants/documentTypes";
import { DeletableThumbnail } from "components/Media";
import { LightBox } from "components/LightBox";
import { Cell, Row } from "components/Layout";
import { SkinsForm } from "./SkinsForm";
import { LotForm } from "./LotForm";
import { FormValues } from "./types";

const quantityValidation = Yup.number()
  .required("Required")
  .typeError("Invalid number")
  .integer("Must be a whole number");

const validationSchema = Yup.object().shape({
  saleLots: Yup.array().of(
    Yup.object().shape({
      quantity: quantityValidation,
      total_mass_grams: Yup.number().when("pricing_type_id", {
        is: pricingTypeId => pricingTypeId === PricingTypes.PER_KILO,
        then: schema =>
          schema
            .positive("Must be > 0")
            .typeError("Invalid number")
            .required("Required!"),
        otherwise: schema => schema.nullable(),
      }),

      unitPrice: Yup.number(),
    }),
  ),
});

const FormBackground = styled.div(
  ({ theme }) => `
    background-color:  ${theme.colors.collapseBackground};
    `,
);
interface ModalProps {
  onClose: () => void;
  consignmentId: string;
}

interface FormInnerProps {
  onClose: () => void;
  dialogTitle: string;
  defaultSaleLot: any;
  defaultSkinsLot: any;
  handleUpload: (file: any) => void;
  attachments: Array<File>;
  setDeleteFileDialog: (object) => void;
  setPreview: (boolean) => void;
}

const FormInner = ({
  onClose,
  dialogTitle,
  defaultSaleLot,
  defaultSkinsLot,
  handleUpload,
  attachments,
  setDeleteFileDialog,
  setPreview,
}: FormInnerProps) => {
  const submitDisabled = false;

  const formikContext = useFormikContext();
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useBoolean();

  const onCloseConfirmWrapper = useCallback(() => {
    if (!isEmpty(formikContext.touched)) {
      openConfirmDialog();
    } else {
      onClose();
    }
  }, [formikContext.touched, onClose, openConfirmDialog]);

  const speciesId = useSelector(getCurrentSpeciesId);

  return (
    <>
      <ZoomyDialog open onClose={onCloseConfirmWrapper} maxWidth="lg" fullWidth>
        <Form>
          <DialogTitle onClose={onCloseConfirmWrapper}>
            {dialogTitle}
          </DialogTitle>
          <FormBackground>
            <DialogContent dividers>
              <Grid container item xs={12} spacing={2}>
                <LotForm defaultSaleLot={defaultSaleLot} />
                {speciesId === Species.SHEEP && (
                  <SkinsForm defaultSkinsLot={defaultSkinsLot} />
                )}
                <Grid item xs={12}>
                  <Input
                    label="External References/Kill Id"
                    name="consignment.billingReference"
                    maxLength={255}
                    tooltip="This value will appear in the header of related Invoices/RCTI's and line items on the Invoice may be grouped by this value."
                  />
                </Grid>
                <Grid item xs={12}>
                  <Label>Upload Feedback/Killsheet</Label>
                  <FileInput
                    upload={handleUpload}
                    rotate={false}
                    closeSelf={EMPTY_OBJECT_FUNCTION}
                    disabled={false}
                  />
                </Grid>

                <Row>
                  {attachments.map(attachment => (
                    <Cell key={attachment.id}>
                      <DeletableThumbnail
                        handleDelete={() => {
                          setDeleteFileDialog(attachment);
                        }}
                        file={attachment}
                        setPreview={setPreview}
                        readOnly={false}
                      />
                    </Cell>
                  ))}
                </Row>
              </Grid>
            </DialogContent>
          </FormBackground>
          <DialogActions>
            <SecondaryButton onClick={onCloseConfirmWrapper} type="button">
              Close
            </SecondaryButton>
            <Button type="submit" disabled={submitDisabled}>
              Save
            </Button>
          </DialogActions>
        </Form>
      </ZoomyDialog>
      <ConfirmDialog
        title="There are changes to the form that will be lost, are you sure?"
        isOpen={isConfirmDialogOpen}
        onCancel={closeConfirmDialog}
        onDelete={onClose}
        buttonMessage="Yes"
      />
    </>
  );
};

function Modal({ onClose, consignmentId }: ModalProps) {
  const consignment = useSelector(getConsignmentById(consignmentId));

  const saleLotIds =
    useSelector(getSaleLotIdsByConsignmentId(consignmentId)) || [];
  const skinsSexId = useSelector(getSkinsSexId);

  const consignmentLots = useSelector(state =>
    saleLotIds.map(saleLotId => getSaleLotById(saleLotId)(state)),
  );

  const [skinsLots, saleLots] = partition(
    consignmentLots,
    saleLot => saleLot.sex_id === skinsSexId,
  );

  const dispatch = useDispatch();

  const dialogTitle = `Kill Sheet Entry - Hd ${consignment?.quantity}`;

  const sale = useSelector(getCurrentSale);

  const defaultSaleLot = useMemo(
    () => ({
      quantity: null,
      total_mass_grams: null,
      cents_per_kilo: null,
      total_price_cents: null,
      pricing_type_id: sale.pricing_type_id,
      consignment_id: consignmentId,
      sale_round_id: sale.rounds[0],
      buyer_id: sale.default_buyer_id,
      unitPrice: 0,
    }),
    [consignmentId, sale.default_buyer_id, sale.pricing_type_id, sale.rounds],
  );

  const defaultSkinsLot = useMemo(
    () => ({
      ...defaultSaleLot,
      sex_id: skinsSexId,
      pricing_type_id: PricingTypes.PER_HEAD,
      total_mass_grams: 0,
    }),
    [defaultSaleLot, skinsSexId],
  );

  const withUnitPrice = saleLot => ({
    ...saleLot,
    unitPrice: calculateUnitPrice(saleLot),
  });

  const initialValues = useMemo(() => {
    const saleLotFormInitialValues =
      saleLots.length > 0 ? saleLots : [defaultSaleLot];

    const skinsLotFormInitialValues =
      skinsLots.length === 0 && sale.species_id === Species.SHEEP
        ? [defaultSkinsLot]
        : skinsLots;
    return {
      saleLots: saleLotFormInitialValues.map(withUnitPrice),
      skinsLots: skinsLotFormInitialValues.map(withUnitPrice),
      consignment,
    };
  }, [
    consignment,
    defaultSaleLot,
    defaultSkinsLot,
    sale.species_id,
    saleLots,
    skinsLots,
  ]);

  const handleSubmit = (values: FormValues) => {
    [...values.saleLots, ...values.skinsLots]
      .filter(saleLot => saleLot.quantity > 0)
      .forEach(saleLot => {
        const totalPriceCents = calculateTotalPriceCents(saleLot);

        const payload = {
          ...saleLot,
          total_price_cents: totalPriceCents,
          total_mass_grams: saleLot.total_mass_grams || 0,
        };

        if (payload.id) {
          // patching
          dispatch(patchSaleLot(payload));
        } else {
          const tempSaleLotId = uuidv4();
          dispatch(addSaleLot(payload, tempSaleLotId));
        }
      });
    if (values.consignment.billingReference) {
      dispatch(
        patchConsignment(
          { billingReference: values.consignment.billingReference },
          consignmentId,
        ),
      );
    }
    onClose();
  };
  const agencyId = useSelector(getSetting(Settings.agencyId));

  const handleUpload = useCallback(
    file => {
      const formData = {
        consignment: defaultSaleLot.consignment_id,
        agency: agencyId !== null ? agencyId : undefined,
        type: NVDRelatedDocumentTypes.KILL_SHEET,
      };

      dispatch(uploadRequest(file, formData));
    },
    [agencyId, dispatch, defaultSaleLot],
  );
  const killSheetAttachments: Array<File> = useSelector(
    state =>
      selectAttachmentsByConsignmentId(state)[defaultSaleLot.consignment_id] ||
      EMPTY_ARRAY,
  ).filter(a => a.type === NVDRelatedDocumentTypes.KILL_SHEET);

  const [deleteDialogFile, setDeleteFileDialog] = React.useState(null);
  const deleteFile = () => {
    dispatch(deleteAttachment(deleteDialogFile));
    setDeleteFileDialog(null);
  };
  const [preview, setPreview] = React.useState(null);
  const closePreview = () => setPreview(null);
  const attachmentTypeOptions = [
    {
      label: "Image/Media",
      value: DocumentTypes.IMAGE,
    },
  ];
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <FormInner
          onClose={onClose}
          dialogTitle={dialogTitle}
          defaultSaleLot={defaultSaleLot}
          defaultSkinsLot={defaultSkinsLot}
          handleUpload={handleUpload}
          attachments={killSheetAttachments}
          setDeleteFileDialog={setDeleteFileDialog}
          setPreview={setPreview}
        />
      </Formik>
      {killSheetAttachments?.length > 0 && preview && (
        <LightBox
          defaultId={preview}
          onClose={closePreview}
          images={killSheetAttachments}
          attachmentTypeOptions={attachmentTypeOptions}
          readOnly={false}
        />
      )}
      {deleteDialogFile && (
        <ConfirmDialog
          title={createModalTitle("this Attachment")}
          isOpen={deleteDialogFile}
          onCancel={() => setDeleteFileDialog(null)}
          onDelete={deleteFile}
        />
      )}
    </>
  );
}

export default Modal;
