import React, { useMemo } from "react";

import { createFilterOptions } from "@material-ui/lab";
import { sortBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { BusinessAction } from "actions";

import { Autocomplete, FILTER_LIMIT } from "components/Form/FormikControls";

import { BUYER, VENDOR } from "constants/businesses";

import { EMPTY_OBJECT } from "lib";

import { isBusinessActive } from "lib/businesses";
import { caselessCompare } from "lib/compare";
import { getLivestockSaleId } from "lib/navigation";

import {
  getCurrentSpeciesId,
  getIsSaleyardAdmin,
  getProperties,
  selectConsignmentIdsByVendorIdLookup,
  selectOptionsForBusinessField,
  selectSaleLotIdsByBuyerIdLookup,
} from "selectors";

import {
  useFieldSetter,
  useFieldValue,
  useUpdateFormWithOfflineLookup,
} from "hooks";
import Species from "sagas/species";

const getOptionValue = option => option?.id;

export const BusinessField = ({
  autoFocus = false,
  disabled = false,
  label,
  name,
  onChangeExtra = undefined,
  sortRecentBusinessType = undefined,
  allowAddNew = true,
  activeOnly = false,
  inlineButton = null,
  extraFilter = undefined,
  ListboxComponent = undefined,
  PopperComponent = undefined,
  renderOption = undefined,
}) => {
  const propertyByIdLookup = useSelector(getProperties);

  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);

  // include buyer pics in the filter search
  const filter = createFilterOptions({
    matchFrom: "any",
    stringify: option => {
      const code = isSaleyardAdmin
        ? option.shortCodeSaleyard
        : option.shortCode;
      return `${option.name} ${code} ${option?.properties
        ?.map(property => propertyByIdLookup[property.id]?.PIC)
        .join(", ")}`;
    },
  });
  const dispatch = useDispatch();
  const setValue = useFieldSetter(name);
  const selectedBusinessId = useFieldValue(name);

  const currentSpecies = useSelector(getCurrentSpeciesId);

  const [tempBusinesses, setTempBusinesses] = React.useState([]);

  const addNewBusiness = businessName => {
    const tempId = uuidv4();
    dispatch(
      BusinessAction.create({
        id: tempId,
        name: businessName.trim(),
      }),
    );

    setTempBusinesses([...tempBusinesses, { id: tempId, name: businessName }]);
    // Add a temp option so its selected straight away.

    setValue(tempId);
  };

  const businessLookup =
    useSelector(selectOptionsForBusinessField) || EMPTY_OBJECT;

  const selectedBusiness = businessLookup[selectedBusinessId];

  const saleLotIdsByBuyerIdLookup = useSelector(
    selectSaleLotIdsByBuyerIdLookup,
  );

  const consignmentIdsByVendorIdLookup = useSelector(
    selectConsignmentIdsByVendorIdLookup,
  );

  // Sort options by top buyers first, then the relevant [saleyard|agent] code.
  const options = useMemo(() => {
    const allOptions = [...Object.values(businessLookup), ...tempBusinesses];

    // Allow for (now) inactive business on existing data
    if (
      selectedBusiness &&
      !isBusinessActive(selectedBusiness, getLivestockSaleId())
    ) {
      allOptions.push(selectedBusiness);
    }
    const sortOrder = [
      // In Sale already.
      sortRecentBusinessType === BUYER
        ? o => saleLotIdsByBuyerIdLookup[o.id]
        : null,
      sortRecentBusinessType === VENDOR
        ? o => consignmentIdsByVendorIdLookup[o.id]
        : null,
      // Top Buyer (Invert, so that we sort true (1) to the top.)
      currentSpecies === Species.CATTLE ? o => !o.isTopBuyerCattle : null,
      currentSpecies === Species.SHEEP ? o => !o.isTopBuyerSheep : null,
      "name",
      // Short Code
      isSaleyardAdmin ? "shortCodeSaleyard" : "shortCode",
    ].filter(Boolean);
    return sortBy(allOptions, sortOrder);
  }, [
    businessLookup,
    tempBusinesses,
    selectedBusiness,
    sortRecentBusinessType,
    currentSpecies,
    isSaleyardAdmin,
    saleLotIdsByBuyerIdLookup,
    consignmentIdsByVendorIdLookup,
  ]);

  useUpdateFormWithOfflineLookup(name);

  // Adjust the filtering function to push a specific short code result to the top.
  // This is defined by the type of sale, and who is searching, so memoize the function call
  const filterOptions = useMemo(() => {
    const doesShortCodeMatch = (option, inputValue) =>
      isSaleyardAdmin
        ? caselessCompare(option.shortCodeSaleyard, inputValue)
        : caselessCompare(option.shortCode, inputValue);

    return (options, params) => {
      let outsideFilterCount = 0;
      let filtered = filter(options, params).filter(o => {
        // Hide any hidden/inactive businesses
        if (o.hide || (activeOnly && !o.isActive === true)) {
          return false;
        }

        if (extraFilter) {
          if (extraFilter(o) !== true) {
            outsideFilterCount += 1;
            return false;
          }
        }

        return true;
      });

      const { inputValue } = params;
      // If we have a single match for a business short code, float it to the top.
      // (We don't know if a saleyard)
      filtered = [].concat(
        filtered.filter(option => doesShortCodeMatch(option, inputValue)),
        filtered.filter(option => !doesShortCodeMatch(option, inputValue)),
      );

      const outsideFilterExtraLabel = outsideFilterCount
        ? `(${outsideFilterCount} filtered out)`
        : "";

      if (filtered.length === 0) {
        filtered.push({
          disabled: true,
          label: `No results ${outsideFilterExtraLabel}`,
        });
      } else if (filtered.length > FILTER_LIMIT) {
        filtered = [
          {
            disabled: true,
            label: `Showing ${FILTER_LIMIT} of ${filtered.length} options ${outsideFilterExtraLabel}`,
          },
        ].concat(filtered.slice(0, FILTER_LIMIT));
      } else if (outsideFilterCount > 0) {
        filtered = [
          {
            disabled: true,
            label: `${outsideFilterCount} options filtered out`,
          },
        ].concat(filtered);
      }

      // Suggest the creation of a new value
      if (allowAddNew && params.inputValue !== "") {
        filtered.push({
          inputValue: params.inputValue,
          label: `Add "${params.inputValue}"`,
        });
      }

      return filtered;
    };
  }, [isSaleyardAdmin, filter, allowAddNew, activeOnly, extraFilter]);

  return (
    <Autocomplete
      autoFocus={autoFocus}
      disabled={disabled}
      ListboxComponent={ListboxComponent}
      filterOptions={filterOptions}
      renderOption={renderOption}
      getOptionValue={getOptionValue}
      handleNew={addNewBusiness}
      label={label}
      name={name}
      inlineButton={inlineButton}
      onChangeExtra={onChangeExtra}
      options={options}
      PopperComponent={PopperComponent}
    />
  );
};
