import React from "react";

import { Box } from "@material-ui/core";
import { Form, Formik, getIn, setIn, useFormikContext } from "formik";
import { useDispatch } from "react-redux";

import { Button, SecondaryButton } from "components/Form";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { pluralize } from "lib/pluralize";
import {
  checkboxFieldNameSuffix,
  getCheckboxFieldName,
} from "components/BulkUpdateOptionalFieldsModal/util";
import {
  BulkUpdateFieldsActionsProps,
  BulkUpdateOptionalFieldsModalProps,
  ModalIntroductionProps,
} from "components/BulkUpdateOptionalFieldsModal/types";
import { BulkUpdateFieldsForm } from "components/BulkUpdateOptionalFieldsModal/Form";
import { CombinedMissingFieldIndicator } from "components/MissingFieldsIndicator";
import { Row } from "components/Layout";

function BulkUpdateFieldsActions(
  props: BulkUpdateFieldsActionsProps,
): React.JSX.Element {
  const { onClose } = props;
  const { values } = useFormikContext();
  const isDisabled = Object.keys(values)
    .filter(fieldName => fieldName.endsWith(checkboxFieldNameSuffix))
    .every(fieldName => values[fieldName] !== true);
  return (
    <>
      <SecondaryButton onClick={onClose}>Close</SecondaryButton>
      <Button
        data-tour="bulkUpdateFieldsSubmit"
        disabled={isDisabled}
        type="submit"
      >
        Bulk Update
      </Button>
    </>
  );
}

function DefaultModalIntroduction(
  props: ModalIntroductionProps,
): React.JSX.Element {
  const { modelCount } = props;
  return (
    <strong>
      You are bulk editing {modelCount} {pluralize("record", modelCount)}. Any
      changes made to checked fields will be applied to these records.
    </strong>
  );
}

export function BulkUpdateOptionalFieldsModal(
  props: BulkUpdateOptionalFieldsModalProps,
): React.JSX.Element {
  const {
    bulkUpdateActionCreator,
    fields,
    initialValues,
    modelIds,
    modelName,
    onClose,
    validationSchema,
    ModalIntroduction = DefaultModalIntroduction,
  } = props;

  const dispatch = useDispatch();

  if (!modelIds.length) {
    return null;
  }

  function onSubmit(values: object): void {
    const updatedValues = fields.reduce((acc, field) => {
      const { fieldName, updateFieldNames, emptyValue } = field;
      const includeInChangeSetFieldName = getCheckboxFieldName(fieldName);
      const shouldIncludeInChangeSet =
        values[includeInChangeSetFieldName] === true;
      if (shouldIncludeInChangeSet) {
        // Updates the acc with the field values for this field.

        // If the field is a composite field containing multiple fields (eg condition has
        // conditionStore, conditionForward, conditionPrime) we need to
        // iterate through them and update.

        // Note - setIn RETURNS the value, which adds a little obtusse-ness to this stanza.
        if (updateFieldNames) {
          return updateFieldNames.reduce(
            (updatedFieldAcc, updateFieldName) =>
              setIn(
                updatedFieldAcc,
                updateFieldName,
                getIn(values, updateFieldName) || emptyValue,
              ),
            acc,
          );
        }
        // For the simpler use case (eg breed is just breed_id) we can just the fieldName as a simple value.
        return setIn(acc, fieldName, getIn(values, fieldName) || emptyValue);
      }
      return acc;
    }, {});
    const payloads = modelIds.map(id => ({
      id,
      ...updatedValues,
    }));
    dispatch(bulkUpdateActionCreator(payloads));
    onClose();
  }
  const modelCount = modelIds.length;
  return (
    <ZoomyDialog open onClose={onClose} maxWidth="md">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <DialogTitle onClose={onClose}>
            <Row justifyBetween alignCenter>
              <div>
                Editing {modelCount} {pluralize(modelName, modelCount)}
              </div>
              <CombinedMissingFieldIndicator modelIds={modelIds} />
            </Row>
          </DialogTitle>
          <DialogContent>
            <Box marginY={1}>
              <ModalIntroduction modelCount={modelCount} />
            </Box>
            <Box padding={2}>
              <BulkUpdateFieldsForm fields={fields} modelIds={modelIds} />
            </Box>
          </DialogContent>

          <DialogActions>
            <BulkUpdateFieldsActions onClose={onClose} />
          </DialogActions>
        </Form>
      </Formik>
    </ZoomyDialog>
  );
}
