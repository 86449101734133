import { useEffect } from "react";

import Big from "big.js";

import { useField, useFormikContext } from "formik";

export function withNamespace(namespace: string, name: string): string {
  return namespace ? `${namespace}.${name}` : name;
}

export function withArrayNamespace(
  namespace: Array<string>,
  index: number,
): string {
  return namespace ? `${namespace}[${index}]` : `[${index}]`;
}

export function withNamespaces(
  namespaces: Array<string>,
  name: string,
): string {
  return namespaces.concat(name).filter(Boolean).reduce(withNamespace, "");
}

export function useSubmitHandler(
  allowInitialSubmit: boolean,
  setIsSubmitEnabled: (isSubmitEnabled: boolean) => void,
) {
  const { isValid, dirty, submitCount } = useFormikContext();

  // Allows for forms in create mode to enable the submit button, regardless of
  // the validation state until the user has attempted to submit the form as least once

  const isSubmitEnabled =
    (submitCount === 0 && allowInitialSubmit) || (dirty && isValid);

  useEffect(() => {
    setIsSubmitEnabled(isSubmitEnabled);
  }, [isSubmitEnabled, setIsSubmitEnabled]);
}

export function useFormikSubmit(allowInitialSubmit) {
  const { isValid, submitCount, handleSubmit } = useFormikContext();
  const isSubmitEnabled = isValid || (allowInitialSubmit && submitCount === 0);
  return { isSubmitEnabled, handleSubmit };
}

function referentialInequality(a: object, b: object): boolean {
  return a !== b;
}

export function useChangeHandler(
  onChange: (values: object, isValid: boolean, isDirty: boolean) => void,
  getIsFormDirty = referentialInequality,
) {
  const { initialValues, isValid, touched, values } =
    useFormikContext<object>();

  const touchedCount = Object.keys(touched).length;
  const isDirty = getIsFormDirty(initialValues, values);
  useEffect(() => {
    if (touchedCount > 0 && isDirty) {
      onChange(values, isValid, isDirty);
    } else {
      onChange(null, false, false);
    }
  }, [isDirty, isValid, touchedCount, onChange, values]);
}

export function useSuggestedValue(
  suggestedValueFieldName: string,
  currentValue: any,
) {
  const [suggestedValueField] = useField(suggestedValueFieldName || "EMPTY");

  return suggestedValueFieldName &&
    suggestedValueField &&
    suggestedValueField.value !== currentValue
    ? suggestedValueField.value
    : undefined;
}

const getInversePowerOfTen = (exponent: number) => new Big(10).pow(-exponent);

export const getDecimalPlacesAndStep = (
  inputValue: Big | null | undefined,
  minimumDecimalPlaces: number,
  maximumDecimalPlaces?: number,
) => {
  let decimalPlacesToShow = minimumDecimalPlaces;
  if (inputValue != null && maximumDecimalPlaces) {
    // https://mikemcl.github.io/big.js/#instance-properties
    const inputDecimalPlaces = inputValue.c.length - inputValue.e - 1;

    decimalPlacesToShow = Math.min(
      Math.max(minimumDecimalPlaces, inputDecimalPlaces),
      maximumDecimalPlaces,
    );
  }

  return {
    decimalPlacesToShow,
    step: getInversePowerOfTen(decimalPlacesToShow),
  };
};
