import Tooltip from "@material-ui/core/Tooltip";

import React from "react";

import styled from "styled-components/macro";

export const ChipElement = styled.div(
  ({
    theme,
    $color,
    $backgroundColor,
    $borderColor,
    $italic,
    $borderLeftColor,
  }) => `
  background: ${
    theme.colors[$backgroundColor] || $backgroundColor || theme.colors.gray95
  };
  color: ${$color ? theme.colors[$color] || theme.colors.white : "inherit"};
  
  border-radius: ${theme.radii[1]}px;
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.colors[$borderColor] || $borderColor || "transparent"};
  padding: 1px 6px;
  margin: 2px;
  ${$italic ? "font-style: italic;" : ""}
  display: flex;
  align-items: center;
  ${
    $borderLeftColor &&
    `border-left: solid thick ${
      theme.colors[$borderLeftColor] || $borderLeftColor
    };`
  };
`,
);

// This allows for chips to be long pressed on mobile to reveal tooltips
export const ChipWrapper = styled.div`
  display: flex;
`;

interface ChipProps {
  children?: React.JSX.Element | string;
  tooltip?: string;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  italic?: boolean;
  borderLeftColor?: string;
  dataTour?: string;
  className?: string;
}

export const Chip = React.memo(
  ({
    children,
    tooltip,
    color,
    backgroundColor,
    borderColor,
    italic,
    borderLeftColor,
    dataTour,
    className,
  }: ChipProps) =>
    tooltip ? (
      <Tooltip title={tooltip} aria-label={tooltip}>
        <ChipElement
          data-tour={dataTour}
          $color={color}
          $backgroundColor={backgroundColor}
          $borderColor={borderColor}
          $italic={italic}
          $borderLeftColor={borderLeftColor}
          className={className}
        >
          {children}
        </ChipElement>
      </Tooltip>
    ) : (
      <ChipElement
        data-tour={dataTour}
        $color={color}
        $backgroundColor={backgroundColor}
        $borderColor={borderColor}
        $italic={italic}
        $borderLeftColor={borderLeftColor}
        className={className}
      >
        {children}
      </ChipElement>
    ),
);

export const ChipBag = styled.div(
  ({ theme }) => `
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: justify-start;
    border: 1px solid ${theme.colors.gray95};
    border-radius: ${theme.radii[1]}px;
    padding: ${theme.space[1]}px;
    
    @media (min-width: ${theme.breakpoints[0]}px) {
      margin:  ${theme.space[1]}px;
    }
    min-height: ${theme.space[4]}px
    `,
);
