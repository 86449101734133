import React from "react";

import { Chip } from "./BaseChip";

export const VendorBredChip = ({ value }) => {
  let message;
  if (value === null) {
    return false;
  } else if (value === Boolean(true)) {
    message = "Ven. Bred";
  } else {
    message = `Not Ven. Bred - ${value}`;
  }
  return (
    <Chip backgroundColor="white" borderColor="gray13">
      {message}
    </Chip>
  );
};
