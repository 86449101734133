import { manualAdjustmentSerializeMap } from "lib/serializers/manualAdjustments";
import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const deploymentSaleCreateSerializeMap: SerializerMap<DeploymentSale> = {
  auctions_plus_url: "auctions_plus_url",
  buyer_terms: "buyer_terms",
  default_buyers_premium: "default_buyers_premium",
  default_listing_fee_cents: "default_listing_fee_cents",
  default_vendor_commission: "default_vendor_commission",
  deployment_id: "deployment_id",
  export_sites: "export_sites",
  is_confirmed: "is_confirmed",
  is_sale_locked: "is_sale_locked",
  livestock_agency_id: "livestock_agency_id",
  livestock_agency_name: "livestock_agency_name",
  manual_vendor_numbers: "manual_vendor_numbers",
  market_report_notes: "market_report_notes",
  status: "status",
  use_lot_number_as_pen_number: "use_lot_number_as_pen_number",
  vendor_terms: "vendor_terms",
};

const deploymentSaleSerializeMap: SerializerMap<DeploymentSale> = {
  ...deploymentSaleCreateSerializeMap,
  comment_ids: "comment_ids",
  deployment_sale_id: "deployment_sale_id",
  // id: "id", // TODO API should send cbid
  is_nominations_locked: "is_nominations_locked",
  livestock_agency_id: "livestock_agency_id",
  livestock_agency_name: "livestock_agency_name",
  market_report_notes: "market_report_notes",
};

const consignableSaleSerializeMap: SerializerMap<ConsignableSale> = {
  cbid: "cbid",
  date: "date",
  defaultBuyerId: "default_buyer_id",
  defaultPropertyId: "default_property_id",
  defaultVendorId: "default_vendor_id",
  defaultVendorPropertyId: "default_vendor_property_id",
  deploymentCount: "deployment_count",
  deploymentSales: deploymentSaleSerializeMap,
  end_datetime: "end_datetime",
  hasCommercialChanges: "has_commercial_changes",
  hasComplianceChanges: "has_compliance_changes",
  index: "index",
  isConfirmed: "is_confirmed",
  lastRelatedDataUpdated: "last_related_data_updated",
  livestockSaleId: "livestocksale_id",
  locationPropertyId: "location_property_id",
  notes: "notes",
  permissions: "permissions",
  placardLogoUrl: "placard_logo_url",
  pricingTypeId: "pricing_type_id",
  rounds: "rounds",
  saleCode: "sale_code",
  saleSubTypeId: "sale_sub_type_id",
  saleTitle: "sale_title",
  saleType: "sale_type",
  saleWatcherLogoUrl: "sale_watcher_logo_url",
  saleyardId: "saleyard_id",
  saleyardName: "saleyard_name",
  showOnline: "show_online",
  speciesId: "species_id",
  start_datetime: "start_datetime",
  streamingVideo: "streaming_video",
  usingRegisteredBidders: "using_registered_bidders",
  watchLink: "watch_link",
};

const consignableSaleNestedObjectMap = {
  deployment_sales: "deploymentSales",
};

export const serializeConsignableSale = buildSerializer(
  consignableSaleSerializeMap,
  "sale",
  consignableSaleNestedObjectMap,
);

// Adds id to deserialized consignableSale
export const deserializeConsignableSale = apiResponse => {
  const deserializer = buildDeserializer(
    consignableSaleSerializeMap,
    "sale",
    consignableSaleNestedObjectMap,
  );
  const deserializedPayload = deserializer(apiResponse);
  deserializedPayload.id = deserializedPayload.livestockSaleId;
  return deserializedPayload;
};

const saleBaseSerializeMap: SerializerMap<SaleBase> = {
  block_non_admin_changing_weights: "block_non_admin_changing_weights",
  date: "date",
  default_buyer_id: "default_buyer_id",
  default_property_id: "default_property_id",
  default_vendor_id: "default_vendor_id",
  default_vendor_property_id: "default_vendor_property_id",
  end_datetime: "end_datetime",
  is_confirmed: "is_confirmed",
  livestocksale_id: "livestocksale_id",
  location_property_id: "location_property_id",
  notes: "notes",
  pricing_type_id: "pricing_type_id",
  rounds: "rounds",
  sale_sub_type_id: "sale_sub_type_id",
  sale_title: "sale_title",
  sale_type: "sale_type",
  saleyard_id: "saleyard_id",
  saleyard_name: "saleyard_name",
  species_id: "species_id",
  start_datetime: "start_datetime",
  using_registered_bidders: "using_registered_bidders",
};

const saleSerializeMap: SerializerMap<Sale> = {
  ...saleBaseSerializeMap,
  cbid: "cbid",
  deployment_count: "deployment_count",
  deployment_sales: deploymentSaleSerializeMap,
  has_commercial_changes: "has_commercial_changes",
  has_compliance_changes: "has_compliance_changes",
  index: "index",
  last_related_data_updated: "last_related_data_updated",
  permissions: "permissions",
  placard_logo_url: "placard_logo_url",
  sale_code: "sale_code",
  sale_watcher_logo_url: "sale_watcher_logo_url",
  show_online: "show_online",
  streaming_video: "streaming_video",
  watch_link: "watch_link",
};

const saleNestedObjectMap = {
  deployment_sales: "deployment_sales",
};

export const serializeSale = buildSerializer(
  saleSerializeMap,
  "sale",
  saleNestedObjectMap,
);

// Adds id to deserialized Sale
export const deserializeSale = apiResponse => {
  const deserializer = buildDeserializer(
    saleSerializeMap,
    "sale",
    saleNestedObjectMap,
  );
  const deserializedPayload = deserializer(apiResponse);
  deserializedPayload.id = deserializedPayload.livestocksale_id;
  return deserializedPayload;
};

const saleCreateSerializeMap: SerializerMap<SaleCreate> = {
  ...saleBaseSerializeMap,
  default_buyers_premium: "default_buyers_premium",
  default_listing_fee_cents: "default_listing_fee_cents",
  default_vendor_commission: "default_vendor_commission",
  deployment_ids: "deployment_ids",
  deployment_sales: deploymentSaleCreateSerializeMap,
  manual_adjustments: manualAdjustmentSerializeMap,
  manual_vendor_numbers: "manual_vendor_numbers",
};

const saleCreateNestedObjectMap = {
  deployment_sales: "deployment_sales",
  manual_adjustments: "manual_adjustments",
};

export const serializeSaleCreate = buildSerializer(
  saleCreateSerializeMap,
  "sale",
  saleCreateNestedObjectMap,
);
