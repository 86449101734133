import React from "react";

import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { Clickable } from "components/Button";
import { Row } from "components/Layout";
import { DialogTitle } from "components/MaterialDialog";
import {
  ReportTabActions,
  ReportFavouriteButton,
} from "components/SaleReports/ReportTabActions";

import { EMPTY_ARRAY } from "lib";

const TopRow = styled(Clickable)`
  ${({ theme }) => `  
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  color: ${theme.colors.primary};
  text-decoration: underline;
  padding: ${theme.space[2]}px;
  font-weight: ${theme.fontWeights.bold};
  transition: all 200ms ease-in-out;
  white-space: normal;
  &:hover {
    text-shadow: 0px 0px 1px rgba(200, 200, 200, 0.59);
  }
  `}
`;

const OptionText = styled.span`
  ${({ theme }) => `  
  color:${theme.colors.gray40};
  padding-right: ${theme.space[2]}px;
  font-size: ${theme.fontSizes.beta}px;
  line-height: ${theme.fontSizes.epsilon}px;
  width: 80%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-right: 1px solid ${theme.colors.gray95};
  `}
`;

const OptionRow = styled(Row)`
  padding: ${({ theme }) => `${theme.space[2]}px ${theme.space[2]}px`};
`;

const FaIconComponent = styled(FontAwesomeIcon)`
  ${({ theme, yflip, color }) => `
  transition: ${theme.transitions[0]};
  transform: scale(1, ${yflip ? -1 : 1});
  font-size: ${theme.fontSizes.gamma}px;
  margin: 0 ${theme.space[2]}px;
  color: ${color && theme.colors[color] ? theme.colors[color] : "#ccc"};
`}
`;
const FaIcon = React.memo(({ yflip, color, icon }) => (
  <FaIconComponent yflip={yflip} color={color} icon={icon} />
));

function LinkList({ options }) {
  // Not all reports are sendable - if, in this list, they all are, or all aren't, it should render fine.
  // If some are, and some aren't, drop a spacer in there to keep things aligned.

  return options.map((option, index) => {
    const { name: linkName, slug } = option;
    return (
      <OptionRow
        fullWidth
        alignCenter
        justifyBetween
        key={`option-${index}`}
        padding={2}
      >
        <ReportFavouriteButton slug={slug} />
        <OptionText>{linkName}</OptionText>
        <ReportTabActions row={option} />
      </OptionRow>
    );
  });
}

function ReportsDropdown({ options = EMPTY_ARRAY, title, businessId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const isOpen = Boolean(anchorEl);
  const id = isOpen ? "simple-popover" : undefined;

  return (
    <div>
      <TopRow data-tour={title} onClick={handleClick}>
        {title}
        <FaIcon
          icon={faCaretUp}
          yflip={isOpen ? undefined : "show"}
          color="primary"
        />
      </TopRow>

      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DialogTitle onClose={handleClose}>&nbsp;</DialogTitle>
        <LinkList options={options} businessId={businessId} />
      </Popover>
    </div>
  );
}

ReportsDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  title: PropTypes.string.isRequired,
};

export default ReportsDropdown;
