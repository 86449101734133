import React from "react";

import { sortBy } from "lodash";
import { useSelector } from "react-redux";

import { Label } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";
import { CombinedFormikMissingFieldIndicator } from "components/MissingFieldsIndicator";

import { EMPTY_ARRAY } from "lib";

import { getDressingRanges, getWeightRangeBySaleRoundId } from "selectors";

import { useFieldValue } from "hooks";

const { OptionTogglerField } = require("components/Form/FormikControls");

export const DressingRangeField = ({
  name,
  disabled,
  showNeedsRoundMessage = false,
  showNoValuesMessage = false,
  requireWeightRanges = false,
  modelIds = EMPTY_ARRAY,
}) => {
  const saleRoundId = useFieldValue("sale_round_id");
  const weightRanges = useSelector(getWeightRangeBySaleRoundId(saleRoundId));

  const dressingRanges = useSelector(getDressingRanges);
  const dressingRangeOptions = sortBy(
    Object.values(dressingRanges),
    "order",
  ).map(range => {
    let dressingLabel = `${parseInt(
      range.dressingRangeMinPercent,
      10,
    )}-${parseInt(range.dressingRangeMaxPercent, 10)}%`;
    if (!range.dressingRangeMaxPercent) {
      dressingLabel = `${range.dressingRangeMinPercent}+%`;
    }
    return {
      label: dressingLabel,
      value: range.id,
    };
  });

  if (
    dressingRangeOptions.length > 0 &&
    (!requireWeightRanges || weightRanges.length !== 0)
  ) {
    return (
      <OptionTogglerField
        labelPosition="top"
        name={name}
        label="Est Dressing %"
        options={dressingRangeOptions}
        disabled={disabled}
        modelIds={modelIds}
        missingMLAFieldsWarningEnabled
      />
    );
  } else if (
    (dressingRangeOptions.length === 0 && showNoValuesMessage) ||
    (showNeedsRoundMessage && !saleRoundId)
  ) {
    const message =
      dressingRangeOptions.length === 0 && showNoValuesMessage
        ? "No Est Dressing % Options are configured for this Sale Round."
        : "Select a Sale Round to see available Est Dressing %.";
    return (
      <Column>
        <Row justifyBetween>
          <CombinedFormikMissingFieldIndicator
            missingMLAFieldsWarningEnabled
            missingNASFieldWarningEnabled={false}
            name={name}
            modelIds={modelIds}
          />
          <Label>Est Dressing %</Label>
        </Row>
        <div>{message}</div>
      </Column>
    );
  } else {
    return null;
  }
};
