export const FrameStructure = {
  SMALL: "Small",
  MEDIUM: "Medium",
  LARGE: "Large",
};

export const WeighLocations = {
  ON_FARM: "On Farm (All)",
  ON_FARM_SAMPLE: "On Farm (Sample)",
  SALEYARDS: "Saleyards (All)",
  SALEYARDS_SAMPLE: "Saleyards (Sample)",
};
export const AdvancedDraftingValidationStates = {
  ERROR: "error",
  WARNING: "warning",
  SUCCESS: "success",
  DISABLED: "disabled",
};
export const PregStatuses = {
  SIL: "SIL",
  STATION_MATED: "Station Mated",
  NOT_STATION_MATED: "Not Station Mated",
  SCANNED_EMPTY: "Scanned Empty",
};
export const PregStatusOptions = [
  PregStatuses.SIL,
  PregStatuses.STATION_MATED,
  PregStatuses.NOT_STATION_MATED,
  PregStatuses.SCANNED_EMPTY,
].map(ps => ({
  label: ps,
  value: ps,
}));

const ShearingStatus = {
  OFF_SHEAR: "Off Shear",
  SUMMER: "Summer",
  AUTUM: "Autumn",
  WINTER: "Winter",
  SPRING: "Spring",
  FULL_WOOL: "Full Wool",
  NA: "N/A",
};

export const Accreditation = {
  ALL: "All",
  SOME: "Some",
  NONE: "None",
};

export const AccreditationOptions = [{ label: "-", value: null }].concat(
  [Accreditation.ALL, Accreditation.SOME, Accreditation.NONE].map(acc => ({
    label: acc,
    value: acc,
  })),
);

export const VendorBredOptions = [{ label: "-", value: null }].concat(
  [Accreditation.ALL, Accreditation.NONE].map(acc => ({
    label: acc,
    value: acc,
  })),
);

const ShearingStatuses = Object.values(ShearingStatus);

export const ShearingStatusOptions = ShearingStatuses.map(shearingStatus => ({
  value: shearingStatus,
  label: shearingStatus,
}));

export const ACCREDITATION = {
  // NLIS/MLA accreditation programs
  accreditationAntibioticFree: "Antibiotic Free",
  accreditationEU: "EU",
  accreditationGrassFed: "Grass Fed",
  accreditationJBAS: "JBAS",
  accreditationPCAS: "PCAS",
  accreditationPTIC: "PTIC",
  accreditationNEE: "NEE",
  // AgriNous internal flag for when a user has explicitly flagged a salelot as vendor bred
  vendorBredOverride: "Vendor Bred",
  // Sheep advanced drafting acceditations
  animalHealthStatementOverride: "AHS",
  sixInOne: "6 IN 1",
  drenched: "Drenched",
  ojdVac: "OJD Vac",
  gudairApproved: "Gudair Approved",
  b12Vac: "B12 Vac",
  backline: "Backline/Dipped",
  MAndTS: "M&TS",
  SAEligible: "SA Eligible",
  painRelief: "Pain Relief",
};

export const ALL_ACCREDITATIONS = Object.keys(ACCREDITATION);
