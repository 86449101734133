import React from "react";

import { PropTypes } from "prop-types";
import styled from "styled-components/macro";

import Button from "components/Button";
import OutlineButton from "components/Button/OutlineButton";
import { Row } from "components/Layout";

import { colors } from "constants/theme";

const ActionButtonsWrapper = styled(Row)`
  padding: 12px;
  height: 76px;
  border-top: 1px solid rgba(200, 200, 200, 0.59);
  background-color: #f2f2f2;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
`;

const ActionOutlineButton = styled(OutlineButton)`
  font-size: 24px;
  font-weight: 300;
  line-height: 48px;
  margin-right: 24px;
  min-width: 102px;
  white-space: nowrap;
`;

const ActionButton = styled(Button)`
  font-size: 24px;
  font-weight: 300;
  line-height: 48px;
  min-width: 216px;
  ${({ disabled }) => disabled && "cursor: default;"};
`;

const getSellText = (allSold, price) => {
  if (allSold) {
    return "Update";
  } else if (price === 0) {
    return "Sell for $0";
  }
  return "Sell";
};

const ActionButtons = ({
  handleReset,
  handleUnSell,
  setNoSale,
  handleSell,
  enableSell,
  enableNoSale = true,
  enableUnSell,
  allSold,
  buyerId,
  price,
  enableEditMode,
  editMode,
}) => (
  <ActionButtonsWrapper justifyBetween>
    <Row>
      {enableUnSell ? (
        <ActionButton
          data-testid="actionButton:unsell"
          data-tour="unsell"
          color="inputError"
          key="reset-button"
          onClick={handleUnSell}
          style={{
            minWidth: "102px",
            marginRight: "24px",
          }}
        >
          Unsell
        </ActionButton>
      ) : (
        <ActionOutlineButton
          color="gray40"
          key="reset-button"
          onClick={handleReset}
          data-testid="actionButton:reset"
          data-tour="resetButton"
        >
          Reset
        </ActionOutlineButton>
      )}

      <ActionOutlineButton
        color="error"
        key="no-sale-button"
        onClick={setNoSale}
        disabled={!enableNoSale}
        data-testid="actionButton:noSale"
        data-tour="noSaleButton"
      >
        No Sale
      </ActionOutlineButton>
    </Row>

    {allSold && !editMode ? (
      <ActionButton
        key="sell-button"
        onClick={enableEditMode}
        style={
          price === 0
            ? { color: colors.black, backgroundColor: colors.warning }
            : {}
        }
        data-testid="actionButton:edit"
        data-tour="actionButton:edit"
      >
        Edit
      </ActionButton>
    ) : (
      <ActionButton
        data-tour="sellButton"
        key="sell-button"
        onClick={handleSell}
        disabled={!enableSell}
        price={price}
        buyerId={buyerId}
        style={
          price === 0 && !allSold
            ? { color: colors.black, backgroundColor: colors.warning }
            : {}
        }
        data-testid="actionButton:sell"
      >
        {getSellText(allSold, price)}
      </ActionButton>
    )}
  </ActionButtonsWrapper>
);

ActionButtons.propTypes = {
  handleReset: PropTypes.func.isRequired,
  handleUnSell: PropTypes.func.isRequired,
  setNoSale: PropTypes.func.isRequired,
  handleSell: PropTypes.func.isRequired,
  enableEditMode: PropTypes.func.isRequired,
  enableSell: PropTypes.bool,
  enableNoSale: PropTypes.bool,
  enableUnSell: PropTypes.bool,
  allSold: PropTypes.bool,
  buyerId: PropTypes.string,
  price: PropTypes.number,
  editMode: PropTypes.bool,
};

export default React.memo(ActionButtons);
