import React from "react";
import { MLAMissingFieldsIndicator } from "./MlaMissingFieldsIndicator";
import { NASMissingFieldsIndicator } from "./NasMissingFieldsIndicator";
import { CombinedMissingFieldIndicatorProps } from "./types";

export const CombinedMissingFieldIndicator: React.FC<
  CombinedMissingFieldIndicatorProps
> = ({ modelIds, onlyAdvancedFields = false, onlyGeneralFields = false }) => (
  <>
    <NASMissingFieldsIndicator keyValue={modelIds} />
    <MLAMissingFieldsIndicator
      keyValue={modelIds}
      onlyGeneralFields={onlyGeneralFields}
      onlyAdvancedFields={onlyAdvancedFields}
    />
  </>
);
