import React from "react";

import { useSelector } from "react-redux";

import { pluralize } from "lib/pluralize";

import { currentSaleSelector } from "selectors";

import { Chip } from "./BaseChip";

export const ImagesChip = ({ count }) => {
  const currentSaleIsOnline = useSelector(currentSaleSelector).show_online;

  if (!currentSaleIsOnline && count === 0) {
    return null;
  } else {
    const backgroundColor = currentSaleIsOnline && !count ? "red" : "white";
    return (
      <Chip
        backgroundColor={backgroundColor}
        borderColor="gray13"
        color={currentSaleIsOnline && !count}
      >
        {count} {pluralize("Image", count)}
      </Chip>
    );
  }
};
export const VideosChip = ({ hasVideo }) =>
  hasVideo && (
    <Chip backgroundColor="white" borderColor="gray13">
      1 Video
    </Chip>
  );
