import { useSelector } from "react-redux";

import { getCurrentSale, getIsSaleyardAdmin } from "selectors";

export const useBlockChangingWeights = hasWeight => {
  const blockNonAdminChangingWeights =
    useSelector(getCurrentSale)?.block_non_admin_changing_weights;

  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);

  return blockNonAdminChangingWeights && !isSaleyardAdmin && hasWeight;
};
