import * as Yup from "yup";

export const SundryTemplateValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required!"),
  note: Yup.string().required("Required!"),
  paidFromType: Yup.string().required("Required!"),
  paidToType: Yup.string().required("Required!"),
  taxType: Yup.string().required("Required!"),
  gstMethod: Yup.string().required("Required!"),
});
