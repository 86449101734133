import { intersection } from "lodash";
import { createSelector } from "reselect";

import { ExportSites } from "constants/exportSites";
import { GlobalSearchFields } from "constants/globalSearch";
import { ScanStatus } from "constants/scanner";

import { getConsignmentCode } from "lib/consignments";
import { isSaleLotInProgress, getSortedMarkDetails } from "lib/saleLot";

import {
  getAuctionPens,
  getConsignments,
  getGlobalSearchBySearchField,
  getCurrentDeploymentSalesList,
  getSaleLots,
  selectAuctionPenIdByEidLookup,
  selectAuctionPenIdBySaleLotIdLookup,
  selectAuctionPenIdsByConsignmentIdLookup,
  selectAuctionPenIdsByDeploymentSaleIdLookup,
  selectCanHaveProgenyBySaleLotIdLookup,
  selectConsignmentHasImageLookup,
  selectConsignmentHasVideoLookup,
  selectExceptionsBySaleLotIdLookup,
  selectSaleLotHasImageLookup,
  selectIsPostSaleBalancedByConsignmentIdLookup,
  selectIsSoldBySaleLotIdLookup,
  selectPenIdsByRoundIdLookup,
  selectSaleLotIdsByDeliveryPenIdLookup,
  selectScanStatusBySaleLotIdLookup,
  selectIsPreSaleBalancedByConsignmentIdLookup,
  selectVendorIdBySaleLotIdLookup,
  selectSaleLotHasVideoLookup,
  selectIsWeighedBySaleLotIdLookup,
  selectNASFieldWarningsByAuctionPenId,
} from "selectors";

import { selectIsIntegrationCompliantBySaleLotIdLookup } from "selectors/integrations";

import {
  saleLotsFilteredBuyerIdsComparator,
  saleLotsFilteredThirdPartyIdsComparator,
  saleLotsFilteredBuyerHashesComparator,
  saleLotsFilteredIsBuyerBalancedComparator,
  saleLotsFilteredIsCountedComparator,
  saleLotsFilteredHaveOverflowPenCompararator,
  saleLotsFilteredIsDeliveredComparator,
  saleLotsFilteredLabelsComparator,
  saleLotsFilteredPricingTypesComparator,
  saleLotsFilteredPropertyIdComparator,
  saleLotsFilteredCheckpointComparator,
} from "./comparators";
import {
  filterAndMapObjectIdsWithLookup,
  rawObjectFilterSelectorFactory,
} from "./lib";

const selectUnfilteredAuctionPenIds = createSelector(
  [getAuctionPens],
  auctionPens => Object.keys(auctionPens),
);

const selectIsAuctionsPlusCompliantAuctionPenIds = createSelector(
  [
    getSaleLots,
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredAuctionPenIds,
    getGlobalSearchBySearchField(GlobalSearchFields.IsAuctionsPlusCompliant),
  ],
  (
    saleLots,
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredAuctionPenIds,
    searchField,
  ) => {
    return searchField === null
      ? unfilteredAuctionPenIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isIntegrationCompliantBySaleLotIdLookup[saleLot.id][
                ExportSites.AUCTIONS_PLUS
              ] === searchField[0],
          )
          .map(saleLot => saleLot.auction_pen_id);
  },
);

const selectIsStockLiveCompliantAuctionPenIds = createSelector(
  [
    getSaleLots,
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredAuctionPenIds,
    getGlobalSearchBySearchField(GlobalSearchFields.IsStockLiveCompliant),
  ],
  (
    saleLots,
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredAuctionPenIds,
    searchField,
  ) => {
    return searchField === null
      ? unfilteredAuctionPenIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isIntegrationCompliantBySaleLotIdLookup[saleLot.id][
                ExportSites.STOCK_LIVE
              ] === searchField[0],
          )
          .map(saleLot => saleLot.auction_pen_id);
  },
);

const selectBuyerFilteredAuctionPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.Buyer,
  selectUnfilteredAuctionPenIds,
  saleLotsFilteredBuyerIdsComparator,
  "auction_pen_id",
);

const selectThirdPartyFilteredAuctionPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.ThirdParty,
  selectUnfilteredAuctionPenIds,
  saleLotsFilteredThirdPartyIdsComparator,
  "auction_pen_id",
);

const selectBuyerAndBuyerWayFilteredAuctionPenIds =
  rawObjectFilterSelectorFactory(
    getSaleLots,
    GlobalSearchFields.BuyerAndBuyerWay,
    selectUnfilteredAuctionPenIds,
    saleLotsFilteredBuyerHashesComparator,
    "auction_pen_id",
  );

const selectVendorFilteredAuctionPenIds = createSelector(
  [
    selectVendorIdBySaleLotIdLookup,
    selectAuctionPenIdBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Vendor),
    selectUnfilteredAuctionPenIds,
  ],
  (
    vendorIdBySaleLotIdLookup,
    auctionPenIdBySaleLotIdLookup,
    vendorIds,
    unfilteredAuctionPenIds,
  ) =>
    vendorIds === null
      ? unfilteredAuctionPenIds
      : Object.entries(vendorIdBySaleLotIdLookup)
          .filter(([ignored, vendorId]) => vendorIds.includes(vendorId))
          .map(
            ([saleLotId, ignored]) => auctionPenIdBySaleLotIdLookup[saleLotId],
          )
          .filter(Boolean),
);

const selectSaleRoundFilteredAuctionPenIds = createSelector(
  [
    selectPenIdsByRoundIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.SaleRound),
    selectUnfilteredAuctionPenIds,
  ],
  (penIdsByRoundIdLookup, saleRoundIds, unfilteredauctionPenIds) =>
    saleRoundIds === null
      ? unfilteredauctionPenIds
      : Object.entries(penIdsByRoundIdLookup)
          .filter(
            ([roundId, ignored]) =>
              roundId && saleRoundIds.includes(parseInt(roundId, 10)),
          )
          .reduce(
            (acc, [ignored, auctionPenIds]) => acc.concat(auctionPenIds),
            [],
          ),
);

const selectAgencyFilteredAuctionPenIds = createSelector(
  [
    getCurrentDeploymentSalesList,
    selectAuctionPenIdsByDeploymentSaleIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Agency),
    selectUnfilteredAuctionPenIds,
  ],
  (
    deploymentSales,
    auctionPenIdsByDeploymentSaleIdLookup,
    agencyIds,
    unfilteredAuctionPenIds,
  ) =>
    agencyIds === null
      ? unfilteredAuctionPenIds
      : deploymentSales
          .filter(deploymentSale =>
            agencyIds.includes(deploymentSale.livestock_agency_id),
          )
          .map(
            deploymentSale =>
              auctionPenIdsByDeploymentSaleIdLookup[
                deploymentSale.deployment_sale_id
              ],
          )
          .reduce((acc, auctionPenIds) => acc.concat(auctionPenIds), []),
);

const selectHasArrivedFilteredAuctionPenIds = createSelector(
  [
    getConsignments,
    selectAuctionPenIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasArrived),
    selectUnfilteredAuctionPenIds,
  ],
  (
    consignments,
    auctionPenIdsByConsignmentIdLookup,
    hasArrived,
    unfilteredAuctionPenIds,
  ) =>
    hasArrived === null
      ? unfilteredAuctionPenIds
      : Object.values(consignments)
          .filter(consignment => consignment.hasArrived === hasArrived[0])
          .reduce(
            (acc, consignment) =>
              acc.concat(auctionPenIdsByConsignmentIdLookup[consignment.id]),
            [],
          ),
);

const selectHasBuyerExceptionsFilteredAuctionPenIds = createSelector(
  [
    selectExceptionsBySaleLotIdLookup,
    selectAuctionPenIdBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasBuyerExceptions),
    selectUnfilteredAuctionPenIds,
  ],
  (
    exceptionsBySaleLotIdLookup,
    auctionPenIdBySaleLotIdLookup,
    hasBuyerExceptions,
    unfilteredAuctionPenIds,
  ) =>
    hasBuyerExceptions === null
      ? unfilteredAuctionPenIds
      : Object.entries(auctionPenIdBySaleLotIdLookup)
          .filter(
            ([saleLotId, ignored]) =>
              (exceptionsBySaleLotIdLookup[saleLotId].length === 0) ===
              hasBuyerExceptions[0],
          )
          .map(([ignored, auctionPenId]) => auctionPenId),
);
// I don't think this is relevant for pens (yet?)
const selectHasConsignmentScansFilteredAuctionPenIds =
  selectUnfilteredAuctionPenIds;

const selectHasOverflowFilteredAuctionPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.HasOverflow,
  selectUnfilteredAuctionPenIds,
  saleLotsFilteredHaveOverflowPenCompararator,
  "auction_pen_id",
);

const selectHasVendorExceptionsFilteredAuctionPenIds =
  selectUnfilteredAuctionPenIds;
const selectHasWeightFilteredAuctionPenIds = createSelector(
  [
    getSaleLots,
    selectIsWeighedBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasWeight),
    selectUnfilteredAuctionPenIds,
  ],
  (
    saleLots,
    isWeighedBySaleLotIdLookup,
    hasWeight,
    unfilteredAuctionPenIds,
  ) => {
    return hasWeight === null
      ? unfilteredAuctionPenIds
      : Object.values(saleLots)
          .filter(
            saleLot => isWeighedBySaleLotIdLookup[saleLot.id] === hasWeight[0],
          )
          .map(lot => lot.auction_pen_id);
  },
);

const selectIsBuyerBalancedFilteredAuctionPenIds =
  rawObjectFilterSelectorFactory(
    getSaleLots,
    GlobalSearchFields.IsBuyerBalanced,
    selectUnfilteredAuctionPenIds,
    saleLotsFilteredIsBuyerBalancedComparator,
    "auction_pen_id",
  );

const selectIsCountedFilteredAuctionPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.IsCounted,
  selectUnfilteredAuctionPenIds,
  saleLotsFilteredIsCountedComparator,
  "auction_pen_id",
);

const selectIsDeliveredFilteredAuctionPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.IsDelivered,
  selectUnfilteredAuctionPenIds,
  saleLotsFilteredIsDeliveredComparator,
  "auction_pen_id",
);

const selectIsEidCompliantFilteredAuctionPenIds = createSelector(
  [
    getSaleLots,
    selectScanStatusBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsEidCompliant),
    selectUnfilteredAuctionPenIds,
  ],
  (
    saleLots,
    scanStatusBySaleLotIdLookup,
    isEidCompliant,
    unfilteredAuctionPenIds,
  ) =>
    isEidCompliant === null
      ? unfilteredAuctionPenIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              ((scanStatusBySaleLotIdLookup[saleLot.id] || ScanStatus.PASS) ===
                ScanStatus.PASS) ===
              isEidCompliant[0],
          )
          .map(saleLot => saleLot.auction_pen_id),
);

const selectIsInProgressFilteredAuctionPenIds = createSelector(
  [
    getSaleLots,
    getConsignments,
    getGlobalSearchBySearchField(GlobalSearchFields.IsInProgress),
    selectUnfilteredAuctionPenIds,
  ],
  (saleLots, consignments, isInProgress, unfilteredAuctionPenIds) =>
    isInProgress === null
      ? unfilteredAuctionPenIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isSaleLotInProgress(
                saleLot,
                consignments[saleLot.consignment_id],
              ) === isInProgress[0],
          )
          .map(saleLot => saleLot.auction_pen_id),
);

const selectIsSoldFilteredAuctionPenIds = createSelector(
  [
    getSaleLots,
    selectIsSoldBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSold),
    selectUnfilteredAuctionPenIds,
  ],
  (saleLots, isSoldBySaleLotIdLookup, isSold, unfilteredAuctionPenIds) =>
    isSold === null
      ? unfilteredAuctionPenIds
      : Object.values(saleLots)
          .filter(saleLot => isSoldBySaleLotIdLookup[saleLot.id] === isSold[0])
          .map(saleLot => saleLot.auction_pen_id),
);

const selectIsVendorPostSaleBalancedFilteredAuctionPenIds = createSelector(
  [
    getSaleLots,
    selectIsPostSaleBalancedByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsPostSaleVendorBalanced),
    selectUnfilteredAuctionPenIds,
  ],
  (
    saleLots,
    isPostSaleBalancedByConsignmentIdLookup,
    isVendorBalanced,
    unfilteredAuctionPenIds,
  ) =>
    isVendorBalanced === null
      ? unfilteredAuctionPenIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isPostSaleBalancedByConsignmentIdLookup[
                saleLot.consignment_id
              ] === isVendorBalanced[0],
          )
          .map(saleLot => saleLot.auction_pen_id),
);

const selectIsVendorPreSaleBalancedFilteredAuctionPenIds = createSelector(
  [
    getSaleLots,
    selectIsPreSaleBalancedByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsPreSaleVendorBalanced),
    selectUnfilteredAuctionPenIds,
  ],
  (
    saleLots,
    isPreSaleBalancedByConsignmentId,
    isVendorPresaleBalanced,
    unfilteredAuctionPenIds,
  ) =>
    isVendorPresaleBalanced === null
      ? unfilteredAuctionPenIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isPreSaleBalancedByConsignmentId[saleLot.consignment_id] ===
              isVendorPresaleBalanced[0],
          )
          .map(saleLot => saleLot.auction_pen_id),
);

const selectHasMissingNasFieldsAuctionPenIds = createSelector(
  [
    getAuctionPens,
    selectNASFieldWarningsByAuctionPenId,
    getGlobalSearchBySearchField(GlobalSearchFields.HasMissingNasFields),
    selectUnfilteredAuctionPenIds,
  ],
  (
    auctionPens,
    hasMissingNASFieldsAuctionPenIds,
    hasMissingNASFields,
    unfilteredAuctionPenIds,
  ) =>
    hasMissingNASFields === null
      ? unfilteredAuctionPenIds
      : Object.values(auctionPens)
          .filter(auctionPen => {
            return (
              hasMissingNASFieldsAuctionPenIds[auctionPen.id].length > 0 ===
              hasMissingNASFields[0]
            );
          })
          .map(auctionPen => auctionPen.id),
);

const selectAuctionPenFilteredAuctionPenIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.AuctionPen),
    selectUnfilteredAuctionPenIds,
  ],
  (auctionPenIds, unfilteredAuctionPenIds) =>
    auctionPenIds === null ? unfilteredAuctionPenIds : auctionPenIds,
);

const selectDeliveryPenFilteredAuctionPenIds = createSelector(
  [
    selectAuctionPenIdBySaleLotIdLookup,
    selectSaleLotIdsByDeliveryPenIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.DeliveryPen),
    selectUnfilteredAuctionPenIds,
  ],
  (
    auctionPenIdBySaleLotIdLookup,
    saleLotIdsByDeliveryPenIdLookup,
    deliveryPenIds,
    unfilteredAuctionPenIds,
  ) =>
    deliveryPenIds === null
      ? unfilteredAuctionPenIds
      : Object.entries(saleLotIdsByDeliveryPenIdLookup)
          .filter(([deliveryPenId, ignored]) =>
            deliveryPenIds.includes(deliveryPenId),
          )
          .reduce(
            (acc, [ignored, saleLotIds]) =>
              acc.concat(
                saleLotIds
                  .map(saleLotId => auctionPenIdBySaleLotIdLookup[saleLotId])
                  .filter(Boolean),
              ),
            [],
          ),
);

const selectOverflowPenFilteredAuctionPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.OverflowPen,
  selectUnfilteredAuctionPenIds,
  overFlowPens => saleLot => overFlowPens.includes(saleLot.id),
  "auction_pen_id",
);

const selectLaneFilteredAuctionPenIds = rawObjectFilterSelectorFactory(
  getAuctionPens,
  GlobalSearchFields.Lane,
  selectUnfilteredAuctionPenIds,
  lanes => auctionPen => lanes.some(lane => lane.includes(auctionPen.id)),
);

const selectLabelFilteredAuctionPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.Label,
  selectUnfilteredAuctionPenIds,
  saleLotsFilteredLabelsComparator,
  "auction_pen_id",
);

const selectPricingTypeFilteredAuctionPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.PricingType,
  selectUnfilteredAuctionPenIds,
  saleLotsFilteredPricingTypesComparator,
  "auction_pen_id",
);

const selectHasProgenyFilteredAuctionPenIds = createSelector(
  [
    getSaleLots,
    selectCanHaveProgenyBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasProgeny),
    selectUnfilteredAuctionPenIds,
  ],
  (
    saleLots,
    canHaveProgenyBySaleLotIdLookup,
    hasProgeny,
    unfilteredAuctionPenIds,
  ) =>
    hasProgeny === null
      ? unfilteredAuctionPenIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              canHaveProgenyBySaleLotIdLookup[saleLot.id] === hasProgeny[0],
          )
          .map(saleLot => saleLot.auction_pen_id),
);

const selectScanFilteredAuctionPenIds = createSelector(
  [
    selectAuctionPenIdByEidLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Scan),
    selectUnfilteredAuctionPenIds,
  ],
  (auctionPenIdByEidLookup, eids, unfilteredAuctionPenIds) =>
    eids === null
      ? unfilteredAuctionPenIds
      : eids.map(eid => auctionPenIdByEidLookup[eid]).filter(Boolean),
);

const selectVendorNumberFilteredAuctionPenIds = createSelector(
  [
    getConsignments,
    selectAuctionPenIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.VendorNumber),
    selectUnfilteredAuctionPenIds,
  ],
  (
    consignments,
    auctionPenIdsByConsignmentIdLookup,
    vendorNumbers,
    unfilteredAuctionPenIds,
  ) =>
    vendorNumbers === null
      ? unfilteredAuctionPenIds
      : Object.values(consignments)
          .filter(consignment =>
            vendorNumbers.includes(getConsignmentCode(consignment)),
          )
          .reduce(
            (acc, consignment) =>
              acc.concat(auctionPenIdsByConsignmentIdLookup[consignment.id]),
            [],
          ),
);

const selectVendorPicFilteredAuctionPenIds = createSelector(
  [
    getConsignments,
    selectAuctionPenIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.VendorPic),
    selectUnfilteredAuctionPenIds,
  ],
  (
    consignments,
    auctionPenIdsByConsignmentIdLookup,
    vendorPic,
    unfilteredAuctionPenIds,
  ) =>
    vendorPic === null
      ? unfilteredAuctionPenIds
      : Object.values(consignments)
          .filter(consignment =>
            vendorPic.includes(consignment.vendor_property_id),
          )
          .reduce(
            (acc, consignment) =>
              acc.concat(auctionPenIdsByConsignmentIdLookup[consignment.id]),
            [],
          ),
);

const selectBuyerPicFilteredAuctionPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.BuyerPic,
  selectUnfilteredAuctionPenIds,
  saleLotsFilteredPropertyIdComparator,
  "auction_pen_id",
);

const selectCheckpointFilteredAuctionPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.Checkpoint,
  selectUnfilteredAuctionPenIds,
  saleLotsFilteredCheckpointComparator,
  "auction_pen_id",
);

const selectIsSaleLotImageUploadedAuctionPenIds =
  filterAndMapObjectIdsWithLookup(
    getSaleLots,
    GlobalSearchFields.IsSaleLotImageUploaded,
    "auction_pen_id",
    selectUnfilteredAuctionPenIds,
    getConsignments,
    selectSaleLotHasImageLookup,
  );

const selectIsConsignmentImageUploadedAuctionPenIds =
  filterAndMapObjectIdsWithLookup(
    getConsignments,
    GlobalSearchFields.IsConsignmentImageUploaded,
    "auction_pen_id",
    selectUnfilteredAuctionPenIds,
    getSaleLots,
    selectConsignmentHasImageLookup,
    (consignment, objectKey, lots) => {
      const saleLots = Object.values(lots).filter(
        lot => lot.consignment_id === consignment.id,
      );
      return saleLots.map(saleLot => saleLot.auction_pen_id);
    },
  );

const selectIsSaleLotVideoUploadedAuctionPenIds =
  filterAndMapObjectIdsWithLookup(
    getSaleLots,
    GlobalSearchFields.IsSaleLotVideoUploaded,
    "auction_pen_id",
    selectUnfilteredAuctionPenIds,
    getConsignments,
    selectSaleLotHasVideoLookup,
  );

const selectIsConsignmentVideoUploadedAuctionPenIds =
  filterAndMapObjectIdsWithLookup(
    getConsignments,
    GlobalSearchFields.IsConsignmentVideoUploaded,
    "auction_pen_id",
    selectUnfilteredAuctionPenIds,
    getSaleLots,
    selectConsignmentHasVideoLookup,
    (consignment, objectKey, lots) => {
      const saleLots = Object.values(lots).filter(
        lot => lot.consignment_id === consignment.id,
      );
      return saleLots.map(saleLot => saleLot.auction_pen_id);
    },
  );

const selectReceivalPenAuctionPenIds = createSelector(
  [
    getConsignments,
    getGlobalSearchBySearchField(GlobalSearchFields.ReceivalPen),
    selectUnfilteredAuctionPenIds,
    selectAuctionPenIdsByConsignmentIdLookup,
  ],
  (
    consignments,
    receivalPens,
    unfilteredAuctionPenIds,
    auctionPenIdsByConsignmentIdLookup,
  ) =>
    receivalPens === null
      ? unfilteredAuctionPenIds
      : Object.values(consignments)
          .filter(consignment =>
            receivalPens.includes(consignment.receiving_pen),
          )
          .reduce(
            (acc, consignment) =>
              acc.concat(auctionPenIdsByConsignmentIdLookup[consignment.id]),
            [],
          ),
);

const selectMarksFilteredAutctionPenIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Marks),
    selectUnfilteredAuctionPenIds,
  ],
  (saleLots, marks, unfilteredAuctionPenIds) =>
    marks === null
      ? unfilteredAuctionPenIds
      : Object.values(saleLots)
          .filter(saleLot =>
            marks.some(mark => mark === getSortedMarkDetails(saleLot)),
          )
          .map(lot => lot.auction_pen_id),
);

export const selectFilteredAuctionPenIds = createSelector(
  [
    selectAgencyFilteredAuctionPenIds,
    selectAuctionPenFilteredAuctionPenIds,
    selectBuyerFilteredAuctionPenIds,
    selectBuyerAndBuyerWayFilteredAuctionPenIds,
    selectDeliveryPenFilteredAuctionPenIds,
    selectHasArrivedFilteredAuctionPenIds,
    selectHasBuyerExceptionsFilteredAuctionPenIds,
    selectHasMissingNasFieldsAuctionPenIds,
    selectHasConsignmentScansFilteredAuctionPenIds,
    selectHasOverflowFilteredAuctionPenIds,
    selectHasProgenyFilteredAuctionPenIds,
    selectHasVendorExceptionsFilteredAuctionPenIds,
    selectHasWeightFilteredAuctionPenIds,
    selectIsBuyerBalancedFilteredAuctionPenIds,
    selectIsCountedFilteredAuctionPenIds,
    selectIsDeliveredFilteredAuctionPenIds,
    selectIsEidCompliantFilteredAuctionPenIds,
    selectIsInProgressFilteredAuctionPenIds,
    selectIsSoldFilteredAuctionPenIds,
    selectIsVendorPreSaleBalancedFilteredAuctionPenIds,
    selectIsVendorPostSaleBalancedFilteredAuctionPenIds,
    selectLabelFilteredAuctionPenIds,
    selectLaneFilteredAuctionPenIds,
    selectOverflowPenFilteredAuctionPenIds,
    selectPricingTypeFilteredAuctionPenIds,
    selectSaleRoundFilteredAuctionPenIds,
    selectScanFilteredAuctionPenIds,
    selectThirdPartyFilteredAuctionPenIds,
    selectVendorFilteredAuctionPenIds,
    selectVendorNumberFilteredAuctionPenIds,
    selectVendorPicFilteredAuctionPenIds,
    selectBuyerPicFilteredAuctionPenIds,
    selectCheckpointFilteredAuctionPenIds,
    selectIsAuctionsPlusCompliantAuctionPenIds,
    selectIsStockLiveCompliantAuctionPenIds,
    selectIsSaleLotImageUploadedAuctionPenIds,
    selectIsConsignmentImageUploadedAuctionPenIds,
    selectIsSaleLotVideoUploadedAuctionPenIds,
    selectIsConsignmentVideoUploadedAuctionPenIds,
    selectReceivalPenAuctionPenIds,
    selectMarksFilteredAutctionPenIds,
  ],
  (
    agencyFilteredAuctionPenIds,
    auctionPenFilteredAuctionPenIds,
    buyerFilteredAuctionPenIds,
    buyerAndBuyerWayFilteredAuctionPenIds,
    deliveryPenFilteredAuctionPenIds,
    hasArrivedFilteredAuctionPenIds,
    hasBuyerExceptionsFilteredAuctionPenIds,
    hasConsignmentScansFilteredAuctionPenIds,
    hasOverflowFilteredAuctionPenIds,
    hasMissingNasFieldsdAuctionPenIds,
    hasProgenyFilteredAuctionPenIds,
    hasVendorExceptionsFilteredAuctionPenIds,
    hasWeightFilteredAuctionPenIds,
    isBuyerBalancedFilteredAuctionPenIds,
    isCountedFilteredAuctionPenIds,
    isDeliveredFilteredAuctionPenIds,
    isEidCompliantFilteredAuctionPenIds,
    isInProgressFilteredAuctionPenIds,
    isSoldFilteredAuctionPenIds,
    isVendorPreSaleBalancedFilteredAuctionPenIds,
    isVendorPostSaleBalancedFilteredAuctionPenIds,
    labelFilteredAuctionPenIds,
    laneFilteredAuctionPenIds,
    overflowPenFilteredAuctionPenIds,
    pricingTypeFilteredAuctionPenIds,
    saleRoundFilteredAuctionPenIds,
    scanFilteredAuctionPenIds,
    thirdPartyFilteredAuctionPenIds,
    vendorFilteredAuctionPenIds,
    vendorNumberFilteredAuctionPenIds,
    vendorPicFilteredAuctionPenIds,
    buyerPicFilteredAuctionPenIds,
    checkpointFilteredAuctionPenIds,
    isAuctionsPlusCompliantAuctionPenIds,
    isStockLiveCompliantAuctionPenIds,
    isSaleLotImageUploadedAuctionPenIds,
    isConsignmentImageUploadedAuctionPenIds,
    isSaleLotVideoUploadedAuctionPenIds,
    isConsignmentVideoUploadedAuctionPenIds,
    receivalPenAuctionPenIds,
    marksFilteredAutctionPenIds,
  ) =>
    intersection(
      agencyFilteredAuctionPenIds,
      auctionPenFilteredAuctionPenIds,
      buyerFilteredAuctionPenIds,
      buyerAndBuyerWayFilteredAuctionPenIds,
      deliveryPenFilteredAuctionPenIds,
      hasArrivedFilteredAuctionPenIds,
      hasBuyerExceptionsFilteredAuctionPenIds,
      hasConsignmentScansFilteredAuctionPenIds,
      hasOverflowFilteredAuctionPenIds,
      hasMissingNasFieldsdAuctionPenIds,
      hasProgenyFilteredAuctionPenIds,
      hasVendorExceptionsFilteredAuctionPenIds,
      hasWeightFilteredAuctionPenIds,
      isBuyerBalancedFilteredAuctionPenIds,
      isCountedFilteredAuctionPenIds,
      isDeliveredFilteredAuctionPenIds,
      isEidCompliantFilteredAuctionPenIds,
      isInProgressFilteredAuctionPenIds,
      isSoldFilteredAuctionPenIds,
      isVendorPreSaleBalancedFilteredAuctionPenIds,
      isVendorPostSaleBalancedFilteredAuctionPenIds,
      labelFilteredAuctionPenIds,
      laneFilteredAuctionPenIds,
      overflowPenFilteredAuctionPenIds,
      pricingTypeFilteredAuctionPenIds,
      saleRoundFilteredAuctionPenIds,
      scanFilteredAuctionPenIds,
      thirdPartyFilteredAuctionPenIds,
      vendorFilteredAuctionPenIds,
      vendorNumberFilteredAuctionPenIds,
      vendorPicFilteredAuctionPenIds,
      buyerPicFilteredAuctionPenIds,
      checkpointFilteredAuctionPenIds,
      isAuctionsPlusCompliantAuctionPenIds,
      isStockLiveCompliantAuctionPenIds,
      isSaleLotImageUploadedAuctionPenIds,
      isConsignmentImageUploadedAuctionPenIds,
      isSaleLotVideoUploadedAuctionPenIds,
      isConsignmentVideoUploadedAuctionPenIds,
      receivalPenAuctionPenIds,
      marksFilteredAutctionPenIds,
    ),
);
