import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { components } from "react-select";

import { SaleAction, setSetting } from "actions";

import SlimButton from "components/Button/SlimButton";

import { LOAD_MORE_SALES } from "constants/sale";
import { Settings } from "constants/settings";

import { getSetting, selectSaleOptionsV2 } from "selectors";

import { SelectField } from "./Select";

const SelectMenuButton = props => {
  const allSalesLoaded = useSelector(getSetting(Settings.allSalesLoaded));
  const dispatch = useDispatch();

  const loadAllSales = () => {
    dispatch(setSetting(Settings.allSalesLoaded, true));
    dispatch(SaleAction.request({ fetchAll: true }));
  };

  return (
    <components.MenuList {...props}>
      {props.children}
      {!allSalesLoaded && (
        <SlimButton fullWidth onClick={loadAllSales}>
          {LOAD_MORE_SALES}
        </SlimButton>
      )}
    </components.MenuList>
  );
};

export const SaleSelectField = ({ name, label }) => {
  const livestockSaleOptions = useSelector(selectSaleOptionsV2);

  return (
    <SelectField
      isClearable
      name={name}
      label={label}
      options={livestockSaleOptions}
      isMulti
      components={{
        MenuList: SelectMenuButton,
      }}
    />
  );
};
