import { simpleUserSerializerMap } from "lib/serializers/simpleUser";
import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const serializeMap = {
  created: "created",
  createdBy: simpleUserSerializerMap,
  defaultTenant: "default_tenant",
  deploymentId: "deployment_id",
  id: "id",
  integrationBusinessesLastSyncedAt: "integration_businesses_last_synced_at",
  integrationDocumentsLastSyncedAt: "integration_documents_last_synced_at",
  isEnabled: "is_enabled",
  message: "message",
  tenantBlob: "tenant_blob",
  type: "type",
  ingestExternalDocuments: "ingest_external_documents",
};

const nestedObjectMap = {
  created_by: "createdBy",
};

export const serializeIntegrationCredential = buildSerializer(
  serializeMap,
  "integrationCredential",
  nestedObjectMap,
);
export const deserializeIntegrationCredential = buildDeserializer(
  serializeMap,
  "integrationCredential",
  nestedObjectMap,
);
