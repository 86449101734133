import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Skeleton } from "@material-ui/lab";
import { isEmpty, uniq } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components/macro";

import { setSetting } from "actions";

import AuctionPenCard from "components/AuctionPens/Cards/AuctionPenCard";
import { TempPenCards } from "components/AuctionPens/Cards/TempPenCards";
import { LaneSetup } from "components/AuctionPens/LaneSetup";
import PenOrder from "components/AuctionPens/PenOrder";
import StatusCard from "components/Card/StatusCard";
import {
  CheckBoxComponentContainer,
  CheckBoxComponentContainerHeader,
} from "components/Checkbox/Checkbox";
import DeferredRender from "components/DeferredRender";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import MessageBox from "components/MessageBox";
import RenderData from "components/RenderData";
import { GlobalSearchHeader } from "components/SearchInput/GlobalSearch";

import { CheckboxContainerTypes } from "constants/checkboxContainerTypes";
import { ApiModel } from "constants/loading";
import { Settings } from "constants/settings";

import { EMPTY_ARRAY } from "lib";

import {
  getSaleyardAuctionRoute,
  openInlineCreateSaleLotModal,
} from "lib/navigation";

import {
  getAuctionPenById,
  getHasWriteAccessInCurrentSale,
  getRounds,
  getSaleLots,
  getSetting,
  getUnpennedSaleLotIdsBySaleRoundId,
  selectAuctionPenSearchIndex,
  selectFilteredSaleLotIds,
  selectSaleLotIdsByAuctionPenIdLookup,
  selectSaleLotIdsByRoundIdLookup,
  selectSaleLotSummaryByAuctionPenIdLookup,
} from "selectors";

import { useGetVisibleAndTempAuctionPenIds } from "hooks/useGetVisibleAndTempAuctionPenIds";

import PenActions from "./PenActions";
import { LaneStartLine, PenRangeIndicator } from "./PenBreaks";

const PEN_DETAIL_ROUTE = `${getSaleyardAuctionRoute(
  ":saleyard",
  ":saleId",
)}/auction-pen-cards`;
const REORDER_ROUTE = `${PEN_DETAIL_ROUTE}/reorder`;
const LANE_SETUP_ROUTE = `${PEN_DETAIL_ROUTE}/lane-setup`;

const BATCH_RENDER_COUNT = 40;

const PenWrapper = styled.div`
  padding-bottom: 12px;
`;

function PenPlaceholder() {
  return (
    <PenWrapper>
      <StatusCard>
        <Skeleton animation="wave" variant="text" />
        <Skeleton animation="wave" variant="rect" height={60} />
      </StatusCard>
    </PenWrapper>
  );
}

function PenComponent({
  auctionPenId,
  basePath,
  isOpen,
  setOpenCard,
  weighedOnly,
  showSelect,
}) {
  const auctionPen = useSelector(getAuctionPenById(auctionPenId));

  const auctionPenSelectedSaleLotIds =
    useSelector(getSetting(Settings.auctionPenSelectedSaleLotIds)) ||
    EMPTY_ARRAY;

  const dispatch = useDispatch();

  const setAuctionPenSelectedSaleLotIds = useCallback(
    saleLotIds => {
      dispatch(setSetting(Settings.auctionPenSelectedSaleLotIds, saleLotIds));
    },
    [dispatch],
  );

  useEffect(() => {
    if (auctionPenSelectedSaleLotIds.length && !showSelect) {
      setAuctionPenSelectedSaleLotIds([]);
    }
  }, [
    auctionPenSelectedSaleLotIds,
    setAuctionPenSelectedSaleLotIds,
    showSelect,
  ]);

  const saleLotIdsByAuctionPenId = useSelector(
    selectSaleLotIdsByAuctionPenIdLookup,
  );

  const saleLotIdsByRoundIdLookup = useSelector(
    selectSaleLotIdsByRoundIdLookup,
  );

  const selectedRoundId = useSelector(getSetting(Settings.round));

  const globalFilteredSaleLotIds = useSelector(selectFilteredSaleLotIds);

  const auctionPenAndSaleRoundSaleLotIds = useMemo(
    () =>
      saleLotIdsByAuctionPenId[auctionPenId].filter(
        saleLotId =>
          saleLotIdsByRoundIdLookup[selectedRoundId].includes(saleLotId) &&
          globalFilteredSaleLotIds.includes(saleLotId),
      ),
    [
      globalFilteredSaleLotIds,
      saleLotIdsByAuctionPenId,
      auctionPenId,
      saleLotIdsByRoundIdLookup,
      selectedRoundId,
    ],
  );

  const allAuctionPenSaleLotsAreSelected =
    auctionPenAndSaleRoundSaleLotIds.every(saleLotId =>
      auctionPenSelectedSaleLotIds.includes(saleLotId),
    );

  const someAuctionPenSaleLotsAreSelected =
    auctionPenAndSaleRoundSaleLotIds.some(saleLotId =>
      auctionPenSelectedSaleLotIds.includes(saleLotId),
    );

  const someButNotAllSaleLotsAreSelected =
    !allAuctionPenSaleLotsAreSelected && someAuctionPenSaleLotsAreSelected;

  const onChange = useCallback(() => {
    // if all sale lots are selected - remove the auction pen lots from selection
    if (allAuctionPenSaleLotsAreSelected) {
      setAuctionPenSelectedSaleLotIds(
        auctionPenSelectedSaleLotIds.filter(
          saleLotId => !auctionPenAndSaleRoundSaleLotIds.includes(saleLotId),
        ),
      );
      // if none or some of the lots are selected - select them all
    } else {
      setAuctionPenSelectedSaleLotIds(
        uniq(
          auctionPenSelectedSaleLotIds.concat(auctionPenAndSaleRoundSaleLotIds),
        ),
      );
    }
  }, [
    allAuctionPenSaleLotsAreSelected,
    setAuctionPenSelectedSaleLotIds,
    auctionPenSelectedSaleLotIds,
    auctionPenAndSaleRoundSaleLotIds,
  ]);

  return (
    <CheckBoxComponentContainer
      showSelect={showSelect}
      indeterminate={someButNotAllSaleLotsAreSelected}
      checked={allAuctionPenSaleLotsAreSelected}
      onChange={onChange}
      checkBoxClassName="h-135"
    >
      <PenWrapper>
        {auctionPenId ? (
          <>
            {auctionPen.isLaneStart && <LaneStartLine />}
            <PenRangeIndicator auctionPenId={auctionPenId} />
          </>
        ) : null}
        <AuctionPenCard
          auctionPenId={auctionPenId}
          key={auctionPenId}
          basePath={basePath}
          isOpen={isOpen}
          onExpand={setOpenCard}
          weighedOnly={weighedOnly}
          showSelect={showSelect}
        />
      </PenWrapper>
    </CheckBoxComponentContainer>
  );
}

export const PenCard = React.memo(PenComponent);

function PenList() {
  const [openCard, setOpenCard] = useState(undefined);
  const [openTempCard, setOpenTempCard] = useState(undefined);
  const selectedRoundId = useSelector(getSetting(Settings.round));

  const auctionPenSearchIndex = useSelector(selectAuctionPenSearchIndex);

  const unpennedSaleLotIds = useSelector(
    getUnpennedSaleLotIdsBySaleRoundId(selectedRoundId),
  );

  const [showSelect, setShowSelect] = React.useState(false);

  const [fusePenIds, setFusePenIds] = useState(null);

  const [visibleAuctionPenIds, tempPensIds] =
    useGetVisibleAndTempAuctionPenIds(fusePenIds);

  const showUnpenned =
    fusePenIds !== null
      ? // there is a search criteria
        fusePenIds.has(null)
      : // there are unpenned lots
        unpennedSaleLotIds?.length > 0;

  const quantityByAuctionPenId = useSelector(
    selectSaleLotSummaryByAuctionPenIdLookup,
  );

  const headInTempPen = useMemo(() => {
    return tempPensIds.length > 0
      ? tempPensIds.reduce((acc, penId) => {
          return acc + quantityByAuctionPenId[penId]?.quantity || 0;
        }, 0)
      : null;
  }, [quantityByAuctionPenId, tempPensIds]);

  function onBasicSearchTextChanged(searchText) {
    setFusePenIds(
      searchText
        ? new Set(
            auctionPenSearchIndex
              .search(searchText)
              .map(result => result.item.id),
          )
        : null,
    );
  }

  const extraHeaderComponents = [
    {
      Component: CheckBoxComponentContainerHeader,
      componentProps: {
        showSelect,
        setShowSelect,
        containerType: CheckboxContainerTypes.AUCTION_PEN,
        containerValuesArgs: { extraFilteredPenIds: fusePenIds },
      },
    },
  ];

  return (
    <>
      <GlobalSearchHeader
        actionButton={
          <PenActions hasVisiblePens={visibleAuctionPenIds.length > 0} />
        }
        onBasicSearchTextChanged={onBasicSearchTextChanged}
        includeRoundSelector
        showBasicSearch
        extraHeaderComponents={extraHeaderComponents}
      />

      {visibleAuctionPenIds.length === 0 && unpennedSaleLotIds?.length === 0 ? (
        <MessageBox>No pens found for the selected round</MessageBox>
      ) : (
        <>
          <TempPenCards
            tempPensIds={tempPensIds}
            quantity={headInTempPen}
            setOpenCard={setOpenCard}
            openCard={openCard}
            setOpenTempCard={setOpenTempCard}
            openTempCard={openTempCard}
            showSelect={showSelect}
          />
          {showUnpenned && (
            <PenCard
              auctionPenId={null}
              setOpenCard={setOpenCard}
              isOpen={openCard === null}
              showSelect={showSelect}
            />
          )}
          {visibleAuctionPenIds.map((auctionPenId, idx) => {
            return (
              <DeferredRender
                deferRenderBy={
                  idx < BATCH_RENDER_COUNT
                    ? null
                    : Math.round(idx / BATCH_RENDER_COUNT)
                }
                placeHolder={<PenPlaceholder />}
                key={auctionPenId}
              >
                <PenCard
                  auctionPenId={auctionPenId}
                  setOpenCard={setOpenCard}
                  isOpen={auctionPenId === openCard}
                  showSelect={showSelect}
                />
              </DeferredRender>
            );
          })}
        </>
      )}
    </>
  );
}

function AuctionPenCardListComponent() {
  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );
  const selectedRoundId = useSelector(getSetting(Settings.round));
  const hasSaleLots = useSelector(
    state => Object.keys(getSaleLots(state)).length > 0,
  );

  const openCreateSaleLotModal = () => {
    openInlineCreateSaleLotModal(
      { sale_round_id: selectedRoundId },
      {
        showDeliveryPen: false,
        showAuctionPenAtTop: true,
        includeCreateUnknownConsignment: true,
      },
    );
  };

  const actionText = hasWriteAccessInCurrentSale ? "Lot" : null;

  return (
    <RenderData
      test={hasSaleLots}
      infoText="No sale lots yet"
      onClick={hasWriteAccessInCurrentSale && openCreateSaleLotModal}
      actionText={actionText}
      dataTour="addAuctionPen"
      desktopRedirect="salelots"
    >
      <Switch>
        {hasWriteAccessInCurrentSale ? (
          <Route exact path={LANE_SETUP_ROUTE} component={LaneSetup} />
        ) : null}
        {hasWriteAccessInCurrentSale ? (
          <Route exact path={REORDER_ROUTE} component={PenOrder} />
        ) : null}
        <Route path={PEN_DETAIL_ROUTE} component={PenList} />
      </Switch>
    </RenderData>
  );
}

function AuctionPenCardList() {
  const rounds = useSelector(getRounds);
  if (isEmpty(rounds)) {
    // If we have no rounds, dont continue, it will crash.
    return;
  }

  return (
    <WaitForSync
      requiredData={[
        ApiModel.AGENCIES,
        ApiModel.CONSIGNMENTS,
        ApiModel.DEPLOYMENTS,
        ApiModel.BUSINESSES,
        ApiModel.AUCTION_PENS,
        ApiModel.SALE_LOTS,
        ApiModel.ROUNDS,
      ]}
    >
      <div id="pen-wrapper">
        <AuctionPenCardListComponent />
      </div>
    </WaitForSync>
  );
}

export default AuctionPenCardList;
