import React from "react";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { flatten } from "lodash";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { Chip, ChipWrapper } from "components/Chip";

import { Species } from "constants/species";

import { getNvdCattleWarnings, getNvdSheepWarnings } from "lib/nlisId";

const NVDChipComponent = props => {
  const { declarations, speciesId, vendorBred, euEligible } = props;
  let warnings = [];

  if (speciesId === Species.SHEEP && declarations.length > 0) {
    warnings = flatten(declarations.map(getNvdSheepWarnings));
  } else if (speciesId === Species.CATTLE && declarations.length > 0) {
    warnings = flatten(declarations.map(getNvdCattleWarnings));
  }

  const flattenedVendorBred = flatten(vendorBred);

  const flattenedEuEligible = flatten(euEligible);

  const areAllVendorBred =
    warnings.includes("Vendor Bred") ||
    (flattenedVendorBred.length > 0 && flattenedVendorBred.every(Boolean));

  const areAllEuEligible =
    flattenedEuEligible.length > 0 && flattenedEuEligible.every(Boolean);

  const warningString = warnings
    .filter(warning => warning !== "Vendor Bred")
    .join(", ");
  if (warnings) {
    return (
      <ChipWrapper>
        {areAllVendorBred && (
          <Chip color backgroundColor="success" tooltip="Vendor Bred">
            VB
          </Chip>
        )}
        {areAllEuEligible && (
          <Chip color backgroundColor="success" tooltip="EU Eligible">
            EU
          </Chip>
        )}
        {warningString && (
          <Chip color backgroundColor="warning" tooltip={warningString}>
            <FaIcon icon={faExclamationCircle} />
            &nbsp; NVD WARNING
          </Chip>
        )}
      </ChipWrapper>
    );
  } else {
    return null;
  }
};

export const NVDChip = React.memo(NVDChipComponent);
