import { UpdateInformationProps } from "components/BulkUpdateOptionalFieldsModal/types";
import React from "react";
import { useSelector } from "react-redux";
import { getSaleLots } from "selectors";
import { getIn, useFormikContext } from "formik";
import { getCheckboxFieldName } from "components/BulkUpdateOptionalFieldsModal/util";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { pluralize } from "lib/pluralize";
import isEqual from "lodash/isEqual";
import { uniqWith } from "lodash";
import { UpdateInformationWrapper } from "components/BulkUpdateOptionalFieldsModal/UpdateInformationComponents";

function objIncludes(collection: any[], value: any) {
  return collection.some(entry => isEqual(value, entry));
}

export function UpdateInformation(
  props: UpdateInformationProps,
): React.JSX.Element | null {
  // Note - the updateFieldNames - as in, compound fields - makes this function a little
  // more difficult than it could be - needing to compare (for instance, for condition)
  // [true, true, false] and [false, true, false], and working out whether they're different accounts for much of
  // the extra comparison-ey stuff.
  const { field, modelIds } = props;
  const { fieldName, emptyValue, updateFieldNames } = field;

  const saleLots = useSelector(getSaleLots);

  const { values } = useFormikContext();

  const isSelected = getIn(values, getCheckboxFieldName(fieldName));

  const fieldNames = updateFieldNames || [fieldName];

  const selectedValue = fieldNames.map(
    thisFieldName => getIn(values, thisFieldName) || field.emptyValue,
  );

  const existingValues = modelIds.map(saleLotId =>
    fieldNames.map(
      thisFieldName =>
        getIn(saleLots[saleLotId], thisFieldName) || field.emptyValue,
    ),
  );
  const uniqueValues = uniqWith(existingValues, isEqual);

  if (isSelected) {
    // Consider a warning based on the variations.
    if (uniqueValues.length === 1 && objIncludes(uniqueValues, selectedValue)) {
      // We're updating all values to the existing value.
      return (
        <UpdateInformationWrapper>
          All lots already have this value - there will be no change.
        </UpdateInformationWrapper>
      );
    }
    if (
      uniqueValues.length === 1 &&
      !objIncludes(uniqueValues, selectedValue) &&
      (objIncludes(uniqueValues, emptyValue) || uniqueValues.includes(null))
    ) {
      // The existing value is empty.  This is just setting values that weren't there, yet.
      return (
        <UpdateInformationWrapper>
          This will set the value in all Sale Lots.
        </UpdateInformationWrapper>
      );
    }

    if (
      uniqueValues.length === 1 &&
      !objIncludes(uniqueValues, selectedValue)
    ) {
      // We're overriding all values, and they're all the same
      return (
        <UpdateInformationWrapper
          icon={faExclamationCircle}
          backgroundColor="warning"
        >
          This will overwrite the value in all Sale Lots.
        </UpdateInformationWrapper>
      );
    }
    if (uniqueValues.length > 1) {
      // We're updating some lots to a different value to what they were.
      const numberOfChanges = existingValues.filter(
        val => !isEqual(val, selectedValue),
      ).length;
      return (
        <UpdateInformationWrapper
          icon={faExclamationCircle}
          backgroundColor="warning"
        >
          This will overwrite the value in {numberOfChanges}{" "}
          {pluralize("Sale Lot", numberOfChanges)}.
        </UpdateInformationWrapper>
      );
    }
  }

  if (uniqueValues.length === 1) {
    return (
      <UpdateInformationWrapper>
        All Sale Lots have the same existing value that will remain unchanged.
      </UpdateInformationWrapper>
    );
  }
  return (
    <UpdateInformationWrapper>
      {uniqueValues.length} different, existing{" "}
      {pluralize("value", uniqueValues.length)} will remain unchanged.
    </UpdateInformationWrapper>
  );
}
