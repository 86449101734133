import { baseVendorCommissionBandSerializeMap } from "lib/serializers/vendorCommissionBands";
import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

import { addressSerializeMap } from "./address";
import { insuranceRatesSerializeMap } from "./insuranceRates";
import { baseInterestSettingsSerializeMap } from "./interest";
import { tradingTermSerializeMap } from "./tradingTerms";

export const deploymentSettingsReportSerializeMap = {
  footerLayout: "footer_layout",
  headerLayout: "header_layout",
  primaryColor: "primary_color",
  secondaryColor: "secondary_color",
  detailsText: "details_text",
};

export const deploymentSettingsEmailSerializeMap = {
  appendNameToSalutation: "append_name_to_salutation",
  body: "body",
  salutation: "salutation",
};

export const deploymentSettingsSerializeMap = {
  abn: "abn",
  accountSalePrefix: "account_sale_prefix",
  autoAcceptCosmeticChangesInDraft: "auto_accept_cosmetic_changes_in_draft",
  autoAcceptFinancialChangesInDraft: "auto_accept_financial_changes_in_draft",
  bpayBillerCode: "bpay_biller_code",
  bpayBillerLongName: "bpay_biller_long_name",
  bpayBillerShortName: "bpay_biller_short_name",
  companyName: "company_name",
  contactPerson: "contact_person",
  defaultRetainedProceedsGlCode: "default_retained_proceeds_gl_code",
  emailAddress: "email_address",
  enableCompoundInterest: "enable_compound_interest",
  enableInterest: "enable_interest",
  faxNumber: "fax_number",
  financeReportLogoUrl: "finance_report_logo_url",
  generalEmailSettings: deploymentSettingsEmailSerializeMap,
  id: "id",
  invoicePrefix: "invoice_prefix",
  nextAccountSaleNumber: "next_account_sale_number",
  nextInvoiceNumber: "next_invoice_number",
  nextRetainedProceedsNumber: "next_retained_proceeds_number",
  paymentDirections: "payment_directions",
  phoneNumber: "phone_number",
  physicalAddress: addressSerializeMap,
  postalAddress: addressSerializeMap,
  rctiEmailSettings: deploymentSettingsEmailSerializeMap,
  rctiSettings: deploymentSettingsReportSerializeMap,
  retainedProceedsPrefix: "retained_proceeds_prefix",
  showPaymentMethodInPerson: "show_payment_method_in_person",
  showSplitDetailsOnRCTI: "show_split_detail_on_rcti",
  taxInvoiceEmailSettings: deploymentSettingsEmailSerializeMap,
  taxInvoiceSettings: deploymentSettingsReportSerializeMap,
  transitInsuranceForSales: insuranceRatesSerializeMap,
  transitInsuranceForPurchases: insuranceRatesSerializeMap,
  transitInsuranceApplicationDefault: "transit_insurance_application_default",
  transitInsuranceText: "transit_insurance_text",
  webAddress: "web_address",
};

export const validationOverridesSerializeMap = {
  excludeSaleSubTypes: "exclude_sale_sub_types",
  headThreshold: "head_threshold",
  validationLevel: "validation_level",
  validationName: "validation_name",
};

export const deploymentSerializeMap = {
  allowBusinessRelations: "allow_business_relations",
  buyerTerms: "buyer_terms",
  channel: "channel",
  code: "code",
  corporateColor: "corporate_color",
  defaultDebtorInsurance: "default_debtor_insurance",
  deploymentSettings: deploymentSettingsSerializeMap,
  id: "id",
  interestSettings: baseInterestSettingsSerializeMap,
  tradingTerms: tradingTermSerializeMap,
  livestockAgencyId: "livestock_agency_id",
  locations: "locations",
  logo: "logo",
  name: "name",
  species: "species",
  validationOverrides: validationOverridesSerializeMap,
  vendorCommissionBands: baseVendorCommissionBandSerializeMap,
  vendorTerms: "vendor_terms",
};

const nestedDeploymentSerializeMap = {
  deployment_settings: "deploymentSettings",
  general_email_settings: "generalEmailSettings",
  physical_address: "physicalAddress",
  postal_address: "postalAddress",
  rcti_email_settings: "rctiEmailSettings",
  rcti_settings: "rctiSettings",
  tax_invoice_email_settings: "taxInvoiceEmailSettings",
  tax_invoice_settings: "taxInvoiceSettings",
  validation_overrides: "validationOverrides",
  vendor_commission_bands: "vendorCommissionBands",
  interest_settings: "interestSettings",
  trading_terms: "tradingTerms",
  transit_insurance_for_sales: "transitInsuranceForSales",
  transit_insurance_for_purchases: "transitInsuranceForPurchases",
};

export const deserializeDeployment = buildDeserializer(
  deploymentSerializeMap,
  "deployment",
  nestedDeploymentSerializeMap,
);

export const serializeDeployment = buildSerializer(
  deploymentSerializeMap,
  "deployment",
  nestedDeploymentSerializeMap,
);
