import React, { useEffect, useMemo } from "react";

import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { Grid, Paper } from "@material-ui/core";
import isArray from "lodash/isArray";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import styled from "styled-components/macro";

import { IntegrationCredentialAction } from "actions/integrationCredentials";
import { XeroAccountingSettingsAction } from "actions/xeroAccountingSettings";

import { MultiButton } from "components/Button";
import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { LargeFaIcon, SmallFaIcon } from "components/FaIcon";
import { Button } from "components/Form";
import { CheckBoxBase } from "components/Form/FormikControls";
import { CenteredGreedy, Column, Row } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import MessageBox from "components/MessageBox";
import { TimeSince } from "components/TimeSince";

import { IntegrationTypes } from "constants/integrations";
import { ApiModel } from "constants/loading";
import { DeploymentPermissions } from "constants/permissions";

import { hasPermission } from "lib/permissions";
import { formatUTCToLocalDateTimeString } from "lib/timeFormats";

import {
  getActiveRoleDeployments,
  getDeploymentById,
  getDeployments,
  selectIntegrationCredentialIdsByTypeLookup,
} from "selectors";

import {
  getIntegrationCredentialById,
  selectIntegrationCredentialIdByTypeAndDeploymentIdLookup,
} from "selectors/integrationCredentials";

import { useBoolean } from "hooks";
import { ReactComponent as XeroConnect } from "img/xero/connect-blue.svg";
import { ReactComponent as XeroDisconnect } from "img/xero/disconnect-blue.svg";

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.space[3]}px;
`;

const PaddedPaper = styled(Paper)`
  ${({ theme }) => `
  padding: ${theme.space[3]}px;
  margin-bottom: ${theme.space[3]}px`}
`;

const XeroHelp = () => {
  return (
    <p>
      Xero is an accounting package that can be integrated into AgriNous.
      Enabling the integration will allow you to match Xero businesses to
      AgriNous businesses facilitating seamless billing exports.
      <br />
      Creating a new integration, or reactivating an existing integration will
      redirect you to Xero where you will need to login and authorize
      AgriNous&apos; access to your organization.
    </p>
  );
};

const IntegrationTypeHelpMap = {
  [IntegrationTypes.Xero]: <XeroHelp />,
};

const IntegrationTypeDisconnectMap = {
  [IntegrationTypes.Xero]: (
    //   Maintain original ratio, but scale to a set 48px height. (222 / 43) * 48 (height) = 248
    <XeroDisconnect width={248} height={48} />
  ),
};

const IntegrationCredentialCard = ({ id }) => {
  const integrationCredential = useSelector(getIntegrationCredentialById(id));
  const tenants = useMemo(() => {
    try {
      const data = integrationCredential.tenantBlob
        ? JSON.parse(integrationCredential.tenantBlob)
        : [];
      if (!isArray(data)) {
        return [];
      } else {
        return data;
      }
    } catch (e) {
      // This gets redacted in developer data, so return an empty array.
      return [];
    }
  }, [integrationCredential]);

  const deployment =
    useSelector(getDeploymentById(integrationCredential.deploymentId)) || {};

  const tenantOptions = tenants.map(tenant => {
    return { value: tenant.tenantId, label: tenant.tenantName };
  });
  const [tenantId, setTenantId] = React.useState(
    integrationCredential.defaultTenant,
  );
  const [isConfirmDeleteOpen, openConfirmDelete, closeConfirmDelete] =
    useBoolean(false);

  const dispatch = useDispatch();

  const onDelete = () => {
    dispatch(IntegrationCredentialAction.delete(id));
    closeConfirmDelete();
  };

  const reactivate = () => {
    dispatch(IntegrationCredentialAction.activate(id, tenantId));
  };

  const loadBusinesses = () => {
    dispatch(IntegrationCredentialAction.loadBusinesses(id));
  };

  const syncAccountingSettings = () => {
    dispatch(IntegrationCredentialAction.syncLedgerAccounts(id));
  };

  const createTaxRates = () => {
    dispatch(IntegrationCredentialAction.createTaxRates(id));
  };

  const updateTenant = () => {
    dispatch(
      IntegrationCredentialAction.update(
        {
          id,
          defaultTenant: tenantId,
          isEnabled: true,
          message: null,
        },
        { changeReason: "Updated selected Tenant" },
      ),
    );
  };

  const setIngestExternalDocument = event => {
    const newValue = event.target.checked;
    dispatch(
      IntegrationCredentialAction.update(
        {
          id,
          ingestExternalDocuments: newValue,
        },
        { changeReason: `Set Ingest External Documents to ${newValue}` },
      ),
    );
  };

  const confirmMessage = (
    <div>
      This will remove the integration from AgriNous and remove any related data
      that may have been connected, including:
      <ul>
        <li>All Business Mappings</li>
        {/* <li>All Mapped Invoices</li> */}
      </ul>
    </div>
  );

  return (
    <>
      <PaddedPaper elevation={2}>
        <Grid container>
          <Grid item xs={12}>
            <h3>{deployment.name}</h3>
          </Grid>

          {integrationCredential.syncing ? (
            <CenteredGreedy>
              <LoadingSpinner />
            </CenteredGreedy>
          ) : (
            <>
              <Grid xs={12} item>
                {/* If we have a message, it's probably important. */}
                {integrationCredential.message ? (
                  <h2>{integrationCredential.message}</h2>
                ) : null}
              </Grid>

              <Grid item xs={9} sm={7} md={5} lg={3}>
                {tenantOptions.length > 1 ||
                !integrationCredential.defaultTenant ? (
                  <ReactSelect
                    defaultValue={tenantOptions.find(
                      tenantOption => tenantOption.value === tenantId,
                    )}
                    options={tenantOptions}
                    placeholder="Select a Tenant..."
                    onChange={option => setTenantId(option.value)}
                  />
                ) : (
                  (tenants || []).find(tenant => tenant.tenantId === tenantId)
                    ?.tenantName ||
                  (tenantId ? "Unknown Tenant selected" : "No Tenant selected")
                )}
              </Grid>
              {tenantOptions.length > 1 && (
                <Grid item xs={3} sm={3} md={3} lg={2}>
                  <Button
                    disabled={
                      !(
                        tenantId &&
                        tenantId !== integrationCredential.defaultTenant
                      )
                    }
                    onClick={updateTenant}
                  >
                    Set Tenant
                  </Button>
                </Grid>
              )}

              <Grid item xs={12}>
                <h4>
                  {integrationCredential.isEnabled ? (
                    <LargeFaIcon
                      icon={faCheckCircle}
                      title="This integration is currently enabled"
                      color="success"
                    />
                  ) : (
                    <LargeFaIcon
                      icon={faExclamationCircle}
                      title="This integration is currently disabled"
                      color="warning"
                    />
                  )}
                  &nbsp; Created by{" "}
                  {integrationCredential.createdBy
                    ? `${integrationCredential.createdBy.firstName} ${integrationCredential.createdBy.lastName}`
                    : "AgriNous Admin"}{" "}
                  on{" "}
                  {formatUTCToLocalDateTimeString(
                    integrationCredential.created,
                  )}
                </h4>
              </Grid>

              <Grid item xs={12}>
                <p>
                  {integrationCredential.integrationBusinessesLastSyncedAt ? (
                    <SmallFaIcon
                      icon={faCheckCircle}
                      title="This integration has synchronised"
                      color="success"
                    />
                  ) : (
                    <SmallFaIcon
                      icon={faExclamationCircle}
                      title="This integration has not yet synchronised"
                      color="warning"
                    />
                  )}
                  &nbsp; Business synced{" "}
                  {integrationCredential.integrationBusinessesLastSyncedAt
                    ? TimeSince({
                        utcDateTimeStr:
                          integrationCredential.integrationBusinessesLastSyncedAt,
                        tooltipPlacement: "right",
                      })
                    : "never"}
                </p>
                <p>
                  {integrationCredential.integrationDocumentsLastSyncedAt ? (
                    <SmallFaIcon
                      icon={faCheckCircle}
                      title="This integration has synchronised documents"
                      color="success"
                    />
                  ) : (
                    <SmallFaIcon
                      icon={faExclamationCircle}
                      title="This integration has not yet synchronised documents"
                      color="warning"
                    />
                  )}
                  &nbsp; Documents synced{" "}
                  {integrationCredential.integrationDocumentsLastSyncedAt
                    ? TimeSince({
                        utcDateTimeStr:
                          integrationCredential.integrationDocumentsLastSyncedAt,
                        tooltipPlacement: "right",
                      })
                    : "never"}
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                container
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button onClick={reactivate}>Reactivate Integration</Button>
                <Button
                  disabled={!integrationCredential.isEnabled}
                  onClick={loadBusinesses}
                >
                  Load Businesses
                </Button>

                <Button
                  disabled={!integrationCredential.isEnabled}
                  onClick={syncAccountingSettings}
                >
                  Sync Accounting Settings
                </Button>
                <Button
                  disabled={!integrationCredential.isEnabled}
                  onClick={createTaxRates}
                >
                  Create Tax Rates
                </Button>
                {integrationCredential.isEnabled ? (
                  <div
                    onClick={openConfirmDelete}
                    className="cursor-pointer flex flex-1"
                  >
                    {IntegrationTypeDisconnectMap[integrationCredential.type]}
                  </div>
                ) : (
                  <div onClick={reactivate}>
                    <XeroConnect className="cursor-pointer" />
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <CheckBoxBase
                  label="Ingest Documents From Xero"
                  onChange={setIngestExternalDocument}
                  value={integrationCredential.ingestExternalDocuments}
                  tooltip="If set, documents will created for businesses that are linked to a Xero business."
                />
              </Grid>
            </>
          )}
        </Grid>
      </PaddedPaper>
      <ConfirmDialog
        title={createModalTitle("this credential")}
        message={confirmMessage}
        isOpen={isConfirmDeleteOpen}
        onCancel={closeConfirmDelete}
        onDelete={onDelete}
        includeDeleteConfirmation
      />
    </>
  );
};

const AddIntegrationButton = ({ type }) => {
  const roleDeployments = useSelector(getActiveRoleDeployments);
  const allDeployments = useSelector(getDeployments);
  const deployments = roleDeployments.map(d => allDeployments[d.id]);

  const integrationCredentialIdByTypeAndDeploymentIdLookup = useSelector(
    selectIntegrationCredentialIdByTypeAndDeploymentIdLookup,
  );
  const dispatch = useDispatch();

  const onAdd = deploymentId => {
    dispatch(
      IntegrationCredentialAction.create(
        {
          deploymentId,
          type,
        },
        {
          redirectToActivate: true,
        },
      ),
    );
  };

  // Only show buttons for those with permission, and those that aren't already integrated into this type.
  const addButtons = Object.values(deployments)
    .filter(
      deployment =>
        hasPermission(
          deployment,
          DeploymentPermissions.canLinkExternalIntegration,
        ) &&
        !integrationCredentialIdByTypeAndDeploymentIdLookup[type]?.[
          deployment.id
        ],
    )
    .map(deployment => ({
      onClick: () => onAdd(deployment.id),
      title: `Add ${type} integration for ${deployment.name}`,
    }));

  if (addButtons.length === 1) {
    return (
      <div onClick={addButtons[0].onClick}>
        <XeroConnect className="cursor-pointer" />
      </div>
    );
  }

  if (addButtons.length > 0) {
    return (
      <Row justifyEnd>
        <MultiButton buttons={addButtons} />
      </Row>
    );
  }
  return null;
};

const IntegrationSet = ({ type }) => {
  const integrationCredentialIdsByType = useSelector(
    selectIntegrationCredentialIdsByTypeLookup,
  );

  return (
    <>
      <h1>{type}</h1>
      {IntegrationTypeHelpMap[type]}

      {integrationCredentialIdsByType[type] ? (
        <>
          {integrationCredentialIdsByType[type].map(id => (
            <IntegrationCredentialCard id={id} key={id} />
          ))}
          <AddIntegrationButton type={type} />
        </>
      ) : (
        <MessageBox>
          <Column>
            <AddIntegrationButton type={type} />
          </Column>
        </MessageBox>
      )}
    </>
  );
};

export const IntegrationSettings = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(XeroAccountingSettingsAction.request());
  }, [dispatch]);

  return (
    <Wrapper>
      <WaitForSync
        requiredData={[ApiModel.DEPLOYMENTS, ApiModel.INTEGRATION_CREDENTIALS]}
      >
        {Object.values(IntegrationTypes).map(type => (
          <IntegrationSet type={type} key={type} />
        ))}
      </WaitForSync>
    </Wrapper>
  );
};
