import React, { memo } from "react";
import { Chip } from "./BaseChip";

interface SpeciesChipsProps {
  ageName?: string;
  breedName?: string;
  categoryName?: string;
  gradeName?: string;
  sexName?: string;
}

// A bit of a bug in eslint around false positives with memos.
// https://github.com/jsx-eslint/eslint-plugin-react/issues/2760
// https://github.com/jsx-eslint/eslint-plugin-react/issues/2760#issuecomment-995896750
const Component: React.FC<SpeciesChipsProps> = ({
  ageName,
  breedName,
  categoryName,
  gradeName,
  sexName,
}): React.JSX.Element[] => [
  breedName && (
    <Chip key="breed" backgroundColor="blueHighlight">
      {breedName}
    </Chip>
  ),
  ageName && (
    <Chip key="age" backgroundColor="blueHighlight">
      {ageName}
    </Chip>
  ),
  sexName && (
    <Chip key="sex" backgroundColor="blueHighlight">
      {sexName}
    </Chip>
  ),
  gradeName && (
    <Chip key="grade" backgroundColor="blueHighlight">
      {gradeName}
    </Chip>
  ),
  categoryName && (
    <Chip key="category" backgroundColor="blueHighlight">
      {categoryName}
    </Chip>
  ),
];

export const SpeciesChippies = memo(Component);
