import React from "react";

import { useSelector } from "react-redux";

import { Label, OptionTogglerField } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";

import { getDentitionOptionsByAgeId } from "selectors";

import { useFieldValue } from "hooks";
import { EMPTY_ARRAY } from "lib";
import { CombinedFormikMissingFieldIndicator } from "components/MissingFieldsIndicator";

interface DentitionFieldProps {
  name: string;
  disabled: boolean;
  showNeedsAgeMessage: boolean;
  modelIds: string[];
}

export function DentitionField(props: DentitionFieldProps): React.JSX.Element {
  const { name, disabled, showNeedsAgeMessage, modelIds = EMPTY_ARRAY } = props;
  const ageId = useFieldValue("age_id");
  const dentitionOptions = useSelector(getDentitionOptionsByAgeId(ageId));
  if (dentitionOptions.length > 0) {
    return (
      <OptionTogglerField
        labelPosition="top"
        name={name}
        label="Est Dentition"
        options={dentitionOptions}
        disabled={disabled}
        modelIds={modelIds}
        overrideMissingFieldName="Dentition"
        missingNASFieldWarningEnabled
      />
    );
  }
  if (showNeedsAgeMessage && !ageId) {
    return (
      <Column>
        <Row justifyBetween>
          <Label>Est Dentition</Label>
          <CombinedFormikMissingFieldIndicator
            missingMLAFieldsWarningEnabled={false}
            missingNASFieldWarningEnabled
            overrideMissingFieldName="Dentition"
            name={name}
            modelIds={modelIds}
          />
        </Row>
        <div>
          The selected Sale Lots have mixed Age values. Please choose an Age to
          update the Selected Lots and view the corresponding Dentition values.
        </div>
      </Column>
    );
  }
  if (dentitionOptions.length === 0 && showNeedsAgeMessage) {
    return (
      <Column>
        <Label>Est Dentition</Label>
        <div>No Dentition Options are configured for this Age.</div>
      </Column>
    );
  }
  return null;
}
