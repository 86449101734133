import React, { useCallback, useMemo, useRef } from "react";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { getIn, useFormikContext } from "formik";
import { v4 as uuidv4 } from "uuid";
import AgGridTable from "components/AgGrid/AgGridContainer";
import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";
import { SubtleBadge } from "components/Badge";
import { BusinessRelationshipDialog } from "components/BusinessForm/Relationships/Form";
import { CollapseLabel, FormCollapse } from "components/Form";
import { withNamespace } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";

import { businessRelationsRateTypes } from "constants/businesses";
import { BusinessRelationColumnDef } from "constants/columnDefinitions/businessRelations";

import { pluralize } from "lib/pluralize";

import { useBoolean } from "hooks";
import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY } from "lib";

import { BusinessColId, BusinessColumnDef } from "constants/columnDefinitions";
import { getBusinesses, getSaleSubTypes, getSpecies } from "selectors";
import { upsertById } from "lib/arrayUtils";
import { AgGridTables } from "constants/aggrid";

const columnDefs = [
  ...Object.values(BusinessRelationColumnDef),
  {
    ...BusinessColumnDef[BusinessColId.NAME],
    headerName: "Business",
    // Don't show the business name as a link to the business - we lose the form state if we navigate and it's
    // not _that_ useful in this context.
    cellRenderer: undefined,
    cellRendererParams: undefined,
  },
];
const getRowId = params => params.data.relationship.id;

const defaultBusinessRelation = {
  id: null,
  relationType: null,
  relatedToId: null,
  rateType: businessRelationsRateTypes.PERCENTAGE,
  rateValue: 0,
  speciesId: null,
  saleType: null,
  saleSubTypeId: null,
};

interface BusinessRelationshipsTableProps {
  isOpen: boolean;
  onToggle: () => void;
  namespace?: string;
}

const BusinessRelationshipsTable = ({
  onToggle,
  isOpen,
  namespace: ns,
}: BusinessRelationshipsTableProps) => {
  const agGridInstance = useRef<AgGridReact>();

  const onGridReady = params => {
    agGridInstance.current = params;
  };

  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const relationshipsField = withNamespace(ns, "relationships");

  const businessLookup = useSelector(getBusinesses);

  const relationships = getIn(values, relationshipsField);
  const speciesByIdLookup = useSelector(getSpecies);
  const saleSubTypeByIdLookup = useSelector(getSaleSubTypes);

  const rowData = relationships.map(relationship => ({
    relationship,
    business: businessLookup[relationship.relatedToId] || EMPTY_ARRAY,
    species: speciesByIdLookup[relationship.speciesId] || null,
    saleSubType: saleSubTypeByIdLookup[relationship.saleSubTypeId] || null,
    saleType: relationship.saleType || null,
  }));

  const numRelationships = rowData?.length || 0;
  const header = (
    <Row>
      <SubtleBadge hasErrorBadge={numRelationships === 0}>
        Relationships
      </SubtleBadge>
      <CollapseLabel>
        {numRelationships} {pluralize("Relationship", numRelationships)}
      </CollapseLabel>
    </Row>
  );

  const saveRelationship = useCallback(
    relationship => {
      relationship.id = relationship.id || uuidv4();

      const newRelationships = upsertById(relationships, relationship);
      setFieldValue(relationshipsField, newRelationships);
      setFieldTouched(relationshipsField);

      // Force the rows to redraw so the context with this function is up to date!
      if (agGridInstance.current) {
        agGridInstance.current.api.redrawRows();
      }
    },
    [relationships, relationshipsField, setFieldTouched, setFieldValue],
  );

  const deleteRelationship = useCallback(
    relationshipId => {
      const newRelationships = relationships.filter(
        relationship => relationship.id !== relationshipId,
      );

      setFieldValue(relationshipsField, newRelationships, false);
      setFieldTouched(relationshipsField);
      // Force the rows to redraw so the context with this function is up to date!

      if (agGridInstance.current) {
        agGridInstance.current.api.redrawRows();
      }
    },
    [relationships, relationshipsField, setFieldTouched, setFieldValue],
  );

  const context = useMemo(
    () => ({
      saveRelationship,
      deleteRelationship,
    }),
    [deleteRelationship, saveRelationship],
  );

  const [isCreateOpen, openCreate, closeCreate] = useBoolean(false);

  const handleSubmit = formValues => {
    saveRelationship(formValues);
    closeCreate();
  };

  const additionalActions = useMemo(
    () => [
      {
        default: true,
        title: "Add Relationship",
        isDisabled: false,
        onClick: () => openCreate(),
        icon: faPlus,
        dataTour: "addNewRelationship",
      },
    ],
    [openCreate],
  );

  return (
    <>
      <FormCollapse
        isOpen={isOpen}
        dataTour="relationshipsCollapse"
        onToggle={onToggle}
        header={header}
      >
        <Column full>
          <AgGridTable
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            getRowId={getRowId}
            rowData={rowData}
            showGlobalSearchFilter={false}
            additionalActions={additionalActions}
            WrapperComponent={CollapseTableWrapper}
            context={context}
            headerJustifyContent="flex-end"
            tableName={AgGridTables.BUSINESS_RELATIONSHIPS}
          />
        </Column>
      </FormCollapse>
      {isCreateOpen && (
        <BusinessRelationshipDialog
          isOpen={isCreateOpen}
          initialValues={defaultBusinessRelation}
          handleSubmit={handleSubmit}
          handleClose={closeCreate}
        />
      )}
    </>
  );
};

export default BusinessRelationshipsTable;
