import React from "react";

import { faEye } from "@fortawesome/pro-light-svg-icons";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { flatten, get, groupBy, orderBy, uniq } from "lodash";
import { useSelector } from "react-redux";

import {
  ActionButton,
  ActionButtonContainer,
} from "components/AgGrid/ActionButton";
import { ColumnType } from "components/AgGrid/constants";

import { ACTION_COLUMN_ID, ACTION_COLUMN_NAME } from "constants/aggrid";
import { CommentColId, CommentColumnDef } from "constants/columnDefinitions";
import { Column, NominationDetailColId } from "constants/columns";
import { CommentTypes } from "constants/comments";
import { NominationPermissions } from "constants/permissions";

import { diffFormatter, diffRenderer } from "lib/agGrid/columns/diff";
import { LPAStatusRenderer } from "lib/agGrid/columns/lpaStatus";
import { nlisProgramsRenderer } from "lib/agGrid/columns/picStatus";
import {
  BusinessRenderer,
  SyRefBusinessRenderer,
} from "lib/agGrid/columns/shortCode";
import {
  kilogramsFormatter,
  kilogramsGetter,
} from "lib/agGrid/columns/totalMassGrams";
import { transitRenderer } from "lib/agGrid/columns/transitInsurance";
import getters from "lib/agGrid/getters";
import { booleanRenderer } from "lib/agGrid/renderers";
import { openEditNominationModal } from "lib/navigation";
import { aggridNominationTermTotalAccumulator } from "lib/nominations";
import { hasPermission } from "lib/permissions";

import {
  getCurrentSale,
  getSpeciesAttributes,
  selectActiveNominationTermByIdLookup,
} from "selectors";

function NominationsActionsColumnRenderer(props) {
  const { data } = props;

  if (!data) {
    return null;
  }

  const { nomination } = data;

  const readOnly = !hasPermission(nomination, NominationPermissions.update);

  return (
    <ActionButtonContainer>
      <ActionButton
        data-tour="editNomination"
        type="button"
        onClick={() => openEditNominationModal(nomination.id, { readOnly })}
      >
        <FontAwesomeIcon icon={readOnly ? faEye : faPencil} />
        &nbsp;{readOnly ? `View` : `Edit`}
      </ActionButton>
    </ActionButtonContainer>
  );
}

function hideExpandedAggregationsCellRenderer(params) {
  const { value, node, valueFormatted } = params;
  const formattedValue = valueFormatted !== null ? valueFormatted : value;
  if (!node.group) {
    return formattedValue;
  } else if (!node.expanded) {
    return formattedValue;
  }
  return null;
}

export const useSelectNominationColumns = () => {
  const nominationTerms = useSelector(selectActiveNominationTermByIdLookup);
  const currentSale = useSelector(getCurrentSale);
  const speciesAttributes = useSelector(getSpeciesAttributes);

  const termsInUse = uniq(
    flatten(Object.values(nominationTerms))
      .filter(term => term.saleyardId === currentSale.saleyard_id)
      .map(term => term.speciesId === currentSale.species_id && term)
      .filter(Boolean),
  );

  const ordered = orderBy(termsInUse, "order");
  const grouped = groupBy(ordered, "groupName");

  const columnDefs = [
    {
      cellRenderer: booleanRenderer,
      colId: "hasArrived",
      enablePivot: true,
      enableRowGroup: true,
      field: "nomination.hasArrived",
      headerName: "Arrived",
      pinned: "left",
      width: 90,
    },
    {
      colId: "vendorName",
      enablePivot: true,
      enableRowGroup: true,
      field: "vendor.name",
      pinned: "left",
    },
    {
      colId: Column.BUSINESS_DISPLAY_NAME,
      enablePivot: true,
      enableRowGroup: true,
      field: "vendor.publicDisplayName",
      pinned: "left",
    },
    {
      colId: "agencyName",
      field: "agency.name",
      enablePivot: true,
      enableRowGroup: true,
    },
    {
      colId: Column.VENDOR_SHORT_CODE,
      field: "vendor.shortCode",
      headerName: "Agent Ref",
      width: 100,
      cellRenderer: BusinessRenderer,
      cellRendererParams: {
        complainIfMissing: true,
        businessKey: "vendor.id",
      },
      enablePivot: true,
      enableRowGroup: true,
    },
    {
      colId: "consigningDeployment",
      field: "consigningDeployment.name",
      headerName: "Consigned From",
      enablePivot: true,
      enableRowGroup: true,
    },
    {
      colId: NominationDetailColId.NOMINATION_TERM,
      field: "nominationDetail.nominationTermId",
      headerName: "Nomination Term",
      enablePivot: true,
      enableRowGroup: true,
      filterValueGetter: params => {
        const { colDef, node, context } = params;
        const nominationTermId = params.getValue(colDef.colId, node);
        const nominationTerm =
          context.nominationTermByIdLookup[nominationTermId];
        return `${nominationTerm?.groupName || ""} - ${
          nominationTerm?.name || ""
        }`;
      },
      useValueFormatterForExport: true,
      valueFormatter: params => {
        const { node, context, value } = params;
        if (!node.group) {
          const nominationTerm = context.nominationTermByIdLookup[value];
          return `${
            nominationTerm?.groupName || ""
          } - ${nominationTerm?.name || ""}`;
        }
        return null;
      },
    },
    ...Object.entries(grouped).map(([groupName, terms]) => ({
      headerName: groupName,
      children: [
        {
          aggFunc: "sum",
          colId: `groupedTermTotal.${groupName}`,
          type: "numericColumn",
          headerName: "Total",
          columnGroupShow: null,
          width: 90,
          valueGetter: params =>
            aggridNominationTermTotalAccumulator(terms, params),

          cellRenderer: hideExpandedAggregationsCellRenderer,
        },
        ...terms.reduce((acc, term) => {
          acc.push({
            aggFunc: "sum",
            colId: `nominationTerm.${term.id}`,
            headerName: term.name,
            field: `nominationDetail.quantity`,
            width: 90,
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            columnGroupShow: "open",
            enableValue: true,
            valueGetter: params => {
              const { colDef, node } = params;
              const nominationTermId = params.getValue(
                NominationDetailColId.NOMINATION_TERM,
                node,
              );
              if (term.id === nominationTermId) {
                return get(node.data, colDef.field);
              }
              return null;
            },
            cellRenderer: hideExpandedAggregationsCellRenderer,
          });
          return acc;
        }, []),
      ],
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      enableValue: true,
    })),
    {
      colId: Column.NOMINATION_GRAND_TOTAL,
      headerName: "Grand Total",
      field: "nominationDetails",
      valueGetter: params => {
        return aggridNominationTermTotalAccumulator(ordered, params);
      },
      type: "numericColumn",
      cellRenderer: hideExpandedAggregationsCellRenderer,
    },
    {
      colId: Column.NOMINATION_CONSIGNMENT_DIFF,
      headerName: "Consignment Diff",
      field: "consignmentDiff",
      valueFormatter: diffFormatter,
      cellRenderer: diffRenderer,
    },
    {
      headerName: "Est weight (kg/hd)",
      colId: NominationDetailColId.WEIGHT,
      field: `nominationDetail.estimatedWeightGrams`,
      width: 90,
      type: ColumnType.NUMERIC,
      valueGetter: kilogramsGetter,
      valueFormatter: kilogramsFormatter,
      columnGroupShow: "open",
    },
    {
      headerName: "Age",
      colId: NominationDetailColId.AGE,
      field: `nominationDetails.ageId`,
      width: 90,
      type: ColumnType.STRING,
      columnGroupShow: "open",
      valueGetter: params => {
        const nominationDetails = params?.data?.nominationDetail;
        const ageId = nominationDetails ? nominationDetails.ageId : null;
        return ageId ? speciesAttributes.ages[ageId]?.name || null : null;
      },
    },
    {
      headerName: "Breed",
      colId: NominationDetailColId.BREED,
      field: `nominationDetails.breedId`,
      width: 90,
      type: ColumnType.STRING,
      columnGroupShow: "open",
      valueGetter: params => {
        const nominationDetails = params?.data?.nominationDetail;
        const breedId = nominationDetails
          ? nominationDetails.breedId
          : undefined;
        return breedId ? speciesAttributes.breeds[breedId]?.name || null : null;
      },
    },
    {
      headerName: "Sex",
      colId: NominationDetailColId.SEX,
      field: `nominationDetails.sexId`,
      width: 90,
      type: ColumnType.STRING,
      columnGroupShow: "open",
      valueGetter: params => {
        const nominationDetails = params?.data?.nominationDetail;
        const sexId = nominationDetails ? nominationDetails.sexId : undefined;
        return sexId ? speciesAttributes.sexes[sexId]?.name || null : null;
      },
    },
    {
      headerName: "Notes",
      colId: NominationDetailColId.NOTES,
      field: `nominationDetail.notes`,
      width: 90,
      type: ColumnType.STRING,
      columnGroupShow: "open",
    },
    {
      colId: "c195eb8e-b9eb-4182-88a4-33fee7ca1786",
      headerName: "Agent",
      field: "agentRelationship",
    },
    {
      colId: "0f742cb4-7731-47e6-ad76-554bd60438e3",
      field: "vendorProperty.lpaStatus",
      headerName: "LPA #",
      width: 125,
      cellRenderer: LPAStatusRenderer,
    },
    {
      colId: "da3dc3c5-a892-4061-913f-c0d0f71a8cb0",
      field: "vendorProperty.nlisPrograms",
      headerName: "PIC Programs",
      width: 110,
      cellRenderer: nlisProgramsRenderer,
      valueGetter: getters.nlisProgramsGetter,
    },
    {
      colId: "d4758863-2dc7-4570-af03-53b18fbfcbd5",
      field: "vendor.hasTransitInsuranceForSales",
      headerName: "Sales TI",
      headerTooltip: "Transit Insurance For Sales",
      cellRenderer: transitRenderer,
      width: 60,
    },
    {
      colId: "07634156-79fd-4228-b753-eba418ccfcd0",
      field: "vendor.hasTransitInsuranceForPurchases",
      headerName: "Purchases TI",
      headerTooltip: "Transit Insurance For Purchases",
      cellRenderer: transitRenderer,
      width: 60,
    },
    {
      colId: "2bf967c1-6b59-4cae-8abe-8d2275cd9f9d",
      field: "vendor.emailRecipients",
      headerName: "Email Recipients",
      valueGetter: getters.emailRecipientGetter,
      width: 60,
    },
    {
      colId: Column.VENDOR_SHORT_CODE_SALEYARD,
      field: Column.VENDOR_SHORT_CODE_SALEYARD,
      headerName: "SY Ref",
      width: 100,
      cellRenderer: SyRefBusinessRenderer,
      cellRendererParams: {
        businessKey: "vendor_id",
      },
    },
    {
      ...CommentColumnDef[CommentColId.COMMENTS],
      valueGetter: params => {
        if (!params.data) {
          return null;
        }

        const { unresolvedCommentCount, resolvedCommentCount, nomination } =
          params.data;

        return {
          commentType: CommentTypes.NOMINATION,
          commentTypeId: nomination.id,
          unresolvedCommentCount,
          resolvedCommentCount,
        };
      },
    },
    {
      headerName: ACTION_COLUMN_NAME,
      cellRenderer: NominationsActionsColumnRenderer,
      colId: ACTION_COLUMN_ID,
      suppressCellFlash: true,
      width: 100,
    },
  ];
  return columnDefs;
};
