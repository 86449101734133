import React, { useCallback, useState } from "react";

import {
  faExclamationTriangle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faBrush as faBrushSolid } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { SubtleBadge } from "components/Badge";
import { IconTextButton } from "components/Button";
import {
  ConfirmDialog,
  createModalTitle,
  MESSAGE_CANT_BE_UNDONE,
} from "components/ConfirmDialog";
import { CollapseLabel, FormCollapse } from "components/Form";
import { GeneralSaleLotFormComponent } from "components/GeneralSaleLotForm";
import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { CombinedMissingFieldIndicator } from "components/MissingFieldsIndicator";
import { CurrentSaleReadOnly } from "components/ReadOnly";

import { ApiModel } from "constants/loading";
import { SaleTypes } from "constants/sale";

import { ForSaleType } from "containers/ForSaleType";

import { sectionHasErrors } from "lib/form";

const MarkIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => `
    margin-left: ${theme.space[1]}px;
    margin-right: ${theme.space[1]}px; 
  `}
  margin-top: 3px;
  display: inline-block;
`;

export function GeneralCollapseSection(props) {
  const {
    isOpen,
    onToggle,
    speciesAttributes,
    isNew,
    deleteSaleLot,
    closeSelf,
    rounds,
    deploymentId,
    readOnly,
    hasChangeSaleLotPermission,
  } = props;
  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);

  const setConfirmDeleteVisible = useCallback(
    () => setIsConfirmDeleteVisible(true),
    [setIsConfirmDeleteVisible],
  );
  const setConfirmDeleteHidden = useCallback(
    () => setIsConfirmDeleteVisible(false),
    [setIsConfirmDeleteVisible],
  );

  const formikProps = useFormikContext();

  const hasErrorBadge = sectionHasErrors(formikProps, [
    "quantity",
    "sale_round_id",
    "breed_id",
    "sex_id",
    "grade_id",
    "age_id",
    "category_id",
    "exemption_id",
    "note",
  ]);

  const {
    values: {
      quantity,
      sale_round_id,
      breed_id,
      sex_id,
      grade_id,
      category_id,
      marks,
      scans = [],
      id,
    },
  } = formikProps;

  const headCountText = quantity && quantity > 0 ? `${quantity} ` : "";
  const roundText =
    speciesAttributes.rounds.find(round => round.value === sale_round_id)
      ?.label || "";
  const breedText =
    speciesAttributes.breeds.find(breed => breed.value === breed_id)?.label ||
    "";
  const sexText =
    speciesAttributes.sexes.find(sex => sex.value === sex_id)?.label || "";
  const gradeText =
    speciesAttributes.grades.find(grade => grade.value === grade_id)?.label ||
    "";
  const categoryText =
    speciesAttributes.categories.find(
      category => category.value === category_id,
    )?.label || "";

  const header = (
    <Row>
      <SubtleBadge hasErrorBadge={hasErrorBadge}>General</SubtleBadge>
      <Row alignCenter flexWrap>
        <CollapseLabel>
          {headCountText}
          {marks?.map(mark => (
            <React.Fragment key={mark.location}>
              <MarkIcon
                icon={faBrushSolid}
                color={mark.color}
                key={mark.location}
              />
              {mark.location}
            </React.Fragment>
          ))}{" "}
          <ForSaleType saleType={[SaleTypes.SALEYARD_AUCTION]}>
            {roundText}{" "}
          </ForSaleType>
          {breedText} {sexText} {gradeText} {categoryText}
        </CollapseLabel>
      </Row>
      <CombinedMissingFieldIndicator modelIds={id} onlyGeneralFields />
    </Row>
  );

  return (
    <>
      <FormCollapse
        isOpen={isOpen}
        onToggle={onToggle}
        hasErrorBadge={hasErrorBadge}
        header={header}
        id="collapse-general"
        dataTour={isOpen ? "hideGeneral" : "showGeneral"}
      >
        <GeneralSaleLotFormComponent
          rounds={rounds}
          deploymentId={deploymentId}
          readOnly={readOnly}
          hasChangeSaleLotPermission={hasChangeSaleLotPermission}
          isNew={isNew}
        />
        <CurrentSaleReadOnly>
          {!isNew && (
            <IconTextButton
              icon={faTrash}
              color="gray40"
              onClick={setConfirmDeleteVisible}
              dataTour="deleteSaleLot"
              disabled={!hasChangeSaleLotPermission}
            >
              Delete Sale Lot
            </IconTextButton>
          )}
        </CurrentSaleReadOnly>
      </FormCollapse>
      <WaitForSync requiredData={[ApiModel.AUCTION_PENS, ApiModel.SALE_LOTS]}>
        <ConfirmDialog
          title={createModalTitle("this Sale Lot")}
          isOpen={isConfirmDeleteVisible}
          messageIcon={scans.length > 0 ? faExclamationTriangle : null}
          message={
            scans.length > 0 ? (
              <>
                This Sale Lot contains Scans. Are you sure you want to delete
                it? {MESSAGE_CANT_BE_UNDONE}
              </>
            ) : (
              MESSAGE_CANT_BE_UNDONE
            )
          }
          onCancel={setConfirmDeleteHidden}
          onDelete={() => {
            deleteSaleLot();
            closeSelf();
          }}
        />
      </WaitForSync>
    </>
  );
}

GeneralCollapseSection.propTypes = {
  closeSelf: PropTypes.func.isRequired,
  deleteSaleLot: PropTypes.func.isRequired,
  deploymentId: PropTypes.number,
  isNew: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  speciesAttributes: PropTypes.object.isRequired,
};

export default GeneralCollapseSection;
