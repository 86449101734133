import React from "react";
import { Chip } from "./BaseChip";

interface NullableChipProps {
  value: string;
  label: string;
}

export const NullableChip = ({ value, label }: NullableChipProps) => {
  if (value && label) {
    return (
      <Chip>
        <div>
          {label}: {value}
        </div>
      </Chip>
    );
  }
  return null;
};
