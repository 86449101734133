import { createOffline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import { applyMiddleware, compose, createStore } from "redux";
import { END } from "redux-saga";

import { getLogRocketMiddleware } from "lib/logRocket/middleware";

import { persistCallback } from "index";
import discard from "offline/discard";
import { effect } from "offline/effect";
import queue from "offline/queue";
import retry from "offline/retry";
import rootReducer from "reducers";
import { persistOptions } from "store/persistConfig";
import { sagaMiddleware } from "store/util";

import {
  crashReporterMiddleware,
  wrongSaleDiscarderMiddleware,
} from "./middleware";

export default function configureStore() {
  const ourOfflineConfig = {
    ...offlineConfig,
    persistCallback: () => persistCallback(),
    persistOptions,
    effect,
    discard,
    queue,
    retry,
  };

  const {
    middleware: offlineMiddleware,
    enhanceReducer,
    enhanceStore,
  } = createOffline(ourOfflineConfig);

  const middlewareList = [
    sagaMiddleware,
    crashReporterMiddleware,
    wrongSaleDiscarderMiddleware,
    offlineMiddleware,
  ];

  // Logrocket should be the last middleware.
  const logRocketMiddleware = getLogRocketMiddleware();
  if (logRocketMiddleware) {
    middlewareList.push(logRocketMiddleware);
  }

  const middleware = applyMiddleware(...middlewareList);

  const store = createStore(
    enhanceReducer(rootReducer),
    compose(enhanceStore, middleware),
  );

  if (window.Cypress) {
    window.store = store;
  }

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
}
