import React from "react";

import { useSelector } from "react-redux";

import { getSaleLotById } from "selectors";

import { Chip } from "./BaseChip";

export const StudSaleChip = ({ saleLotId }) => {
  const { draftingAttributes = {} } =
    useSelector(getSaleLotById(saleLotId)) || {};

  if (!draftingAttributes) {
    return null;
  }

  const { tagNumber, animalName } = draftingAttributes;

  return [
    tagNumber && (
      <Chip backgroundColor="successGreen" key="tagNumber">
        {`Tag # ${tagNumber}`}
      </Chip>
    ),
    animalName && (
      <Chip backgroundColor="successGreen" key="animalName">
        {animalName}
      </Chip>
    ),
  ].filter(Boolean);
};
