import { BulkUpdateFieldType } from "components/BulkUpdateOptionalFieldsModal/types";
import { BreedField } from "components/Form/Fields/BreedField";
import { AgeField } from "components/Form/Fields/AgeField";
import { SexField } from "components/Form/Fields/SexField";
import {
  DatePicker,
  Input,
  OptionTogglerField,
  SelectField,
} from "components/Form/FormikControls";
import {
  FrameStructure,
  ShearingStatusOptions,
} from "constants/draftingAttributes";
import { EstAvgWeightRangeField } from "components/WeightRange";
import { DressingRangeField } from "components/DressingRange";
import { UpdateInformation } from "./UpdateInformation";

// Note - the order of many of fields is operationally/aesthetically relevant as it's the order they're displayed
// in - don't alphabetize for neatness.

// All sales - sale data!
// Required
//    All sale data (location, head, type, etc)
//    From the lot - Quantity, Buyer Type (buyer PIC), Price, Price Type,

// All Sale Types
//   Required
//     Age, Sex

// Prime Cattle
//   Required
//     Live Weight (NOT in this dataset)
//   Optional
//     Breed, Fat Score, Muscle Score, Dressing Percentage

// Prime Sheep
//   Required
//     Estimated Carcasse Weight  OR Estimated Average Weight & Dressing Percenteage
//   Optional
//     Breed, Joining, Shearing Status

// Store Cattle
//   Required
//     Breed, Estimated Carcasse Weight OR Estimated Average Weight & Dressing Percenteage OR Estimated Dressing & LiveW eight
//   Optional
//     Fat Score, Muscle Score

// Store Sheep
//   Required
//     Frame Size
//   Optional
//     Breed, Joining

const age: BulkUpdateFieldType = {
  fieldName: "ageId",
  Component: AgeField,
  componentProps: {
    name: "ageId",
    label: "Age",
  },

  UpdateInformationComponent: UpdateInformation,
  emptyValue: null,
};

const breed: BulkUpdateFieldType = {
  fieldName: "breedId",
  Component: BreedField,
  componentProps: {
    name: "breedId",
    label: "Breed",
  },

  UpdateInformationComponent: UpdateInformation,
  emptyValue: null,
};

const sex: BulkUpdateFieldType = {
  fieldName: "sexId",
  Component: SexField,
  componentProps: {
    name: "sexId",
    label: "Sex",
  },

  UpdateInformationComponent: UpdateInformation,
  emptyValue: null,
};

const fatScore: BulkUpdateFieldType = {
  fieldName: "fatScore",
  Component: Input,
  componentProps: {
    label: "Fat Score",
    type: "number",
    disableAutoComplete: true,
    missingMLAFieldsWarningEnabled: true,
  },

  UpdateInformationComponent: UpdateInformation,
  emptyValue: null,
};

const muscleScore: BulkUpdateFieldType = {
  fieldName: "muscleScore",
  Component: Input,
  componentProps: {
    label: "Muscle Score",
    type: "number",
    disableAutoComplete: true,
    missingMLAFieldsWarningEnabled: true,
  },

  UpdateInformationComponent: UpdateInformation,
  emptyValue: null,
};

const estimatedAverageWeight: BulkUpdateFieldType = {
  fieldName: "estimatedAverageWeightId",
  Component: EstAvgWeightRangeField,
  componentProps: {
    showNeedsRoundMessage: true,
    missingRoundMessage:
      "Not all of the Sale Lots share the same round - select Sale Lots within the same round to update.",
    showNoValuesMessage: true,
  },

  UpdateInformationComponent: UpdateInformation,
  emptyValue: null,
};

const dressingRange: BulkUpdateFieldType = {
  fieldName: "dressingRangeId",
  Component: DressingRangeField,
  componentProps: {
    requireWeightRanges: false,
    showNoValuesMessage: true,
  },
  UpdateInformationComponent: UpdateInformation,
  emptyValue: null,
};

const frameStructure: BulkUpdateFieldType = {
  fieldName: "frameStructure",
  Component: OptionTogglerField,
  componentProps: {
    label: "Frame Structure",
    options: Object.values(FrameStructure),
    labelPosition: "top",
    allowToggleOff: true,
    missingMLAFieldsWarningEnabled: true,
  },
  UpdateInformationComponent: UpdateInformation,
  emptyValue: null,
};

const carcasseWeight = {
  fieldName: "estCarcassWeight",
  Component: Input,
  componentProps: {
    label: "Estimated Carcasse Weight",
    type: "number",
    disableAutoComplete: true,
    missingMLAFieldsWarningEnabled: true,
  },

  UpdateInformationComponent: UpdateInformation,
  emptyValue: null,
};

const joinedStart: BulkUpdateFieldType = {
  fieldName: "joinedStart",
  Component: DatePicker,
  componentProps: {
    label: "Joined Start",
    views: ["year", "month"],
    openTo: "month",
    missingMLAFieldsWarningEnabled: true,
  },

  UpdateInformationComponent: UpdateInformation,
  emptyValue: null,
};

const joinedEnd: BulkUpdateFieldType = {
  fieldName: "joinedEnd",
  Component: DatePicker,
  componentProps: {
    label: "Joined End",
    views: ["year", "month"],
    openTo: "month",
    missingMLAFieldsWarningEnabled: true,
  },

  UpdateInformationComponent: UpdateInformation,
  emptyValue: null,
};
const shearing: BulkUpdateFieldType = {
  fieldName: "shearing",
  Component: DatePicker,
  componentProps: {
    label: "Shearing Date",
    views: ["year", "month"],
    openTo: "month",
    missingMLAFieldsWarningEnabled: true,
  },

  UpdateInformationComponent: UpdateInformation,
  emptyValue: null,
};
const shearingStatus: BulkUpdateFieldType = {
  fieldName: "shearingStatus",
  Component: SelectField,
  componentProps: {
    label: "Shearing Status",
    options: ShearingStatusOptions,
    isClearable: true,
    openTo: "month",
  },
  UpdateInformationComponent: UpdateInformation,
  emptyValue: null,
};

// Consider categories
// General, Weights, Sheep.
// (Or NO collapse titles? )

export const primeCattle: BulkUpdateFieldType[] = [
  age,
  breed,
  sex,
  fatScore,
  muscleScore,
  dressingRange,
  carcasseWeight,
];

export const storeCattle: BulkUpdateFieldType[] = [
  age,
  breed,
  sex,
  estimatedAverageWeight,
  dressingRange,
  carcasseWeight,
  fatScore,
  muscleScore,
];

export const allCattle: BulkUpdateFieldType[] = [
  age,
  breed,
  sex,
  fatScore,
  muscleScore,
  estimatedAverageWeight,
  dressingRange,
  carcasseWeight,
];

export const primeSheep: BulkUpdateFieldType[] = [
  age,
  breed,
  sex,
  estimatedAverageWeight,
  carcasseWeight,
  dressingRange,
  joinedStart,
  joinedEnd,
  shearing,
  shearingStatus,
];
export const storeSheep: BulkUpdateFieldType[] = [
  age,
  breed,
  sex,
  frameStructure,
  joinedStart,
  joinedEnd,
];

export const allSheep: BulkUpdateFieldType[] = [
  age,
  breed,
  sex,
  estimatedAverageWeight,
  carcasseWeight,
  dressingRange,
  frameStructure,
  joinedStart,
  joinedEnd,
  shearing,
  shearingStatus,
];
