import React from "react";

import { useSelector } from "react-redux";

import Property from "components/Property";

import { selectPropertyIdByPICLookup } from "selectors";

import { Chip } from "./BaseChip";

export const PICChip = ({ businessId, PICs }) => {
  const propertyByPicLookup = useSelector(selectPropertyIdByPICLookup);

  return Array.isArray(PICs) && PICs.length ? (
    <Chip
      color="PICChipText"
      backgroundColor="PICChipBackground"
      tooltip={PICs.length > 1 && PICs.join(", ")}
    >
      {PICs.length === 1 ? (
        <Property
          businessId={businessId}
          propertyId={propertyByPicLookup[PICs[0]]}
        />
      ) : (
        `${PICs.length} PICs`
      )}
    </Chip>
  ) : null;
};
