import React, { memo, useCallback } from "react";

import flatten from "lodash/flatten";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Column } from "components/Layout";
import TabRow from "components/TabSelector/TabRow";

import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import {
  filterTabsBySaleType,
  LivestockAuctionSaleTypes,
  SaleTypes,
  TransactionalSaleTypes,
} from "constants/sale";
import { ScreenTypes } from "constants/screen";
import { Species } from "constants/species";
import { userTypes } from "constants/users";

import { SecurityTrimmedNavigationTab } from "containers/LivestockSales/SecurityTrimmedNavigationTab";
import { TranslatedSaleTypeLabel } from "containers/TranslatedSaleTypeLabel";

import { isUserOfType } from "lib/auth";
import {
  getLivestockSaleId,
  getSaleyardAuctionRoute,
  getSaleyardName,
} from "lib/navigation";
import { hasPermission, someHasPermission } from "lib/permissions";

import {
  getAuth,
  getCurrentSale,
  getHasWriteAccessInCurrentSale,
  getSaleLots,
  selectCurrentDeployments,
  selectCurrentSaleyard,
  selectHasSingleWeighInCurrentSale,
  selectRoleCurrentDeployments,
} from "selectors";

const showSaleButton = (
  auth,
  saleLots,
  selectedSaleId,
  saleType,
  hasWriteAccessInCurrentSale,
) => {
  if (
    hasWriteAccessInCurrentSale &&
    auth &&
    isUserOfType([userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN], auth) &&
    saleLots &&
    [
      SaleTypes.SALEYARD_AUCTION,
      SaleTypes.ON_FARM_AUCTION,
      SaleTypes.CLEARING,
    ].includes(saleType)
  ) {
    return Object.values(saleLots).some(
      l => l.auction_pen_id && l.livestocksale_id === selectedSaleId,
    );
  }
  return false;
};

const getMobileTabs = (
  auth,
  saleLots,
  saleId,
  usingRegisteredBidders,
  saleType,
  hasWriteAccessInCurrentSale,
  watchLink,
) => [
  {
    id: "overview",
    display: isUserOfType(
      [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
      auth,
    ),
  },
  {
    id: "nomination-cards",
    title: "Nominations",
    display: isUserOfType(
      [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
      auth,
    ),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(
        selectCurrentDeployments(state),
        DeploymentPermissions.featureNominations,
      ),
  },
  {
    id: "bidder-cards",
    title: "Bidders",
    display:
      usingRegisteredBidders &&
      hasWriteAccessInCurrentSale &&
      isUserOfType([userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN], auth),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      hasPermission(
        selectCurrentSaleyard(state),
        SaleyardPermissions.featureBidders,
      ),
  },

  {
    id: "consignment-cards",
    title: <TranslatedSaleTypeLabel label="Consignments" />,
    component: SecurityTrimmedNavigationTab,
    display: isUserOfType(
      [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
      auth,
    ),
    hasPermissionSelector: state =>
      someHasPermission(
        selectCurrentDeployments(state),
        DeploymentPermissions.featureConsignments,
      ),
  },

  {
    id: "arrival-scanning-cards",
    title: "Arrival Scanning",
    display: isUserOfType(
      [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
      auth,
    ),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      hasPermission(
        selectCurrentSaleyard(state),
        SaleyardPermissions.featureReceivalLots,
      ),
  },

  {
    id: "pen-scanning-cards",
    title: "Pen Scanning",
    display: isUserOfType(
      [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
      auth,
    ),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      hasPermission(
        selectCurrentSaleyard(state),
        SaleyardPermissions.featurePenScanLots,
      ),
  },
  {
    id: "weigh-scanning",
    title: "Weigh Scanning",
    display: isUserOfType(
      [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
      auth,
    ),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      hasPermission(
        selectCurrentSaleyard(state),
        SaleyardPermissions.featureWeighLots,
      ),
  },

  {
    id: "auction-pen-cards",
    title: "Pens",
    display:
      (isUserOfType(
        [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
        auth,
      ) &&
        LivestockAuctionSaleTypes.includes(saleType)) ||
      saleType === SaleTypes.EXTERNAL_AGENT_SALE,
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(
        selectCurrentDeployments(state),
        DeploymentPermissions.featurePens,
      ),
  },
  {
    id: "MLA",
    title: "MLA",
    display: isUserOfType(
      [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
      auth,
    ),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      hasPermission(
        selectCurrentSaleyard(state),
        SaleyardPermissions.featureMLASupplementaryData,
      ),
  },
  {
    id: "buyer-cards",
    title: "Buyers",
    display: isUserOfType(
      [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
      auth,
    ),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(
        selectCurrentDeployments(state),
        DeploymentPermissions.featureBuyers,
      ),
  },
  {
    id: "firstround",
    title: "Auction",
    display: showSaleButton(
      auth,
      saleLots,
      saleId,
      saleType,
      hasWriteAccessInCurrentSale,
    ),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(
        selectRoleCurrentDeployments(state),
        DeploymentPermissions.featureAuction,
      ),
  },
  {
    id: "eids",
    title: "EIDs",
    display: isUserOfType(
      [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
      auth,
    ),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(
        selectCurrentDeployments(state),
        DeploymentPermissions.featureScans,
      ),
  },
  {
    id: "reports",
    title: "Sale Reports",
    display: isUserOfType(
      [
        userTypes.LIVESTOCK_AGENT,
        userTypes.SALEYARD_ADMIN,
        userTypes.BUSINESS_USER,
      ],
      auth,
    ),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(
        selectCurrentDeployments(state),
        DeploymentPermissions.featureReports,
      ),
  },
  {
    id: "watch",
    title: "Watcher",
    display: Boolean(
      isUserOfType([userTypes.SALEYARD_ADMIN], auth) && watchLink,
    ),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      hasPermission(
        selectCurrentSaleyard(state),
        SaleyardPermissions.featureWatcher,
      ),
  },
  {
    id: "nlis",
    title: "NLIS",
    display: Boolean(isUserOfType([userTypes.SALEYARD_ADMIN], auth)),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      hasPermission(
        selectCurrentSaleyard(state),
        SaleyardPermissions.featureNlis,
      ),
  },
];

const getDesktopTabs = (
  auth,
  usingRegisteredBidders,
  saleType,
  saleSpeciesId,
  weighbridgeSpeciesIds,
  hasWriteAccessInCurrentSale,
  hasSingleWeighInCurrentSale,
  watchLink,
) =>
  [
    {
      id: "overview",
      display: isUserOfType(
        [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
        auth,
      ),
    },
    {
      id: "nominations",
      title: "Nominations",
      display: isUserOfType(
        [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
        auth,
      ),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        someHasPermission(
          selectCurrentDeployments(state),
          DeploymentPermissions.featureNominations,
        ),
    },
    {
      id: "bidder-cards",
      title: "Bidders",
      display:
        hasWriteAccessInCurrentSale &&
        usingRegisteredBidders &&
        isUserOfType(
          [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
          auth,
        ),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        hasPermission(
          selectCurrentSaleyard(state),
          SaleyardPermissions.featureBidders,
        ),
    },
    {
      id: "consignments",
      title: <TranslatedSaleTypeLabel label="Consignments" />,
      display:
        isUserOfType(
          [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
          auth,
        ) && !TransactionalSaleTypes.includes(saleType),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        someHasPermission(
          selectCurrentDeployments(state),
          DeploymentPermissions.featureConsignments,
        ),
    },
    {
      id: "consignment-cards",
      title: <TranslatedSaleTypeLabel label="Consignments" />,
      display:
        isUserOfType(
          [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
          auth,
        ) && TransactionalSaleTypes.includes(saleType),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        someHasPermission(
          selectCurrentDeployments(state),
          DeploymentPermissions.featureConsignments,
        ),
    },
    {
      id: "arrival-scanning",
      title: "Arrival Scanning",
      display: isUserOfType(
        [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
        auth,
      ),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        hasPermission(
          selectCurrentSaleyard(state),
          SaleyardPermissions.featureReceivalLots,
        ),
    },
    {
      id: "pen-scanning",
      title: "Pen Scanning",
      display: isUserOfType(
        [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
        auth,
      ),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        hasPermission(
          selectCurrentSaleyard(state),
          SaleyardPermissions.featurePenScanLots,
        ),
    },
    {
      id: "weigh-scanning",
      title: "Weigh Scanning",
      display: isUserOfType(
        [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
        auth,
      ),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        hasPermission(
          selectCurrentSaleyard(state),
          SaleyardPermissions.featureWeighLots,
        ),
    },
    {
      id: "salelots",
      title: "Sale Lots",
      display: isUserOfType(
        [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
        auth,
      ),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        someHasPermission(
          selectCurrentDeployments(state),
          DeploymentPermissions.featureSaleLots,
        ),
    },
    {
      id: "MLA",
      title: "MLA",
      display: isUserOfType(
        [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
        auth,
      ),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        hasPermission(
          selectCurrentSaleyard(state),
          SaleyardPermissions.featureMLASupplementaryData,
        ),
    },
    {
      id: "weighbridge",
      title: "Weigh Bridge",
      display:
        hasWriteAccessInCurrentSale &&
        isUserOfType(
          [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
          auth,
        ) &&
        (saleSpeciesId === Species.CATTLE ||
          weighbridgeSpeciesIds.includes(saleSpeciesId)),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        isUserOfType([userTypes.LIVESTOCK_AGENT], auth)
          ? someHasPermission(
              selectCurrentDeployments(state),
              DeploymentPermissions.featureBulkWeigh,
            )
          : hasPermission(
              selectCurrentSaleyard(state),
              SaleyardPermissions.featureBulkWeigh,
            ),
    },

    {
      id: "single-weigh",
      title: "SingleWeigh",
      display:
        hasWriteAccessInCurrentSale &&
        isUserOfType([userTypes.SALEYARD_ADMIN], auth) &&
        hasSingleWeighInCurrentSale,
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        hasPermission(
          selectCurrentSaleyard(state),
          SaleyardPermissions.featureSingleWeigh,
        ),
    },
    {
      id: "buyer-cards",
      title: "Buyers",
      display: isUserOfType(
        [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
        auth,
      ),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        someHasPermission(
          selectCurrentDeployments(state),
          DeploymentPermissions.featureBuyers,
        ),
    },
    {
      id: "eids",
      title: "EIDs",
      display: isUserOfType(
        [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
        auth,
      ),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        someHasPermission(
          selectCurrentDeployments(state),
          DeploymentPermissions.featureScans,
        ),
    },
    {
      id: "reports",
      title: "Sale Reports",
      display: isUserOfType(
        [
          userTypes.LIVESTOCK_AGENT,
          userTypes.SALEYARD_ADMIN,
          userTypes.BUSINESS_USER,
        ],
        auth,
      ),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        someHasPermission(
          selectCurrentDeployments(state),
          DeploymentPermissions.featureReports,
        ),
    },
    {
      id: "scans",
      title: "EIDs",
      display: isUserOfType([userTypes.BUSINESS_USER], auth),
    },
    {
      id: "watch",
      title: "Watcher",
      display: Boolean(
        isUserOfType([userTypes.SALEYARD_ADMIN], auth) && watchLink,
      ),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        hasPermission(
          selectCurrentSaleyard(state),
          SaleyardPermissions.featureWatcher,
        ),
    },
    {
      id: "nlis",
      title: "NLIS",
      display: Boolean(isUserOfType([userTypes.SALEYARD_ADMIN], auth)),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        hasPermission(
          selectCurrentSaleyard(state),
          SaleyardPermissions.featureNlis,
        ) || selectCurrentSaleyard(state)?.isAgentManagedNlisYard,
    },
    {
      id: "nlis",
      title: "NLIS",
      display: Boolean(isUserOfType([userTypes.LIVESTOCK_AGENT], auth)),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        selectCurrentSaleyard(state)?.isAgentManagedNlisYard,
    },
    {
      id: "billing",
      title: "Billing",
      display: Boolean(isUserOfType([userTypes.LIVESTOCK_AGENT], auth)),
      component: SecurityTrimmedNavigationTab,
      hasPermissionSelector: state =>
        someHasPermission(selectRoleCurrentDeployments(state), [
          DeploymentPermissions.featureBilling,
          DeploymentPermissions.featureManualCharges,
        ]),
    },
  ].filter(
    // By default include all tabs that have unspecified visibility or are explicitly visible,
    // This allows us to only include conflicting tab ids such as NLIS, which has two sets of
    // permission checks for different user types
    tab => tab.display === undefined || tab.display === true,
  );

function LivestockSaleTabsComponent() {
  const auth = useSelector(getAuth);
  const sale = useSelector(getCurrentSale);
  const saleLots = useSelector(getSaleLots);
  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );
  const screenType = useSelector(state => state.screen.type);

  const { params } = useRouteMatch();
  const history = useHistory();

  const setSection = useCallback(
    section => {
      history.push(
        `${getSaleyardAuctionRoute(
          getSaleyardName(),
          getLivestockSaleId(),
        )}/${section}`,
      );
    },
    [history],
  );
  const { section } = params;
  const {
    livestocksale_id: saleId,
    sale_type: saleType,
    species_id: speciesId,
    using_registered_bidders: usingRegisteredBidders,
    saleyard_id: saleyardId,
    watch_link: watchLink,
  } = sale;

  const weighbridgeSpeciesIds = React.useMemo(() => {
    // Salewatchers don't have saleyards on their active role, so need to check for that
    const weighbridgeSpeciesIds = auth.activeRole.saleyards
      ? flatten(
          auth.activeRole.saleyards
            .filter(saleyard => saleyard.saleyard_id === saleyardId)
            .map(saleyard => saleyard.weighbridge_species_ids),
        )
      : [];
    return weighbridgeSpeciesIds;
  }, [saleyardId, auth]);
  const hasSingleWeighInCurrentSale = useSelector(
    selectHasSingleWeighInCurrentSale,
  );

  const isDesktop = screenType === ScreenTypes.DESKTOP;

  const [tabsByUser, showTabs] = React.useMemo(() => {
    const tabsByUser = isDesktop
      ? getDesktopTabs(
          auth,
          usingRegisteredBidders,
          saleType,
          speciesId,
          weighbridgeSpeciesIds,
          hasWriteAccessInCurrentSale,
          hasSingleWeighInCurrentSale,
          watchLink,
        ).filter(filterTabsBySaleType(saleType, ScreenTypes.DESKTOP))
      : getMobileTabs(
          auth,
          saleLots,
          saleId,
          usingRegisteredBidders,
          saleType,
          hasWriteAccessInCurrentSale,
          watchLink,
        ).filter(filterTabsBySaleType(saleType, ScreenTypes.MOBILE));

    const showTabs = tabsByUser.filter(t => t.display).length > 0;
    return [tabsByUser, showTabs];
  }, [
    auth,
    saleLots,
    saleId,
    usingRegisteredBidders,
    saleType,
    speciesId,
    weighbridgeSpeciesIds,
    hasWriteAccessInCurrentSale,
    hasSingleWeighInCurrentSale,
    isDesktop,
    watchLink,
  ]);

  // Shortcircuit a few cases where tabs will not be shown - this could be wrapped in a
  // waitForSync
  if (isUserOfType(userTypes.SALE_WATCHER, auth) || section === "attachments") {
    return null;
  }

  return (
    <>
      {showTabs && (
        <Column printHidden>
          <TabRow
            tabs={tabsByUser}
            selectedTab={section}
            setSelectedTab={setSection}
            backgroundColor="gray95"
          />
        </Column>
      )}
    </>
  );
}

export default memo(LivestockSaleTabsComponent);
