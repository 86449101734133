import React from "react";

import { Chip } from "./BaseChip";

export const PenTotalChip = React.memo(
  ({ lotHeadCount, penHeadCount, penLotCount }) => {
    if (lotHeadCount === penHeadCount) {
      return null;
    }
    return (
      <Chip>
        {lotHeadCount} of {penHeadCount} Hd in pen ({penLotCount} Sale Lots)
      </Chip>
    );
  },
);
