import React, { useMemo } from "react";

import { faLink } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, useFormikContext } from "formik";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import AuditLogLink from "components/AuditLog/AuditLogLink";
import { DeploymentBusinessForm } from "components/BusinessForm/DeploymentBusinessForm";
import { TemporaryBusinessMessage } from "components/BusinessForm/TemporaryBusinessMessage";
import { AddButton as SyncButton } from "components/Button/CollapseInlineButton";
import { Button, SecondaryButton } from "components/Form";
import { addressValidationSchema } from "components/Form/Fields/AddressForm";
import { vendorCommissionBandsValidation } from "components/Form/FormikControls/VendorCommissionBands";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { AuditLogTypes } from "constants/auditLog";
import { ApiModel } from "constants/loading";
import { DeploymentPermissions } from "constants/permissions";

import { getBusinessSubType } from "lib/businesses";
import {
  mapBusinessValuesToInsuranceType,
  mapDefaultDeploymentTransitInsuranceToBusinessValues,
} from "lib/insuranceRates";
import { getSelectSyncDataHashRoute } from "lib/navigation";
import { ABNValidator } from "lib/validators";

import {
  getActiveLivestockAgentDeployment,
  getActiveSaleyardAdminSaleyard,
  getAgencyAllowsBusinessRelationsByAgencyId,
  getDefaultVendorSplitByBusinessId,
  getInterestSettingsByBusinessId,
  getIsLivestockAgent,
  getIsSaleyardAdmin,
  getPrimaryAgency,
  getVendorCommissionBandsByDeploymentBusinessId,
  selectRoleDeployments,
} from "selectors";

import {
  useHasDeploymentPermission,
  useSomeHasPermission,
} from "hooks/useHasPermission";

import { ValidationSchema as BankingDetailsValidationSchema } from "./FinanceAndAccounting/BankingDetailsForm";

const getBusinessValidationSchema = (
  options = { includeVendorCommissionBands: false },
) => {
  const shape = {
    name: Yup.string().max(100).required("Required"),
    publicDisplayName: Yup.string()
      .label("Public Display Name")
      .max(200)
      .nullable(),
    shortCode: Yup.string().label("Agent Account Reference").max(10).nullable(),
    shortCodeSaleyard: Yup.string()
      .label("Saleyard Account Reference")
      .max(10)
      .nullable(),
    slug: Yup.string()
      .label("Agent Account Reference")
      .matches(
        /^[[A-Za-z0-9\-_]+$/,
        "Agent Account Reference can only contain letters, numbers, underscores and hyphens",
      )
      .max(20)
      .nullable(),
    shortCodeAuctionsPlus: Yup.string()
      .label("Auctions Plus Shortcode")
      .max(50)
      .nullable(),
    abn: ABNValidator,
    bankingDetails: BankingDetailsValidationSchema.nullable(),
    address: addressValidationSchema.nullable(),
    bpayCustomerReferenceNumber: Yup.string()
      .matches(
        /^[0-9]{3,20}$/,
        "CRN must be all digits and between 3 and 20 in length",
      )
      .nullable(),
    generateBpayCRN: Yup.boolean(),
    myobCustomerCardId: Yup.string()
      .label("Customer Card Id")
      .max(15)
      .nullable(),
    myobSupplierCardId: Yup.string()
      .label("Supplier Card Id")
      .max(15)
      .nullable(),
    identicalCardIds: Yup.mixed().test(
      "Customer Card Ids are not identical",
      "The Customer Card ID and Supplier Card ID should not be identical.",
      (value, context) => {
        if (!context) {
          return;
        }
        // make sure customer card id and supplier card id are not identical
        const { myobCustomerCardId, myobSupplierCardId } = context.parent;
        if (
          myobCustomerCardId != null &&
          myobSupplierCardId != null &&
          myobCustomerCardId === myobSupplierCardId
        ) {
          return false;
        }
        return true;
      },
    ),
  };
  if (options.includeVendorCommissionBands) {
    shape.vendorCommissionBands = vendorCommissionBandsValidation;
  }
  return Yup.object().shape(shape);
};

const blankDefaults = {
  address: {},
  bankingDetails: null,
  businessUsers: [],
  buyerWays: [],
  emailRecipients: [],
  generateBpayCRN: true,
  isActive: true,
  isBuyer: false,
  isTopBuyerCattle: false,
  isTopBuyerSheep: false,
  isTransporter: false,
  isVendor: false,
  properties: [],
  relationships: [],
  shortCodeSaleyard: "",
  shortCode: "",
  shortCodeAuctionsPlus: "",
};

const Form = props => {
  const { closeSelf, business, handleSubmit, defaultAddressSearch } = props;
  const formikProps = useFormikContext();
  const { setFieldValue } = formikProps;
  const hasSyncAllPermission = useSomeHasPermission(
    selectRoleDeployments,
    DeploymentPermissions.featureSyncAllAlternativeBusinessFields,
  );

  // Hide most of the contents of this page when editing a temporary busines.
  // Clicking the "Convert Business" action will change the form to its normal state, and set the value
  // in the form ready for pushing back to the server.
  const [isTemporary, setIsTemporary] = React.useState(
    business?.isTemporary || false,
  );
  const setNotIsTemporary = () => {
    setIsTemporary(false);
    setFieldValue("isTemporary", false);
  };

  // In the case of a not-saleyard admin (who should not have tabs) they should
  // have a single primary agency - use that check for the business relations permission.
  // TODO - allow for deployments (that allow it) and not saleyard admins.
  const primaryAgency = useSelector(getPrimaryAgency);
  const allowBusinessRelations = useSelector(
    getAgencyAllowsBusinessRelationsByAgencyId(primaryAgency.agency_id),
  );

  const doHandleClose = () => {
    // if (touched) {
    //   // TODO changes Dialog.
    // }
    closeSelf();
  };

  const [isSubFormActive, setIsSubFormActive] = React.useState(false);
  const submitDisabled =
    !(formikProps.dirty && formikProps.isValid) ||
    isSubFormActive ||
    isTemporary;

  const { abn } = formikProps.values;
  const titleActionString = business
    ? `Edit ${getBusinessSubType(business)} "${business.name}"`
    : "Create Business";

  const submitTitle = abn ? "Submit & Query ABN" : "Submit";

  const isLivestockAgent = useSelector(getIsLivestockAgent);
  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);

  const parentObjectSelector = isLivestockAgent
    ? getActiveLivestockAgentDeployment
    : getActiveSaleyardAdminSaleyard;
  const parentObject = useSelector(parentObjectSelector) || {};

  const auditLogLinkProps = useMemo(() => {
    if (isLivestockAgent) {
      return {
        auditLogType: AuditLogTypes.DEPLOYMENT_BUSINESS,
        queryParams: {
          deploymentId: parentObject.id,
        },
      };
    }
    if (isSaleyardAdmin) {
      return {
        auditLogType: AuditLogTypes.SALEYARD_BUSINESS,
        queryParams: {
          saleyardId: parentObject.id,
        },
      };
    }
    return {};
  }, [isLivestockAgent, isSaleyardAdmin, parentObject.id]);

  return (
    <ZoomyDialog
      open
      onClose={doHandleClose}
      maxWidth="xl"
      scroll="body"
      fullWidth
    >
      <WaitForSync requiredData={[ApiModel.BUSINESSES, ApiModel.AGENCIES]}>
        <DialogTitle onClose={doHandleClose}>
          {business && (
            <AuditLogLink
              dataId={business.id}
              returnTo={window.location.hash}
              {...auditLogLinkProps}
            />
          )}
          &nbsp;
          {titleActionString}{" "}
          {hasSyncAllPermission && business && (
            <SyncButton
              to={`#${getSelectSyncDataHashRoute(
                business.id,
                window.location.hash,
              )}`}
              data-tour=""
            >
              <FontAwesomeIcon icon={faLink} /> Sync All
            </SyncButton>
          )}
        </DialogTitle>
        <DialogContent dividers form="true">
          {isTemporary ? (
            <TemporaryBusinessMessage setNotIsTemporary={setNotIsTemporary} />
          ) : (
            <DeploymentBusinessForm
              {...props}
              setIsSubFormActive={setIsSubFormActive}
              allowBusinessRelations={allowBusinessRelations}
              defaultAddressSearch={defaultAddressSearch}
            />
          )}
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={doHandleClose}>Cancel</SecondaryButton>
          <Button
            data-tour="submit"
            onClick={() => {
              !submitDisabled && handleSubmit(formikProps.values);
            }}
            disabled={submitDisabled}
          >
            {submitTitle}
          </Button>
        </DialogActions>
      </WaitForSync>
    </ZoomyDialog>
  );
};

const FormikedForm = ({
  business,
  closeSelf,
  branches,
  defaultTab,
  handleSubmit,
  defaultAddressSearch,
}) => {
  const initialValues = business ? { ...business } : { ...blankDefaults };

  // Vendor Commission Bands
  const hasVendorCommissionsFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureVendorCommissions,
  );

  const vendorCommissionBands =
    useSelector(
      getVendorCommissionBandsByDeploymentBusinessId(
        business?.deploymentBusinessId,
      ),
    ) || [];

  if (hasVendorCommissionsFeature) {
    initialValues.vendorCommissionBands = vendorCommissionBands;
  }

  // Vendor Splits
  const hasVendorSplitsFeature = useHasDeploymentPermission(
    DeploymentPermissions.featurePercentageVendorSplits,
  );

  const defaultVendorSplit = useSelector(
    getDefaultVendorSplitByBusinessId(business?.id),
  );
  if (hasVendorSplitsFeature) {
    initialValues.defaultVendorSplit = defaultVendorSplit || undefined;
  }

  // Interest
  const hasInterestFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureInterest,
  );

  const interestSettings =
    useSelector(getInterestSettingsByBusinessId(business?.id)) || [];

  if (hasInterestFeature) {
    if (interestSettings.length === 0) {
      initialValues.interestSettings = [
        { interestRate: 0, daysOverDueThreshold: 0, id: uuidv4() },
      ];
    } else {
      initialValues.interestSettings = interestSettings;
    }
  }

  const deployment = useSelector(getActiveLivestockAgentDeployment) || {};
  // If we have a deployment, we are an agent, so set insurance values
  if (!isEmpty(deployment)) {
    const transitInsuranceDefault =
      deployment?.deploymentSettings?.transitInsuranceApplicationDefault;

    // If we have no business we are creating one,
    // so set the default from the deployment, and map it into initial values
    if (!business) {
      initialValues.transitInsuranceApplicationDefault =
        transitInsuranceDefault;
      const transitInsuranceRates =
        mapDefaultDeploymentTransitInsuranceToBusinessValues(
          transitInsuranceDefault,
        );
      Object.assign(initialValues, transitInsuranceRates);
    } else {
      // Otherwise we are editing, so determine the values from the business
      // and set it in the initial values.
      const mappedTransitInsuranceValue =
        mapBusinessValuesToInsuranceType(initialValues) ||
        transitInsuranceDefault;
      initialValues.transitInsuranceApplicationDefault =
        mappedTransitInsuranceValue;
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getBusinessValidationSchema({
        includeVendorCommissionBands: hasVendorCommissionsFeature,
      })}
      // Enable reinitialise so when we get pusher updates, the form is updated.
      enableReinitialize
    >
      <Form
        closeSelf={closeSelf}
        business={business}
        branches={branches}
        defaultTab={defaultTab}
        handleSubmit={handleSubmit}
        defaultAddressSearch={defaultAddressSearch}
      />
    </Formik>
  );
};

export default FormikedForm;
