import React from "react";

import { useSelector } from "react-redux";

import {
  getNotAngusVerifiedCountByPenId,
  getNotAngusVerifiedCountBySaleLotId,
  getNotAngusVerifiedCountByVendorId,
  getScanByEid,
} from "selectors";

import { Chip } from "./BaseChip";

const AngusNotVerifiedChip = ({ value }) => {
  // Only render if we have a number
  if (typeof value === "number") {
    return (
      <Chip
        backgroundColor="angusAustraliaBackground"
        color="angusAustraliaText"
      >
        {value ? `${value} Not Angus Verified` : "Angus Verified"}
      </Chip>
    );
  }
  return null;
};
export const AngusVerifiedSaleLotChip = ({ saleLotId }) => {
  const value = useSelector(getNotAngusVerifiedCountBySaleLotId(saleLotId));
  return <AngusNotVerifiedChip value={value} />;
};
export const AngusVerifiedAuctionPenChip = ({ auctionPenId }) => {
  const value = useSelector(getNotAngusVerifiedCountByPenId(auctionPenId));
  return <AngusNotVerifiedChip value={value} />;
};
export const AngusVerifiedVendorChip = ({ vendorId }) => {
  const value = useSelector(getNotAngusVerifiedCountByVendorId(vendorId));
  return <AngusNotVerifiedChip value={value} />;
};
export const AngusVerifiedEIDChip = ({ EID }) => {
  const value = useSelector(
    state => getScanByEid(EID)(state)?.animal?.is_angus_verified,
  );
  if (value === null) {
    return null;
  }
  return (
    <Chip backgroundColor="angusAustraliaBackground" color="angusAustraliaText">
      {value ? "Angus Verified" : "Not Angus Verified"}
    </Chip>
  );
};
