import * as Sentry from "@sentry/react";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";

import { currentSaleFilter } from "actions/lib";

import { SentrySeverityWarning } from "constants/sentry";

import { getLivestockSaleId } from "lib/navigation";
import { isSentryActive } from "lib/sentry/config";

import { getServerTimeDriftMs } from "selectors";

import { store } from "index";
import { BlockedError } from "offline/blockedError";
import { JWTError } from "offline/jwtError";
import { RequestError } from "offline/requestError";
import { WrongSaleError } from "offline/wrongSaleError";

/*
 * How it works:
 * - Temp IDs are created using UUIDs on object creation
 * - When POST API calls return with actual IDs, a temp-real ID dictionary
 *   is updated in the offineTemp redux store
 * - If an update to a temp object e.g. a newly split salelot is sold, the
 *   request is queued in the redux-offline outbox, but it still has the
 *   temp ID
 * - This effect will update the url before sending the effect
 *
 * */

const replaceTempIdWithRealId = tempId => {
  const state = store.getState();
  const realId = state.offlineTemp[tempId];
  if (realId) {
    return realId;
  }
  return tempId;
};

const replaceUrlTempIdWithRealId = url => {
  const trailingIdRegex =
    /.*\/([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|-\d+)\//;
  const match = url.match(trailingIdRegex);
  if (match && match.length) {
    const tempId = match[1];
    const realId = replaceTempIdWithRealId(tempId);
    if (realId) {
      return url.replace(tempId, realId);
    }
  }
  return url;
};

const replaceObjectTempIdWithRealId = input => {
  if (isArray(input)) {
    return input.map(p => replaceObjectTempIdWithRealId(p));
  } else if (isObject(input)) {
    return Object.keys(input).reduce((accum, param) => {
      if (isArray(input[param])) {
        accum[param] = input[param].map(p => replaceObjectTempIdWithRealId(p));
      } else if (isObject(input[param])) {
        accum[param] = replaceObjectTempIdWithRealId(input[param]);
      } else if (!param.startsWith("temp")) {
        // Don't override 'temp' variables.
        accum[param] = replaceTempIdWithRealId(input[param]);
      } else {
        accum[param] = input[param];
      }
      return accum;
    }, {});
  } else {
    return replaceTempIdWithRealId(input);
  }
};

const replaceBodyTempIdWithRealId = jsonString => {
  const object = JSON.parse(jsonString);
  const output = replaceObjectTempIdWithRealId(object);
  return JSON.stringify(output);
};

function prepareHeaders(effect) {
  // NOTE: if adding new custom headers, these must be allowed in Access-Control-Allow-Headers
  // see https://github.com/agrinous/agrinous-infrastructure/blob/master/aws/agrinous/infrastructure/cloudfront.yaml

  const headers = {
    "content-type": "application/json",
  };

  const state = store.getState();
  const drift = getServerTimeDriftMs(state) || 0;
  headers["Updated-At"] = Date.now() - drift;

  const {
    authentication: { socketId },
    activeRole,
  } = state.auth;

  if (activeRole && activeRole.slug) {
    headers["X-User-Role"] = activeRole.slug;
  }

  if (socketId) {
    headers["socket-id"] = socketId;
  }

  if (effect.changeReason) {
    headers["X-Change-Reason"] = effect.changeReason;
  }

  return headers;
}

function prepareRequestBody(effect) {
  const { body } = effect;
  if (body) {
    return replaceBodyTempIdWithRealId(body);
  }
  return body;
}

function rewriteCommitActionIds(action) {
  const commitMeta = action.meta.offline.commit.meta;
  if (commitMeta) {
    action.meta.offline.commit.meta = replaceObjectTempIdWithRealId(commitMeta);
  }
}

function rewriteRollbackActionIds(action) {
  const rollbackMeta = action.meta.offline.rollback.meta;
  if (rollbackMeta) {
    action.meta.offline.rollback.meta =
      replaceObjectTempIdWithRealId(rollbackMeta);
  }
}

function rewriteActionTempIds(action) {
  rewriteCommitActionIds(action);
  rewriteRollbackActionIds(action);
}

// Take a Redux Offline action and prepares the fully resolved url and the init args for a fetch request
export function prepareRequest(action) {
  const offlineActionMeta = action.meta.offline.effect;
  const {
    cache,
    integrity,
    keepalive,
    method,
    mode,
    redirect,
    referrer,
    referrerPolicy,
    signal,
    url,
    window,
  } = offlineActionMeta;

  const headers = prepareHeaders(offlineActionMeta);

  const body = prepareRequestBody(offlineActionMeta);

  // We also want to update any temp ids in action metas we know about here, before
  // dispatching.
  rewriteActionTempIds(action);

  const preparedUrl = replaceUrlTempIdWithRealId(url);

  const requestInitArgs = {
    body,
    cache,
    // credentials, overridden by 'include' in fetchWithFullyQualifiedUrl
    headers,
    integrity,
    keepalive,
    method,
    mode,
    redirect,
    referrer,
    referrerPolicy,
    signal,
    window,
  };

  return { url: preparedUrl, options: requestInitArgs };
}

function rewriteActionCacheAttrs(action, response) {
  // Check for and inject any "changesSince" related headers into the meta.
  const lastModifiedTimestamp = response.headers.get("lastChange");
  if (lastModifiedTimestamp) {
    const cacheHit = Boolean(response.headers.get("x-ag-cache"));
    action.meta.offline.commit.meta = {
      ...action.meta.offline.commit.meta,
      lastModifiedTimestamp,
      cacheHit,
    };
  }
}

// Takes a Response object, and the text content of the response body,
// applies some validation of the response type and
// returns the result of calling JSON.parse on the response text (or raises a RequestError)
export function processTextResponse(action, res, body) {
  // No body (eg a 204 deleted) is actually OK!
  if (!body) {
    return null;
  }
  // ANY JSON response is "ok" and digestable.  (eg 401, etc)
  // HTML content, not so much.  So try detect intercept before it enters.
  const contentType = res.headers.get("content-type") || "";
  if (contentType && !contentType.includes("json")) {
    // This is treating a bad response as a total error - not totally certain whether
    // it should be treated as a general failure or a more specific
    // action.meta.offline.failureactionsomethingsomething?
    // We shouldn't be getting these... Let sentry know.
    const errorMessage = `Received non JSON response(${contentType}) with status ${res.status} from ${res.request.url} for ${action.type}`;
    // eslint-disable-next-line no-console
    console.warn(errorMessage);
    throw new RequestError(
      body || "",
      res.status,
      action.meta.offline.effect.method,
      errorMessage,
    );
  } else {
    // We should have json content - parse it.
    // If it's a pain caused by cloudfront/etc, and a 200 with html body... this will be caught here and not
    // make it into the internals of reducers, etc.
    let responseBody;
    try {
      responseBody = JSON.parse(body);
    } catch (e) {
      const errorMessage = `Received non JSON parsable response(${contentType}) with status ${res.status} from ${action.meta.offline.effect} for ${action.type}.  JSON Parse Error: ${e}`;
      // eslint-disable-next-line no-console
      console.warn(errorMessage);
      if (isSentryActive) {
        Sentry.captureMessage(errorMessage, SentrySeverityWarning);
      }

      throw new RequestError(
        body || "",
        res.status,
        action.meta.offline.effect.method,
        errorMessage,
      );
    }
    if (res.ok) {
      return responseBody;
    } else {
      // Inject status code into meta
      action.meta.offline.rollback.meta = {
        ...action.meta.offline.rollback.meta,
        statusCode: res.status,
      };

      if (res.status === 428) {
        throw new BlockedError(responseBody);
      }
      throw new RequestError(
        responseBody,
        res.status,
        action.meta.offline.effect.method,
        `Invalid status: ${res.status}`,
      );
    }
  }
}

// Takes a Response object, applies some checks and returns a promise containing the string content of the response body
export function processResponse(action, res) {
  rewriteActionCacheAttrs(action, res);

  if (!currentSaleFilter(action)) {
    throw new WrongSaleError(
      `Discarding network response for ${action.type} from sale ${
        action.meta.livestockSaleId
      } while in ${getLivestockSaleId()}`,
    );
  }

  // On 401 throw - this will be caught and land in discard.js, where a refresh token may be dispatched from;

  if (res.status === 401) {
    throw new JWTError(
      "",
      res.status,
      action.meta.offline.effect.method,
      `Received 401 response.`,
    );
  }

  return res.text();
}
