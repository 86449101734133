import { isEmpty, uniq } from "lodash";
import isEqual from "lodash/isEqual";
import memoizeOne from "memoize-one";

import { SALE_VALIDATION_ERRORS, SaleStatus, SaleTypes } from "constants/sale";
import { ROLE_TYPES } from "constants/users";

import { caseInsensitiveCompare } from "./compare";
import { getLivestockSaleId, getSaleyardName } from "./navigation";
import { getAUFormattedDate } from "./timeFormats";

const areEqualSaleIdOnly = (cur, next) => {
  const currentMatch = cur[0]?.match;
  const nextMatch = next[0]?.match;

  if (currentMatch && nextMatch) {
    return currentMatch.params.saleId === nextMatch.params.saleId;
  } else {
    return false;
  }
};

/**
 * Extract the saleId from props as a new object. Used to compose reselectors
 * so that unnecessary computation does not occur
 *
 * @param {object} props
 * @returns {object}
 */
export const saleIdOnly = memoizeOne(
  props => ({
    saleId: parseInt(
      props?.saleId ||
        props?.livestocksale_id ||
        props?.match?.params?.saleId ||
        getLivestockSaleId(),
      10,
    ),
  }),
  areEqualSaleIdOnly,
);

const areEqualSaleIdRoundIdOnly = (cur, next) => {
  const currentMatch = cur[0].match;
  const nextMatch = next[0].match;

  if (currentMatch && nextMatch) {
    return (
      currentMatch.params.saleId === nextMatch.params.saleId &&
      currentMatch.params.roundId === nextMatch.params.roundId
    );
  } else {
    return false;
  }
};

/**
 * Extract the saleId and roundIDfrom props as a new object. Used to compose reselectors
 * so that unnecessary computation does not occur
 *
 * @param {object} props
 * @returns {object}
 */
export const saleIdRoundIdOnly = memoizeOne(
  props => ({
    saleId: parseInt(props.match.params.saleId, 10),
    roundId: parseInt(props.match.params.roundId, 10),
  }),
  areEqualSaleIdRoundIdOnly,
);

const routeParamsAreEqual = (cur, next) => {
  const currentMatch = cur[0].match;
  const nextMatch = next[0].match;

  return currentMatch && nextMatch
    ? isEqual(currentMatch.params, nextMatch.params)
    : false;
};

export const routeParamsOnly = memoizeOne(
  props => props.match.params,
  routeParamsAreEqual,
);

export const dateSort = (n0, n1) => caseInsensitiveCompare(n1.date, n0.date);
/**
 * Performs validation on a sale prior to being submitted to the api. Returns an array
 * containing all of the appropriate business rules violated, described by SALE_VALIDATION_ERRORS.
 * @param {Sale} updatedSale the values of the sale to either be created or updated
 * @param {Sale[]} allSales the sale values from the state
 * @param {boolean} isSaleyardAdmin is the user a saleyard admin
 * @param {boolean} isLivestockAgent is the user a livestock agent
 * @returns {String[]} {@link SALE_VALIDATION_ERRORS}
 */
export const validateSale = (
  updatedSale,
  allSales,
  isSaleyardAdmin,
  isLivestockAgent,
  isEdit,
) => {
  const {
    livestocksale_id,
    sale_type,
    saleyard_id,
    date,
    species_id,
    pricing_type_id,
  } = updatedSale;
  const validationErrors = [];
  // Check if there is an existing Bobbycalf or saleyard auction with the
  // same details.
  const disAllowDuplicateSaleTypes = [SaleTypes.BOBBYCALF];
  // Saleyard admins can (kind of) create multiple saleyard auctions.
  // If they already exist, it will create the companion deployment sales.
  if (!isSaleyardAdmin && isLivestockAgent && !isEdit) {
    disAllowDuplicateSaleTypes.push(SaleTypes.SALEYARD_AUCTION);
  }
  const conflictingSaleExists = allSales.some(
    sale =>
      disAllowDuplicateSaleTypes.indexOf(sale.sale_type) >= 0 &&
      sale.sale_type === sale_type &&
      sale.date === date &&
      sale.saleyard_id === saleyard_id &&
      sale.species_id === species_id &&
      sale.pricing_type_id === pricing_type_id &&
      sale.livestocksale_id !== livestocksale_id,
  );

  if (conflictingSaleExists) {
    validationErrors.push(SALE_VALIDATION_ERRORS.DUPLICATE_SALE);
  }

  return validationErrors;
};

export function getSaleNameForSale(sale) {
  return `${sale.saleyard_name} ${getAUFormattedDate(new Date(sale.date))}`;
}

/**
 * @typedef {Object} ApiSale
 * @property {number} livestocksale_id
 * @property {string} saleyard_name
 */

/**
 * Get a Sale Object for the purposes of an API request. A Sale object is comprised of the current Saleyard Name and the ID of the current Livestock Sale
 * @returns {ApiSale}
 */
export const getApiSale = () => {
  return {
    saleyard_name: getSaleyardName(),
    livestocksale_id: getLivestockSaleId(),
  };
};

export function isDeploymentSaleBalanced(
  receivedCount,
  areAllConsignmentsBalanced,
  areAllRoundsBalanced,
) {
  return (
    receivedCount > 0 && areAllConsignmentsBalanced && areAllRoundsBalanced
  );
}

export const getSaleExportSites = sale =>
  uniq(
    sale.deployment_sales.reduce((acc, deploymentSale) => {
      deploymentSale.export_sites.map(exportSite => acc.push(exportSite));
      return acc;
    }, []),
  );

export const getDateDrivenSaleStatus = saleDate => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (saleDate > today) {
    return SaleStatus.FUTURE;
  } else if (saleDate >= yesterday && saleDate <= today) {
    return SaleStatus.IN_PROGRESS;
  } else {
    return SaleStatus.PAST;
  }
};

// to be used in the global search on the dashboard so we can search
// for the manually overridden sales statuses for livestock agents
export const getSaleStatus = (sale, role) => {
  const defaultStatus = getDateDrivenSaleStatus(new Date(sale.date));

  return role?.type !== ROLE_TYPES.STOCK_AGENT
    ? defaultStatus
    : sale.deployment_sales[0]?.status || defaultStatus;
};

export function isPrimeSaleSubtype(saleSubType) {
  return (
    !isEmpty(saleSubType) && saleSubType.name.toLowerCase().includes("prime")
  );
}

export function isStoreSaleSubtype(saleSubType) {
  return (
    !isEmpty(saleSubType) && saleSubType.name.toLowerCase().includes("store")
  );
}
