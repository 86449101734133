// Use the exact same chevron as the react select dropdown.
import React, { useMemo } from "react";

import { faTag } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "@material-ui/core";
import Downshift from "downshift";
import { useField } from "formik";
import { flatten } from "lodash";
import { uniqBy } from "lodash/array";
import { useDispatch, useSelector } from "react-redux";
import { components } from "react-select";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";

import { updateDeploymentAttributes } from "actions";

import { Chip } from "components/Chip";
import { Button } from "components/Form/Button";
import { StyledCheckbox } from "components/Form/FormikControls/CheckBox";
import { Error } from "components/Form/FormikControls/Error";
import { Label } from "components/Form/FormikControls/Label";
import { SearchInput } from "components/Form/SearchInput";
import { Column, Row } from "components/Layout";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { UPDATE_DEPLOYMENT_LABELS } from "constants/actionTypes";

import {
  getActiveLivestockAgentDeployment,
  getCurrentSpeciesId,
  getDeployments,
  getIsSaleyardAdmin,
  getLabels,
  selectCurrentDeploymentIds,
} from "selectors";

import { getLabelsBySpecies } from "selectors/speciesAttributes";

import { useTheme } from "hooks";

import { Expander, SelectInput } from "./Downshift";

const FaIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  margin: 0px ${({ theme }) => theme.space[1]}px;
`;

const Item = styled.li`
  cursor: pointer;
  display: block;
  border: none;
  font-size: 14px;
  text-transform: none;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryHighlight}50;
  }
`;

const stateReducer = (state, changes) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return {
        ...changes,
        highlightedIndex: state.highlightedIndex,
        isOpen: true,
        inputValue: "",
      };

    default:
      return changes;
  }
};

export const LabelSelectorField = ({
  label,
  name,
  tooltip,
  required = false,
  disabled,
  options,
  addLabel,
  placeHolder = "Click to add descriptive labels",
}) => {
  const [filterText, setFilterText] = React.useState("");
  const [field, meta, helpers] = useField(name);
  const error = meta.touched && meta.error;
  const selectedOptions = field.value || [];

  const theme = useTheme();

  const SelectedOption = ({ item }) => {
    const selectedLabel = options.find(o => o.value === item)?.label || "";

    return (
      <Chip>
        <strong>{selectedLabel}</strong>
      </Chip>
    );
  };

  const handleChange = item => {
    if (selectedOptions.includes(item)) {
      helpers.setValue(selectedOptions.filter(r => r !== item));
    } else {
      helpers.setValue([...selectedOptions, item]);
    }
  };

  const fullScreen = useMediaQuery(`(max-width: ${theme.breakpoints[1]}px)`);
  const filteredOptions = options.filter(o => o.label.includes(filterText));

  return (
    <Column fullWidth>
      <Label
        htmlFor={name}
        error={!!error}
        required={required}
        tooltip={tooltip}
      >
        {label}
      </Label>
      <Downshift
        selectedItem={selectedOptions}
        onChange={handleChange}
        stateReducer={stateReducer}
        itemToString={item => item}
      >
        {({
          getToggleButtonProps,
          getMenuProps,
          isOpen,
          selectedItem,
          getItemProps,
          highlightedIndex,
          toggleMenu,
        }) => {
          const mapSelectedOptions = (item, index) => (
            <SelectedOption
              key={item}
              item={item}
              selectedItem={selectedItem}
              index={index}
            />
          );
          const menuRef = getMenuProps(
            { isOpen },
            { suppressRefError: true },
          ).ref;

          return (
            <div>
              <SelectInput onClick={toggleMenu} disabled={disabled}>
                <Row flexWrap paddingHorizontal={1}>
                  {selectedItem.length > 0
                    ? selectedItem.map(mapSelectedOptions)
                    : placeHolder}
                </Row>
                <Expander
                  {...getToggleButtonProps({
                    // prevents the menu from immediately toggling
                    // closed (due to our custom click handler above).
                    onClick(event) {
                      event.stopPropagation();
                    },
                  })}
                >
                  <components.DownChevron />
                </Expander>
              </SelectInput>
              {!disabled && isOpen && (
                <Dialog
                  open
                  maxWidth="sm"
                  fullWidth
                  fullScreen={fullScreen}
                  ref={menuRef}
                >
                  <DialogTitle onClose={toggleMenu}>Labels</DialogTitle>
                  <DialogContent dividers>
                    <SearchInput
                      value={filterText}
                      onChange={setFilterText}
                      selectOnFocus={false}
                    />

                    {filteredOptions.map((option, index) => {
                      const isSelected = selectedOptions.includes(option.value);
                      const isActive = highlightedIndex === index;
                      return (
                        <Item
                          key={option.value}
                          {...getItemProps({
                            item: option.value,
                            index,
                            isActive,
                            isSelected,
                          })}
                        >
                          <Row
                            data-tour={option.label}
                            justifyBetween
                            alignCenter
                          >
                            <div>
                              <FaIcon icon={faTag} />
                              {option.label}
                            </div>
                            <StyledCheckbox checked={isSelected} />
                          </Row>
                        </Item>
                      );
                    })}

                    {/* If there a filter active and no exact match, show a button to add a new filter. */}
                    {filterText &&
                      !options.find(o => o.label === filterText) &&
                      addLabel && (
                        <Row justifyCenter alignCenter>
                          <Button
                            data-tour="createNewLabel"
                            onClick={() => addLabel(filterText)}
                          >
                            Create new label
                          </Button>
                        </Row>
                      )}
                  </DialogContent>
                  <DialogActions>
                    <Button data-tour="done" onClick={toggleMenu}>
                      Done
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </div>
          );
        }}
      </Downshift>

      {error && <Error>{error}</Error>}
    </Column>
  );
};

export const SaleLotLabelSelectorField = ({
  name,
  label,
  tooltip,
  disabled,
  required,
  deploymentId,
}) => {
  const dispatch = useDispatch();

  const handleUpdate = payload =>
    dispatch(updateDeploymentAttributes(UPDATE_DEPLOYMENT_LABELS, payload));

  const currentSpeciesId = useSelector(getCurrentSpeciesId);

  const labels = useSelector(getLabelsBySpecies);

  const labelsInUse = flatten(useField(name).map(field => field.value));

  const options = labels
    .filter(
      l =>
        labelsInUse.includes(l.id) ||
        (l.deployment_id === deploymentId && l.quick_select && !l.deprecated),
    )
    .map(option => ({
      label: option.name,
      value: option.id,
    }));

  const addLabel = label => {
    const payload = {
      order: labels.length,
      deployment_id: deploymentId,
      name: label,
      species_id: currentSpeciesId,
      is_used: true,
      id: uuidv4(),
      quick_select: true,
      is_manual_charge_label: false,
      deprecated: false,
    };
    handleUpdate([payload]);
  };

  return (
    <LabelSelectorField
      name={name}
      label={label}
      tooltip={tooltip}
      required={required}
      disabled={disabled}
      addLabel={addLabel}
      options={options}
    />
  );
};

export const ManualChargeLabelSelectorField = ({
  name,
  label,
  tooltip,
  disabled,
  required,
}) => {
  const dispatch = useDispatch();

  const handleUpdate = payload =>
    dispatch(updateDeploymentAttributes(UPDATE_DEPLOYMENT_LABELS, payload));

  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);
  const deployments = useSelector(getDeployments);

  const currentSpeciesId = useSelector(getCurrentSpeciesId);

  // On create, create for all available deployments.  On Select, if there are duplicates, select the first one.
  // This is not ideal, and would be nicer if Labels weren't DeploymentLabels.
  const currentDeploymentIds = useSelector(selectCurrentDeploymentIds);

  const labels = useSelector(getLabels);

  const labelsInUse = flatten(useField(name).map(field => field.value));

  const options = useMemo(
    () =>
      uniqBy(
        Object.values(labels).filter(
          label =>
            labelsInUse.includes(label.id) ||
            (currentDeploymentIds.includes(label.deployment_id) &&
              label.is_manual_charge_label &&
              label.species_id === currentSpeciesId &&
              !label.deleted &&
              !label.deprecated),
        ),
        "id",
      ).map(option => ({
        label: isSaleyardAdmin
          ? `${deployments[option.deployment_id]?.name}: ${option.name}`
          : option.name,
        value: option.id,
      })),
    [
      currentDeploymentIds,
      currentSpeciesId,
      deployments,
      isSaleyardAdmin,
      labels,
      labelsInUse,
    ],
  );

  const addLabel = label => {
    currentDeploymentIds.forEach(deploymentId => {
      const payload = {
        order: labels.length,
        deployment_id: deploymentId,
        name: label,
        species_id: currentSpeciesId,
        is_used: true,
        id: uuidv4(),
        quick_select: false,
        is_manual_charge_label: true,
        deprecated: false,
      };
      handleUpdate([payload]);
    });
  };

  return (
    <LabelSelectorField
      name={name}
      label={label}
      tooltip={tooltip}
      required={required}
      disabled={disabled}
      addLabel={addLabel}
      options={options}
      placeHolder="Define the reason for this sundry."
    />
  );
};

export const SundryTemplateLabelSelectorField = ({
  name,
  label,
  tooltip,
  disabled,
  required,
}) => {
  // 1. Specialised case where we aren't in the context of a sale.
  // This means we cant filter the deployment labels by species, instead we are just
  // uniquing them by name - which is how they are interpreted by mincenous anyway.

  // 2. Only for Livestock Agents

  // 3. No need to allow creation.

  const deploymentId = useSelector(getActiveLivestockAgentDeployment)?.id;

  const labels = Object.values(useSelector(getLabels));

  const labelsInUse = flatten(useField(name).map(field => field.value));

  const options = uniqBy(
    labels.filter(
      label =>
        labelsInUse.includes(label.id) ||
        (label.deployment_id === deploymentId &&
          label.is_manual_charge_label &&
          !label.deprecated),
    ),
    "name",
  ).map(option => ({
    label: option.name,
    value: option.id,
  }));

  return (
    <LabelSelectorField
      name={name}
      label={label}
      tooltip={tooltip}
      required={required}
      disabled={disabled}
      options={options}
      placeHolder="Extra labels for this sundry."
    />
  );
};
