import React from "react";

import { getIn, setIn, useFormikContext } from "formik";
import { useSelector } from "react-redux";

import {
  QuickOptionsWithSelect,
  SelectField,
  withNamespace,
} from "components/Form/FormikControls";

import { SaleTypes } from "constants/sale";

import { getBestMatchingProductId, productFields } from "lib/products";

import { currentSaleSelector, selectCurrentDeploymentIds } from "selectors";

import { getProductsByDeploymentId } from "selectors/speciesAttributes";

const getProductQuickSelectKey = saleType => {
  switch (saleType) {
    case SaleTypes.PADDOCK:
      return "quick_select_paddock";
    case SaleTypes.OVER_HOOKS:
      return "quick_select_hooks";
    default:
      return "quick_select";
  }
};

interface DefaultFormFieldMap {
  age_id: string;
  breed_id: string;
  product_id: string;
  sale_round_id: string;
  sex_id: string;
}

interface ProductSelectFieldProps {
  bestMatchAutoSelect?: boolean;
  deploymentId?: number;
  disabled?: boolean;
  formFieldMap?: DefaultFormFieldMap;
  hideQuickSelect?: boolean;
  label?: string;
  name?: string;
  ns?: string;
}

export const camelCaseProductFormFieldMap = {
  age_id: "ageId",
  breed_id: "breedId",
  product_id: "productId",
  sale_round_id: "saleRoundId",
  sex_id: "sexId",
};

export const snakeCaseProductFormFieldMap = {
  age_id: "age_id",
  breed_id: "breed_id",
  product_id: "product_id",
  sale_round_id: "sale_round_id",
  sex_id: "sex_id",
};

export const ProductSelectField = React.memo(
  ({
    bestMatchAutoSelect = true,
    deploymentId = null,
    disabled = false,
    formFieldMap = snakeCaseProductFormFieldMap,
    hideQuickSelect = false,
    label = "Description",
    name = "product_id",
    ns = null,
  }: ProductSelectFieldProps): React.JSX.Element => {
    const formik = useFormikContext<{
      product_id: number;
      sale_round_id: number;
    }>();
    const { setFieldValue, values, setValues } = formik;
    const defaultDeploymentId = useSelector(selectCurrentDeploymentIds)[0];
    const selectedDeploymentId = deploymentId || defaultDeploymentId;
    const products = useSelector(
      getProductsByDeploymentId(selectedDeploymentId),
    );
    const currentProductId = getIn(values, withNamespace(ns, name));
    const productValues = React.useMemo(
      () =>
        productFields.reduce((acc, field) => {
          const productField = formFieldMap[field];
          acc[field] = getIn(values, withNamespace(ns, productField));
          return acc;
        }, {}),
      [formFieldMap, values, ns],
    );

    // Build the list of options for the sale type (and round if applicable)
    const currentSale = useSelector(currentSaleSelector) || {};
    const saleType = currentSale.sale_type;
    const quickSelectKey = getProductQuickSelectKey(saleType);
    const saleRoundId = getIn(values, formFieldMap.sale_round_id);
    const productOptions = React.useMemo(() => {
      let filteredProducts = products;
      if (![SaleTypes.OVER_HOOKS, SaleTypes.PADDOCK].includes(saleType)) {
        filteredProducts = filteredProducts.filter(
          p =>
            p.sale_rounds.includes(saleRoundId) || p.sale_rounds.length === 0,
        );
      }
      return filteredProducts.map(p => ({
        label: `${p.name} ${p[quickSelectKey] ? `(${p.quick_code})` : ""}`,
        value: p.id,
        quick_code: p[quickSelectKey] && p.quick_code,
      }));
    }, [products, quickSelectKey, saleRoundId, saleType]);

    // if bestMatchAutoSelect is enabled and any value changes, check that the most applicable product is selected.
    // For example Product X has breed 1, the user selects breed 2, so Product X is no longer valid.
    React.useEffect(() => {
      if (bestMatchAutoSelect) {
        const bestMatchingProductId = getBestMatchingProductId(
          productValues,
          products,
          currentProductId,
        );
        if (bestMatchingProductId !== currentProductId) {
          setFieldValue(withNamespace(ns, name), bestMatchingProductId);
        }
      }
    }, [
      products,
      ns,
      setFieldValue,
      productValues,
      currentProductId,
      bestMatchAutoSelect,
      name,
    ]);
    const anyProductHasBreed = products.some(product => product.breed_id);
    const setProduct = (productId: any) => {
      const product = products.find(p => p.id === productId);
      if (product) {
        let newValues = { ...values, [name]: productId };
        productFields.forEach(field => {
          const newFieldValue = product[field];
          // If all our products don't have a breed, then we should persist the selected breed_id.
          // Otherwise we should set whatever value is in the product
          if (
            field !== "breed_id" || // If we aren't dealing with breed_id let it through
            anyProductHasBreed || // or if any of our products have a breed, let it though
            !!newFieldValue // or if product has a breed value, let it through
          )
            newValues = setIn(
              newValues,
              withNamespace(ns, formFieldMap[field]),
              newFieldValue,
            );
        });
        setValues(newValues);
      }
    };

    if (hideQuickSelect) {
      // Hide quick select and don't return the select inside a grid.
      return (
        <SelectField
          label={label}
          name={withNamespace(ns, name)}
          options={productOptions}
          isClearable
          onChangeExtra={setProduct}
          disabled={disabled}
          menuPortalTarget={document.body}
          missingMLAFieldsWarningEnabled
          overrideMissingFieldName="sexId"
        />
      );
    }

    return (
      <QuickOptionsWithSelect
        ns={ns}
        name={name}
        label={label}
        options={productOptions}
        onChangeExtra={setProduct}
        disabled={disabled}
        missingMLAFieldsWarningEnabled
        overrideMissingFieldName="sexId"
        tooltip={
          anyProductHasBreed
            ? "Products have Breeds, your Breed selection will be replaced with the breed on the Product, regardless of if the selected product has no Breed"
            : "Products have no Breeds, your Breed selection will persist regardless of your Product"
        }
      />
    );
  },
);
