import React, { useCallback, useRef, useState } from "react";

import { faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import Button from "components/Button";
import { Label } from "components/Form/FormikControls/Label";
import { Row } from "components/Layout";
import MarkingPopover from "components/MarkingPopover";

import toast from "lib/toast";

import {
  getMarksBySpecies,
  selectMarkOptionsByDeploymentIdLookup,
} from "selectors";

const ColorBlock = styled.div`
  height: 30px;
  width: 30px;
  ${({ color }) => `background-color: ${color}`};
  ${({ borderColor }) => borderColor && `border: 1px solid ${borderColor}`};
`;

const BodyPartText = styled.div`
  font-size: 14px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 6px;
  margin-right: 6px;
`;

const MarkingBlock = styled(Row)`
  border: 1px solid rgb(200, 200, 200);
  background-color: transparent;
  margin-left: 6px;
  justify-content: center;
  align-items: center;
`;

const MarksListContainer = styled(Row).attrs({ flexGrow: true })(
  ({ theme, disabled }) => `
  padding: 0.5rem;
  color: inherit;
  overflow: auto;
  background: ${disabled ? theme.colors.grayF7 : theme.colors.white};
  border: 1px solid ${theme.colors.gray78};
`,
);

const AddButton = styled(Button).attrs({ type: "button" })`
  min-height: 2rem;
`;

const Trash = styled(FontAwesomeIcon).attrs({ icon: faTrashAlt })(
  ({ theme, disabled }) => `
  font-size: ${theme.fontSizes.beta};
  color: ${disabled ? theme.colors.grayAE : theme.colors.primary};
  margin-right: ${theme.space[1]}px;
`,
);

export const OtherMarkingField = ({
  name = "marks",
  label = "Custom Marks",
  required = false,
  tooltip = "Use this field for one off custom marks that you want displayed on printouts",
  deploymentId = null,
  disabled = false,
  useAllDeployments = false,
  defaultColor = null,
  dataTour = null,
}) => {
  // This field allows users to add an uncommon identifying mark to a lot.
  // usually something that only makes sense in the context of a particular sale and might not be used again.

  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const refAddButton = useRef(null);

  const formikProps = useFormikContext();

  // Handles the case where the accessor is a dot notation one (that formik supports) like "consignment.saleLot.marks"
  const marks = React.useMemo(
    () => get(formikProps.values, name.split(".")) || [],
    [formikProps.values, name],
  );

  const deploymentMarkOptions = useSelector(
    selectMarkOptionsByDeploymentIdLookup,
  )[deploymentId];

  const allDeploymentMarkOptions = useSelector(getMarksBySpecies);

  const markOptions = React.useMemo(
    () => deploymentMarkOptions?.map(m => m.short_code) || [],
    [deploymentMarkOptions],
  );

  const allMarkOptions = React.useMemo(
    () => allDeploymentMarkOptions?.map(m => m.short_code) || [],
    [allDeploymentMarkOptions],
  );

  const customMarks = React.useMemo(() => {
    if (useAllDeployments) {
      return marks.filter(m => !allMarkOptions.includes(m.location));
    } else {
      return marks.filter(m => !markOptions.includes(m.location));
    }
  }, [marks, markOptions, allMarkOptions, useAllDeployments]);

  const setMarks = useCallback(
    marks => {
      formikProps.setFieldValue(name, marks);
      formikProps.setFieldTouched(name, true, false);
    },
    [formikProps, name],
  );

  function onClickAdd() {
    setIsPickerVisible(true);
  }

  const onPopoverCloseSelf = useCallback(() => {
    setIsPickerVisible(false);
  }, [setIsPickerVisible]);

  const addMark = useCallback(
    (color, location) => {
      if (markOptions.includes(location)) {
        toast.error(
          `Mark "${location}" is a predefined mark, please select it using the toggle button.`,
        );
        return;
      }
      const newMark = {
        color: color || defaultColor || undefined,
        location,
      };

      // Place marks at start of list so that the oldest are the ones which are
      // hidden if scrolling necessary
      setMarks([newMark, ...marks]);
    },
    [marks, setMarks, markOptions, defaultColor],
  );

  return (
    <Grid item xs={12}>
      <Label tooltip={tooltip} required={required}>
        {label}
      </Label>
      <MarksListContainer disabled={disabled}>
        <AddButton
          data-tour={dataTour || "addCustomMark"}
          onClick={onClickAdd}
          ref={refAddButton}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faPlus} />
          ADD
        </AddButton>

        {customMarks.length > 0 &&
          customMarks.map(({ color, location }, index) => {
            const removeMark = () => {
              const newMarks = marks.filter(m => m.location !== location);
              setMarks(newMarks);
            };
            const borderColor =
              color?.toUpperCase() === "#FFFFFF" ? "#C2C2C2" : "";
            return (
              <MarkingBlock
                justifyContent
                key={index}
                onClick={!disabled ? removeMark : null}
              >
                <ColorBlock color={color} borderColor={borderColor} />
                <BodyPartText>{location}</BodyPartText>
                <Trash disabled={disabled} />
              </MarkingBlock>
            );
          })}
      </MarksListContainer>
      {isPickerVisible && (
        <MarkingPopover
          autoFocus
          onMarksChanged={addMark}
          anchorEl={refAddButton.current}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          isOpen={isPickerVisible}
          closeSelf={onPopoverCloseSelf}
        />
      )}
    </Grid>
  );
};

OtherMarkingField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
};
