import React, { Component } from "react";

import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import ReactTable, { ReactTableDefaults } from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import styled from "styled-components/macro";

import Paginator from "components/Paginator";

import { EMPTY_ARRAY, EMPTY_OBJECT_FUNCTION, formatDecimal } from "lib";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const NoResultsMessage = styled.div`
  ${({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  color: rgba(102, 102, 102, 0.5);
  background-color:  ${theme.colors.collapseBackground};
`}
`;

const NoResultsWrapper = styled.div`
  padding: 12px;
`;

export const HeaderRightAlign = styled.div`
  width: 100%;
  text-align: right;
`;

export const HeaderCenterAlign = styled.div`
  width: 100%;
  text-align: center;
`;

export const CellRightAlign = styled.div`
  width: 100%;
  text-align: right;
`;

export const CellCentPriceDollars = row => (
  <CellRightAlign>{`$${formatDecimal(row.value / 100)}`}</CellRightAlign>
);

class Table extends Component {
  state = {
    page: 1,
  };

  componentDidUpdate(nextProps) {
    const { page } = this.state;
    const {
      data = EMPTY_ARRAY,
      columns = EMPTY_ARRAY,
      onPageChange = EMPTY_OBJECT_FUNCTION,
    } = this.props;
    // reset page number on search & number of rows changed
    if (
      !isEqual(nextProps.data.length, data.length) ||
      !isEqual(
        nextProps.columns.map(c => c.Header),
        columns.map(c => c.Header),
      )
    ) {
      this.setState({ page: 1 });
      typeof onPageChange === "function" && onPageChange();
    } else if (nextProps.page !== page) {
      typeof onPageChange === "function" && onPageChange();
    }
  }

  numPages = () => {
    const { data = EMPTY_ARRAY, pageSize = 30, pivotByColumnId } = this.props;
    let rowCount = data.length;
    if (pivotByColumnId) {
      rowCount = data.reduce((sum, col) => {
        const pivotValue = col[pivotByColumnId];
        if (!sum.includes(pivotValue)) {
          return sum.concat(pivotValue);
        }
        return sum;
      }, []).length;
    }

    return Math.ceil(rowCount / pageSize);
  };

  setPage = page => {
    if (page > 0 && page <= this.numPages()) {
      this.setState({ page });
    }
  };

  render() {
    const { page } = this.state;
    const {
      columns = EMPTY_ARRAY,
      data = EMPTY_ARRAY,
      pageSize = 30,
      noResultsMessage,
      suppressNoResult,
      ...otherProps
    } = this.props;
    let noResultsText = "No results found.";
    // Use explicit check for string type to allow an empty message
    if (typeof noResultsMessage === "string") {
      noResultsText = noResultsMessage;
    }

    if (data.length === 0) {
      return suppressNoResult ? null : (
        <NoResultsWrapper>
          <NoResultsMessage>{noResultsText}</NoResultsMessage>
        </NoResultsWrapper>
      );
    } else {
      return (
        <>
          <ReactTableFixedColumns
            page={page - 1}
            pageSize={pageSize}
            getTdProps={() => ({
              style: {
                padding: "6px 12px",
                minHeight: 48,
                alignItems: "center",
                display: "flex",
              },
            })}
            data={data}
            columns={columns}
            className="-striped -highlight"
            style={{
              fontSize: 12,
              border: "none",
            }}
            showPagination={false}
            column={{
              ...ReactTableDefaults.column,
            }}
            minRows={0}
            {...otherProps}
          />
          {this.numPages() > 1 && (
            <Paginator
              page={page}
              numPages={this.numPages()}
              setPage={this.setPage}
            />
          )}
        </>
      );
    }
  }
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  columns: PropTypes.arrayOf(PropTypes.object),
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
  pivotByColumnId: PropTypes.string,
  noResultsMessage: PropTypes.string,
  suppressNoResult: PropTypes.bool,
};

export default Table;
