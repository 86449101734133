import {
  Autocomplete,
  getOptionAsValue,
  withNamespace,
} from "components/Form/FormikControls";
import React, { useMemo } from "react";
import { sortBy } from "lodash";
import { useSelector } from "react-redux";
import { getSpecies } from "selectors";

interface SaleTypeFieldProps {
  namespace?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

export const SpeciesField = ({
  namespace: ns,
  label,
  name = "speciesId",
  required = false,
}: SaleTypeFieldProps) => {
  const speciesByIdLookup = useSelector(getSpecies);

  const options = useMemo(
    () =>
      [
        {
          value: null,
          label: "All",
        },
      ].concat(
        sortBy(
          Object.values(speciesByIdLookup).map((species: Species) => ({
            label: species.name,
            value: species.id,
          })),
          "label",
        ),
      ),
    [speciesByIdLookup],
  );

  return (
    <Autocomplete
      name={withNamespace(ns, name)}
      options={options}
      required={required}
      getOptionValue={getOptionAsValue}
      disableClearable
      label={label}
    />
  );
};
