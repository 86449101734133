import { ColumnType } from "components/AgGrid/constants";

import { EmailColId } from "constants/columnDefinitions";

import { filterComparators } from "lib/agGrid";
import { datetimeFormatter } from "lib/agGrid/columns/datetime";
import getters from "lib/agGrid/getters";
import {
  invertedTimeValueExistsRenderer,
  timeValueExistsRenderer,
} from "lib/agGrid/renderers";

export const EmailColumnDef = {
  [EmailColId.ATTACHMENTS]: {
    colId: EmailColId.ATTACHMENTS,
    field: "attachments",
    headerName: "Attachments",
    valueGetter: getters.arrayLengthGetter,
  },

  [EmailColId.AUTORESPONDED_TIME]: {
    colId: EmailColId.AUTORESPONDED_TIME,
    field: "autorespondedTime",
    headerName: "Autoresponded",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    cellRenderer: invertedTimeValueExistsRenderer,
  },
  [EmailColId.BOUNCED_TIME]: {
    colId: EmailColId.BOUNCED_TIME,
    field: "bouncedTime",
    headerName: "Bounced",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    cellRenderer: invertedTimeValueExistsRenderer,
  },
  [EmailColId.CLICKED_TIME]: {
    colId: EmailColId.CLICKED_TIME,
    field: "clickedTime",
    headerName: "Clicked",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    cellRenderer: timeValueExistsRenderer,
  },
  [EmailColId.COMPLAINED_TIME]: {
    colId: EmailColId.COMPLAINED_TIME,
    field: "complainedTime",
    headerName: "Complained",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    cellRenderer: invertedTimeValueExistsRenderer,
  },
  [EmailColId.CREATED]: {
    colId: EmailColId.CREATED,
    field: "created",
    headerName: "Created",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
  },
  [EmailColId.DEFERRED_TIME]: {
    colId: EmailColId.DEFERRED_TIME,
    field: "deferredTime",
    headerName: "Deferred",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    cellRenderer: invertedTimeValueExistsRenderer,
  },
  [EmailColId.DELIVERED_TIME]: {
    colId: EmailColId.DELIVERED_TIME,
    field: "deliveredTime",
    headerName: "Delivered",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    cellRenderer: timeValueExistsRenderer,
  },
  [EmailColId.DISPATCHED]: {
    colId: EmailColId.DISPATCHED,
    field: "dispatched",
    headerName: "Dispatched",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
  },
  [EmailColId.ERROR_REASON]: {
    colId: EmailColId.ERROR_REASON,
    field: "errorReason",
    headerName: "Error Reason",
  },
  [EmailColId.ERROR_TIME]: {
    colId: EmailColId.ERROR_TIME,
    field: "errorTime",
    headerName: "Error",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    cellRenderer: invertedTimeValueExistsRenderer,
  },
  [EmailColId.FAILED_TIME]: {
    colId: EmailColId.FAILED_TIME,
    field: "failedTime",
    headerName: "Failed",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    cellRenderer: invertedTimeValueExistsRenderer,
  },
  [EmailColId.FORMATTED_RECIPIENT]: {
    colId: EmailColId.FORMATTED_RECIPIENT,
    field: "formattedRecipient",
    headerName: "Recipient",
  },
  [EmailColId.FORMATTED_SENDER]: {
    colId: EmailColId.FORMATTED_SENDER,
    field: "formattedSender",
    headerName: "Sender",
  },
  [EmailColId.OPENED_TIME]: {
    colId: EmailColId.OPENED_TIME,
    field: "openedTime",
    headerName: "Opened",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    cellRenderer: timeValueExistsRenderer,
  },
  [EmailColId.QUEUED_TIME]: {
    colId: EmailColId.QUEUED_TIME,
    field: "queuedTime",
    headerName: "Queued",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    cellRenderer: timeValueExistsRenderer,
  },
  [EmailColId.REASON]: {
    colId: EmailColId.REASON,
    field: "reason",
    headerName: "Reason",
  },
  [EmailColId.REJECTED_TIME]: {
    colId: EmailColId.REJECTED_TIME,
    field: "rejectedTime",
    headerName: "Rejected",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    cellRenderer: invertedTimeValueExistsRenderer,
  },
  [EmailColId.REJECT_REASON]: {
    colId: EmailColId.REJECT_REASON,
    field: "rejectedReason",
    headerName: "Reject Reason",
  },

  [EmailColId.SENT_TIME]: {
    colId: EmailColId.SENT_TIME,
    field: "sentTime",
    headerName: "Sent",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    cellRenderer: timeValueExistsRenderer,
  },
  [EmailColId.STATUS]: {
    colId: EmailColId.STATUS,
    field: "status",
    headerName: "Status",
  },

  [EmailColId.SUBJECT]: {
    colId: EmailColId.SUBJECT,
    field: "subject",
    headerName: "Subject",
  },
  [EmailColId.UNSUBSCRIBED_TIME]: {
    colId: EmailColId.UNSUBSCRIBED_TIME,
    field: "unsubscribedTime",
    headerName: "Unsubscribed",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    cellRenderer: invertedTimeValueExistsRenderer,
  },
};

// detailCellRendererParams doesn't like ColumnType.date
const defaultTimeDateColDef = {
  valueFormatter: datetimeFormatter,
  filter: "agDateColumnFilter",
  filterParams: { comparator: filterComparators.localDateComparator },
};

export const EmailDetailGridOptions = {
  suppressRowClickSelection: true,
  enableRangeSelection: true,
  columnDefs: [
    { field: "subject" },
    { field: "status" },
    { field: "formattedRecipient" },
    {
      field: "queuedTime",
      ...defaultTimeDateColDef,
    },
    {
      field: "deliveredTime",
      ...defaultTimeDateColDef,
    },
    {
      field: "openedTime",
      ...defaultTimeDateColDef,
    },
    {
      field: "bouncedTime",
      ...defaultTimeDateColDef,
    },
  ],
  defaultColDef: {
    sortable: true,
    flex: 1,
    resizable: true,
  },
};
