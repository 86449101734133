import React from "react";

import { Grid } from "@material-ui/core";

import { OptionTogglerField, SelectField, withNamespace } from ".";

export const QuickOptionsWithSelect = ({
  options,
  label,
  name,
  ns,
  onChangeExtra,
  disabled,
  tooltip,
  missingMLAFieldsWarningEnabled,
  overrideMissingFieldName = null,
}) => {
  const quickOptions = React.useMemo(
    () =>
      options
        .filter(o => o.quick_code)
        .map(o => ({ value: o.value, label: o.quick_code })),
    [options],
  );

  return (
    <>
      {quickOptions.length > 0 && (
        <Grid item xs={12}>
          <OptionTogglerField
            labelPosition="top"
            label={label}
            name={withNamespace(ns, name)}
            options={quickOptions}
            onChangeExtra={onChangeExtra}
            disabled={disabled}
            tooltip={tooltip}
            missingMLAFieldsWarningEnabled={missingMLAFieldsWarningEnabled}
            overrideMissingFieldName={overrideMissingFieldName}
          />
        </Grid>
      )}

      <Grid data-tour={label.toLowerCase()} item xs={12} sm={12}>
        <SelectField
          label={quickOptions.length === 0 ? label : ""}
          name={withNamespace(ns, name)}
          options={options}
          isClearable
          onChangeExtra={onChangeExtra}
          disabled={disabled}
          missingMLAFieldsWarningEnabled={
            quickOptions.length === 0 && missingMLAFieldsWarningEnabled
              ? missingMLAFieldsWarningEnabled
              : false
          }
        />
      </Grid>
    </>
  );
};
