import React from "react";

import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

import {
  ActionButton,
  ActionButtonContainer,
} from "components/AgGrid/ActionButton";

import { ModalTypes } from "constants/navigation";

import { ClickableColumn } from "lib/agGrid/columns/columnStyles";
import { openModalLink } from "lib/navigation";

import { getSundryTemplateById } from "selectors";

interface SundryTemplateRendererProps {
  value: string;
}

export function SundryTemplateRenderer(
  props: SundryTemplateRendererProps,
): React.JSX.Element {
  const { value } = props;

  const sundryTemplate = useSelector(getSundryTemplateById(value));

  if (!sundryTemplate) {
    return null;
  }

  return (
    <ClickableColumn
      key={sundryTemplate}
      onClick={() =>
        openModalLink(ModalTypes.SundryTemplate, {
          id: sundryTemplate.id,
        })
      }
    >
      {sundryTemplate.name}
    </ClickableColumn>
  );
}

interface EditManualAdjustmentRendererProps {
  data: {
    manualAdjustment: any;
  };
}

export function EditManualAdjustmentRenderer(
  props: EditManualAdjustmentRendererProps,
): React.JSX.Element {
  if (!props.data) {
    return null;
  }
  const { manualAdjustment } = props.data;

  const onClickEdit = () => {
    const modalProps = {
      id: manualAdjustment.id,
    };
    openModalLink(ModalTypes.ManualAdjustmentForm, modalProps);
  };

  return (
    <ActionButtonContainer>
      <ActionButton
        data-tour="action-edit-manual-adjustment"
        onClick={onClickEdit}
      >
        <FontAwesomeIcon icon={faPencil} />
        &nbsp;Edit
      </ActionButton>
    </ActionButtonContainer>
  );
}
