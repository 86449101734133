import { DeclarationTypes } from "constants/documentTypes";

const SHEEP_DECLARATION_QUESTIONS = [
  "fed_feed_containing_animal_fats",
  "owned_since_birth",
  "qa_accredited",
  "treated_for_scabby_mouth",
  "veterinary_treatment",
  "consumed_material_in_withholding_period",
  "post_breeder_tags",
];

const EU_CATTLE_DECLARATION_QUESTIONS = [
  "fed_feed_containing_animal_fats",
  "owned_since_birth",
  "fed_byproduct_stockfeeds",
  "chemical_residue_restrictions",
  "veterinary_treatment",
  "consumed_material_in_withholding_period",
  "spray_drift_risk",
];

const CATTLE_DECLARATION_QUESTIONS = [
  "treated_with_hgp",
  "fed_feed_containing_animal_fats",
  "owned_since_birth",
  "fed_byproduct_stockfeeds",
  "chemical_residue_restrictions",
  "veterinary_treatment",
  "consumed_material_in_withholding_period",
  "spray_drift_risk",
];

const BOBBYCALF_DECLARATION_QUESTIONS = [
  "qa_accredited",
  "owned_since_birth",
  "consumed_material_in_withholding_period",
  "veterinary_treatment",
  "veterinary_treatment_milk",
  "chemical_residue_restrictions",
];

// TODO Deprecate this in NOV 2025 when G0720 is no longer in use
const GOAT_DECLARATION_QUESTIONS = [
  "owned_since_birth",
  "feedlot_or_treatment",
  "veterinary_treatment",
  "consumed_material_in_withholding_period",
  "fed_feed_containing_animal_fats",
];

const UPDATED_GOAT_DECLARATION_QUESTIONS = [
  "owned_since_birth",
  "veterinary_treatment",
  "consumed_material_in_withholding_period",
  "fed_feed_containing_animal_fats",
];

const OPTIONAL_DECLARATION_QUESTIONS = ["post_breeder_tags"];

const DeclarationQuestions = {
  [DeclarationTypes.S0413]: SHEEP_DECLARATION_QUESTIONS,
  [DeclarationTypes.S0720]: SHEEP_DECLARATION_QUESTIONS,
  [DeclarationTypes.C0413]: CATTLE_DECLARATION_QUESTIONS,
  [DeclarationTypes.C0720]: CATTLE_DECLARATION_QUESTIONS,
  [DeclarationTypes.E0413]: EU_CATTLE_DECLARATION_QUESTIONS,
  [DeclarationTypes.E0720]: EU_CATTLE_DECLARATION_QUESTIONS,
  [DeclarationTypes.BC0720]: BOBBYCALF_DECLARATION_QUESTIONS,
  [DeclarationTypes.G0720]: GOAT_DECLARATION_QUESTIONS,
  [DeclarationTypes.G0524]: UPDATED_GOAT_DECLARATION_QUESTIONS,
};

export const EuDeclarationVersions = [
  DeclarationTypes.E0413,
  DeclarationTypes.E0720,
];

export function getIsDeclarationComplete(declaration) {
  const { VERSION: declarationType } = declaration || {};

  const requiredAnswers = DeclarationQuestions[declarationType]?.filter(
    q => !OPTIONAL_DECLARATION_QUESTIONS.includes(q),
  );

  return Array.isArray(requiredAnswers)
    ? requiredAnswers.every(question => {
        const answer = declaration[question];
        return typeof answer === "boolean";
      })
    : false;
}

export const getDeclarationFields = declarationType =>
  DeclarationQuestions[declarationType] || null;
