import React, { useMemo } from "react";

import { Form, Formik } from "formik";
import { orderBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { DeploymentAction } from "actions";

import { Button } from "components/Form";
import { useSubmitHandler } from "components/Form/FormikControls";
import { tradingTermValidation } from "components/Form/FormikControls/TradingTerms";
import { vendorCommissionBandsValidation } from "components/Form/FormikControls/VendorCommissionBands";
import { Column, Row } from "components/Layout";
import { TitleText } from "components/Text";

import {
  InsuranceApplicationTypes,
  TRANSIT_INSURNACE,
} from "constants/billing";
import { DeploymentPermissions } from "constants/permissions";

import { hasPermission } from "lib/permissions";
import { ABNValidator, validatePercentage } from "lib/validators";

import {
  getActiveLivestockAgentDeployment,
  getInterestSettingsByDeploymentId,
  getVendorCommissionBandsByDeploymentId,
} from "selectors";

import { getTradingTermsByDeploymentId } from "selectors/tradingTerms";

import { useHasDeploymentPermission, useSectionToggle } from "hooks";

import { AddressSection } from "./Address";
import { ContactSection } from "./Contact";
import { EmailSection } from "./Email";
import { FinanceSection } from "./Finance";
import { GeneralSection } from "./General";

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.space[3]}px;
`;

export const FormColumn = styled(Column)(
  ({ theme }) => `
  flex: 1;
  padding: ${theme.space[1]}px;
  &:first-of-type {
    padding-left: 0px;
  }
  &:last-of-type {
    padding-right: 0px;
  }
`,
);

const SubmitRow = styled(Row)(
  ({ theme }) => `
  padding: ${theme.space[2]}px 0 0;
  `,
);

export const DeploymentSettingsModalSection = {
  GENERAL: "GENERAL",
  CONTACT: "CONTACT",
  ADDRESS: "ADDRESS",
  FINANCE_REPORTS: "FINANCE_REPORTS",
  EMAIL: "EMAIL",
};

const MAX_DIGITS = 9;
const DECIMAL_PLACES = 6;

export const deploymentSettingsValidation = Yup.object()
  .nullable()
  .shape({
    abn: ABNValidator,
    emailAddress: Yup.string().nullable().email("Please enter a valid email"),
    bpayBillerCode: Yup.string().nullable(),
    transitInsuranceForSales: Yup.object()
      .nullable()
      .shape({
        standardRate: validatePercentage(MAX_DIGITS, DECIMAL_PLACES).required(),
        extendedRate: validatePercentage(MAX_DIGITS, DECIMAL_PLACES),
        providerStandardRate: validatePercentage(MAX_DIGITS, DECIMAL_PLACES),
        providerExtendedRate: validatePercentage(MAX_DIGITS, DECIMAL_PLACES),
      }),
    transitInsuranceForPurchases: Yup.object()
      .nullable()
      .shape({
        standardRate: validatePercentage(MAX_DIGITS, DECIMAL_PLACES).required(),
        extendedRate: validatePercentage(MAX_DIGITS, DECIMAL_PLACES),
        providerStandardRate: validatePercentage(MAX_DIGITS, DECIMAL_PLACES),
        providerExtendedRate: validatePercentage(MAX_DIGITS, DECIMAL_PLACES),
      }),
  });

export const validationSchema = Yup.object().shape({
  corporateColor: Yup.string().required("Corporate colour required."),
  defaultDebtorInsurance: validatePercentage(9, 6),
  vendorCommissionBands: vendorCommissionBandsValidation,
  deploymentSettings: deploymentSettingsValidation,
  tradingTerms: tradingTermValidation,
});

function DeploymentForm() {
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  useSubmitHandler(false, setIsSubmitEnabled);
  const deployment = useSelector(getActiveLivestockAgentDeployment);

  const sectionHelper = useSectionToggle(
    DeploymentSettingsModalSection.GENERAL,
  );

  const deploymentSettingsNameSpace = "deploymentSettings";
  const hasBillingPermission = useHasDeploymentPermission(
    DeploymentPermissions.featureBilling,
  );

  return (
    <Form>
      <Column>
        <TitleText>Update settings for {deployment.name}</TitleText>
        <Row>
          <FormColumn>
            <GeneralSection
              sectionHelper={sectionHelper}
              ns={deploymentSettingsNameSpace}
            />
            <ContactSection
              sectionHelper={sectionHelper}
              ns={deploymentSettingsNameSpace}
            />
            <AddressSection
              sectionHelper={sectionHelper}
              ns={deploymentSettingsNameSpace}
            />
            <FinanceSection
              sectionHelper={sectionHelper}
              ns={deploymentSettingsNameSpace}
            />
            {hasBillingPermission && (
              <EmailSection
                sectionHelper={sectionHelper}
                ns={deploymentSettingsNameSpace}
              />
            )}
          </FormColumn>
        </Row>
        <SubmitRow>
          <Button type="submit" disabled={!isSubmitEnabled}>
            Submit
          </Button>
        </SubmitRow>
      </Column>
    </Form>
  );
}

const defaultInsuranceValues = {
  insuranceType: TRANSIT_INSURNACE,
  standardRate: 0,
  extendedRate: 0,
  providerStandardRate: 0,
  providerExtendedRate: 0,
};

function EditDeployment() {
  const dispatch = useDispatch();

  const deployment = useSelector(getActiveLivestockAgentDeployment);
  // If they have permissions to vendor commissions, pull the data into the form.
  const hasVendorCommissionsFeature = hasPermission(
    deployment,
    DeploymentPermissions.featureVendorCommissions,
  );

  const hasTradingTermsFeature = hasPermission(
    deployment,
    DeploymentPermissions.featureTradingTerms,
  );

  const hasInterestFeature = hasPermission(
    deployment,
    DeploymentPermissions.featureInterest,
  );

  const initialValues = useMemo(
    () => ({
      vendorTerms: deployment.vendorTerms,
      buyerTerms: deployment.buyerTerms,
      defaultDebtorInsurance: deployment.defaultDebtorInsurance,
      corporateColor: deployment.corporateColor,
      deploymentSettings: {
        ...deployment.deploymentSettings,
        ...(!deployment?.deploymentSettings?.generalEmailSettings && {
          generalEmailSettings: { appendNameToSalutation: false },
        }),
        ...(!deployment?.deploymentSettings?.rctiEmailSettings && {
          rctiEmailSettings: { appendNameToSalutation: false },
        }),
        ...(!deployment?.deploymentSettings?.taxInvoiceEmailSettings && {
          taxInvoiceEmailSettings: { appendNameToSalutation: false },
        }),
        transitInsuranceForSales:
          deployment?.deploymentSettings?.transitInsuranceForSales ||
          defaultInsuranceValues,
        transitInsuranceForPurchases:
          deployment?.deploymentSettings?.transitInsuranceForPurchases ||
          defaultInsuranceValues,
        transitInsuranceApplicationDefault:
          deployment?.deploymentSettings?.transitInsuranceApplicationDefault ||
          InsuranceApplicationTypes.N_A,
      },
    }),
    [
      deployment.buyerTerms,
      deployment.corporateColor,
      deployment.defaultDebtorInsurance,
      deployment.deploymentSettings,
      deployment.vendorTerms,
    ],
  );

  const vendorCommissionBands =
    useSelector(getVendorCommissionBandsByDeploymentId(deployment.id)) || [];

  if (hasVendorCommissionsFeature) {
    initialValues.vendorCommissionBands = vendorCommissionBands;
  }

  const interestSettings =
    useSelector(getInterestSettingsByDeploymentId(deployment.id)) || [];

  if (hasInterestFeature) {
    if (interestSettings.length === 0) {
      initialValues.interestSettings = [
        { interestRate: 0, daysOverDueThreshold: 0, id: uuidv4() },
      ];
    } else {
      initialValues.interestSettings = interestSettings;
    }
  }
  const tradingTerms =
    useSelector(getTradingTermsByDeploymentId(deployment.id)) || [];
  if (hasTradingTermsFeature) {
    initialValues.tradingTerms = orderBy(
      tradingTerms,
      ({ saleType, speciesId, saleSubTypeId }) => {
        return `${saleType || ""} ${speciesId || 0} ${saleSubTypeId || 0}`;
      },
    );
  }

  const onSubmit = values => {
    dispatch(DeploymentAction.update({ id: deployment.id, ...values }));
  };

  return (
    <Wrapper>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <DeploymentForm />
      </Formik>
    </Wrapper>
  );
}

export default EditDeployment;
