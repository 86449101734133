import React, { useMemo, useState } from "react";

import { faTruck } from "@fortawesome/pro-regular-svg-icons";
import { Grid } from "@material-ui/core";
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { SaleLotAction } from "actions";

import { MasterBusinessEmailRecipientCount } from "components/Business";
import { MultiButton } from "components/Button";
import { DeploymentBusinessName } from "components/ClearingSaleCards/BusinessComponents";
import { ConfirmDialog } from "components/ConfirmDialog";
import GroupedBuyerCard from "components/GroupedBuyerCard";
import RenderData from "components/RenderData";
import { GlobalSearchHeader } from "components/SearchInput/GlobalSearch";
import { BigText } from "components/Text";
import Subtitle from "components/Text/Subtitle";

import { Settings } from "constants/settings";

import { EMPTY_OBJECT, isNumeric, sortByKey } from "lib";

import { applyFilters, auctionPenBuyersFilterOptions } from "lib/filters";
import { equalsString, filterNestedArrayOfObjects } from "lib/search";

import {
  getBidders,
  getCurrentSale,
  getHasWriteAccessInCurrentSale,
  getSetting,
  selectBidderIdBySaleLotIdLookup,
} from "selectors";

import { useToggle } from "hooks";

const buyersSearchFields = {
  keys: ["buyerName", "status", "quantity", "thirdPartyName"],
  nested: {
    name: "buyerWays",
    keys: ["buyerWayName", "quantity"],
    nested: {
      name: "saleLots",
      keys: [
        "startPen",
        "endPen",
        "vendorName",
        "quantity",
        "vendorPublicDisplayName",
      ],
      nested: {
        name: "bidder",
        keys: ["registrationNumber"],
      },
    },
  },
};

const searchFields = {
  keys: ["thirdPartyName"],
  nested: {
    name: "buyers",
    ...buyersSearchFields,
  },
};

const bidderSearchFields = {
  keys: [],
  nested: {
    name: "buyerWays",
    keys: [],
    nested: {
      name: "saleLots",
      keys: [],
      nested: {
        name: "bidder",
        keys: ["registrationNumber"],
      },
    },
  },
};

const ThirdPartyGroup = styled.div`
  ${({ theme, hasThirdParty }) =>
    hasThirdParty &&
    `
    padding: 0 0 ${theme.space[2]}px ${theme.space[1]}px ;
    margin: ${theme.space[1]}px 0;
    
    background-color: ${theme.colors.gray85};
    border-top: 1px solid ${theme.colors.gray7A};
    border-bottom: 1px solid ${theme.colors.gray7A};
    `}
  &:first-child {
    margin-top: 0;
  }
`;

const ThirdPartyHeader = props => {
  const { masterBusinessId, quantity } = props;

  return (
    <Grid container alignItems="center">
      <Grid item xs={3} sm={2}>
        <Subtitle inline>Third Party </Subtitle>
      </Grid>
      <Grid item xs={9} sm={4}>
        <BigText>
          <DeploymentBusinessName businessId={masterBusinessId} />
        </BigText>
      </Grid>
      <Grid item xs={3} sm={2}>
        <Subtitle inline>Total Hd</Subtitle>
      </Grid>
      <Grid item xs={6} sm={2}>
        <BigText>{quantity}</BigText>
      </Grid>
      <Grid item xs={3} sm={2}>
        <MasterBusinessEmailRecipientCount
          masterBusinessId={masterBusinessId}
        />
      </Grid>
    </Grid>
  );
};

export const GroupedThirdPartyCard = props => {
  const {
    thirdParty,
    basePath,
    searchText = "",
    filterValues = EMPTY_OBJECT,
    showCommercialData,
    agencyId,
  } = props;
  const { id: thirdPartyId, quantity, buyers } = thirdParty;
  const hasThirdParty = Boolean(thirdPartyId);
  const bidders = useSelector(getBidders);
  const bidderBySaleLotId = useSelector(selectBidderIdBySaleLotIdLookup);
  const isUsingRegisteredBidders =
    useSelector(getCurrentSale)?.using_registered_bidders;
  // We need to re-filter here, as we only want to show the relevant items within this group.
  let visibleBuyers = buyers;

  if (searchText !== "") {
    if (isUsingRegisteredBidders && isNumeric(searchText)) {
      visibleBuyers = filterNestedArrayOfObjects(
        visibleBuyers,
        searchText,
        bidderSearchFields,
        equalsString,
      );
    } else {
      visibleBuyers = filterNestedArrayOfObjects(
        visibleBuyers,
        searchText,
        buyersSearchFields,
      );
    }
  }

  visibleBuyers = applyFilters(
    auctionPenBuyersFilterOptions,
    filterValues,
    visibleBuyers,
  );

  if (agencyId) {
    visibleBuyers = visibleBuyers.filter(thirdParty => {
      return thirdParty.saleLots.some(saleLot => {
        return saleLot.agency && saleLot.agency.id === agencyId;
      });
    });
  }

  // If we don't have anything after more filtering... hide me.
  if (visibleBuyers.length === 0) {
    return null;
  }

  return (
    <ThirdPartyGroup hasThirdParty={hasThirdParty}>
      {hasThirdParty && (
        <ThirdPartyHeader masterBusinessId={thirdPartyId} quantity={quantity} />
      )}
      {sortByKey(visibleBuyers, "buyerName").map(buyer => (
        <GroupedBuyerCard
          agencyId={agencyId}
          {...buyer}
          basePath={basePath}
          key={`${buyer.id}-${buyer.thirdPartyId}`}
          showCommercialData={showCommercialData}
          bidders={bidders}
          bidderBySaleLotId={bidderBySaleLotId}
          filterValues={filterValues}
        />
      ))}
    </ThirdPartyGroup>
  );
};

function getVisibleGroupedBuyers(
  roundId,
  thirdParties,
  thirdPartiesByRound,
  searchText,
) {
  const visiblethirdParties =
    roundId === null ? thirdParties : thirdPartiesByRound[roundId] || [];

  return filterNestedArrayOfObjects(
    visiblethirdParties,
    searchText,
    searchFields,
  );
}

const BuyerCards = ({ thirdParties, thirdPartiesByRound, basePath }) => {
  const dispatch = useDispatch();
  const roundId = useSelector(getSetting(Settings.round));
  const [searchText, setSearchText] = useState("");
  const [isConfirmDialogOpen, toggleIsConfirmDialogOpen] = useToggle();

  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );

  const readOnly = !hasWriteAccessInCurrentSale;

  const visibleThirdParties = useMemo(
    () =>
      getVisibleGroupedBuyers(
        roundId,
        thirdParties,
        thirdPartiesByRound,
        searchText,
      ),
    [roundId, thirdParties, thirdPartiesByRound, searchText],
  );

  function onBasicSearchTextChanged(searchText) {
    setSearchText(searchText);
  }

  const bulkDeliverAll = () => {
    dispatch(SaleLotAction.bulkDeliverAll());
    toggleIsConfirmDialogOpen();
  };

  const additionalActions = [
    {
      title: "Bulk Deliver All",
      isDisabled: readOnly,
      onClick: toggleIsConfirmDialogOpen,
      default: true,
      icon: faTruck,
      dataTour: "bulkDeliverAllBuyers",
    },
  ];

  return (
    <RenderData
      test={thirdParties.length > 0}
      infoText="No buyers yet."
      data-tour="addBuyer"
    >
      <GlobalSearchHeader
        searchSize={12}
        includeRoundSelector
        onBasicSearchTextChanged={onBasicSearchTextChanged}
        showAllRounds
        showBasicSearch
        actionButton={<MultiButton buttons={additionalActions} />}
      />
      <RenderData
        test={visibleThirdParties.length > 0}
        infoText="No buyers found for the selected round."
      >
        <>
          {sortByKey(visibleThirdParties, "thirdPartyName").map(thirdParty => (
            <GroupedThirdPartyCard
              key={`gtpc-${thirdParty.id}`}
              basePath={basePath}
              searchText={searchText}
              thirdParty={thirdParty}
            />
          ))}
          <ConfirmDialog
            title="Confirm Deliver All Sale Lots"
            isOpen={!!isConfirmDialogOpen}
            onCancel={toggleIsConfirmDialogOpen}
            onDelete={bulkDeliverAll}
            message="Are you sure you want to deliver all Sale Lots?"
            buttonMessage="Deliver All"
          />
        </>
      </RenderData>
    </RenderData>
  );
};

BuyerCards.propTypes = {
  thirdParties: PropTypes.array.isRequired,
  thirdPartiesByRound: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
};

export default BuyerCards;
