import { store } from "index";
import { OfflineAction } from "@redux-offline/redux-offline/lib/types";
import { fetchWithFullyQualifiedUrl } from "lib/fetch";
import {
  prepareRequest,
  processTextResponse,
  processResponse,
} from "lib/request";
import { PrioritisedAction } from "./queue";

type ConcurrentAction = { meta: { allowConcurrent?: boolean } };
type AgriNousOfflineAction = OfflineAction &
  PrioritisedAction &
  ConcurrentAction;

enum PipelineStage {
  INIT_REQUEST = 0,
  PROCESS_RESPONSE_HEADERS = 1,
  PROCESS_RESPONSE_BODY = 2,
  FINISHED = 3,
}

type ProbableResponse = string | { [key: string]: any } | Array<any> | null;

interface WorkerState {
  action: AgriNousOfflineAction;
  options: RequestInit;
  response: Response;
  result: ProbableResponse;
  textResponse: string;
  url: string;
  requestWorker: Promise<Response>;
  responseWorker: Promise<string>;
  pipelineStage: PipelineStage;
}

// Stores a map of transaction IDs to worker state
// A worker state is the context for the state machine that processes an offline actions
// This state machine contains promises to requests that were fired off optimistically,
// before the action is passed to the effect function
const workerStates = new Map<number, WorkerState>();

function createWorkerState(action: AgriNousOfflineAction): WorkerState {
  const { url, options } = prepareRequest(action);
  const workerState: WorkerState = {
    pipelineStage: PipelineStage.INIT_REQUEST,
    requestWorker: null,
    responseWorker: null,
    action,
    response: null,
    result: null,
    textResponse: null,
    url,
    options: options as RequestInit,
  };

  workerStates.set(action.meta.transaction, workerState);
  return workerState;
}

function discardWorkerState(workerState: WorkerState) {
  workerStates.delete(workerState.action.meta.transaction);
}

export function getConsecutiveConcurrentActions(
  offlineQueue: AgriNousOfflineAction[],
  priority: number,
): AgriNousOfflineAction[] {
  // Return the slice queue of actions which can be started together.
  // Actions can be started together if starting from the start of the queue,
  // they have the same priority and allow concurrent execution,
  // and are sequential in the queue
  let lastConcurrentIndex = -1;
  for (let i = 0; i < offlineQueue.length; i += 1) {
    const { meta } = offlineQueue[i];
    if (meta.priority === priority && meta.allowConcurrent) {
      lastConcurrentIndex = i + 1;
    } else {
      break;
    }
  }

  return lastConcurrentIndex === -1
    ? // None of the actions in the queue have the same priority and allow concurrent execution
      []
    : // return a slice of the queue from the start up 'til the index of the first action that doesn't allow concurrent execution, or has a different priority
      offlineQueue.slice(0, lastConcurrentIndex);
}

function executeRequest(workerState: WorkerState): Promise<Response> {
  // helper function to processing the INIT_REQUEST stage
  workerState.requestWorker = fetchWithFullyQualifiedUrl(
    workerState.url,
    workerState.options,
  );

  // return the worker promise so this can be awaited or chained with a then
  return workerState.requestWorker;
}

function startPendingConcurrentActions(currentPriority: number) {
  // Check for any other offline actions that can be worked on concurrently

  // Always work with a fresh copy of the state
  const state: State = store.getState() as State;
  const offlineQueue = state.offline.outbox as AgriNousOfflineAction[];

  // Starting from the front of the queue, find all consecutive actions with the same priority
  const concurrentActions = getConsecutiveConcurrentActions(
    offlineQueue,
    currentPriority,
  );
  const unstartedConcurrentActions = concurrentActions.filter(
    action => !workerStates.has(action.meta.transaction),
  );

  // In bulk, create a worker, start the request and advance the pipeline to the next stage.
  unstartedConcurrentActions.forEach(action => {
    const workerState = createWorkerState(action);

    // Fetch immediately executes the request so we don't need to await it.
    // The result of the request will be awaited and processed when redux offline
    // proceeds through its queue and process the action.
    // The effect will pick up the  existing worker state via a call to
    // getWorkerState and continue processing as though it was executed sequentially.
    executeRequest(workerState);
    workerState.pipelineStage = PipelineStage.PROCESS_RESPONSE_HEADERS;
  });
}

function advancePipelineStep(workerState: WorkerState) {
  workerState.pipelineStage += 1;
  if (workerState.action.meta.allowConcurrent) {
    startPendingConcurrentActions(workerState.action.meta.priority);
  }
}

async function processResponseHeaders(
  workerState: WorkerState,
): Promise<string> {
  // helper function to map the worker state object to the processResponse method

  workerState.response = await workerState.requestWorker;
  workerState.responseWorker = processResponse(
    workerState.action,
    workerState.response,
  );
  return workerState.responseWorker;
}

async function processResponseBody(workerState: WorkerState): Promise<void> {
  // helper function to map the worker state object to the processTextResponse method
  workerState.textResponse = await workerState.responseWorker;
  workerState.result = processTextResponse(
    workerState.action,
    workerState.response,
    workerState.textResponse,
  );
}

function getWorkerState(action: AgriNousOfflineAction): WorkerState {
  if (workerStates.has(action.meta.transaction)) {
    // Use the existing state to continue processing this action

    // This redux action was in the offline queue when another concurrent
    // action was started. The worker state was created and the request has
    // already been sent.
    return workerStates.get(action.meta.transaction);
  }

  return createWorkerState(action);
}

export async function effect(
  effectMeta: any, // A shorthand reference to action.meta.offline.effect for the offline action
  action: AgriNousOfflineAction, // The offline action
): Promise<ProbableResponse> {
  if (action.meta.allowConcurrent) {
    // Batch start all concurrent actions with the same priority
    // This will execute the request and advance the worker to the
    // process response headers stage
    startPendingConcurrentActions(action.meta.priority);
  }

  // Get the worker state for the action, when this is not a concurrent request
  // this will create a new worker state at the INIT stage
  const workerState = getWorkerState(action);
  try {
    if (workerState.pipelineStage === PipelineStage.INIT_REQUEST) {
      await executeRequest(workerState);
      advancePipelineStep(workerState);
    }
    if (workerState.pipelineStage === PipelineStage.PROCESS_RESPONSE_HEADERS) {
      await processResponseHeaders(workerState);
      advancePipelineStep(workerState);
    }
    if (workerState.pipelineStage === PipelineStage.PROCESS_RESPONSE_BODY) {
      await processResponseBody(workerState);
      advancePipelineStep(workerState);
    }

    // Return the JSON parsed from the response to redux offline.
    return workerState.result;
  } finally {
    discardWorkerState(workerState);
  }
}
