import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  getAges,
  getAuctionPenById,
  getBreeds,
  getCategories,
  getDressingRanges,
  getGrades,
  getMLASupplementaryData,
  getSaleLotById,
  getSaleLots,
  getSetting,
  getSexes,
  getUnpennedSaleLotIdsBySaleRoundId,
  getWeightRanges,
  selectAuctionPenSearchIndex,
  selectSaleLotIdsByAuctionPenIdLookup,
} from "selectors";
import { useDispatch, useSelector } from "react-redux";
import MessageBox from "components/MessageBox";
import { isEmpty, sortedUniq } from "lodash";
import { StatusCard } from "components/Card";
import { Row } from "components/Layout";

import { useBoolean, useToggle } from "hooks";
import { OpenIndicator } from "components/Icons/CollapseIcon";
import { getMLASupplementaryDataById } from "selectors/mlaSupplementaryData";
import {
  MarkChips,
  AverageWeightChip,
  TotalWeightChip,
  PICChip,
  NullableChip,
  ChipBag,
  SpeciesChippies,
} from "components/Chip";
import {
  PrimaryInfoHeader,
  PrimaryInfoNoMargin,
} from "components/AuctionPens/Cards/PrimaryInfoSet";
import { resolveValue } from "lib/mlaSupplementaryData";
import { EMPTY_ARRAY, EMPTY_OBJECT, getUnitPriceString } from "lib";
import { PricingTypes } from "constants/pricingTypes";
import {
  CheckBoxComponentContainer,
  CheckBoxComponentContainerHeader,
} from "components/Checkbox/Checkbox";
import { CheckboxContainerTypes } from "constants/checkboxContainerTypes";
import { Settings } from "constants/settings";
import { setSetting } from "actions";
import { BulkUpdateMLASupplementaryDataModal } from "components/BulkUpdateOptionalFieldsModal/MLASupplementaryData";
import { MultiButton } from "components/Button";
import { GlobalSearchHeader } from "components/SearchInput/GlobalSearch";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useGetVisibleAndTempAuctionPenIds } from "hooks/useGetVisibleAndTempAuctionPenIds";
import { formatDate } from "lib/timeFormats";
import styled from "styled-components/macro";
import { MLAMissingFieldsIndicator } from "components/MissingFieldsIndicator";
import {
  MLALotCardProps,
  MLAPenCardProps,
  MLASupplementaryDataProps,
  SupplementaryDataRecord,
} from "./types";

const Spacer = styled.div`
  margin: 1vw;
`;

function MLALotCard(props: MLALotCardProps): React.ReactElement {
  const { mlaSupplementaryDataId, showSelect } = props;

  const mlaSupplementaryData = useSelector(
    getMLASupplementaryDataById(mlaSupplementaryDataId),
  );
  const sexByIdLookup = useSelector(getSexes);
  const ageByIdLookup = useSelector(getAges);
  const breedByIdLookup = useSelector(getBreeds);
  const categoryByIdLookup = useSelector(getCategories);
  const gradeByIdLookup = useSelector(getGrades);
  const dressingRangeIdLookup = useSelector(getDressingRanges);
  const estimatedAverageWeightIdLookup = useSelector(getWeightRanges);

  const { quantity, id } = mlaSupplementaryData.saleLot;
  const saleLot = useSelector(getSaleLotById(id)) || {};

  const {
    lot_number: lotNumber,
    totalWeightFormatted,
    averageWeightFormatted,
    marks,
    destinationProperty,
  } = saleLot;

  const ageName =
    ageByIdLookup[resolveValue(mlaSupplementaryData, "ageId", "")]?.name;
  const breedName =
    breedByIdLookup[resolveValue(mlaSupplementaryData, "breedId", "")]?.name;
  const categoryName =
    categoryByIdLookup[resolveValue(mlaSupplementaryData, "categoryId", "")]
      ?.name;
  const gradeName =
    gradeByIdLookup[resolveValue(mlaSupplementaryData, "gradeId", "")]?.name;
  const sexName =
    sexByIdLookup[resolveValue(mlaSupplementaryData, "sexId", "")]?.name;

  const dressingRange =
    dressingRangeIdLookup[
      resolveValue(mlaSupplementaryData, "dressingRangeId", "")
    ]?.name;
  const estCarcassWeight = resolveValue(
    mlaSupplementaryData,
    "estCarcassWeight",
    "",
  );
  const estimatedAverageWeightObject =
    estimatedAverageWeightIdLookup[
      resolveValue(mlaSupplementaryData, "estimatedAverageWeightId", "")
    ];
  const estimatedAverageWeightString =
    estimatedAverageWeightObject?.weightRangeMinKg
      ? `${estimatedAverageWeightObject.details} ${estimatedAverageWeightObject.weightRangeMinKg}${
          !estimatedAverageWeightObject.weightRangeMaxKg
            ? "+"
            : ` - ${estimatedAverageWeightObject.weightRangeMaxKg}`
        }kg`
      : "";

  const fatScore = resolveValue(mlaSupplementaryData, "fatScore", "");
  const frameStructure = resolveValue(
    mlaSupplementaryData,
    "frameStructure",
    "",
  );
  const joinedEnd = resolveValue(mlaSupplementaryData, "joinedEnd", "");
  const joinedStart = resolveValue(mlaSupplementaryData, "joinedStart", "");
  const muscleScore = resolveValue(mlaSupplementaryData, "muscleScore", "");
  const shearing = resolveValue(mlaSupplementaryData, "shearing", "");
  const shearingStatus = resolveValue(
    mlaSupplementaryData,
    "shearingStatus",
    "",
  );

  const dispatch = useDispatch();

  const selectedSaleLotIds = useSelector(
    getSetting(Settings.auctionPenSelectedSaleLotIds),
  );

  const setSelectedSaleLotIds = saleLotIds =>
    dispatch(setSetting(Settings.auctionPenSelectedSaleLotIds, saleLotIds));

  const onChange = saleLotId => {
    if (!selectedSaleLotIds.includes(saleLotId)) {
      setSelectedSaleLotIds(selectedSaleLotIds.concat(saleLotId));
    } else {
      setSelectedSaleLotIds(
        selectedSaleLotIds.filter(
          selectedSaleLotId => selectedSaleLotId !== saleLotId,
        ),
      );
    }
  };

  return (
    <CheckBoxComponentContainer
      showSelect={showSelect}
      childClassName="mt-12"
      indeterminate={false}
      checked={selectedSaleLotIds.includes(id)}
      onChange={() => onChange(id)}
      disabled={false}
      containerClassName=""
    >
      <StatusCard status="complete">
        <Row fullWidth justifyBetween>
          <Row alignCenter>
            <PrimaryInfoHeader>Lot</PrimaryInfoHeader>
            <PrimaryInfoNoMargin>{lotNumber}</PrimaryInfoNoMargin>
          </Row>
          <Row alignCenter>
            <PrimaryInfoNoMargin>
              {saleLot.total_price_cents ? getUnitPriceString(saleLot) : "-"}
            </PrimaryInfoNoMargin>
            <PrimaryInfoHeader>
              {saleLot.total_price_cents
                ? PricingTypes.toString(saleLot.pricing_type_id)
                : ""}
            </PrimaryInfoHeader>
          </Row>
          <Row>
            <MLAMissingFieldsIndicator keyValue={id} />
            {/* Button here maybe? We don't have any actions to put here yet, maybe a direct edit? */}
          </Row>
        </Row>
        <Row fullWidth justifyBetween>
          <Row alignCenter justifyBetween width="50%">
            <Row alignCenter>
              <PrimaryInfoHeader>Hd</PrimaryInfoHeader>
              <PrimaryInfoNoMargin>{Math.round(quantity)}</PrimaryInfoNoMargin>
            </Row>
          </Row>
        </Row>
        <Row fullWidth>
          <ChipBag>
            <SpeciesChippies
              ageName={ageName}
              breedName={breedName}
              categoryName={categoryName}
              gradeName={gradeName}
              sexName={sexName}
            />
            <NullableChip label="Dressing" value={dressingRange} />
            <NullableChip
              label="Est. Carcass Weight"
              value={estCarcassWeight ? `${estCarcassWeight} kg` : ""}
            />
            <NullableChip
              label="Est. Avg. Weight"
              value={estimatedAverageWeightString}
            />
            <NullableChip label="Fat Score" value={fatScore} />
            <NullableChip label="Frame" value={frameStructure} />
            <NullableChip
              label="Joined Start"
              value={joinedStart ? formatDate(joinedStart) : ""}
            />
            <NullableChip
              label="Joined End"
              value={joinedEnd ? formatDate(joinedEnd) : ""}
            />
            <NullableChip label="Muscle Score" value={muscleScore} />
            <NullableChip
              label="Shearing"
              value={shearing ? formatDate(shearing) : ""}
            />
            <NullableChip label="Shearing Status" value={shearingStatus} />
            {totalWeightFormatted && (
              <TotalWeightChip>
                Tot. Wt. {totalWeightFormatted} Kg
              </TotalWeightChip>
            )}
            {averageWeightFormatted && (
              <AverageWeightChip>
                Avg. Wt. {averageWeightFormatted} Kg
              </AverageWeightChip>
            )}
            <MarkChips marks={marks} showNoMarks={false} />
            {!isEmpty(destinationProperty) && (
              <PICChip
                businessId={saleLot.buyerId}
                PICs={[destinationProperty]}
              />
            )}
          </ChipBag>
        </Row>
      </StatusCard>
    </CheckBoxComponentContainer>
  );
}

function MLAPenCard(props: MLAPenCardProps): React.JSX.Element {
  const { auctionPenId, showSelect } = props;

  const auctionPen =
    useSelector(getAuctionPenById(auctionPenId)) || EMPTY_OBJECT;
  const auctionPenSelectedSaleLotIds =
    useSelector(getSetting(Settings.auctionPenSelectedSaleLotIds)) ||
    EMPTY_ARRAY;
  const dispatch = useDispatch();

  const setAuctionPenSelectedSaleLotIds = useCallback(
    saleLotIds => {
      dispatch(setSetting(Settings.auctionPenSelectedSaleLotIds, saleLotIds));
    },
    [dispatch],
  );

  const saleLotIdsByAuctionPenId = useSelector(
    selectSaleLotIdsByAuctionPenIdLookup,
  );

  useEffect(() => {
    if (auctionPenSelectedSaleLotIds.length && !showSelect) {
      setAuctionPenSelectedSaleLotIds([]);
    }
  }, [
    auctionPenSelectedSaleLotIds,
    setAuctionPenSelectedSaleLotIds,
    showSelect,
  ]);

  const auctionPenAndSaleRoundSaleLotIds = useMemo(
    () => saleLotIdsByAuctionPenId[auctionPenId],
    [saleLotIdsByAuctionPenId, auctionPenId],
  );

  const saleLots = useSelector(getSaleLots);

  const allAuctionPenSaleLotsAreSelected =
    auctionPenAndSaleRoundSaleLotIds?.every(saleLotId =>
      auctionPenSelectedSaleLotIds.includes(saleLotId),
    );

  const someAuctionPenSaleLotsAreSelected =
    auctionPenAndSaleRoundSaleLotIds?.some(saleLotId =>
      auctionPenSelectedSaleLotIds.includes(saleLotId),
    );

  const someButNotAllSaleLotsAreSelected =
    !allAuctionPenSaleLotsAreSelected && someAuctionPenSaleLotsAreSelected;

  const onChange = useCallback(() => {
    // if any sale lots are selected - remove the auction pen lots from selection
    if (allAuctionPenSaleLotsAreSelected || someAuctionPenSaleLotsAreSelected) {
      setAuctionPenSelectedSaleLotIds(
        auctionPenSelectedSaleLotIds.filter(
          saleLotId => !auctionPenAndSaleRoundSaleLotIds.includes(saleLotId),
        ),
      );
      // if none of the lots are selected - select them all
    } else if (!someAuctionPenSaleLotsAreSelected) {
      setAuctionPenSelectedSaleLotIds(
        auctionPenSelectedSaleLotIds.concat(auctionPenAndSaleRoundSaleLotIds),
      );
    }
  }, [
    allAuctionPenSaleLotsAreSelected,
    someAuctionPenSaleLotsAreSelected,
    setAuctionPenSelectedSaleLotIds,
    auctionPenSelectedSaleLotIds,
    auctionPenAndSaleRoundSaleLotIds,
  ]);

  const mlaSupplementaryDatas: SupplementaryDataRecord = useSelector(
    getMLASupplementaryData,
  );
  const [showLots, toggleShowLots] = useToggle(false);
  const mlaSupplementaryDatasInPen: MLASupplementaryDataProps[] = Object.values(
    mlaSupplementaryDatas,
  ).filter(mlaSupplementaryData =>
    mlaSupplementaryData.saleLot
      ? mlaSupplementaryData.saleLot.auctionPenId === auctionPenId
      : false,
  );
  const calculateTotalQuantity = (items, getQuantity) =>
    items.reduce((acc, item) => acc + parseInt(getQuantity(item), 10), 0);

  const quantity =
    mlaSupplementaryDatasInPen.length > 0
      ? calculateTotalQuantity(
          mlaSupplementaryDatasInPen,
          item => item.saleLot.quantity,
        )
      : calculateTotalQuantity(
          auctionPenAndSaleRoundSaleLotIds,
          item => saleLots[item].quantity,
        );
  const { start_pen: startPen, end_pen: endPen } = auctionPen;

  const prices = sortedUniq(
    mlaSupplementaryDatasInPen
      .filter(suppData => suppData.saleLot.totalPriceCents !== "0.00")
      .map(suppData =>
        getUnitPriceString({
          total_price_cents: parseFloat(suppData.saleLot.totalPriceCents),
          pricing_type_id: suppData.saleLot.pricingTypeId,
          total_mass_grams: suppData.saleLot.totalMassGrams,
          quantity: parseFloat(suppData.saleLot.quantity),
        }),
      ),
  );
  const pricingTypes = sortedUniq(
    mlaSupplementaryDatasInPen.map(suppData => suppData.saleLot.pricingTypeId),
  );
  let price = "";
  if (prices.length > 0) {
    price = prices.length > 1 ? `${prices.length} prices` : `${prices[0]}`;
  }

  const saleLotIds = useMemo(
    () =>
      mlaSupplementaryDatasInPen.length > 0
        ? mlaSupplementaryDatasInPen.map(
            mlaSupplementaryDataItem => mlaSupplementaryDataItem.id,
          )
        : auctionPenAndSaleRoundSaleLotIds,
    [mlaSupplementaryDatasInPen, auctionPenAndSaleRoundSaleLotIds],
  );

  return (
    <CheckBoxComponentContainer
      showSelect={showSelect}
      indeterminate={someButNotAllSaleLotsAreSelected}
      checked={allAuctionPenSaleLotsAreSelected}
      onChange={onChange}
      checkBoxClassName="mt-12"
      disabled={false}
      containerClassName=""
    >
      <StatusCard status="complete">
        <Row fullWidth justifyBetween onClick={toggleShowLots}>
          <Row alignCenter>
            <PrimaryInfoHeader>Pen</PrimaryInfoHeader>
            <PrimaryInfoNoMargin>
              {startPen || "-"}
              {endPen && ` - ${endPen}`}
            </PrimaryInfoNoMargin>
          </Row>
          <Row>
            &nbsp;
            <MLAMissingFieldsIndicator keyValue={saleLotIds} />
          </Row>
        </Row>
        <Row fullWidth justifyBetween onClick={toggleShowLots}>
          <Row alignCenter justifyBetween width="50%">
            <Row alignCenter>
              <PrimaryInfoHeader>Hd</PrimaryInfoHeader>
              <PrimaryInfoNoMargin>{quantity}</PrimaryInfoNoMargin>
            </Row>
            <Row alignCenter>
              <PrimaryInfoNoMargin>{price || "-"}</PrimaryInfoNoMargin>
              <PrimaryInfoHeader>
                {pricingTypes.length === 1 && price
                  ? PricingTypes.toString(pricingTypes[0])
                  : ""}
              </PrimaryInfoHeader>
            </Row>
          </Row>
          <Row>
            <OpenIndicator isOpen={showLots} onClick={() => toggleShowLots} />
          </Row>
        </Row>
        {showLots
          ? mlaSupplementaryDatasInPen.map(mlaSupplementaryDataItem => (
              <MLALotCard
                mlaSupplementaryDataId={mlaSupplementaryDataItem.id}
                showSelect={showSelect}
                key={mlaSupplementaryDataItem.id}
              />
            ))
          : null}
      </StatusCard>
    </CheckBoxComponentContainer>
  );
}

export function MLAPenList(): React.JSX.Element {
  const mlaSupplementaryDatas = useSelector(getMLASupplementaryData);
  const [fusePenIds, setFusePenIds] = useState(null);
  const auctionPenSearchIndex = useSelector(selectAuctionPenSearchIndex);
  const selectedRoundId = useSelector(getSetting(Settings.round));
  const unpennedSaleLotIds = useSelector(
    getUnpennedSaleLotIdsBySaleRoundId(selectedRoundId),
  );
  function onBasicSearchTextChanged(searchText) {
    setFusePenIds(
      searchText
        ? new Set(
            auctionPenSearchIndex
              .search(searchText)
              .map(result => result.item.id),
          )
        : null,
    );
  }

  const [visibleAuctionPenIds] = useGetVisibleAndTempAuctionPenIds(fusePenIds);

  const [showSelect, setShowSelect] = useState(false);
  const [
    isBulkUpdateMLASupplementaryShowModalOpen,
    openBulkUpdateMLASupplementaryModal,
    closeBulkUpdateMLASupplementaryModal,
  ] = useBoolean(false);

  const auctionPenSelectedSaleLotIds =
    useSelector(getSetting(Settings.auctionPenSelectedSaleLotIds)) ||
    EMPTY_ARRAY;

  const buttons = [
    {
      title: `Bulk Update ${auctionPenSelectedSaleLotIds.length > 0 ? auctionPenSelectedSaleLotIds.length : ""} Lots`,
      isDisabled: auctionPenSelectedSaleLotIds.length === 0,
      onClick: () => openBulkUpdateMLASupplementaryModal(),
      default: true,
      icon: faPencil,
    },
  ];

  const showUnpenned =
    fusePenIds !== null
      ? // there is a search criteria
        fusePenIds.has(null)
      : // there are unpenned lots
        unpennedSaleLotIds?.length > 0;

  return !isEmpty(mlaSupplementaryDatas) ? (
    <div>
      <GlobalSearchHeader
        actionButton={<MultiButton buttons={buttons} />}
        showFacetedSearch={false}
        includeRoundSelector
        showBasicSearch
        onBasicSearchTextChanged={onBasicSearchTextChanged}
      />
      {isBulkUpdateMLASupplementaryShowModalOpen && (
        <BulkUpdateMLASupplementaryDataModal
          saleLotIds={auctionPenSelectedSaleLotIds}
          onClose={closeBulkUpdateMLASupplementaryModal}
        />
      )}
      <CheckBoxComponentContainerHeader
        showSelect={showSelect}
        setShowSelect={setShowSelect}
        containerType={CheckboxContainerTypes.MLA}
        containerValuesArgs={{ extraFilteredPenIds: fusePenIds }}
      />
      {showUnpenned && (
        <>
          <MLAPenCard auctionPenId={null} showSelect={showSelect} />
          <Spacer />
        </>
      )}
      {visibleAuctionPenIds.map(auctionPenId => (
        <>
          <MLAPenCard
            key={auctionPenId}
            auctionPenId={auctionPenId}
            showSelect={showSelect}
          />
          <Spacer />
        </>
      ))}
    </div>
  ) : (
    <MessageBox>No pens found for the selected round</MessageBox>
  );
}
