import { PropTypes } from "prop-types";
import styled from "styled-components/macro";

import HorizontalScrollBox from "./HorizontalScrollBox";
import PaddedIcon from "./PaddedIcon";

export { HorizontalScrollBox, PaddedIcon };

export const Row = styled.div(
  props => `
  ${props.reverse ? "flex-direction: row-reverse" : ""};
  ${props.justifyCenter ? "justify-content: center" : ""};
  ${props.justifyStart ? "justify-content: flex-start" : ""};
  ${props.justifyEnd ? "justify-content: flex-end" : ""};
  ${props.justifyBetween ? "justify-content: space-between" : ""};
  ${props.justifyAround ? "justify-content: space-around" : ""};
  ${props.textAlignCenter ? "text-align: center" : ""};
  ${props.textAlignLeft ? "text-align: left" : ""};
  ${props.textAlignRight ? "text-align: right" : ""};
  ${props.alignCenter ? "align-items: center" : ""};
  ${props.alignStart ? "align-items: flex-start" : ""};
  ${props.alignEnd ? "align-items: flex-end" : ""};
  ${props.alignStretch ? "align-items: stretch" : ""};
  ${props.full ? "flex-basis: 100%" : ""};
  ${props.basis ? `flex-basis: ${props.basis}` : ""};
  ${props.fullWidth ? "width: 100%" : ""};
  ${props.baseline ? "align-items: baseline" : ""};
  ${props.flexWrap ? "flex-wrap: wrap" : ""};
  ${props.flexGrow ? "flex-grow: 1" : ""};
  ${props.whiteSpaceNoWrap ? "white-space: nowrap" : ""}
  ${props.overflow !== undefined ? `overflow: ${props.overflow}` : ""};
  ${props.xOverflow !== undefined ? `overflow-x: ${props.xOverflow}` : ""};
  ${props.yOverflow !== undefined ? `overflow-y: ${props.yOverflow}` : ""};
  ${props.printHidden ? "@media print { display: none; }" : ""}
  ${props.maxHeight ? `max-height: ${props.maxHeight}` : ""};
  ${props.minHeight ? `min-height: ${props.minHeight}` : ""};
  ${props.maxWidth ? `max-width: ${props.maxWidth}` : ""};
  ${props.minWidth ? `min-width: ${props.minWidth}` : ""};
  ${props.width ? `width: ${props.width}` : ""};
  ${props.lineHeight ? `line-height: ${props.lineHeight}` : ""};
  ${props.relative ? "position: relative;" : ""}
  ${
    props.padding !== undefined
      ? `padding: ${props.theme.space[props.padding]}px;`
      : ""
  }
  ${
    props.paddingVertical !== undefined
      ? `padding-top: ${props.theme.space[props.paddingVertical]}px;
        padding-bottom: ${props.theme.space[props.paddingVertical]}px;`
      : ""
  }
  ${
    props.paddingHorizontal !== undefined
      ? `padding-left: ${props.theme.space[props.paddingHorizontal]}px;
        padding-right: ${props.theme.space[props.paddingHorizontal]}px;`
      : ""
  }
  ${
    props.margin !== undefined
      ? `margin: ${props.theme.space[props.margin]}px;`
      : ""
  }
  ${
    props.marginLeft !== undefined
      ? `margin-left: ${props.theme.space[props.marginLeft]}px;`
      : ""
  }
  ${
    props.marginRight !== undefined
      ? `margin-right: ${props.theme.space[props.marginRight]}px;`
      : ""
  }
  ${
    props.marginVertical !== undefined
      ? `margin-top: ${props.theme.space[props.marginVertical]}px;
      margin-bottom: ${props.theme.space[props.marginVertical]}px;`
      : ""
  }
  ${
    props.marginHorizontal !== undefined && props.marginHorizontal !== "auto"
      ? `
      margin-left: ${props.theme.space[props.marginHorizontal]}px;
      margin-right: ${props.theme.space[props.marginHorizontal]}px;
    `
      : props.marginHorizontal === "auto"
        ? `
      margin-left: auto;
      margin-right: auto;
    `
        : ""
  }
  ${
    props.background !== undefined
      ? `background: ${props.theme.colors[props.background]};`
      : ""
  }
  ${
    props.borderColor !== undefined
      ? `border: 1px solid ${props.theme.colors[props.borderColor]};`
      : ""
  }
  ${
    props.truncate
      ? `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`
      : ""
  }
  ${props.flex !== false ? "display: flex;" : ""}
  ${props.order ? `order: ${props.order}` : ""}
  ${
    props.gridGap !== undefined
      ? `grid-gap: ${props.theme.space[props.gridGap]}px;`
      : ""
  }
  
`,
);
Row.propTypes = {
  alignCenter: PropTypes.bool,
  alignEnd: PropTypes.bool,
  alignStart: PropTypes.bool,
  alignStretch: PropTypes.bool,
  background: PropTypes.string,
  baseline: PropTypes.bool,
  basis: PropTypes.string,
  borderColor: PropTypes.string,
  flexGrow: PropTypes.bool,
  flexWrap: PropTypes.bool,
  full: PropTypes.bool,
  fullWidth: PropTypes.bool,
  gridGap: PropTypes.number,
  justifyAround: PropTypes.bool,
  justifyBetween: PropTypes.bool,
  justifyCenter: PropTypes.bool,
  justifyEnd: PropTypes.bool,
  justifyStart: PropTypes.bool,
  margin: PropTypes.number,
  marginHorizontal: PropTypes.number,
  marginVertical: PropTypes.number,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
  minWidth: PropTypes.string,
  order: PropTypes.number,
  overflow: PropTypes.string,
  padding: PropTypes.number,
  paddingHorizontal: PropTypes.number,
  paddingVertical: PropTypes.number,
  printHidden: PropTypes.bool,
  textAlignCenter: PropTypes.bool,
  textAlignLeft: PropTypes.bool,
  textAlignRight: PropTypes.bool,
  whiteSpaceNoWrap: PropTypes.bool,
  xOverflow: PropTypes.string,
  yOverflow: PropTypes.string,
};

export const Column = styled(Row)`
  flex-direction: column;
  ${props => (props.fullHeight ? "height: 100%" : "")};
`;
Column.propTypes = {
  ...Row.propTypes,
  fullHeight: PropTypes.bool,
};

export const Cell = styled.div`
  margin: ${({ theme }) => theme.space[1]}px;
`;

export const MainContentWrapper = styled.div`
  margin: ${({ theme }) => theme.space[3]}px;
  background-color: ${({ theme }) => theme.colors.white};
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    margin: 0;
  }
`;
export const CenteredGreedy = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SpaceBetweenRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray85};
  &:last-child {
    border-bottom: 0;
  }
`;

export const InformationPanel = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.blueHighlight};
  padding: ${theme.space[2]}px;
  text-align: center;
`,
);
