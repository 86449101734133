import React from "react";

import { createFilterOptions } from "@material-ui/lab";
import { useField, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { PenTypes } from "constants/auctionPens";

import { toTitleCase } from "lib";

import {
  generateNewPenPayload,
  getAuctionPenDisplayName,
  penNameToPenComponents,
  splicePensWithArchetypes,
} from "lib/auctionPens";
import { serializeAuctionPen } from "lib/serializers/auctionPens";

import {
  getAuctionPens,
  getPenArchetypes,
  getPenArchetypesByPenType,
} from "selectors";

import { Autocomplete } from ".";

function getOptionId(option) {
  return option;
}

const PenOptionType = {
  ARCHETYPE: 0,
  ARCHETYPE_DERIVED: 1,
  CUSTOM_PEN: 2,
};

const auctionPenRangeRegex = /^[^\d-]*(\d+)[^\d-]*(-(\d+)[^\d-]*)?$/;

function getIsPenRangeInputValue(input) {
  const matches = auctionPenRangeRegex.exec(input);
  if (!matches) {
    return false;
  }
  if (matches[3]) {
    return +matches[1] < +matches[3];
  }
  return true;
}

const filter = createFilterOptions();

export function PenPicker(props) {
  const {
    label,
    idName,
    isCreateEnabled = true,
    isCreateFreeFormEnabled = true,
    isCreateArchetypeDerivedEnabled = true,
    dataName,
    onAfterSelected,
    penType = PenTypes.SELLING,
    penFilters = null,
    required,
    penName,
  } = props;

  const [
    { value: idValue },
    _ignored1,
    { setTouched: setIdTouched, setError: setIdError },
  ] = useField(idName);

  const formikProps = useFormikContext();

  const { setValues, values, errors } = formikProps;

  const archetypes = useSelector(getPenArchetypesByPenType(penType)) || [];

  // when a pen name is passed in we need to provide all the new pen data to the form
  // and validate that the pen name is correct
  if (penName && !idValue && !errors[idName]) {
    const penArchetype = archetypes.find(
      archetype => archetype.penName === penName,
    );

    const newPenPayload = generateNewPenPayload(
      penName,
      penType,
      penArchetype?.id,
    );
    // if the pen name is incorrect inform the user
    if (!getIsPenRangeInputValue(penName)) {
      setIdError(`${penName} is an invalid pen`);
      setIdTouched(true, false);
    } else {
      // otherwise set the values
      setValues({
        ...values,
        [idName]: newPenPayload.id,
        [dataName]: newPenPayload,
      });
    }
  }

  const [{ value: dataValue }] = useField(dataName);

  const penArchetypeByIdLookup = useSelector(getPenArchetypes) || {};

  const pens = useSelector(getAuctionPens);

  function getOptionPenName(option) {
    return option.penName
      ? option.penName
      : pens[option]
        ? pens[option]?.startPen
        : dataValue?.penName || option;
  }

  const additionalFilters = Object.entries({ penType, ...penFilters });

  const filteredPens = Object.values(pens).filter(pen =>
    additionalFilters.every(([key, value]) => pen[key] === value),
  );
  const filteredArchetypes = Object.values(archetypes).filter(pen =>
    additionalFilters.every(([key, value]) => pen[key] === value),
  );

  const options = [];

  const pensAndArchetypes = splicePensWithArchetypes(
    filteredPens,
    filteredArchetypes,
  );

  pensAndArchetypes.forEach(penObject => {
    const penArchetype = penArchetypeByIdLookup[penObject.id];

    const derivedArchetype = penArchetypeByIdLookup[penObject.penArchetypeId];

    const isPenArchetype = !!penArchetype;

    if (!isPenArchetype) {
      // we have a pen
      options.push({
        capacity: penObject.capacity,
        id: penObject.id,
        isLocked: penObject.isLocked,
        isLaneStart: penObject.isLaneStart,
        optionType: PenOptionType.CUSTOM_PEN,
        order: penObject.order,
        penArchetypeId: derivedArchetype?.id || null,
        penType,
        saleRoundId: null,
        ...penNameToPenComponents(getAuctionPenDisplayName(penObject)),
      });
    } else {
      // we have an archetype
      options.push({
        capacity: penArchetype.capacity,
        id: null,
        isLocked: false,
        isLaneStart: penArchetype.isLaneStart,
        optionType: PenOptionType.ARCHETYPE,
        order: penArchetype.order,
        penArchetypeId: penArchetype.id,
        penType,
        saleRoundId: null,
        penName: penArchetype?.penName,
        ...penNameToPenComponents(penArchetype?.penName),
      });
    }
  });

  function onChangeExtra(newValue) {
    const existingValueEquivalent = getAuctionPenDisplayName(
      dataValue,
      "",
    ).toLowerCase();

    const lowerCasePenName =
      typeof newValue === "string"
        ? newValue.toLowerCase()
        : newValue?.penName?.toLowerCase();

    const isValidInput = getIsPenRangeInputValue(lowerCasePenName);

    // if input value is not the same as an existing pen equivilant
    if (
      isValidInput &&
      lowerCasePenName &&
      lowerCasePenName !== existingValueEquivalent
    ) {
      // try to find an exisitng pen with the pen name of the input value
      const penOption = options.find(
        penOption => penOption.penName.toLowerCase() === lowerCasePenName,
      );

      // if found and the pen option type is custom or archetype derived
      // set the value to the id of the pen found
      if (
        penOption &&
        (penOption.optionType === PenOptionType.CUSTOM_PEN ||
          penOption.optionType === PenOptionType.ARCHETYPE_DERIVED)
      ) {
        const { id } = penOption;

        setValues({ ...values, [idName]: id, [dataName]: null }).then(() =>
          setIdTouched(true, false),
        );

        return;

        // if the inputValue has returned a pen and the pens option type is archetype
        // set the penData from the archetype data
      } else if (
        isValidInput &&
        penOption &&
        penOption.optionType === PenOptionType.ARCHETYPE &&
        isCreateEnabled &&
        isCreateArchetypeDerivedEnabled
      ) {
        const newPen = {
          ...penOption,
          id: uuidv4(),
        };

        const payload = {
          ...serializeAuctionPen(newPen),
          start_pen: newPen.startPen,
          end_pen: newPen.endPen,
        };

        setValues({
          ...values,
          [idName]: newPen?.id,
          [dataName]: payload,
        }).then(() => setIdTouched(true, false));

        return;
      }
      // if no exisitng pen or pen archetype exists - retreive as much information
      // as we can from the data input
      if (isValidInput && isCreateEnabled && isCreateFreeFormEnabled) {
        const payload = generateNewPenPayload(newValue, penType);

        setValues({
          ...values,
          [idName]: payload.id,
          [dataName]: payload,
        }).then(() => setIdTouched(true, false));
      } else {
        // don't set any values if we dont meet the critera above
        setValues({ ...values, [idName]: "", [dataName]: null }).then(() =>
          setIdTouched(true, false),
        );
      }
    } else if (!lowerCasePenName) {
      // don't set any values if we dont meet the critera above
      setValues({ ...values, [idName]: "", [dataName]: null }).then(() =>
        setIdTouched(true, false),
      );
    }

    typeof onAfterSelected === "function" && onAfterSelected(newValue);
  }

  function onChange(_ignored1, newValue) {
    onChangeExtra(newValue);
  }
  function onClose(event, reason) {
    if (reason === "blur") {
      onChangeExtra(event.target.value);
    }
  }

  function getSelectedOption(value) {
    if (value) {
      if (typeof value === "string") {
        return pens[value]?.start_pen || value;
      } else {
        return value.start_pen;
      }
    } else if (idValue) {
      const option = options.find(penOption => penOption.id === idValue);
      return option?.penName || option?.start_pen;
    } else if (dataValue) {
      return dataValue.penName;
    }
    return null;
  }

  function filterOptions(options, params) {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (params.inputValue !== "") {
      if (!getIsPenRangeInputValue(params.inputValue)) {
        filtered.push({
          disabled: true,
          penName: `${params.inputValue} (Invalid Pen)`,
        });
      } else {
        filtered.push(params.inputValue);
      }
    }

    return filtered.filter(fv =>
      typeof fv === "string"
        ? !options.filter(option => option.penName === fv).length > 0
        : fv,
    );
  }

  // create grouping to seperate pens by types this also will show the
  // user that they're going to create a new pen or the pen isn't found
  // if create is disabled
  const groupBy = option => {
    if (
      !option.id &&
      !option.penArchetypeId &&
      !options.filter(o => o.penName === option).length
    ) {
      return isCreateEnabled ? "Create New" : "Not Found";
    }
    return option && option.penArchetypeId !== null
      ? `${toTitleCase(penType)} Pens`
      : `Free-form ${toTitleCase(penType)} Pens`;
  };

  const getOptionSelected = option => {
    return option?.id === idValue || option?.id === dataValue?.id;
  };
  return (
    <Autocomplete
      filterOptions={filterOptions}
      getOptionLabel={getOptionPenName}
      getOptionValue={getOptionId}
      getSelectedOption={getSelectedOption}
      groupBy={groupBy}
      onChange={onChange}
      getOptionSelected={getOptionSelected}
      onClose={onClose}
      label={label}
      name={idName}
      options={options}
      required={required}
    />
  );
}
