import React, { useEffect } from "react";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { IntegrationBusinessAction } from "actions";

import { SubtleBadge } from "components/Badge";
import { IconTextButton } from "components/Button";
import { Warning } from "components/ErrorMessage";
import { CollapseLabel, FormCollapse } from "components/Form";
import { Button } from "components/Form/Button";
import { Input } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import Table from "components/Table";

import { IntegrationTypes } from "constants/integrations";
import { ApiModel } from "constants/loading";
import {
  DeploymentPermissions,
  IntegrationBusinessPermissions,
} from "constants/permissions";

import { openEditIntegrationBusinessModal } from "lib/navigation";
import { hasPermission } from "lib/permissions";
import { pluralize } from "lib/pluralize";

import { getBusinessById, getIntegrationBusinesses } from "selectors";

import { useHasDeploymentPermission, useSectionToggle } from "hooks";

const ActionsCellRenderer = props => {
  const { id, businessId } = props.value;

  const hasUpdatePermission = hasPermission(
    props.value,
    IntegrationBusinessPermissions.update,
  );

  const dispatch = useDispatch();

  function onClickUnlink() {
    dispatch(IntegrationBusinessAction.unlinkFromBusiness(id, businessId));
  }

  return hasUpdatePermission ? (
    <IconTextButton icon={faTrash} onClick={onClickUnlink} />
  ) : null;
};

const columns = [
  {
    id: "Name",
    Header: "Name",
    accessor: "name",
  },
  {
    id: "type",
    Header: "Type",
    accessor: "type",
  },
  {
    id: "actions",
    Header: "",
    accessor: "",
    Cell: ActionsCellRenderer,
    className: "center",
  },
];

const IntegrationTableSection = {
  XERO: "XERO",
  MYOB: "MYOB",
};

const IntegrationsTable = props => {
  const { businessId, onToggle, isOpen } = props;

  const { errors } = useFormikContext();

  // Load some extra data into our integration business from the (refrehsed) global state - specifically
  // looking at permissions here.
  const allIntegrationBusinesses = useSelector(getIntegrationBusinesses);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(IntegrationBusinessAction.requestChanges());
  }, [dispatch]);

  const [section, setSection] = useSectionToggle(IntegrationTableSection.XERO);

  const business = useSelector(getBusinessById(businessId)) || {};

  const businessHasMyobData =
    business.myobCustomerCardId != null || business.myobSupplierCardId != null;

  const { integrationBusinesses = [] } = business;
  const enrichedIntegrationBusinesses = integrationBusinesses.map(
    integrationBusiness => ({
      ...allIntegrationBusinesses?.[integrationBusiness.id],
      ...integrationBusiness,
      businessId,
    }),
  );

  const hasMyobExportsFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureMyobIntegration,
  );

  const numIntegrations =
    integrationBusinesses.length + (businessHasMyobData ? 1 : 0);

  const header = (
    <Row>
      <SubtleBadge>Integrations</SubtleBadge>
      <CollapseLabel>
        {numIntegrations} {pluralize("Integration", numIntegrations)}
      </CollapseLabel>
    </Row>
  );

  const XeroHeader = (
    <Row>
      <SubtleBadge>Xero</SubtleBadge>
      <Row alignCenter flexWrap />
    </Row>
  );

  const MyobHeader = (
    <Row>
      <SubtleBadge>MYOB</SubtleBadge>
      <Row alignCenter flexWrap />
    </Row>
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header}
      dataTour="integrationsCollapse"
    >
      <Column fullWidth>
        <FormCollapse
          isOpen={section === IntegrationTableSection.XERO}
          onToggle={() => setSection(IntegrationTableSection.XERO)}
          header={XeroHeader}
        >
          <Column fullWidth>
            <Button
              onClick={() =>
                openEditIntegrationBusinessModal(
                  IntegrationTypes.Xero,
                  businessId,
                )
              }
            >
              Link To Xero
            </Button>

            <WaitForSync requiredData={[ApiModel.INTEGRATION_BUSINESSES]}>
              <Table data={enrichedIntegrationBusinesses} columns={columns} />
            </WaitForSync>
          </Column>
        </FormCollapse>
      </Column>
      {hasMyobExportsFeature && (
        <Column fullWidth>
          <FormCollapse
            isOpen={section === IntegrationTableSection.MYOB}
            onToggle={() => setSection(IntegrationTableSection.MYOB)}
            header={MyobHeader}
          >
            <Column fullWidth>
              <Grid item xs={12} sm={12} className="p-2">
                <Input
                  label="Customer Card Id"
                  name="myobCustomerCardId"
                  maxLength={15}
                />
              </Grid>
              <Grid item xs={12} sm={12} className="p-2">
                <Input
                  label="Supplier Card Id"
                  name="myobSupplierCardId"
                  maxLength={15}
                />
              </Grid>
              {errors.identicalCardIds && (
                <Grid item xs={12} sm={12} className="p-2">
                  <Warning>{errors.identicalCardIds}</Warning>
                </Grid>
              )}
            </Column>
          </FormCollapse>
        </Column>
      )}
    </FormCollapse>
  );
};

IntegrationsTable.propTypes = {
  businessId: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default IntegrationsTable;
