import React, { useEffect, useMemo } from "react";

import Grid from "@material-ui/core/Grid";
import { addDays } from "date-fns";
import { useFormikContext } from "formik";
import isNumber from "lodash/isNumber";
import { useDispatch, useSelector } from "react-redux";

import { BillingRunAction } from "actions";

import { Warning } from "components/ErrorMessage";
import { BusinessField } from "components/Form/Fields";
import { HTMLTemplateTextField } from "components/Form/Fields/DetailsText";
import {
  DateInput,
  Input,
  SelectField,
  withNamespace,
} from "components/Form/FormikControls";

import { BillingNumberPrefixes } from "constants/billing";
import {
  BillingDocumentStatus,
  BillingDocumentStatusOptions,
  BillingDocumentType,
} from "constants/billingDocuments";
import { BillingRunStatus } from "constants/billingRuns";
import { SaleTypes } from "constants/sale";

import {
  formatDateString,
  getDateFromISO8601DateString,
} from "lib/timeFormats";

import {
  getActiveLivestockAgentDeployment,
  getBillingDocumentById,
  getBillingRunById,
  getCurrentSale,
  getSales,
} from "selectors";

export function BillingDocumentForm(props) {
  const {
    billingDocumentId,
    saleId = null,
    namespace: ns = "",
    prefillApplied = false,
  } = props;

  const { deploymentSettings } =
    useSelector(getActiveLivestockAgentDeployment) || {};

  let sale = useSelector(getCurrentSale);
  const sales = useSelector(getSales);
  if (saleId) {
    sale = sales[saleId];
  }
  const saleDate = sale.date;

  const deploymentSale = sale.deployment_sales?.[0] || {};
  const { buyer_terms: buyerTerms, vendor_terms: vendorTerms } = deploymentSale;

  const billingDocument =
    useSelector(getBillingDocumentById(billingDocumentId)) || {};
  const { type: documentType, billingRunId } = billingDocument;

  const dispatch = useDispatch();
  useEffect(() => {
    if (billingRunId) {
      dispatch(BillingRunAction.requestOne({ id: billingRunId }));
    }
  }, [billingRunId, dispatch]);

  const billingRun = useSelector(getBillingRunById(billingRunId));

  const {
    values: { status: currentStatus, dueDate, number },
  } = useFormikContext();

  // Show a warning when the due date is different to the expected.
  const terms =
    (documentType === BillingDocumentType.RCTI ? vendorTerms : buyerTerms) || 0;

  // Convert to a date, add the terms, then back to a string for comparison.
  const expectedDueDate = formatDateString(
    addDays(getDateFromISO8601DateString(saleDate), terms),
  );

  const showDueDateWarning =
    dueDate &&
    expectedDueDate !== formatDateString(getDateFromISO8601DateString(dueDate));

  const prefix = useMemo(() => {
    // If number is not a number, assume it already has a prefix.
    if (number && !isNumber(number)) {
      return "";
    }

    if (documentType === BillingDocumentType.RCTI) {
      return (
        deploymentSettings?.accountSalePrefix || BillingNumberPrefixes.RCTI
      );
    } else {
      return deploymentSettings?.invoicePrefix || BillingNumberPrefixes.Invoice;
    }
  }, [
    number,
    documentType,
    deploymentSettings?.accountSalePrefix,
    deploymentSettings?.invoicePrefix,
  ]);

  const referenceSale = sale.sale_type === SaleTypes.REFERENCE_SALE;
  const billingRunFinished = billingRun?.status === BillingRunStatus.FINISHED;
  const disabled = useMemo(() => {
    return referenceSale || billingRunFinished;
  }, [billingRunFinished, referenceSale]);
  return (
    <Grid container spacing={2}>
      {referenceSale && (
        <Grid item xs={12}>
          <Warning>
            Information record only, this has been invoiced externally.
          </Warning>
        </Grid>
      )}
      {billingRunFinished && (
        <Grid item xs={12}>
          <Warning>
            Information record only, this documents billing run is finished,
          </Warning>
        </Grid>
      )}
      <Grid item xs={6}>
        <SelectField
          label="Status"
          name={withNamespace(ns, "status")}
          options={BillingDocumentStatusOptions}
          required
          menuPortalTarget={document.body}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6}>
        <Input
          beforeSymbol={prefix}
          label="Document Number"
          name={withNamespace(ns, "number")}
          tooltip="The reference number assigned to this document.  This will be automatically populated when a document is approved."
          placeholder="Assigned on Approval"
          emptyValue={null}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6}>
        <BusinessField name="recipientBusinessId" label="Recipient" disabled />
      </Grid>
      <Grid item xs={6}>
        <BusinessField name="issuerBusinessId" label="Issuer" disabled />
      </Grid>

      <Grid item xs={12}>
        <DateInput
          name="dueDate"
          label="Due Date"
          required
          disabled={currentStatus !== BillingDocumentStatus.DRAFT || disabled}
        />
        {!disabled && showDueDateWarning ? (
          <Warning>
            Warning: The date entered is different to the default (
            {expectedDueDate}) for this document type.
          </Warning>
        ) : null}
      </Grid>
      <Grid item xs={12} md={12}>
        <HTMLTemplateTextField name="detailsText" disabled={disabled} />
        {!disabled && prefillApplied ? (
          <Warning>
            The Details Text has been prefilled from your Agency Settings.
          </Warning>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <Input
          label="External Ref/s"
          name="billingReference"
          maxLength={255}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}
