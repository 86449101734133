import React, { useEffect, useState, useRef } from "react";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components/macro";

import { setSetting } from "actions/settings";

import { OutlineButton } from "components/Button";
import { Row, Column } from "components/Layout";

import { Settings } from "constants/settings";

import { MARKING_COLOUR_HEX_TO_NAME_MAP } from "lib/markColors";

import { getSetting } from "selectors";

const PickerBody = styled(Column)(
  ({ theme }) => `
  width: 100%;
  max-width: 228px;
  padding: ${theme.space[2]}px;
`,
);

const WrapperInput = styled(Row)(
  ({ theme }) => `
  margin-top: ${theme.space[1]}px;
  margin-bottom: ${theme.space[1]}px;
  color: ${theme.colors.titleMedium};
  font-size ${theme.fontSizes.beta}px;
  line-height: ${theme.fontSizes.epsilon}px;
`,
);
const WrapperColors = styled(Row)`
  flex-wrap: wrap;
  width: 100%;
`;

const ColorBlock = styled.div(
  ({ borderColor, color, theme }) => `
  -webkit-appearance: initial !important;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  height: 36px;
  min-width: 36px;
  margin-bottom: ${theme.space[1]}px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(:first-child):not(:nth-child(5n + 1)) {
    margin-left: ${theme.space[1]}px;
  }
  z-index: 2;
  background-color: ${color};
  ${borderColor && `border: 1px solid ${borderColor};`}
`,
);

const TicketIcon = styled(FontAwesomeIcon)(
  ({ color, theme }) => `
  font-size: ${theme.fontSizes.charlie}px;
  color: ${color || "white"};
`,
);

const StyledInput = styled.input(
  ({ theme }) => `
  min-width: 0;
  width: 100%;
  height: 36px;
  padding: ${theme.space[1]}px ${theme.space[2]}px;
  color: inherit;
`,
);

const defaultColors = Object.keys(MARKING_COLOUR_HEX_TO_NAME_MAP);

const MarkingPopover = ({
  anchorEl,
  anchorOrigin,
  closeSelf,
  colors = defaultColors,
  isOpen,
  onMarksChanged,
  transformOrigin,
  autoFocus,
}) => {
  const defaultColor = useSelector(getSetting(Settings.markColor));
  const dispatch = useDispatch();

  const [colorSelected, setColorSelected] = useState(null);
  const [location, setLocation] = useState("");

  const locationRef = useRef(null);

  useEffect(() => {
    setColorSelected(defaultColor);
  }, [defaultColor]);

  const handleSelectColor = color => {
    const newColorValue = colorSelected === color ? undefined : color;

    setColorSelected(newColorValue);

    dispatch(setSetting(Settings.markColor, newColorValue));
  };

  const onEnteredPopover = () => {
    if (autoFocus) {
      locationRef.current.focus();
    }
  };

  function onClickAdd() {
    typeof onMarksChanged === "function" &&
      onMarksChanged(colorSelected, location);
    closeSelf();
    return false;
  }

  function handleChangeTextInput(e) {
    setLocation(e.target.value.toUpperCase());
  }

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      TransitionProps={{ onEntered: onEnteredPopover }}
    >
      <PickerBody>
        <WrapperInput>
          <StyledInput
            data-tour="bodyPart"
            id="body_part"
            value={location}
            ref={locationRef}
            onChange={handleChangeTextInput}
            placeholder="Add the body part"
            autoComplete="off"
            maxLength={50}
          />
        </WrapperInput>
        <WrapperColors>
          {colors.length > 0 &&
            colors.map((color, index) => {
              const borderColor =
                color.toUpperCase() === "#FFFFFF" ? "#C2C2C2" : "";
              const ticketColor =
                color.toUpperCase() === "#FFFFFF" ? "#000000" : "";
              return (
                <ColorBlock
                  color={color}
                  borderColor={borderColor}
                  key={`color_${index}`}
                  onClick={() => handleSelectColor(color)}
                >
                  {colorSelected === color && (
                    <TicketIcon color={ticketColor} icon={faCheck} />
                  )}
                </ColorBlock>
              );
            })}
        </WrapperColors>

        <Row>
          <OutlineButton
            data-tour="close"
            color="primary"
            style={{ flex: 1, marginRight: 4 }}
            onClick={closeSelf}
          >
            Close
          </OutlineButton>
          <OutlineButton
            data-tour="add"
            color="primary"
            style={{ flex: 1, marginLeft: 4 }}
            onClick={onClickAdd}
            disabled={!location}
          >
            Add
          </OutlineButton>
        </Row>
      </PickerBody>
    </Popover>
  );
};

MarkingPopover.propTypes = {
  onMarksChanged: PropTypes.func,
  colors: PropTypes.array,
  closeSelf: PropTypes.func,
  isOpen: PropTypes.bool,
  anchorEl: PropTypes.object,
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOf(["left", "center", "right"]),
    vertical: PropTypes.oneOf(["top", "center", "bottom"]),
  }),
  transformOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOf(["left", "center", "right"]),
    vertical: PropTypes.oneOf(["top", "center", "bottom"]),
  }),
};

export default MarkingPopover;
