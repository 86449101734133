import React from "react";

import { faCircleDot, faCircle } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "@material-ui/core";

import { SmallFaIcon } from "components/FaIcon";
import { Row } from "components/Layout";
import { Text } from "components/Text";

import { makeCommaSeparatedString, pluralize } from "lib/pluralize";

import { MissingFieldsIndicatorTypes } from "constants/saleLots";

import { useHasMLAPermission, useHasNASPermissions, useIsMobile } from "hooks";

import { MissingFieldsIndicatorBaseProps } from "./types";

export function MissingFieldsIndicatorBase(
  props: MissingFieldsIndicatorBaseProps,
): React.JSX.Element {
  const {
    warnings,
    label,
    overrideNoMissingField = false,
    includeLabel = true,
  } = props;
  // We can use the override when displaying warnings on a single field
  // For example, check if the warning array contains our field,
  // if it doesn't then we can overide with a green status

  const hasNASPermissions = useHasNASPermissions();
  const hasMLAPermission = useHasMLAPermission();
  const isMobile = useIsMobile();
  const isMobileAndNoLabel = isMobile && !includeLabel;

  if (
    (label === MissingFieldsIndicatorTypes.NAS && !hasNASPermissions) ||
    (label === MissingFieldsIndicatorTypes.MLA && !hasMLAPermission)
  ) {
    return null;
  }

  if (overrideNoMissingField) {
    warnings.length = 0;
  }

  let tooltipText = "";
  if (warnings.length > 0) {
    tooltipText = `The Required ${label} ${pluralize("Field", warnings.length)}: ${makeCommaSeparatedString(warnings)} ${warnings.length > 1 ? "are" : "is"} missing`;
  } else if (!overrideNoMissingField) {
    tooltipText = `All Required ${label} Fields Entered`;
  }

  return (
    <Row
      gridGap={1}
      padding={isMobileAndNoLabel ? 0 : 1}
      alignCenter
      alignStart={isMobileAndNoLabel}
      marginRight={1}
    >
      <Text fontSize="beta" color="titleLight">
        {includeLabel ? label : ""}
      </Text>
      <Tooltip title={tooltipText}>
        <SmallFaIcon
          icon={warnings.length > 0 ? faCircleDot : faCircle}
          color={warnings.length > 0 ? "warning" : "success"}
        />
      </Tooltip>
    </Row>
  );
}
