import React from "react";

import { getEstimatedAlternativeCostPerUnit } from "lib/saleLot";

import { Chip } from "./BaseChip";

export const MoneyChip = ({ tooltip, children }) => (
  <Chip backgroundColor="gold" tooltip={tooltip} color>
    {children}
  </Chip>
);

export const AlternativeMoneyChip = ({ tooltip, saleLot }) => {
  const estimates = getEstimatedAlternativeCostPerUnit(saleLot);
  if (estimates) {
    return (
      <Chip backgroundColor="lavender" tooltip={tooltip} color>
        ~ {estimates.price} {estimates.priceUnits}
      </Chip>
    );
  } else {
    return null;
  }
};
