import React from "react";

import { Input } from "./Input";

// Note - these are currently hardcoded - if need be they can be database driven (and pulled from a selector in the component)
// but at current time there is no need to complicate.
const RECEIVAL_MARKS = [
  "NM",
  "S",
  "B",
  "L",
  "T",
  "P",
  "SL",
  "ST",
  "BL",
  "BT",
  "LT",
  "3D",
];

function getReceivalMarkPrefillOptions() {
  return RECEIVAL_MARKS;
}

export function ReceivalMarkInput(props) {
  const { name, label, required } = props;

  return (
    <Input
      dataTour="Marks"
      name={name}
      label={label}
      getPrefillOptions={getReceivalMarkPrefillOptions}
      required={required}
    />
  );
}
