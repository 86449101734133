import React from "react";

import {
  faCheck,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";

import { getPropertyById } from "selectors";

import { Chip } from "./BaseChip";

export const NLISChip = ({ children }) => (
  <Chip backgroundColor="white" borderColor="gray13">
    {children}
  </Chip>
);
export const NLISChips = ({ propertyId }) => {
  const lpaStatus = useSelector(
    state => getPropertyById(propertyId)(state)?.lpaStatus || "",
  );

  if (lpaStatus === "") {
    return null;
  }
  return (
    <NLISChip key="lpa">
      LPA{" "}
      {lpaStatus === "A" ? (
        <FaIcon icon={faCheck} color="success" />
      ) : (
        <FaIcon icon={faExclamationCircle} color="warning" />
      )}
    </NLISChip>
  );
};
export const FullOrPartial = ({ label, values, positive }) => {
  const isSet = values instanceof Set;
  const isArray = Array.isArray(values);

  if ((isArray && values.length === 0) || (isSet && values.size === 0)) {
    return null;
  }
  // If we have multiple entries, it's a mixed lot.
  if ((isArray && values.length > 1) || (isSet && values.size > 1)) {
    return <NLISChip>{label} Partial</NLISChip>;
  }
  // Single value, get it and report it.
  const { value } = values.values().next();
  if (value === positive) {
    return <NLISChip>{label} All</NLISChip>;
  }
  return <NLISChip>{label} None</NLISChip>;
};
