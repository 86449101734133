import React from "react";

import { useSelector } from "react-redux";

import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { MissingFieldsIndicatorTypes } from "constants/saleLots";

import { selectNASFieldWarningsBySaleLotId } from "selectors";

import {
  useHasSaleyardOrDeploymentPermission,
  useGetMissingFieldWarnings,
} from "hooks";
import { NASMissingFieldsIndicatorProps } from "./types";
import { MissingFieldsIndicatorBase } from "./MissingFieldsIndicator";

export function NASMissingFieldsIndicator(
  props: NASMissingFieldsIndicatorProps,
): React.JSX.Element {
  const { keyValue } = props;
  const hasDentitionPermission = useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.featureDentition,
    SaleyardPermissions.featureDentition,
  );

  const hasWeightRangePermission = useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.featureWeightRange,
    SaleyardPermissions.featureWeightRange,
  );

  const hasMissingNasFieldsSaleLotIdLookup = useSelector(
    selectNASFieldWarningsBySaleLotId,
  );

  let keyValues = keyValue;
  if (!Array.isArray(keyValues)) {
    keyValues = [keyValues];
  }

  const warnings = useGetMissingFieldWarnings({
    modelIds: keyValues,
    lookup: hasMissingNasFieldsSaleLotIdLookup,
  });

  if (!hasWeightRangePermission || !hasDentitionPermission) {
    return null;
  }

  return (
    <MissingFieldsIndicatorBase
      label={MissingFieldsIndicatorTypes.NAS}
      warnings={warnings}
    />
  );
}
