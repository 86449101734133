import React from "react";

import { uniq } from "lodash";
import { useSelector } from "react-redux";

import { getAuctionPenDisplayName } from "lib/auctionPens";

import {
  getAuctionPens,
  selectDeliveryPenIdBySaleLotIdLookup,
} from "selectors";

import { Chip } from "./BaseChip";

export const DeliveryPenChip = ({ saleLotIds }) => {
  const deliveryPenIdBySaleLotIdLookup = useSelector(
    selectDeliveryPenIdBySaleLotIdLookup,
  );

  const auctionPensLookup = useSelector(getAuctionPens);

  const deliveryPens = React.useMemo(() => {
    return uniq(
      saleLotIds.map(
        saleLotId =>
          auctionPensLookup[deliveryPenIdBySaleLotIdLookup[saleLotId]],
      ),
    )
      .filter(Boolean)
      .map(getAuctionPenDisplayName);
  }, [saleLotIds, deliveryPenIdBySaleLotIdLookup, auctionPensLookup]);

  return (
    deliveryPens.length > 0 && (
      <Chip
        backgroundColor="deliveryPenBackground"
        tooltip={deliveryPens.length > 1 && deliveryPens.join(", ")}
      >
        {deliveryPens.length === 1
          ? `DPen ${deliveryPens[0]}`
          : `${deliveryPens.length} DPens`}
      </Chip>
    )
  );
};
