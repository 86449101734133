import React from "react";

import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import styled from "styled-components/macro";

import { Row } from "components/Layout";

export const OverlayModal = styled(Row)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1300;
  overflow-y: auto;
`;
OverlayModal.displayName = "OverlayModal";

export const Content = styled.div`
  min-width: 375px;
  height: 90%;
  background-color: #fff;
  padding: ${({ theme }) => theme.space[2]}px;
  z-index: 1000;
  ${({ theme, expandHeight }) => `
     ${
       expandHeight &&
       `@media only screen and (max-width: ${theme.breakpoints[0]}px) {
          height: 100% !important;
        }`
     }
    `}};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints[2]}px) {
    width: 100%;
    height: 100%;
  }
`;
Content.displayName = "Content";

const appRoot = document.getElementById("root");

export class ModalBg extends React.Component {
  componentDidMount() {
    const { closeOnClickOutside = false } = this.props;
    document.body.style.overflow = "hidden";
    closeOnClickOutside &&
      document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    const { closeOnClickOutside } = this.props;
    document.body.style.overflow = "auto";
    closeOnClickOutside &&
      document.removeEventListener("click", this.handleClickOutside, true);
  }

  getComponent() {
    const { children, contentStyle, backgroundStyle, expandHeight } =
      this.props;

    return (
      <OverlayModal style={backgroundStyle} justifyCenter alignCenter>
        <Content
          style={contentStyle}
          ref={this.setWrapperRef}
          expandHeight={expandHeight || false}
        >
          {children}
        </Content>
      </OverlayModal>
    );
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleClickOutside = event => {
    const { closeSelf } = this.props;
    if (
      closeSelf &&
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target)
    ) {
      closeSelf();
    }
  };

  render() {
    const component = this.getComponent();
    return ReactDOM.createPortal(component, appRoot);
  }
}

ModalBg.propTypes = {
  children: PropTypes.any.isRequired,
  backgroundStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  closeSelf: PropTypes.func,
  expandHeight: PropTypes.bool,
  closeOnClickOutside: PropTypes.bool,
};
