import React from "react";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { Chip, ChipWrapper } from "components/Chip";

import { GOOD_STATUSES, LPA_N } from "constants/nlis";

export const ERPChip = saleLots => {
  let hasERPWarnings;
  let badErpMessages = null;
  if (saleLots.erpMessages) {
    badErpMessages = [...new Set(saleLots.erpMessages)].filter(
      m => !GOOD_STATUSES.includes(m),
    );
    hasERPWarnings = badErpMessages.length > 0;
  } else {
    const erpMessages = [];
    if (saleLots.saleLots && saleLots.saleLots.length > 0) {
      saleLots.saleLots.forEach(saleLot => {
        if (saleLot.scans) {
          saleLot.scans.forEach(scan => {
            if (scan.ERP_status) {
              erpMessages.push(scan.ERP_status);
            }
          });
        }
      });
    }
    badErpMessages = [...new Set(erpMessages)].filter(
      m => !GOOD_STATUSES.includes(m),
    );
    hasERPWarnings = badErpMessages.length > 0;
  }

  const onlyLpaWarnings = badErpMessages
    .filter(Boolean)
    .some(message => message.includes("LPA N "));

  const mixedWarnings =
    badErpMessages.includes(LPA_N) &&
    badErpMessages.length > 1 &&
    !badErpMessages.every(value => value === LPA_N);

  let warningText;
  if (onlyLpaWarnings) {
    warningText = "LPA WARNING";
  } else if (mixedWarnings) {
    warningText = "ERP & LPA WARNING";
  } else {
    warningText = "ERP WARNING";
  }

  return hasERPWarnings ? (
    <ChipWrapper>
      <Chip color backgroundColor="warning" tooltip={badErpMessages}>
        <FaIcon icon={faExclamationCircle} />
        &nbsp;{warningText}
      </Chip>
    </ChipWrapper>
  ) : null;
};
