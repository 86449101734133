import { useCallback, useMemo } from "react";

import { flatten, uniq } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { setSetting } from "actions";

import { Settings } from "constants/settings";

import { EMPTY_ARRAY } from "lib";

import { pluralize } from "lib/pluralize";

import {
  getSetting,
  getUnpennedSaleLotIdsBySaleRoundId,
  selectFilteredSaleLotIds,
  selectSaleLotIdsByAuctionPenIdLookup,
} from "selectors";

import { useGetVisibleAndTempAuctionPenIds } from "hooks/useGetVisibleAndTempAuctionPenIds";

export const useGetAuctionPenCheckBoxValues = ({ extraFilteredPenIds }) => {
  const dispatch = useDispatch();

  const selectedSaleRound = useSelector(getSetting(Settings.round));

  const unpennedSaleLotIds =
    useSelector(getUnpennedSaleLotIdsBySaleRoundId(selectedSaleRound)) ||
    EMPTY_ARRAY;

  const auctionPenSelectedSaleLotIds = useSelector(
    getSetting(Settings.auctionPenSelectedSaleLotIds),
  );

  const setAuctionPenSelectedSaleLotIds = useCallback(
    saleLotIds =>
      dispatch(setSetting(Settings.auctionPenSelectedSaleLotIds, saleLotIds)),
    [dispatch],
  );

  const globalFilteredSaleLotIds = useSelector(selectFilteredSaleLotIds);

  const saleLotByAuctionPenIdLookup = useSelector(
    selectSaleLotIdsByAuctionPenIdLookup,
  );

  const [visibleAuctionPenIds, tempPensIds] =
    useGetVisibleAndTempAuctionPenIds(extraFilteredPenIds);

  const visibleAuctionPenSaleLotIds = useMemo(
    () =>
      visibleAuctionPenIds.map(penId =>
        Object.values(saleLotByAuctionPenIdLookup[penId]),
      ),
    [visibleAuctionPenIds, saleLotByAuctionPenIdLookup],
  );

  const tempPenSaleLotIds = tempPensIds.map(penId =>
    Object.values(saleLotByAuctionPenIdLookup[penId]),
  );

  const auctionPenSaleLotIds = useMemo(
    () =>
      flatten(
        visibleAuctionPenSaleLotIds
          .concat(tempPenSaleLotIds)
          .concat(unpennedSaleLotIds),
      ).filter(saleLotId => globalFilteredSaleLotIds.includes(saleLotId)),
    [
      visibleAuctionPenSaleLotIds,
      tempPenSaleLotIds,
      unpennedSaleLotIds,
      globalFilteredSaleLotIds,
    ],
  );

  const allSaleLotsAreSelected = useMemo(
    () =>
      auctionPenSaleLotIds.every(saleLotId =>
        auctionPenSelectedSaleLotIds.includes(saleLotId),
      ),
    [auctionPenSaleLotIds, auctionPenSelectedSaleLotIds],
  );

  const someSaleLotsAreSelected = useMemo(
    () =>
      auctionPenSelectedSaleLotIds.some(saleLotId =>
        auctionPenSaleLotIds.includes(saleLotId),
      ) &&
      !auctionPenSaleLotIds.every(saleLotId =>
        auctionPenSelectedSaleLotIds.includes(saleLotId),
      ),
    [auctionPenSelectedSaleLotIds, auctionPenSaleLotIds],
  );
  const selectedLotsNotVisible = auctionPenSelectedSaleLotIds.filter(
    saleLotId =>
      !auctionPenSaleLotIds.includes(saleLotId) &&
      // unpenned lots are always visible
      !unpennedSaleLotIds.includes(saleLotId),
  );

  const onChange = useCallback(() => {
    if (allSaleLotsAreSelected) {
      setAuctionPenSelectedSaleLotIds(
        auctionPenSelectedSaleLotIds.filter(
          saleLotId => !auctionPenSaleLotIds.includes(saleLotId),
        ),
      );
    } else {
      setAuctionPenSelectedSaleLotIds(
        uniq(auctionPenSelectedSaleLotIds.concat(auctionPenSaleLotIds)),
      );
    }
  }, [
    allSaleLotsAreSelected,
    setAuctionPenSelectedSaleLotIds,
    auctionPenSelectedSaleLotIds,
    auctionPenSaleLotIds,
  ]);

  const info = auctionPenSelectedSaleLotIds.length
    ? `${auctionPenSelectedSaleLotIds.length} ${pluralize("Lot", auctionPenSelectedSaleLotIds.length)} Selected`
    : null;

  const warning = selectedLotsNotVisible.length
    ? `Including ${selectedLotsNotVisible.length} Not Visible`
    : null;

  return {
    checked: allSaleLotsAreSelected && !!auctionPenSaleLotIds.length,
    indeterminate: someSaleLotsAreSelected,
    disabled: !auctionPenSaleLotIds.length,
    onChange,
    warning,
    info,
  };
};
