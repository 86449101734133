import React, { useMemo } from "react";

import { ListItemText } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { updateFileProductMapping } from "actions";

import {
  AutoCompleteV2,
  contextOptionCellEditorParamsGetter,
  contextOptionFormatter,
} from "components/AgGrid/AutoComplete";
import { BaseTable } from "components/Importer/BaseTable";

import { valueExistsRenderer } from "lib/agGrid/renderers";

import {
  getAges,
  getBreeds,
  getCurrentSpeciesId,
  getProductsBySpecies,
  getSexes,
  selectAllAttributes,
} from "selectors";

import { getRowId } from "./genericImport";

const ColumnId = {
  FileBreedId: "67814929-0cac-4e79-a320-f6dbbe4ec11e",
  FileDescription: "4b575fcc-e677-45f0-946b-b629f3f703f5",
  FileProductId: "9a2a3fb3-8f0f-4d43-afe4-311306971dd0",
  FileProductIsMapped: "eb4964d4-18d1-4534-a8db-38f82a920939",
};

const ProductColumns = [
  {
    headerName: "Product in File",
    children: [
      {
        colId: ColumnId.FileProductIsMapped,
        headerName: "Is Mapped",
        field: "fileData.productId",
        cellRenderer: valueExistsRenderer,
        sortable: true,
      },
      {
        colId: ColumnId.FileDescription,
        headerName: "Description",
        field: "fileData.description",
        sortable: true,
      },
    ],
  },
  {
    headerName: "AgriNous Products",
    children: [
      {
        cellEditor: AutoCompleteV2,
        cellEditorParams: params => {
          return {
            ...contextOptionCellEditorParamsGetter("products")(params),
            renderOption: value => {
              const options = params.context.products || [];
              const currentOption = options.find(
                option => option.value === value,
              );
              return (
                <ListItemText
                  primary={currentOption.primary}
                  secondary={currentOption.secondary}
                />
              );
            },
          };
        },
        colId: ColumnId.FileProductId,
        editable: true,
        field: "fileData.productId",
        headerName: "Product",
        valueFormatter: contextOptionFormatter("products"),
      },
    ],
  },
  {
    headerName: "AgriNous Breeds",
    children: [
      {
        cellEditor: AutoCompleteV2,
        cellEditorParams: contextOptionCellEditorParamsGetter("breeds"),
        colId: ColumnId.FileBreedId,
        editable: true,
        field: "fileData.breedId",
        headerName: "Breed",
        valueFormatter: contextOptionFormatter("breeds"),
      },
    ],
  },
];

const initialProductTableColumnState = {
  state: [
    { colId: ColumnId.FileProductIsMapped },
    { colId: ColumnId.FileDescription, sort: "asc" },
  ],
  applyOrder: true,
};

function onProductGridReady(params) {
  const { columnApi } = params;
  columnApi.applyColumnState(initialProductTableColumnState);
}

export function MapProductTable({ importName, type }) {
  const descriptions = useSelector(
    state => state.importers?.[importName].products,
  );
  const products = useSelector(getProductsBySpecies);
  const ages = useSelector(getAges);
  const breeds = useSelector(getBreeds);
  const sexes = useSelector(getSexes);
  const attributeData = useSelector(selectAllAttributes);
  const speciesId = useSelector(getCurrentSpeciesId);
  const dispatch = useDispatch();

  const agrinousProducts = Object.values(attributeData.products).filter(
    // Ignore rounds for this specific import
    dp => dp.species_id === speciesId,
  );

  const agrinousBreeds = Object.values(attributeData.breeds)
    .map(deploymentBreed => breeds[deploymentBreed.breed_id])
    .filter(breed => breed.species_id === speciesId);

  const productOptions = agrinousProducts.map(product => {
    const ageName = product.age_id && ages[product.age_id].name;
    const breedName = product.breed_id && breeds[product.breed_id].name;
    const sexName = product.sex_id && sexes[product.sex_id].name;
    const description = [ageName, breedName, sexName]
      .filter(Boolean)
      .join(", ");
    return {
      label: `${product.name}${description ? `: ${description}` : ""}`,
      primary: product.name,
      secondary: description,
      value: product.id || "",
    };
  });

  const breedOptions = agrinousBreeds.map(b => ({
    label: b.name || "",
    value: b.id || "",
  }));

  const context = {
    products: productOptions,
    breeds: breedOptions,
  };

  const data = useMemo(
    () =>
      descriptions.map(fileData => ({
        fileData,
        agrinousProduct: products[fileData.productId],
        agrinousBreed: breeds[fileData.breedId],
      })),
    [descriptions, products, breeds],
  );

  return (
    <BaseTable
      columns={ProductColumns}
      context={context}
      data={data}
      getRowId={getRowId}
      onCellEditRequest={params => {
        const { api, column, node, value } = params;
        const products = [];
        api.forEachNode(rowNode => {
          if (node.id !== rowNode.id) {
            products.push(rowNode.data.fileData);
          } else if (column.getId() === ColumnId.FileProductId) {
            products.push({ ...rowNode.data.fileData, productId: value });
          } else if (column.getId() === ColumnId.FileBreedId) {
            products.push({ ...rowNode.data.fileData, breedId: value });
          }
        });
        dispatch(updateFileProductMapping(products, type));
      }}
      readOnlyEdit
      onGridReady={onProductGridReady}
    />
  );
}
