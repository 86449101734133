import { BulkUpdateFieldType } from "components/BulkUpdateOptionalFieldsModal/types";
import { SaleRoundPickerField } from "components/Form/Fields";
import { UpdateInformation } from "components/BulkUpdateOptionalFieldsModal/SaleLots/UpdateInformation";
import { BreedField } from "components/Form/Fields/BreedField";
import { AgeField } from "components/Form/Fields/AgeField";
import { SexField } from "components/Form/Fields/SexField";
import { AnimalHealthField } from "components/Form/Fields/AnimalHealthField";
import { EIDExemptionReasonField } from "components/Form/Fields/EIDExemptionField";
import {
  CheckBox,
  DatePicker,
  Input,
  MutuallyExclusiveInput,
  OptionTogglerField,
  SelectField,
  TextArea,
  withNamespace,
} from "components/Form/FormikControls";
import {
  Accreditation,
  FrameStructure,
  PregStatusOptions,
  ShearingStatusOptions,
  VendorBredOptions,
} from "constants/draftingAttributes";
import StateAwareBusinessPICSelector from "components/BusinessPICSelector";
import { BUYER } from "constants/businesses";
import {
  ConditionSection,
  sheepAttributes,
} from "components/DetailedSaleLotModal/AdvancedDrafting/Sheep";
import { cattleAttributes } from "components/DetailedSaleLotModal/AdvancedDrafting/Cattle";
import { EstAvgWeightRangeField } from "components/WeightRange";
import { DressingRangeField } from "components/DressingRange";
import { DentitionField } from "components/Dentition/Index";
import { CollapseTitle } from "../util";

// Note - the order of many of fields is operationally/aesthetically relevant as it's the order they're displayed
// in - don't alphabetize for neatness.

// Fields relevant to all livestock sale.
export const baseLivestockFields: BulkUpdateFieldType[] = [
  {
    fieldName: "sale_round_id",
    Component: SaleRoundPickerField,
    componentProps: {},
    collapseTitle: CollapseTitle.GENERAL,
    UpdateInformationComponent: UpdateInformation,
    rootFieldOrder: 1,
  },
  {
    fieldName: "breed_id",
    Component: BreedField,
    componentProps: {
      name: "breed_id",
      label: "Breed",
    },
    collapseTitle: CollapseTitle.GENERAL,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
    rootFieldOrder: 3,
  },
  {
    fieldName: "age_id",
    Component: AgeField,
    componentProps: {
      name: "age_id",
      label: "Age",
    },
    collapseTitle: CollapseTitle.GENERAL,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
    rootFieldOrder: 2,
  },
  {
    fieldName: "sex_id",
    Component: SexField,
    componentProps: {
      name: "sex_id",
      label: "Sex",
    },
    collapseTitle: CollapseTitle.GENERAL,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
    rootFieldOrder: 4,
  },
  {
    fieldName: "category_id",
    Component: AnimalHealthField,
    componentProps: {},
    collapseTitle: CollapseTitle.GENERAL,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
  },
];

export const EIDExemptionFields: BulkUpdateFieldType[] = [
  {
    fieldName: "exemption_id",
    Component: EIDExemptionReasonField,
    componentProps: {},
    collapseTitle: CollapseTitle.GENERAL,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
  },
];

export const commonAdvancedDraftingFields: BulkUpdateFieldType[] = [
  // Sheep
  // Cattle
  {
    fieldName: withNamespace("draftingAttributes", "frameStructure"),
    Component: OptionTogglerField,
    componentProps: {
      label: "Frame Structure",
      options: Object.values(FrameStructure),
      labelPosition: "top",
      allowToggleOff: true,
    },
    collapseTitle: CollapseTitle.ADVANCED_DRAFTING,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
  },
  // Sheep
  // Cattle
  {
    fieldName: withNamespace("draftingAttributes", "publicDescription"),
    Component: TextArea,
    componentProps: {
      label: "Comments (Placard Text)",
      tooltip:
        "Any other details you wish to provide. This will display on placards",
    },
    collapseTitle: CollapseTitle.ADVANCED_DRAFTING,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: "",
  },
  // Sheep
  // Cattle
  {
    fieldName: withNamespace("draftingAttributes", "publicFaultDescription"),
    Component: TextArea,
    componentProps: {
      label: "Fault Description",
      tooltip: "Anything you would declare in an assessment.",
    },
    collapseTitle: CollapseTitle.ADVANCED_DRAFTING,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: "",
  },
  // Sheep
  // Cattle
  {
    fieldName: withNamespace("draftingAttributes", "partOfRun"),
    Component: TextArea,
    componentProps: {
      label: "Pen may be sold as part of a run",
      tooltip:
        "If this pen is part of a run, please state the pen numbers in the run.",
    },
    collapseTitle: CollapseTitle.ADVANCED_DRAFTING,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: "",
  },
  // Sheep
  // Cattle
  {
    fieldName: withNamespace("draftingAttributes", "vendorBredOverride"),
    Component: OptionTogglerField,
    componentProps: {
      label: "Vendor Bred",
      options: VendorBredOptions,
      labelPosition: "top",
    },
    collapseTitle: CollapseTitle.ADVANCED_DRAFTING,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
  },
];

export const buyerFields: BulkUpdateFieldType[] = [
  {
    fieldName: "thirdPartyId",
    Component: StateAwareBusinessPICSelector,
    componentProps: {
      label: "Third Party",
      businessRoles: [BUYER],
      optional: true,
      clearable: true,
      businessFieldName: "thirdPartyId",
    },
    collapseTitle: CollapseTitle.BUYER,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
  },
  {
    fieldName: "invoiceToBusinessId",
    Component: StateAwareBusinessPICSelector,
    componentProps: {
      label: "Invoice To",
      businessRoles: [BUYER],
      optional: true,
      clearable: true,
      businessFieldName: "invoiceToBusinessId",
    },
    collapseTitle: CollapseTitle.BUYER,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
  },
  {
    fieldName: "isGSTExempt",
    Component: CheckBox,
    componentProps: {
      label: "GST Exempt?",
      labelPlacement: "end",
    },
    collapseTitle: CollapseTitle.BUYER,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: false,
  },
];

export const sheepAdvancedDraftingFields: BulkUpdateFieldType[] = [
  // Sheep
  {
    fieldName: withNamespace("draftingAttributes", "xBloodline"),
    Component: Input,
    componentProps: {
      label: "X-Bloodline",
    },
    collapseTitle: CollapseTitle.ADVANCED_DRAFTING,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: "",
  },
  // Sheep
  {
    fieldName: withNamespace("draftingAttributes", "bySire"),
    Component: Input,
    componentProps: {
      label: "By - Sire",
    },
    collapseTitle: CollapseTitle.ADVANCED_DRAFTING,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: "",
  },
  // Sheep
  {
    fieldName: withNamespace("draftingAttributes", "dropStart"),
    Component: DatePicker,
    componentProps: {
      label: "Drop Start",
      views: ["year", "month"],
    },
    collapseTitle: CollapseTitle.ADVANCED_DRAFTING,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
  },
  // Sheep
  {
    fieldName: withNamespace("draftingAttributes", "dropEnd"),
    Component: DatePicker,
    componentProps: {
      label: "Drop End",
      views: ["year", "month"],
    },
    collapseTitle: CollapseTitle.ADVANCED_DRAFTING,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
  },
  // Sheep
  {
    fieldName: withNamespace("draftingAttributes", "pregStatus"),
    Component: OptionTogglerField,
    componentProps: {
      label: "Preg Status",
      options: PregStatusOptions,
      allowToggleOff: true,
      labelPosition: "top",
    },
    collapseTitle: CollapseTitle.ADVANCED_DRAFTING,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
  },
  // Sheep
  {
    fieldName: withNamespace("draftingAttributes", "shearing"),
    Component: DatePicker,
    componentProps: {
      label: "Shearing Date",
      views: ["year", "month"],
    },
    collapseTitle: CollapseTitle.ADVANCED_DRAFTING,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
  },
  // Sheep
  {
    fieldName: withNamespace("draftingAttributes", "shearingStatus"),
    Component: SelectField,
    componentProps: {
      label: "Shearing Status",
      options: ShearingStatusOptions,
      isClearable: true,
    },
    collapseTitle: CollapseTitle.ADVANCED_DRAFTING,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
  },
];

export const weightRangeAndDressingFields: BulkUpdateFieldType[] = [
  {
    fieldName: "estimatedAverageWeightId",
    Component: EstAvgWeightRangeField,
    componentProps: {
      showNeedsRoundMessage: true,
    },
    collapseTitle: CollapseTitle.GENERAL,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
    rootFieldOrder: 5,
  },
  {
    fieldName: "dressingRangeId",
    Component: DressingRangeField,
    componentProps: {
      showNeedsRoundMessage: true,
    },
    collapseTitle: CollapseTitle.GENERAL,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
    rootFieldOrder: 6,
  },
];

export const dentitionFields: BulkUpdateFieldType[] = [
  {
    fieldName: "dentitionId",
    Component: DentitionField,
    componentProps: {
      showNeedsAgeMessage: true,
    },
    collapseTitle: CollapseTitle.GENERAL,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: null,
    rootFieldOrder: 5,
  },
];

export const cattleAdvancedDraftingFields: BulkUpdateFieldType[] = [
  {
    fieldName: withNamespace("draftingAttributes", "bloodline"),
    Component: Input,
    componentProps: {
      label: "Bloodline",
    },
    collapseTitle: CollapseTitle.ADVANCED_DRAFTING,
    UpdateInformationComponent: UpdateInformation,
    emptyValue: "",
  },
];

export const sheepDraftingAttributes: BulkUpdateFieldType[] = sheepAttributes
  .map(
    attribute =>
      ({
        fieldName: withNamespace("draftingAttributes", attribute.fieldName),
        Component: CheckBox,
        componentProps: {
          label: attribute.label,
          tooltip: attribute.tooltip,
          trueValue: attribute.trueValue,
          falseValue: attribute.falseValue,
          labelPlacement: "end",
        },
        collapseTitle: CollapseTitle.DRAFTING_ATTRIBUTES,
        UpdateInformationComponent: UpdateInformation,
        emptyValue: attribute.falseValue,
      }) as BulkUpdateFieldType,
  )
  .concat([
    {
      fieldName: withNamespace("draftingAttributes", "condition"),
      updateFieldNames: [
        withNamespace("draftingAttributes", "conditionStore"),
        withNamespace("draftingAttributes", "conditionForward"),
        withNamespace("draftingAttributes", "conditionPrime"),
      ],
      Component: ConditionSection,
      componentProps: {},
      collapseTitle: CollapseTitle.ADVANCED_DRAFTING,
      UpdateInformationComponent: UpdateInformation,
      emptyValue: false,
    },
  ]);

export const cattleDraftingAttributes = cattleAttributes
  .map(
    attribute =>
      ({
        fieldName: withNamespace("draftingAttributes", attribute.fieldName),
        Component: CheckBox,
        componentProps: {
          label: attribute.label,
          tooltip: attribute.tooltip,
          trueValue: attribute.trueValue,
          falseValue: attribute.falseValue,
          mutuallyExclusiveWithName: attribute.mutuallyExclusiveWithName
            ? `draftingAttributes.${attribute.mutuallyExclusiveWithName}`
            : null,

          mutuallyExclusiveWithFalseValue:
            attribute.mutuallyExclusiveWithFalseValue,
          labelPlacement: "end",
        },
        collapseTitle: CollapseTitle.DRAFTING_ATTRIBUTES,
        UpdateInformationComponent: UpdateInformation,
        emptyValue: attribute.falseValue,
      }) as BulkUpdateFieldType,
  )
  .concat([
    {
      fieldName: withNamespace("draftingAttributes", "accreditationJBAS"),
      Component: Input,
      componentProps: {
        label: "JBAS",
        type: "number",
        maxLength: 1,
        tooltip:
          "This value is prefilled from the JBAS status on the Vendor's PIC",
      },
      collapseTitle: CollapseTitle.DRAFTING_ATTRIBUTES,
      UpdateInformationComponent: UpdateInformation,
      emptyValue: null,
    },
    {
      fieldName: withNamespace("draftingAttributes", "accreditationGrassFed"),
      Component: Input,
      componentProps: {
        label: "Grass Fed",
        trueValue: Accreditation.ALL,
        falseValue: Accreditation.NONE,
        mutuallyExclusiveWithName: "draftingAttributes.accreditationGrassFed",
        mutuallyExclusiveWithFalseValue: 0,
      },
      collapseTitle: CollapseTitle.DRAFTING_ATTRIBUTES,
      UpdateInformationComponent: UpdateInformation,
      emptyValue: 0,
    },
    {
      fieldName: withNamespace("draftingAttributes", "grainFedDays"),
      Component: MutuallyExclusiveInput,
      componentProps: {
        label: "Number of Days on Feed",
        maxLength: 4,
        type: "number",
        mutuallyExclusiveWithName: "draftingAttributes.accreditationGrassFed",
        mutuallyExclusiveWithFalseValue: Accreditation.NONE,
      },
      collapseTitle: CollapseTitle.DRAFTING_ATTRIBUTES,
      UpdateInformationComponent: UpdateInformation,
      emptyValue: null,
    },
  ]);
