import React from "react";

import { PropTypes } from "prop-types";
import styled from "styled-components/macro";

const LabelContainer = styled.label`
  /* Customize the label (the container) */
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  & .checkmark {
    position: absolute;
    top: 5px;
    left: 5px;
    height: 25px;
    width: 25px;
    background-color: white;
    border: 1px solid #c3c3c3;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #eee;
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ .checkmark {
    background-color: white;
    border: 1px solid #62afff;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  & .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  & input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  & .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid #62afff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const Checkbox = ({
  id,
  question,
  handleChange,
  handleBlur,
  checked,
  useAlternative = false,
  ...props
}) => (
  <>
    {useAlternative ? (
      <LabelContainer className="container" htmlFor={id || question.id}>
        <input
          id={id || question.id}
          name={id || question.id}
          type="checkbox"
          onChange={handleChange}
          onBlur={handleBlur}
          checked={checked}
          {...props}
        />
        <span className="checkmark" />
      </LabelContainer>
    ) : (
      <input
        id={id || question.id}
        name={id || question.id}
        type="checkbox"
        onChange={handleChange}
        onBlur={handleBlur}
        checked={checked}
        style={{
          marginRight: "6px",
        }}
        {...props}
      />
    )}
  </>
);

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  question: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  useAlternative: PropTypes.bool,
};
