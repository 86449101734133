import React, { useCallback, useRef, useState } from "react";

import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import { Field, getIn, setIn, useFormikContext } from "formik";
import { v4 } from "uuid";

import {
  CheckBox,
  SelectField,
  withNamespace,
} from "components/Form/FormikControls";
import { RuleFieldToolTip } from "components/Form/FormikControls/Tooltip";
import { Row } from "components/Layout";

import { groupedLineItemToolTipText } from "constants/billing";
import {
  RuleValueFormatOptionsByRawValueFormat,
  ValueSource,
} from "constants/ruleBooks";

import {
  FieldType,
  FieldValueTypeRuleValueFormatMap,
} from "containers/Settings/RuleBooks/constants";
import {
  CustomQueryField,
  DataSource,
} from "containers/Settings/RuleBooks/DataSource";
import {
  updateCriterionRelations,
  updateRuleRelations,
} from "containers/Settings/RuleBooks/lib";
import { useRuleFieldSchema } from "containers/Settings/RuleBooks/schemaContext";

import { AddCriterionModal } from "./AddCriterionModal";
import { RuleOutputFormatField } from "./RuleOutputFormatField";
import { ValueClause } from "./ValueClause";
import { WhenClause } from "./WhenClause";

export function RuleForm(props) {
  const {
    categories = [],
    billingTags,
    namespace: ns = "",
    isMasterRule = false,
  } = props;
  // used to store persistent unique DOM element ids for datalist options below
  const categoryDataListIdRef = useRef(v4());

  const [isAddFilterModalOpen, setIsAddFilterModalOpen] = useState(false);
  const { values: value, setValues: setValue } = useFormikContext();

  const schema = useRuleFieldSchema();

  const fieldId = getIn(value, "input_field.fieldId");

  const criteria = getIn(value, "criteria");

  const openAddFilterModal = useCallback(
    () => setIsAddFilterModalOpen(true),
    [setIsAddFilterModalOpen],
  );

  const closeAddFilterModal = useCallback(
    () => setIsAddFilterModalOpen(false),
    [setIsAddFilterModalOpen],
  );

  function onChangeInputField(event) {
    setValue(
      updateRuleRelations(
        value,
        {
          attribute: "",
          customQuery: "",
          fieldId: event.target.value,
          source: ValueSource.FIELD,
        },
        schema,
      ),
    );
  }

  function addCriterion(criterion) {
    const updatedCriterion = criterion.query
      ? updateCriterionRelations(
          criterion,
          { fieldId: criterion.query.fieldId },
          fieldId,
          fieldId,
          schema,
        )
      : criterion;

    setValue(setIn(value, "criteria", [...criteria, updatedCriterion]));
  }

  const unitAmountRawFormat = getIn(value, "value.amount.valueType");
  const unitAmountOutputFormatOptions =
    RuleValueFormatOptionsByRawValueFormat[
      FieldValueTypeRuleValueFormatMap[unitAmountRawFormat]
    ] || [];

  const quantityRawFormat = getIn(value, "value.units.valueType");
  const quantityOutputFormatOptions =
    RuleValueFormatOptionsByRawValueFormat[
      FieldValueTypeRuleValueFormatMap[quantityRawFormat]
    ] || [];

  return (
    <div>
      <Row full alignCenter paddingVertical={1}>
        <span>Name:</span>&nbsp;
        <Field
          data-tour="name"
          className="p-2 flex-1"
          name={withNamespace(ns, "name")}
        />
      </Row>
      <Row full alignCenter paddingVertical={1}>
        <span>Category:</span>&nbsp;
        <Field
          data-tour="category"
          className="p-2 flex-1"
          list={categoryDataListIdRef.current}
          name={withNamespace(ns, "category")}
        />
        <datalist id={categoryDataListIdRef.current}>
          {categories.map((category, i) => (
            <option key={`${category}_${i}`}>{category}</option>
          ))}
        </datalist>
      </Row>
      <Row full alignCenter paddingVertical={1}>
        <span>Comment:</span>&nbsp;
        <Field
          data-tour="comment"
          className="p-2 flex-1"
          name={withNamespace(ns, "comment")}
          as="textarea"
        />
      </Row>
      <Row full alignCenter paddingVertical={1}>
        <span>Title Template:</span>&nbsp;
        <Field
          data-tour="titleTemplate"
          className="p-2 flex-1"
          name={withNamespace(ns, "title_template")}
          as="textarea"
        />
      </Row>
      <Row full alignEnd paddingVertical={1}>
        <span>Document Grouped Line Description: </span>&nbsp;
        <RuleFieldToolTip title={groupedLineItemToolTipText}>
          <Field
            data-tour="invoiceLineItemTemplate"
            className="p-2 flex-1"
            name={withNamespace(ns, "invoice_line_item_template")}
            as="textarea"
          />
        </RuleFieldToolTip>
      </Row>
      <Row data-tour="billingTag" alignEnd paddingVertical={1}>
        <span>Billing Tag:</span>
        <SelectField
          name={withNamespace(ns, "billing_tags")}
          options={billingTags}
          tooltip="A tag that will be applied to all Ledger Entries output by this rule. These can be used for filtering and reporting at a later stage."
          isMulti
        />
      </Row>
      <AddCriterionModal
        addCriterion={addCriterion}
        isOpen={isAddFilterModalOpen}
        closeModal={closeAddFilterModal}
        parentFieldId={fieldId}
      />
      For each:{" "}
      <CustomQueryField
        dataTour="forEach"
        namespace={withNamespace(ns, "input_field")}
        onChangeDropdown={onChangeInputField}
        options={schema}
      />
      <IconButton
        aria-label="Add Criteria"
        onClick={openAddFilterModal}
        disabled={!fieldId}
        title={
          fieldId
            ? "Apply a filter to the data"
            : "You must select a field before you can add a filter"
        }
      >
        <FontAwesomeIcon icon={faPlus} size="xs" />
        &nbsp;Criteria
      </IconButton>
      <WhenClause
        namespace={withNamespace(ns, "criteria")}
        parentFieldId={fieldId}
      />
      <ValueClause
        criteria={criteria}
        namespace={ns}
        parentFieldId={fieldId}
        isMasterRule={isMasterRule}
      />
      <fieldset className="p-2">
        <legend className="p-2">To/From</legend>
        <Row full alignCenter paddingVertical={1} gridGap={2}>
          <span>Billed from: </span>
          <DataSource
            dataTour="billedFrom"
            fieldType={FieldType.BUSINESS}
            namespace={withNamespace(ns, "from_business")}
            parentFieldId={fieldId}
            includeGlobalBusinesses
          />
        </Row>
        <Row full alignCenter paddingVertical={1} gridGap={2}>
          <span>Billed to: </span>
          <DataSource
            dataTour="billedTo"
            fieldType={FieldType.BUSINESS}
            namespace={withNamespace(ns, "to_business")}
            parentFieldId={fieldId}
            includeGlobalBusinesses
          />
        </Row>
      </fieldset>
      <fieldset className="p-2">
        <legend className="p-2">Presentation/Formatting</legend>

        <span>Units Format: </span>
        <RuleOutputFormatField
          name="quantity_output_format"
          options={quantityOutputFormatOptions}
        />
        <span>Unit Price Format: </span>
        <RuleOutputFormatField
          name="unit_amount_output_format"
          options={unitAmountOutputFormatOptions}
        />
      </fieldset>
      <p>
        <CheckBox
          name={withNamespace(ns, "include_if_zero")}
          label="Include Zero Value Entries"
          tooltip="Include ledger entries that have 0 value."
        />
      </p>
    </div>
  );
}
