import React from "react";

import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { faCrosshairs } from "@fortawesome/pro-light-svg-icons";
import {
  faList,
  faPlus,
  faPrint,
  faSort,
} from "@fortawesome/pro-regular-svg-icons";
import { faCaretRight, faPencil } from "@fortawesome/pro-solid-svg-icons";
import flatten from "lodash/flatten";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { openDispatchingModal, screenResize, setSetting } from "actions";

import { MultiButton } from "components/Button";

import { PenTypes } from "constants/auctionPens";
import { ModalTypes } from "constants/navigation";
import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { Settings } from "constants/settings";
import { Species } from "constants/species";

import {
  getAuctionPenCardsRoute,
  getLivestockSaleId,
  getSaleRoute,
  getSaleyardAuctionRoute,
  getSaleyardName,
  openInlineCreateSaleLotModal,
  openScanToPenHashRoute,
} from "lib/navigation";
import { BookingCardVersions } from "lib/pdf";
import { pluralize } from "lib/pluralize";
import { disableReadOnlyActions } from "lib/readOnly";

import {
  getHasWriteAccessInCurrentSale,
  getSetting,
  getCurrentSale,
  getAgencies,
  getIsSaleyardAdmin,
  getSaleRoundsOptions,
  selectAreStudFeaturesEnabled,
} from "selectors";

import {
  useHasSaleLotPermission,
  useHasSaleyardOrDeploymentPermission,
  useTheme,
} from "hooks";

function PenActions({ hasVisiblePens }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const sale = useSelector(getCurrentSale);
  const saleRounds = useSelector(getSaleRoundsOptions());
  const deploymentSales = sale.deployment_sales;
  const agencies = useSelector(state => getAgencies(state));
  const isSheepSale = sale.species_id === Species.SHEEP;
  const areStudFeaturesEnabled = useSelector(selectAreStudFeaturesEnabled);
  const hasChangeSaleLotPermission = useHasSaleLotPermission();
  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );

  const hasAutoIncrementPermission = useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.featureAutoIncrement,
    SaleyardPermissions.featureAutoIncrement,
  );
  const isSaleyardAdmin = useSelector(state => getIsSaleyardAdmin(state));
  const selectedRoundId = useSelector(getSetting(Settings.round));
  const history = useHistory();
  const setPenView = penView => {
    dispatch(setSetting(Settings.penView, penView));
  };
  const readOnly = !hasWriteAccessInCurrentSale || !hasChangeSaleLotPermission;

  const auctionPenCardsRoute = getAuctionPenCardsRoute();

  const baseCardUrl = `${getSaleyardAuctionRoute(
    getSaleyardName(),
    getLivestockSaleId(),
    selectedRoundId,
  )}`;

  const sheepPlacardUrl = getSaleRoute(getSaleyardName(), getLivestockSaleId());

  const selectedSaleLotIds = useSelector(
    getSetting(Settings.auctionPenSelectedSaleLotIds),
  );

  const openLanePath = () => {
    const lanePath = `${auctionPenCardsRoute}/lane-setup`;
    history.push(lanePath);
  };

  const openReorderPens = () => {
    const reoorderPath = `${auctionPenCardsRoute}/reorder`;
    history.push(reoorderPath);
  };

  const openSheepSpottingCard = agentId => {
    agentId
      ? history.push(`${baseCardUrl}/sheepspottingcard/${agentId}`)
      : history.push(`${baseCardUrl}/sheepspottingcard`);
  };

  const openCattleSpottingCard = agentId => {
    agentId
      ? history.push(`${baseCardUrl}/cattlespottingcard/${agentId}`)
      : history.push(`${baseCardUrl}/cattlespottingcard`);
  };

  const openCheckingCard = agentId => {
    agentId
      ? history.push(`${baseCardUrl}/checkingcard/${agentId}`)
      : history.push(`${baseCardUrl}/checkingcard`);
  };

  const openBookingCardPortrait = agentId => {
    agentId
      ? history.push(`${baseCardUrl}/bookingcardportrait/${agentId}`)
      : history.push(`${baseCardUrl}/bookingcardportrait/`);
  };

  const openSheepPenStoreCard = round => {
    history.push(`${sheepPlacardUrl}/sheeppenstorecard/consolidated/${round}`);
  };

  const openSheepPlacard = round => {
    history.push(`${sheepPlacardUrl}/sheepplacard/consolidated/${round}`);
  };

  const openBookingCardLandscape = (agentId, version) => {
    agentId
      ? history.push(
          `${baseCardUrl}/bookingcardlandscape/${version}/${agentId}`,
        )
      : history.push(`${baseCardUrl}/bookingcardlandscape/${version}`);
  };

  const openSpacedBookingCardPortrait = (version, agentId) => {
    agentId
      ? history.push(
          `${baseCardUrl}/bookingcardspacedportrait/${version}/${agentId}`,
        )
      : history.push(`${baseCardUrl}/bookingcardspacedportrait/${version}`);
  };

  const openSpacedBookingCardLandScape = (version, agentId) => {
    agentId
      ? history.push(
          `${baseCardUrl}/bookingcardspacedlandscape/${version}/${agentId}`,
        )
      : history.push(`${baseCardUrl}/bookingcardspacedlandscape/${version}`);
  };

  const openDeliveryCard = agentId => {
    agentId
      ? history.push(`${baseCardUrl}/deliverycard/${agentId}`)
      : history.push(`${baseCardUrl}/deliverycard`);
  };

  const openPenWeighCardPortrait = agentId => {
    agentId
      ? history.push(`${baseCardUrl}/penweighcardportrait/${agentId}`)
      : history.push(`${baseCardUrl}/penweighcardportrait`);
  };
  const openPenWeighCardLandscape = agentId => {
    agentId
      ? history.push(`${baseCardUrl}/penweighcardlandscape/${agentId}`)
      : history.push(`${baseCardUrl}/penweighcardlandscape`);
  };
  const openCreateSaleLotModal = () => {
    openInlineCreateSaleLotModal(
      { sale_round_id: selectedRoundId },
      {
        showDeliveryPen: false,
        showAuctionPenAtTop: true,
        includeCreateUnknownConsignment: true,
        autoPopulateNextPen: !!hasAutoIncrementPermission,
        includeStoredValues: true,
      },
    );
  };

  const buttons = [
    {
      dataTour: "addPen",
      title: "Lot",
      isDisabled: false,
      onClick: openCreateSaleLotModal,
      default: true,
      icon: faPlus,
      readOnly,
    },

    {
      title: "Selling Pen View",
      isDisabled: false,
      onClick: () => {
        setPenView(PenTypes.SELLING);
      },
      default: false,
      icon: faCaretRight,
      iconColor: "black",
      dataTour: "auctionPenView",
    },

    {
      title: "Delivery Pen View",
      isDisabled: false,
      onClick: () => {
        setPenView(PenTypes.DELIVERY);
      },
      default: false,
      icon: faCaretRight,
      iconColor: "transparent",
      dataTour: "deliveryPenView",
      dividerAfter: true,
    },

    {
      title: "Scan To Pen",
      isDisabled: !hasVisiblePens,
      onClick: () => {
        openScanToPenHashRoute();
      },
      default: false,
      icon: faCrosshairs,
      readOnly,
    },
    {
      title: "Lane Setup",
      isDisabled: !hasVisiblePens,
      onClick: openLanePath,
      default: false,
      icon: faList,
      readOnly,
    },
    {
      title: "Re-order Pens",
      dataTour: "reorderPens",
      isDisabled: !hasVisiblePens,
      onClick: openReorderPens,
      default: false,
      icon: faSort,
      readOnly,
    },
    {
      dataTour: "bulkUpdate",
      title: `Bulk Update (${selectedSaleLotIds.length}) ${pluralize("Lot", selectedSaleLotIds.length)}`,
      isDisabled: readOnly || selectedSaleLotIds.length === 0,
      onClick: () =>
        dispatch(
          openDispatchingModal(
            ModalTypes.BulkUpdateSaleLot,
            window.location.hash,
            {
              storedSaleLotIdsSetting: Settings.auctionPenSelectedSaleLotIds,
            },
          ),
        ),
      default: !readOnly,
      icon: faPencil,
    },
    {
      title: "Desktop View",
      icon: faDesktop,
      onClick: () => {
        dispatch(screenResize(theme.breakpoints[2]));
        history.push(
          `${getSaleRoute(getSaleyardName(), getLivestockSaleId())}/salelots`,
        );
      },
      dataTour: "desktopView",
    },

    ...flatten(
      sale.rounds.map(round => {
        const roundName = saleRounds.find(e => e.value === round).label;
        if (isSheepSale) {
          return {
            title: `${roundName} Cards`,
            isDisabled: !hasVisiblePens,
            subNavItems: [
              {
                title: `${roundName} Pen Store Card`,
                onClick: () => {
                  openSheepPenStoreCard(round);
                },
                default: false,
                icon: faPrint,
                isDisabled: !hasVisiblePens,
              },
              {
                title: `${roundName} Placard`,
                onClick: () => {
                  openSheepPlacard(round);
                },
                default: false,
                icon: faPrint,
                isDisabled: !hasVisiblePens,
              },
            ],
          };
        } else {
          return [];
        }
      }),
    ),

    isSaleyardAdmin && {
      title: `Reports For All Agents`,
      subNavItems: [
        {
          title: `Spotting Card`,
          onClick: () => {
            isSheepSale ? openSheepSpottingCard() : openCattleSpottingCard();
          },
          default: false,
          icon: faPrint,
          isDisabled: !hasVisiblePens,
        },
        {
          title: `Checking Card`,
          onClick: () => {
            openCheckingCard();
          },
          default: false,
          icon: faPrint,
          isDisabled: !hasVisiblePens,
        },
        {
          title: `Pen Weigh Card Portrait`,
          onClick: () => {
            openPenWeighCardPortrait();
          },
          default: false,
          icon: faPrint,
          isDisabled: !hasVisiblePens,
        },
        {
          title: `Pen Weigh Card Landscape`,
          onClick: () => {
            openPenWeighCardLandscape();
          },
          default: false,
          icon: faPrint,
          isDisabled: !hasVisiblePens,
        },
        {
          title: `Booking Card Portrait`,
          onClick: () => {
            openBookingCardPortrait();
          },
          default: false,
          icon: faPrint,
          isDisabled: !hasVisiblePens,
        },
        {
          title: `Booking Card Landscape`,
          onClick: () => {
            openBookingCardLandscape(
              null,
              areStudFeaturesEnabled ? BookingCardVersions.Stud : null,
            );
          },
          default: false,
          icon: faPrint,
          isDisabled: !hasVisiblePens,
        },
        {
          title: `Booking Card - Extra spacing Portrait Pre`,
          onClick: () => {
            openSpacedBookingCardPortrait(BookingCardVersions.PreSale);
          },
          default: false,
          icon: faPrint,
          isDisabled: !hasVisiblePens,
        },
        {
          title: `Booking Card - Extra spacing Portrait Post`,
          onClick: () => {
            openSpacedBookingCardPortrait(BookingCardVersions.PostSale);
          },
          default: false,
          icon: faPrint,
          isDisabled: !hasVisiblePens,
        },
        {
          title: `Booking Card - Extra spacing Landscape Pre`,
          onClick: () => {
            openSpacedBookingCardLandScape(BookingCardVersions.PreSale);
          },
          default: false,
          icon: faPrint,
          isDisabled: !hasVisiblePens,
        },
        {
          title: `Booking Card - Extra spacing Landscape Post`,
          onClick: () => {
            openSpacedBookingCardLandScape(BookingCardVersions.PostSale);
          },
          default: false,
          icon: faPrint,
          isDisabled: !hasVisiblePens,
        },
        {
          title: `Delivery Card`,
          onClick: () => {
            openDeliveryCard();
          },
          default: false,
          icon: faPrint,
          isDisabled: !hasVisiblePens,
        },
      ].filter(Boolean),
    },

    ...flatten(
      deploymentSales.map(deploymentSale => {
        const shortName =
          agencies[deploymentSale.livestock_agency_id]?.shortName ||
          agencies[deploymentSale.livestock_agency_id]?.name ||
          "";
        return {
          title: `Reports For ${shortName}`,
          subNavItems: [
            {
              title: `Spotting Card (${shortName})`,
              onClick: () => {
                isSheepSale
                  ? openSheepSpottingCard(deploymentSale.livestock_agency_id)
                  : openCattleSpottingCard(deploymentSale.livestock_agency_id);
              },
              default: false,
              icon: faPrint,
              isDisabled: !hasVisiblePens,
            },
            {
              title: `Checking Card (${shortName})`,
              onClick: () => {
                openCheckingCard(deploymentSale.livestock_agency_id);
              },
              default: false,
              icon: faPrint,
              isDisabled: !hasVisiblePens,
            },
            {
              title: `Pen Weigh Card (${shortName}) Portrait`,
              onClick: () => {
                openPenWeighCardPortrait(deploymentSale.livestock_agency_id);
              },
              default: false,
              icon: faPrint,
              isDisabled: !hasVisiblePens,
            },
            {
              title: `Pen Weigh Card (${shortName}) Landscape`,
              onClick: () => {
                openPenWeighCardLandscape(deploymentSale.livestock_agency_id);
              },
              default: false,
              icon: faPrint,
              isDisabled: !hasVisiblePens,
            },
            {
              title: `Booking Card (${shortName}) Portrait`,
              onClick: () => {
                openBookingCardPortrait(deploymentSale.livestock_agency_id);
              },
              default: false,
              icon: faPrint,
              isDisabled: !hasVisiblePens,
            },
            {
              title: `Booking Card (${shortName}) Landscape`,
              onClick: () => {
                openBookingCardLandscape(
                  deploymentSale.livestock_agency_id,
                  areStudFeaturesEnabled ? BookingCardVersions.Stud : null,
                );
              },
              default: false,
              icon: faPrint,
              isDisabled: !hasVisiblePens,
            },
            {
              title: `Booking Card - Extra spacing (${shortName}) Portrait Pre`,
              onClick: () => {
                openSpacedBookingCardPortrait(
                  BookingCardVersions.PreSale,
                  deploymentSale.livestock_agency_id,
                );
              },
              default: false,
              icon: faPrint,
              isDisabled: !hasVisiblePens,
            },
            {
              title: `Booking Card - Extra spacing (${shortName}) Portrait Post`,
              onClick: () => {
                openSpacedBookingCardPortrait(
                  BookingCardVersions.PostSale,
                  deploymentSale.livestock_agency_id,
                );
              },
              default: false,
              icon: faPrint,
              isDisabled: !hasVisiblePens,
            },
            {
              title: `Booking Card - Extra spacing (${shortName}) Landscape Pre`,
              onClick: () => {
                openSpacedBookingCardLandScape(
                  BookingCardVersions.PreSale,
                  deploymentSale.livestock_agency_id,
                );
              },
              default: false,
              icon: faPrint,
              isDisabled: !hasVisiblePens,
            },
            {
              title: `Booking Card - Extra spacing (${shortName}) Landscape Post`,
              onClick: () => {
                openSpacedBookingCardLandScape(
                  BookingCardVersions.PostSale,
                  deploymentSale.livestock_agency_id,
                );
              },
              default: false,
              icon: faPrint,
              isDisabled: !hasVisiblePens,
            },
            {
              title: `Booking Card - (${shortName}) Sign Off`,
              onClick: () => {
                openBookingCardLandscape(
                  deploymentSale.livestock_agency_id,
                  BookingCardVersions.SignOff,
                );
              },
              default: false,
              icon: faPrint,
              isDisabled: !hasVisiblePens,
            },
            {
              title: `Booking Card - (${shortName}) Sign Off (compact)`,
              onClick: () => {
                openBookingCardLandscape(
                  deploymentSale.livestock_agency_id,
                  BookingCardVersions.SignOffCompact,
                );
              },
              default: false,
              icon: faPrint,
              isDisabled: !hasVisiblePens,
            },
            {
              title: `Delivery Card (${shortName})`,
              onClick: () => {
                openDeliveryCard(deploymentSale.livestock_agency_id);
              },
              default: false,
              icon: faPrint,
              isDisabled: !hasVisiblePens,
            },
          ],
        };
      }),
    ),
  ]
    .filter(Boolean)
    .map(disableReadOnlyActions);
  return <MultiButton buttons={buttons} />;
}
export default React.memo(PenActions);
