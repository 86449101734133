import React from "react";

import { useSelector } from "react-redux";

import { ERPChip } from "components/Chip";

import { getSaleLotsBySale } from "selectors";

export const AuctionPenERPChip = React.memo(({ auctionPenId }) => {
  const saleLots = useSelector(getSaleLotsBySale);
  return (
    <ERPChip
      saleLots={saleLots.filter(sl => sl.auction_pen_id === auctionPenId)}
    />
  );
});
