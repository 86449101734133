import { createSelector } from "reselect";

import { getLabels, getSundryTemplates } from "selectors";

export const selectSundryTemplatesAggridData = createSelector(
  [getSundryTemplates, getLabels],
  (sundryTemplatesByIdLookup, labelsLookup) =>
    Object.values(sundryTemplatesByIdLookup).map(sundryTemplate => ({
      sundryTemplate,
      labels: sundryTemplate.labels?.map(l => labelsLookup[l]?.name) || [],
    })),
);
