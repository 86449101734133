import {
  BulkUpdateFieldType,
  BulkUpdateMLASupplementaryDataModalProps,
  ModalIntroductionProps,
} from "components/BulkUpdateOptionalFieldsModal/types";
import React from "react";
import { BulkUpdateOptionalFieldsModal } from "components/BulkUpdateOptionalFieldsModal/Modal";
import { bulkUpdateMLASupplementaryData } from "actions";
import * as Yup from "yup";
import { getCheckboxFieldName } from "components/BulkUpdateOptionalFieldsModal/util";
import { pluralize } from "lib/pluralize";
import { Tooltip } from "components/Form/FormikControls/Tooltip";
import { Row } from "components/Layout";
import {
  getCurrentSaleSubType,
  getCurrentSpeciesId,
  getSaleLots,
} from "selectors";
import { uniq } from "lodash";
import { useSelector } from "react-redux";
import { Species } from "constants/species";
import { isPrimeSaleSubtype, isStoreSaleSubtype } from "lib/sale";
import {
  allCattle,
  allSheep,
  primeCattle,
  primeSheep,
  storeCattle,
  storeSheep,
} from "./Fields";

export const validationSchema = Yup.object().shape({
  [getCheckboxFieldName("fatScore")]: Yup.boolean(),
  fatScore: Yup.number().when(getCheckboxFieldName("fatScore"), {
    is: true,
    then: Yup.number()
      .min(0, "Must be greater than 0.")
      .max(5, "Must be less or equal to 5."),
  }),
  [getCheckboxFieldName("muscleScore")]: Yup.boolean(),
  muscleScore: Yup.number().when(getCheckboxFieldName("muscleScore"), {
    is: true,
    then: Yup.number()
      .min(0, "Must be greater than 0.")
      .max(6, "Must be less or equal to 6."),
  }),
});

function ModalIntroduction(props: ModalIntroductionProps): React.JSX.Element {
  const { modelCount } = props;

  return (
    <Row justifyBetween fullWidth paddingHorizontal={3}>
      <div>
        <strong>
          You are bulk editing {modelCount} {pluralize("record", modelCount)}.
          Any changes made to checked fields will be applied to these records.
        </strong>
      </div>
      <Tooltip
        title={
          <>
            All data below relates to supplemental data provided for reporting
            purposes. This information is additive and it will not change
            information on an actual Sale Lot.
            <br />
            For example, if a Sale Lot has an Breed of &quot;Angus&quot; already
            entered, you may override this value for reporting, or clear the
            override in order to revert reporting to show that Breed. Regardless
            of changes made here, the Breed will remain in place on the Sale Lot
          </>
        }
      />
    </Row>
  );
}

function useGetBulkUpdateFields(): BulkUpdateFieldType[] {
  // Fetch the best set of fields for this sale type and species
  // - returning all fields for a species if we can't deduce is a "safe" default, in case
  // that is impperfect, or unset.
  const currentSaleSubType = useSelector(getCurrentSaleSubType);
  const speciesId = useSelector(getCurrentSpeciesId);
  if (speciesId === Species.CATTLE) {
    if (isPrimeSaleSubtype(currentSaleSubType)) {
      return primeCattle;
    }
    if (isStoreSaleSubtype(currentSaleSubType)) {
      return storeCattle;
    }
    return allCattle;
  }
  if (speciesId === Species.SHEEP) {
    if (isPrimeSaleSubtype(currentSaleSubType)) {
      return primeSheep;
    }
    if (isStoreSaleSubtype(currentSaleSubType)) {
      return storeSheep;
    }
    return allSheep;
  }

  // Nothing... won't render well, but what're MLA doing at a Llama sale?
  return [];
}

export function BulkUpdateMLASupplementaryDataModal(
  props: BulkUpdateMLASupplementaryDataModalProps,
): React.JSX.Element {
  const { saleLotIds, onClose } = props;

  const saleLots = useSelector(getSaleLots);

  const uniqueSaleRounds = uniq(
    saleLotIds
      .map(saleLotId => saleLots[saleLotId]?.sale_round_id)
      .filter(Boolean),
  );

  // Choose which fields to show based on which sub-type of sale it is.
  const bulkUpdateFields = useGetBulkUpdateFields();
  // Inject a common sale round id, in order to allow reference in the estimated average weight selector
  const initialValues =
    uniqueSaleRounds.length === 1 ? { sale_round_id: uniqueSaleRounds[0] } : {};

  return (
    <BulkUpdateOptionalFieldsModal
      onClose={onClose}
      modelIds={saleLotIds}
      modelName="Supplementary Data Sets for Sale Lot"
      bulkUpdateActionCreator={bulkUpdateMLASupplementaryData}
      fields={bulkUpdateFields}
      initialValues={initialValues}
      validationSchema={validationSchema}
      ModalIntroduction={ModalIntroduction}
    />
  );
}
