import React from "react";

import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { EMPTY_OBJECT } from "lib";

const SearchInput = styled.input`
  text-indent: 12px;
  height: 34px; /* 36-2 to allow for border on the wrapper. */
  width: 100%;

  line-height: 36px;
  border: none;
  background: inherit;
  &:focus {
    outline: none;
  }
`;

const Wrapper = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  height: 36px;
  flex: 1;
  display: flex;
  align-self: center;
  ${({ theme }) => `
    border: 1px solid ${theme.colors.gray78};
    background: ${theme.colors.white};
    ::placeholder {
      color: ${theme.colors.gray78};
    }
  `};
  &:focus-within {
    outline: -webkit-focus-ring-color auto 5px;
  }
`;

const FaIcon = styled(FontAwesomeIcon)`
  display: flex;
  align-self: center;
  justify-self: center;
  width: 1rem;
  margin: 8px;
  ${({ theme, color }) => {
    if (color) {
      return `color: ${theme.colors[color]}`;
    }
  }};
`;

const FaTouchIcon = styled(FaIcon)`
  cursor: pointer;
`;

const SearchInputTable = ({
  handleChange,
  value = "",
  style = EMPTY_OBJECT,
}) => {
  const clearInput = () => {
    handleChange("");
  };

  const onChange = event => {
    handleChange(event.target.value);
  };

  return (
    <Wrapper style={style}>
      <SearchInput
        data-tour="search"
        placeholder="Search"
        value={value}
        onChange={onChange}
      />
      {value && <FaTouchIcon onClick={clearInput} icon={faTimes} />}
      <FaIcon icon={faSearch} />
    </Wrapper>
  );
};

SearchInputTable.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.string,
  style: PropTypes.object,
};

export default SearchInputTable;
