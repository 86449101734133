import { ACTION_COLUMN_NAME } from "constants/aggrid";

import { getters } from "lib/agGrid";
import { BusinessRelationshipActionRender } from "lib/agGrid/renderers";

export const BusinessRelationColId = {
  ACTIONS: "2310cdd6-c9ce-420c-9a5a-e78468ec42fa",
  RATE_TYPE: "c1856a41-9739-475c-945e-5a99be72618d",
  RATE_VALUE: "453f64f0-e97f-442a-8010-a6b22a2cdd46",
  RELATION_TYPE: "7dd04ab4-e713-4ab4-8a18-24f64f7cb968",
  SALE_SUB_TYPE: "48fb518f-7a8f-461b-bee6-f5ec07e7ea53",
  SALE_TYPE: "25125db8-2c77-4c16-a401-5ae2724a62ff",
  SPECIES: "c78f8aa1-861d-4a64-b7b8-fff411195e66",
};

export const BusinessRelationColumnDef = {
  [BusinessRelationColId.RELATION_TYPE]: {
    colId: BusinessRelationColId.RELATION_TYPE,
    headerName: "Type",
    field: "relationship.relationType",
    enableRowGroup: true,
  },
  [BusinessRelationColId.RATE_TYPE]: {
    colId: BusinessRelationColId.RATE_TYPE,
    headerName: "Calc Type",
    field: "relationship.rateType",
  },
  [BusinessRelationColId.RATE_VALUE]: {
    colId: BusinessRelationColId.RATE_VALUE,
    headerName: "Rate",
    field: "relationship.id",
    valueGetter: getters.businessRelationshipRateGetter,
  },

  [BusinessRelationColId.SALE_TYPE]: {
    colId: BusinessRelationColId.SALE_TYPE,
    headerName: "Sale Type",
    field: "relationship.saleType",
    valueGetter: getters.valueOrDefaultGetter("All"),
  },
  [BusinessRelationColId.SPECIES]: {
    colId: BusinessRelationColId.SPECIES,
    headerName: "Species",
    field: "species.name",
    valueGetter: getters.valueOrDefaultGetter("All"),
  },
  [BusinessRelationColId.SALE_SUB_TYPE]: {
    colId: BusinessRelationColId.SALE_SUB_TYPE,
    headerName: "Sale Sub Type",
    field: "saleSubType.name",
    valueGetter: getters.valueOrDefaultGetter("All"),
  },

  [BusinessRelationColId.ACTIONS]: {
    colId: BusinessRelationColId.ACTIONS,
    field: "relationship.id",
    headerName: ACTION_COLUMN_NAME,
    cellRenderer: BusinessRelationshipActionRender,
    pinned: "right",
    width: 150,
  },
};
