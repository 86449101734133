import * as Yup from "yup";

import { ENTER_VALID_NVD_NUMBER_ERROR_MESSAGE } from "constants/errorMessage";

export const NVD_REGEX = /^[0-9a-zA-Z]{1,15}$/;

export const NVDValidator = Yup.string()
  .nullable()
  .test("pass", ENTER_VALID_NVD_NUMBER_ERROR_MESSAGE, v => NVD_REGEX.test(v));

export const saleyardAuctionNVDValidator = Yup.string()
  .nullable()
  .test(
    "valid",
    "NVD must be 8 or 9 numbers",
    nvdNumber => !nvdNumber || nvdNumber.length === 9 || nvdNumber.length === 8,
  )
  .test("pass", ENTER_VALID_NVD_NUMBER_ERROR_MESSAGE, v => NVD_REGEX.test(v));

const validateABN = value => {
  // https://abr.business.gov.au/Help/AbnFormat
  // Subtract 1 from the first (left-most) digit of the ABN to give a new 11 digit number
  // Multiply each of the digits in this new number by a "weighting factor" based on its position as shown in the table below
  // Sum the resulting 11 products
  // Divide the sum total by 89, noting the remainder
  // If the remainder is zero the number is a valid ABN
  const weightingFactors = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
  const abn = value.replace(/[^\d]/, "");
  let result = false;

  if (abn.length === 11) {
    const sum = weightingFactors.reduce((acc, weight, index) => {
      const digit = index === 0 ? abn[index] - 1 : abn[index];
      acc += weight * digit;
      return acc;
    }, 0);

    result = sum % 89 === 0;
  }

  return result;
};

export const ABNValidator = Yup.string()
  .nullable()
  .label("Australian Business Number")
  .test("abn", "Invalid ABN", value => {
    if (value) {
      return validateABN(value);
    } else {
      return true;
    }
  });

const validateACN = value => {
  // https://asic.gov.au/for-business/registering-a-company/steps-to-register-a-company/australian-company-numbers/australian-company-number-digit-check/#:~:text=The%20Australian%20Company%20Number%20(ACN,block%20separated%20by%20a%20blank.
  // Step 1 - Apply weighting to digits 1 to 8.
  // Step 2 - Sum the products
  // Step 3 - Divide by 10 to obtain remainder
  // Step 4 - Complement the remainder to 10
  // Step 5 - Compare the result with the check
  // digit (last digit)
  const acn = value.replace(/[^\d]/, "");
  if (acn !== 9) {
    return false;
  }

  const weightingFactors = [8, 7, 6, 5, 4, 3, 2, 1];

  const weighted = weightingFactors.reduce((acc, weight, index) => {
    acc += weight * acn[index];
    return acc;
  }, 0);

  let checkDigit = 10 - (weighted % 10);
  checkDigit = checkDigit === 10 ? 0 : checkDigit;
  return checkDigit === acn[8];
};

export const ACNValidator = Yup.string()
  .label("Australian Company Number")
  .required("Required")
  .test("acn", "Invalid ACN", value => value && validateACN(value));

/* Ported from django.core.validators.EmailValidator */
const EMAIL_USER_REGEXP =
  /^(^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*$|^"([\001-\010\013\014\016-\037!#-[]-\177]|\[\001-\011\013\014\016-\177])*"$)/i;

// There is a slight variation in this regex when compared to the original Django code which is that
// the last capture group has a negative look behind to assert the TLD doesn't end in a hyphen.
// Safari does not support negative look behinds. As at 31/05/2021
// https://caniuse.com/js-regexp-lookbehind :( Safari
const EMAIL_DOMAIN_REGEXP =
  /^((?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+)[A-Z0-9-]{0,62}[A-Z0-9]$/i;

const DOMAIN_WHITELIST = ["localhost"];

function validateEmailDomain(emailDomain) {
  if (EMAIL_DOMAIN_REGEXP.test(emailDomain)) {
    return true;
  }
  // In the django.core.validators.EmailValidator __call__ method, this goes on to validate IP address domains.
  // These are not supported in this port
  return false;
}

export function validateEmail(email) {
  if (typeof email !== "string" || email.length < 1 || !email.includes("@")) {
    return false;
  }

  const emailParts = String(email).split("@");
  if (emailParts.length !== 2) {
    return false;
  }
  const [userPart, domainPart] = emailParts;
  if (!userPart || !domainPart) {
    return false;
  }
  if (!EMAIL_USER_REGEXP.test(userPart)) {
    return false;
  }
  if (!DOMAIN_WHITELIST.includes(domainPart)) {
    return validateEmailDomain(domainPart);
  }
  return true;
}

export const validatePercentage = (
  maxDigits,
  decimalPlaces,
  allowNegative = false,
) => {
  /*
    Should line up with a django decimal field.  eg.

             field=models.DecimalField(
                blank=True,
                decimal_places=6,
                default=None,
                help_text="The percentage of total price paid by the vendor",
                max_digits=9,
                null=True,
            ),
   */

  const wholeNumberDigits = maxDigits - decimalPlaces;
  const allowedSigns = allowNegative ? "+-" : "+";
  const validScenarios = `^[${allowedSigns}]?((\\d{0,${wholeNumberDigits}})|(\\d{1,${wholeNumberDigits}}\\.)|(\\.\\d{1,${decimalPlaces}})|(\\d{1,${wholeNumberDigits}}\\.\\d{1,${decimalPlaces}}))$`;
  const regex = new RegExp(validScenarios);
  const errorString = `Accepted format ${"x".repeat(
    maxDigits - decimalPlaces + 2,
  )}.${"x".repeat(decimalPlaces - 2)}`;

  return Yup.number()
    .typeError("Must be a number")
    .test("is-decimal", errorString, value => {
      return regex.test(value);
    });
};
