import React from "react";

import { Grid } from "@material-ui/core";
import { Form, useFormikContext } from "formik";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import StateAwareBusinessPICSelector from "components/BusinessPICSelector";
import { Button, DeleteButton, SecondaryButton } from "components/Form";
import { SelectField, DateInput, Input } from "components/Form/FormikControls";
import { Row } from "components/Layout";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "components/MaterialDialog";

import { EMPTY_ARRAY } from "lib";

import { getSaleDateOptions } from "lib/timeFormats";

import { useGetSaleyardName } from "hooks/useGetSaleyardName";

const ButtonStyled = styled(Button)`
  margin-left: ${({ theme }) => theme.space[2]}px;
`;

const Small = styled(Row)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray40};
  margin-left: 10px;
`;

/*
  This is for create + edit
  To switch modes, a handleDelete function must be passed into the component
*/
const BobbyCalfSaleForm = ({
  saleyards = EMPTY_ARRAY,
  handleDelete,
  handleClose,
  title,
}) => {
  const saleDateOptions = getSaleDateOptions();
  const formikProps = useFormikContext();
  const {
    values: { saleyard_id },
  } = formikProps;

  const saleyardName = useGetSaleyardName(saleyard_id);
  return (
    <Dialog id="sale-modal" open onClose={handleClose} maxWidth="xs">
      <Form data-tour="bobby-calf-sale-form">
        <DialogTitle onClose={handleClose}>{title}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input disabled label="Sale type" name="sale_type" />
            </Grid>

            <Grid item xs={6}>
              <SelectField
                label="Saleyard location"
                name="saleyard_id"
                options={saleyards}
                required
              />
            </Grid>
            <Grid item xs={12}>
              {handleDelete ? (
                <DateInput label="Sale Date" name="date" />
              ) : (
                <SelectField
                  maxMenuHeight={80}
                  label="Sale Date"
                  name="date"
                  options={saleDateOptions}
                  required
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <StateAwareBusinessPICSelector
                label="Default Buyer"
                businessFieldName="default_buyer_id"
                propertyFieldName="default_property_id"
                formikProps={formikProps}
                error={
                  formikProps.touched.default_property_id &&
                  formikProps.errors.default_property_id
                }
                saleyardName={saleyardName}
                disabled={!saleyardName}
              />
            </Grid>
            <Small>Other buyers can be added to the sale later</Small>
          </Grid>
        </DialogContent>
        <DialogActions>
          {handleDelete ? (
            <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
          ) : (
            <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
          )}
          <ButtonStyled
            data-tour="submit"
            type="submit"
            onClick={formikProps.handleSubmit}
            disabled={!(formikProps.isValid || formikProps.submitCount === 0)}
          >
            {handleDelete ? "Update" : "Create"}
          </ButtonStyled>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

BobbyCalfSaleForm.propTypes = {
  saleyards: PropTypes.array,
  handleDelete: PropTypes.func,
  handleClose: PropTypes.func,
};

export default BobbyCalfSaleForm;
