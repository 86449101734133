import React from "react";

import PropTypes from "prop-types";
import { withTheme } from "styled-components";

const Logo = ({ color, dashed = false, theme }) => {
  const logoColor = color || theme.colors.primary;

  const commonProps = {
    fill: dashed ? undefined : logoColor,
    stroke: dashed ? logoColor : undefined,
    strokeDasharray: dashed ? "5 2" : undefined,
  };

  return (
    <svg width="25" height="126" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="none">
        <path
          d="M4.171 108.666l2.394 2.362c2.475-2.442 6.502-2.442 8.977 0a6.214 6.214 0 0 1 0 8.858c-2.475 2.442-6.502 2.442-8.977 0l-2.394 2.363c3.795 3.744 9.97 3.744 13.764 0a9.504 9.504 0 0 0 2.7-5.141h3.422v-3.34H20.63a9.505 9.505 0 0 0-2.694-5.102c-3.795-3.745-9.969-3.745-13.764 0"
          {...commonProps}
        />
        <path
          d="M13.771 115.457c0-1.515-1.268-2.743-2.832-2.743-.923 0-1.741.43-2.258 1.092H.057v3.302h8.624a2.857 2.857 0 0 0 2.258 1.092c1.564 0 2.832-1.228 2.832-2.743"
          {...commonProps}
        />
      </g>
    </svg>
  );
};

Logo.propTypes = {
  color: PropTypes.string,
  dashed: PropTypes.bool,
  theme: PropTypes.object.isRequired,
};

export default withTheme(Logo);
