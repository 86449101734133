import React from "react";

import { Grid } from "@material-ui/core";
import { getIn, useFormikContext } from "formik";
import get from "lodash/get";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import DeferredRender from "components/DeferredRender";
import { DentitionField } from "components/Dentition/Index";
import { DressingRangeField } from "components/DressingRange";
import {
  HasDeploymentMarks,
  OtherMarkingField,
  ProductSelectField,
  SaleLotLabelSelectorField,
  SaleRoundPickerField,
} from "components/Form/Fields";
import { AnimalHealthField } from "components/Form/Fields/AnimalHealthField";
import { EIDExemptionReasonField } from "components/Form/Fields/EIDExemptionField";
import MarkingField from "components/Form/Fields/MarkingField";
import {
  DatePicker,
  Input,
  OptionTogglerField,
  QuickOptionsWithSelect,
  SelectField,
  TextArea,
  withNamespace,
  withNamespaces,
} from "components/Form/FormikControls";
import { SellSafeOptionToggler } from "components/SellSafe/SellSafe";
import { EstAvgWeightRangeField } from "components/WeightRange";

import {
  ItemCondition,
  LotStatusAsOptions,
  NewUsed,
} from "constants/clearingSaleAttributes";
import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { SaleTypes } from "constants/sale";
import { SaleLotNamespaces } from "constants/saleLots";
import { Species } from "constants/species";

import {
  ForClearingSale,
  ForLivestockAuction,
  ForNotClearingSale,
  ForNotHooks,
  ForSaleType,
} from "containers/ForSaleType";
import { ForCattle, ForSheep } from "containers/ForSpecies";

import {
  getCurrentSale,
  getDentitionOptionsByAgeId,
  getDeploymentAgeOptionsByDeploymentId,
  getDeploymentBreedOptionsByDeploymentId,
  getSpeciesAttributes,
  selectAreStudFeaturesEnabled,
  selectAreWeightAndDressingFeaturesEnabled,
  selectAvaliableDentitions,
  selectIsSaleSubTypeDentitionEnabled,
} from "selectors";

import {
  useHasSaleyardOrDeploymentPermission,
  useTranslatedSaleTypeText,
} from "hooks";
import {
  useHasDeploymentPermission,
  useHasMLAPermission,
} from "hooks/useHasPermission";

import AuctionsPlusClearingSaleDescriptionForm from "./AuctionsPlusClearingSaleDescriptionForm";
import AuctionsPlusClearingSaleModelForm from "./AuctionsPlusClearingSaleModelForm";

export function isGeneralFormDirty(initialValues, values, namespace = "") {
  return ![
    withNamespace(namespace, "quantity"),
    withNamespace(namespace, "sale_round_id"),
    withNamespace(namespace, "breed_id"),
    withNamespace(namespace, "sex_id"),
    withNamespace(namespace, "grade_id"),
    withNamespace(namespace, "age_id"),
    withNamespace(namespace, "category_id"),
    withNamespace(namespace, "exemption_id"),
    withNamespace(namespace, "estimatedAverageMassGrams"),
    withNamespace(namespace, "note"),
    withNamespace(namespace, "marks"),
    withNamespace(namespace, "quantityProgeny"),
    withNamespace(namespace, "listingId"),
    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "category",
    ),
    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "comments",
    ),
    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "hours",
    ),
    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "inspection",
    ),
    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "itemCondition",
    ),

    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "make",
    ),
    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "model",
    ),
    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "newUsed",
    ),
    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "odometer",
    ),

    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "ppsrComments",
    ),
    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "ppsrStatus",
    ),
    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "publicDescription",
    ),

    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "registration",
    ),
    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "subType",
    ),
    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "title",
    ),
    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "vin",
    ),
    withNamespaces(
      [namespace, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
      "year",
    ),
  ].every(key => {
    const a = get(values, key);
    const b = get(initialValues, key);
    return (
      a === b || (!a && !b) // used for all optional values, which may show up as "", or null, or undefined;
    );
  });
}

function ClearingSaleFormComponent({ namespace: ns = "", readOnly }) {
  const namespace = withNamespace(
    ns,
    SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
  );

  return (
    <>
      <Grid item xs={12}>
        <TextArea
          label="Public Description"
          name={withNamespace(namespace, "publicDescription")}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <TextArea
          label="Comments"
          name={withNamespace(namespace, "comments")}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <TextArea
          label="Inspection"
          name={withNamespace(namespace, "inspection")}
          disabled={readOnly}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <OptionTogglerField
          label="New/Used"
          name={withNamespace(namespace, "newUsed")}
          labelPosition="top"
          options={Object.values(NewUsed)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePicker
          name={withNamespace(namespace, "year")}
          views={["year"]}
          openTo="year"
          label="Year"
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <OptionTogglerField
          label="Condition"
          name={withNamespace(namespace, "itemCondition")}
          labelPosition="top"
          options={Object.values(ItemCondition)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <SellSafeOptionToggler
          name={withNamespace(namespace, "sellSafeCategory")}
          readOnly={readOnly}
        />
      </Grid>

      {/* These are BIG dropdowns - they render pretty fast, considering, but still clunk the form into place.  */}
      {/* They're (generally) below the fold, so render them a tic after the main render. */}

      <DeferredRender deferRenderBy={200}>
        <AuctionsPlusClearingSaleDescriptionForm
          namespace={withNamespace(
            ns,
            SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
          )}
          disabled={readOnly}
        />
        <AuctionsPlusClearingSaleModelForm
          namespace={withNamespace(
            ns,
            SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
          )}
          disabled={readOnly}
        />
      </DeferredRender>

      <Grid item xs={12} sm={6}>
        <Input
          type="number"
          label="Hours"
          name={withNamespace(namespace, "hours")}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          type="number"
          label="Odometer"
          afterSymbol="km"
          name={withNamespace(namespace, "odometer")}
          disabled={readOnly}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Input
          label="VIN"
          name={withNamespace(namespace, "vin")}
          disabled={readOnly}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Input
          label="Registration"
          name={withNamespace(namespace, "registration")}
          disabled={readOnly}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          label="PPSR Status"
          name={withNamespace(namespace, "ppsrStatus")}
          disabled={readOnly}
        />
      </Grid>

      <Grid item xs={12}>
        <TextArea
          label="PPSR Comments"
          name={withNamespace(namespace, "ppsrComments")}
          disabled={readOnly}
        />
      </Grid>
    </>
  );
}

export function GeneralSaleLotFormComponent({
  namespace: ns,
  deploymentId,
  readOnly,
  hasChangeSaleLotPermission,
  isNew,
}) {
  const formik = useFormikContext();
  const { setFieldValue, values } = formik;
  const areStudFeaturesEnabled = useSelector(selectAreStudFeaturesEnabled);
  const sale = useSelector(getCurrentSale);
  const dentitions = useSelector(selectAvaliableDentitions);
  const dentitionOptions = useSelector(
    getDentitionOptionsByAgeId(values.age_id || null),
  );

  const isSheepSale = sale.species_id === Species.SHEEP;

  const saleTypeText = useTranslatedSaleTypeText("Head Count");

  const sexId = getIn(values, withNamespace(ns, "sex_id"));
  const quantityProgeny = getIn(values, withNamespace(ns, "quantityProgeny"));

  const breedOptions = useSelector(
    getDeploymentBreedOptionsByDeploymentId(deploymentId),
  );
  const ageOptions = useSelector(
    getDeploymentAgeOptionsByDeploymentId(deploymentId),
  );

  const { sexes } = useSelector(getSpeciesAttributes);

  // If saleyard permission we want to use that, otherwise fall back to deployment permission
  const hasWeightRangePerm = useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.featureWeightRange,
    SaleyardPermissions.featureWeightRange,
  );

  const isDentitionEnabled = useSelector(selectIsSaleSubTypeDentitionEnabled);
  const isWeightRangeAndDressingEnabled = useSelector(
    selectAreWeightAndDressingFeaturesEnabled,
  );

  const hasMlaPermission = useHasMLAPermission();

  const showWeightAndDressing =
    (isSheepSale && isWeightRangeAndDressingEnabled && hasWeightRangePerm) ||
    hasMlaPermission;

  const selectedSexHasProgeny = Boolean(sexes[sexId]?.hasProgeny);

  React.useEffect(() => {
    if (!selectedSexHasProgeny && quantityProgeny && quantityProgeny > 0) {
      setFieldValue(withNamespace(ns, "quantityProgeny"), 0);
    }
  }, [ns, quantityProgeny, setFieldValue, selectedSexHasProgeny]);

  const currentDentition = dentitions.find(
    dentition => dentition.id === values?.dentitionId,
  );

  const currentDentitionMatchesAge =
    currentDentition?.age_id === values.age_id ||
    currentDentition?.age_id === null;

  React.useEffect(() => {
    if (!currentDentitionMatchesAge) {
      setFieldValue(withNamespace(ns, "dentitionId"), null);
    }
  }, [ns, currentDentitionMatchesAge, setFieldValue]);

  React.useEffect(() => {
    if (dentitionOptions.length === 1) {
      setFieldValue(
        withNamespace(ns, "dentitionId"),
        dentitionOptions[0].value,
      );
    }
  }, [ns, dentitionOptions, setFieldValue]);
  const hasDentitionPermission = useHasDeploymentPermission(
    DeploymentPermissions.featureDentition,
  );

  const dentitionEnabled =
    dentitionOptions.length > 0 && hasDentitionPermission && isDentitionEnabled;

  // Most of these fields can check for readonly,
  // Nutriens special fields on the other hand needs to be editable on a locked sale
  // disabled when the user is missing the edit permission,
  // but editable on creating a new lot.
  const areNASFieldsDisabled = !isNew && !hasChangeSaleLotPermission;

  return (
    <>
      <ForClearingSale>
        <Grid item xs={12}>
          <SelectField
            label="Status"
            name={withNamespaces(
              [ns, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
              "status",
            )}
            options={LotStatusAsOptions}
            required
            tooltip='A lot can only be modified by a consigning agent when in a "Pending" status.'
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Lot Title"
            name={withNamespaces(
              [ns, SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES],
              "title",
            )}
            disabled={readOnly}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Input
            label="Listing Id"
            name={withNamespace(ns, "listingId")}
            required
            maxLength="6"
            disabled={readOnly}
          />
        </Grid>
      </ForClearingSale>
      <Grid item xs={12} sm={6}>
        <Input
          type="number"
          label={saleTypeText}
          name={withNamespace(ns, "quantity")}
          placeholder='Enter "0" to count later'
          required
          disableAutoComplete
          disabled={readOnly}
        />
      </Grid>
      <ForClearingSale>
        {deploymentId && (
          <Grid item xs={12}>
            <SaleLotLabelSelectorField
              label="Labels"
              name={withNamespace(ns, "labels")}
              deploymentId={deploymentId}
              disabled={readOnly}
            />
          </Grid>
        )}
      </ForClearingSale>
      <ForNotClearingSale>
        <Grid item xs={12} sm={6}>
          <ForLivestockAuction>
            <SaleRoundPickerField
              name={withNamespace(ns, "sale_round_id")}
              required
              disabled={readOnly}
            />
          </ForLivestockAuction>
          <ForSaleType
            saleType={[
              SaleTypes.OVER_HOOKS,
              SaleTypes.PADDOCK,
              SaleTypes.CLEARING,
            ]}
          >
            {/* This relies on there being a single round for the given sale */}
            {/* type, so selecting that default round in the initial values. */}
            <input type="hidden" name="sale_round_id" />
          </ForSaleType>
        </Grid>
        <ProductSelectField
          ns={ns}
          deploymentId={deploymentId}
          disabled={readOnly}
        />
        {selectedSexHasProgeny && (
          <Grid item xs={12}>
            <Input
              type="number"
              label="Progeny Count"
              name={withNamespace(ns, "quantityProgeny")}
              disabled={readOnly}
            />
          </Grid>
        )}

        <ForCattle>
          <QuickOptionsWithSelect
            ns={ns}
            name="age_id"
            label="Age"
            options={ageOptions}
            disabled={readOnly}
            missingMLAFieldsWarningEnabled
          />
        </ForCattle>
        {dentitionEnabled && (
          <Grid item xs={12}>
            <DentitionField
              name="dentitionId"
              disabled={areNASFieldsDisabled}
            />
          </Grid>
        )}

        <QuickOptionsWithSelect
          ns={ns}
          name="breed_id"
          label="Breed"
          options={breedOptions}
          disabled={readOnly}
          missingMLAFieldsWarningEnabled
        />
        {areStudFeaturesEnabled && (
          <>
            <Grid item xs={12}>
              <Input
                label="Tag #"
                name="draftingAttributes.tagNumber"
                disabled={readOnly}
              />
            </Grid>

            <Grid item xs={12} data-tour="animalName">
              <TextArea
                label="Animal Name"
                name="draftingAttributes.animalName"
                disabled={readOnly}
              />
            </Grid>
          </>
        )}
        {showWeightAndDressing && (
          <>
            <Grid item xs={6} md={12}>
              <EstAvgWeightRangeField
                name="estimatedAverageWeightId"
                disabled={areNASFieldsDisabled}
              />
            </Grid>
            <Grid item xs={6} md={12}>
              <DressingRangeField name="dressingRangeId" disabled={readOnly} />
            </Grid>
          </>
        )}

        {deploymentId && (
          <ForNotHooks>
            <HasDeploymentMarks deploymentId={deploymentId}>
              <Grid item xs={12}>
                <MarkingField
                  deploymentId={deploymentId}
                  name={withNamespace(ns, "marks")}
                  disabled={readOnly}
                />
              </Grid>
            </HasDeploymentMarks>

            <Grid item xs={12}>
              <OtherMarkingField
                deploymentId={deploymentId}
                name={withNamespace(ns, "marks")}
                disabled={readOnly}
              />
            </Grid>
          </ForNotHooks>
        )}
        {deploymentId && (
          <Grid item xs={12}>
            <SaleLotLabelSelectorField
              label="Labels"
              name={withNamespace(ns, "labels")}
              deploymentId={deploymentId}
              disabled={readOnly}
            />
          </Grid>
        )}
        <Grid data-tour="category" item xs={12} sm={6}>
          <AnimalHealthField disabled={readOnly} isClearable />
        </Grid>
        <ForNotHooks>
          <ForSheep>
            <Grid data-tour="eid" item xs={12} sm={6}>
              <EIDExemptionReasonField isClearable disabled={readOnly} />
            </Grid>
          </ForSheep>
        </ForNotHooks>
      </ForNotClearingSale>

      <ForClearingSale>
        <ClearingSaleFormComponent namespace={ns} readOnly={readOnly} />
      </ForClearingSale>

      <Grid item xs={12} data-tour="notes">
        <TextArea
          label="Notes"
          name={withNamespace(ns, "note")}
          disabled={readOnly}
        />
      </Grid>
    </>
  );
}
GeneralSaleLotFormComponent.propTypes = {
  deploymentId: PropTypes.number,
  namespace: PropTypes.string,
};

export default GeneralSaleLotFormComponent;
