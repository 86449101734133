import React from "react";

import { OptionTogglerField } from "components/Form/FormikControls";
import { SellSafeCategory } from "constants/clearingSaleAttributes";

import { Circle } from "components/Icons/Circle";

const SellSafeIcons = ["activeGreen", "selectiveYellow", "lightCoral"].map(
  color => <Circle color={color} />,
);

interface SellSafeOptionTogglerProps {
  name: string;
  readOnly?: boolean;
}

export function SellSafeOptionToggler({
  name,
  readOnly = false,
}: SellSafeOptionTogglerProps): React.JSX.Element {
  return (
    <OptionTogglerField
      label="Sell Safe Category"
      name={name}
      labelPosition="top"
      options={Object.values(SellSafeCategory)}
      disabled={readOnly}
      optionIcons={SellSafeIcons}
      forceTabStop
    />
  );
}
