import React from "react";

import { Chip } from "./BaseChip";

export const NoteChip = ({ note }) => {
  if (!note) {
    return null;
  }
  return (
    <Chip color={false} backgroundColor="warningOrange" italic tooltip={note}>
      {note.length > 50 ? `${note.substring(0, 50)}...` : note}
    </Chip>
  );
};
