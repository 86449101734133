import React from "react";

import { faCopy } from "@fortawesome/pro-solid-svg-icons";
import { Grid, Tooltip } from "@material-ui/core";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import StateAwareBusinessPICSelector from "components/BusinessPICSelector";
import { DeploymentSaleInterfaceOptionsForm } from "components/DeploymentSaleOptions/DeploymentSaleInterfaceOptionsForm";
import ErrorMessage from "components/ErrorMessage";
import {
  Button,
  DeleteButton,
  IconButton,
  SecondaryButton,
} from "components/Form";
import {
  DeploymentSelectField,
  OrderableMultiSelect,
} from "components/Form/Fields";
import { PICField } from "components/Form/Fields/PICField";
import {
  CheckBox,
  DateInput,
  FullPageForm,
  Input,
  IntegerPriceInput,
  PercentageInput,
  SelectField,
  TextArea,
  useSubmitHandler,
} from "components/Form/FormikControls";
import { LockIcon } from "components/Icons/";
import { Column, Row } from "components/Layout";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { ExportSites } from "constants/exportSites";
import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { PricingTypes } from "constants/pricingTypes";
import { SaleTypes } from "constants/sale";
import { Species } from "constants/species";

import {
  ForClearingSale,
  ForHooksOrPaddock,
  ForLivestockAuction,
  ForLivestockAuctionAndClearingSale,
  ForNotExternalAgentSale,
  ForPaddock,
  ForSaleType,
  ForSaleyardAuction,
} from "containers/ForSaleType";
import { ForSaleyardAdmin } from "containers/ForUserType";

import { EMPTY_ARRAY } from "lib";

import { floatIsEqual } from "lib/compare";
import toast from "lib/toast";

import {
  getBusinesses,
  getCurrentSale,
  getSaleSubTypes,
  selectSpeciesBySaleyardIdLookup,
} from "selectors";

import {
  useHasDeploymentPermission,
  useHasSaleyardPermission,
  useTranslatedSaleTypeText,
} from "hooks";
import { useGetSaleyardName } from "hooks/useGetSaleyardName";

const IdGridContainer = styled(Grid)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px;
`;

const CopyButton = styled(IconButton)`
  min-height: 30px;
`;

const SaleForm = ({
  handleClose,
  handleReInit,
  isEditMode,
  rounds,
  saleTypes = EMPTY_ARRAY,
  saleyards = EMPTY_ARRAY,
  title,
  handleDelete,
  readOnly,
}) => {
  const sale = useSelector(getCurrentSale);
  const pricingTypesOptions = PricingTypes.all();
  const formikProps = useFormikContext();
  const selectedSaleType = formikProps.values.sale_type;
  const saleTypeText = useTranslatedSaleTypeText("Sale Date", selectedSaleType);

  const saleSubTypes = useSelector(getSaleSubTypes);

  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  useSubmitHandler(!isEditMode, setIsSubmitEnabled);

  const businesses = useSelector(getBusinesses);

  const hasFinancialSaleTypePermission = useHasDeploymentPermission(
    DeploymentPermissions.featureFinancialSaleSubTypes,
  );

  const hasCanBlockNonAdminChangingWeightsPermission = useHasSaleyardPermission(
    SaleyardPermissions.canBlockNonAdminChangingWeights,
  );

  const {
    sale_type,
    default_buyer_id,
    deployment_sales,
    saleyard_id,
    is_sale_locked,
    deploymentIds,
    species_id,
    cbid,
    using_registered_bidders,
    livestocksale_id,
  } = formikProps.values;

  const { touched } = formikProps;

  const list2sellExportSiteSelected = deployment_sales.some(deploymentSale =>
    deploymentSale.export_sites?.includes(ExportSites.LIST_2_SELL),
  );

  const isPaddockSale = sale_type === SaleTypes.PADDOCK;
  const isOverHooksSale = sale_type === SaleTypes.OVER_HOOKS;
  const isClearingSale = sale_type === SaleTypes.CLEARING;
  const isBobbyCalfSale = sale_type === SaleTypes.BOBBYCALF;
  const isSundrySale = sale_type === SaleTypes.SUNDRY_SALE;

  let isSaleLotWarningVisble = false;

  const speciesOptions = useSelector(state =>
    selectSpeciesBySaleyardIdLookup(state, saleyard_id),
  ).map(s => ({
    label: s.name,
    value: s.id,
  }));

  // sundry sales, among others (clearing sales, etc.) use reference to themselves as species
  // we need to reference them so subtypes can be used
  let subTypeSpeciesId = species_id;

  if (isSundrySale) {
    subTypeSpeciesId = Species.SUNDRY_SALE;
  }

  const subTypeOptions = Object.values(saleSubTypes)
    .filter(subSaleType => {
      let show = true;
      if (
        !!subSaleType.isFinancialSaleSubType &&
        !hasFinancialSaleTypePermission
      ) {
        show = false;
      }
      if (show) {
        show =
          subSaleType.saleType === sale_type &&
          subSaleType.speciesId === subTypeSpeciesId;
      }
      return show;
    })
    .map(v => ({
      label: v.name,
      value: v.id,
    }));

  const showSubType = subTypeOptions.length > 0 && !isBobbyCalfSale;

  const subTypeRequired = subTypeOptions.length > 0 && !isSundrySale;

  const buyerName = businesses[default_buyer_id]?.name;

  const { setFieldValue } = formikProps;

  React.useEffect(() => {
    if (isOverHooksSale && !touched.sale_title) {
      if (default_buyer_id) {
        const defaultTitle = `Over The Hooks to ${buyerName || "unknown"}`;
        setFieldValue("sale_title", defaultTitle);
      }
    }
  }, [
    default_buyer_id,
    setFieldValue,
    buyerName,
    isOverHooksSale,
    touched.sale_title,
  ]);

  if (isClearingSale) {
    const {
      default_buyers_premium: originalDefaultBuyersPremium,
      default_listing_fee_cents: originalDefaultListingFeeCents,
      default_vendor_commission: originalDefaultVendorCommission,
    } = sale?.deployment_sales[0] || {};

    const {
      default_buyers_premium,
      default_listing_fee_cents,
      default_vendor_commission,
    } = formikProps.values;

    isSaleLotWarningVisble =
      !floatIsEqual(default_buyers_premium, originalDefaultBuyersPremium) ||
      !floatIsEqual(
        default_listing_fee_cents,
        originalDefaultListingFeeCents,
      ) ||
      !floatIsEqual(default_vendor_commission, originalDefaultVendorCommission);
  }

  function onAfterDeploymentIdsChanged(deploymentIds) {
    if (!isEditMode) {
      const deploymentSales = [];
      const existingDeploymentSales = formikProps.values.deployment_sales || [];
      deploymentIds.forEach(deploymentId => {
        const deploymentSale = existingDeploymentSales.find(
          deploymentSale => deploymentSale.deployment_id === deploymentId,
        ) || { deployment_id: deploymentId };

        deploymentSales.push(deploymentSale);
      });
      formikProps.setFieldValue("deployment_sales", deploymentSales);
    }
  }

  const url = window.location.origin;

  const saleyardName = useGetSaleyardName(saleyard_id);

  const roundOptions = rounds.map(round => ({
    label: round.name,
    value: round.id,
  }));

  return (
    <ZoomyDialog
      id="sale-modal"
      open
      onClose={handleClose}
      scroll="paper"
      maxWidth="md"
      fullWidth
    >
      <FullPageForm data-tour="sale-form">
        <DialogTitle onClose={handleClose}>{title}</DialogTitle>
        <DialogContent dividers form>
          {cbid && (
            <IdGridContainer container>
              <Grid xs={8}>
                <strong>Internal Id: </strong> {cbid}
                <Tooltip title="Copy">
                  <CopyToClipboard
                    text={cbid}
                    onCopy={() => toast.success("Id copied to clipboard.")}
                  >
                    <CopyButton icon={faCopy} type="button" />
                  </CopyToClipboard>
                </Tooltip>
              </Grid>
            </IdGridContainer>
          )}
          {using_registered_bidders && livestocksale_id && (
            <IdGridContainer container>
              <Grid xs={8}>
                <strong>Bidder Rego Link: </strong>{" "}
                {`${url}/bidder/${livestocksale_id}`}
                <Tooltip title="Copy">
                  <CopyToClipboard
                    text={`${url}/bidder/${livestocksale_id}`}
                    onCopy={() =>
                      toast.success(`${url}/bidder/${livestocksale_id}`)
                    }
                  >
                    <CopyButton icon={faCopy} type="button" />
                  </CopyToClipboard>
                </Tooltip>
              </Grid>
            </IdGridContainer>
          )}
          <Column fullHeight padding={2}>
            <Grid container spacing={2}>
              <ForSaleType
                selectedSaleType={selectedSaleType}
                exceptSaleType={[SaleTypes.CLEARING, SaleTypes.SUNDRY_SALE]}
              >
                <Grid item xs={12}>
                  <SelectField
                    label="Sale Species"
                    name="species_id"
                    options={speciesOptions}
                    onChangeExtra={species_id =>
                      handleReInit({ ...formikProps.values, species_id })
                    }
                    required
                    disabled={readOnly || isEditMode}
                  />
                </Grid>
              </ForSaleType>
              <Grid item xs={12}>
                <SelectField
                  label="Sale Type"
                  name="sale_type"
                  options={saleTypes}
                  required
                  onChangeExtra={sale_type =>
                    handleReInit({ ...formikProps.values, sale_type })
                  }
                  disabled={readOnly || isEditMode}
                />
              </Grid>
              {showSubType && (
                <Grid item xs={12}>
                  <SelectField
                    label="Sale Subtype"
                    name="sale_sub_type_id"
                    options={subTypeOptions}
                    isClearable
                    onChangeExtra={sale_sub_type_id =>
                      handleReInit({
                        ...formikProps.values,
                        sale_sub_type_id,
                      })
                    }
                    disabled={readOnly}
                    required={subTypeRequired}
                  />
                </Grid>
              )}
              <ForLivestockAuction
                selectedSaleType={selectedSaleType}
                exceptSaleType={SaleTypes.EXTERNAL_AGENT_SALE}
              >
                <Grid item xs={12}>
                  <OrderableMultiSelect
                    dataTour="saleRounds"
                    label="Sale Rounds"
                    name="rounds"
                    required
                    options={roundOptions}
                    disabled={readOnly}
                    placeHolder="Select a round"
                  />
                </Grid>
              </ForLivestockAuction>
              <ForSaleyardAuction selectedSaleType={selectedSaleType}>
                <Grid item xs={12}>
                  <SelectField
                    label="Saleyard Location"
                    name="saleyard_id"
                    options={saleyards}
                    required
                    onChangeExtra={saleyard_id =>
                      handleReInit({ ...formikProps.values, saleyard_id })
                    }
                    disabled={readOnly || isEditMode}
                  />
                </Grid>
              </ForSaleyardAuction>
              <Grid item xs={12}>
                <DateInput
                  name="date"
                  required
                  label={saleTypeText}
                  disabled={readOnly}
                />
              </Grid>
              {list2sellExportSiteSelected && (
                <ForSaleType saleType={SaleTypes.SALEYARD_AUCTION}>
                  <Grid item xs={6}>
                    <DateInput
                      name="start_datetime"
                      label="Sale Start Date And Time"
                      includeTime
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DateInput
                      name="end_datetime"
                      label="Sale End Date And Time"
                      includeTime
                      disabled={readOnly}
                    />
                  </Grid>
                </ForSaleType>
              )}
              <ForClearingSale selectedSaleType={selectedSaleType}>
                <Grid item xs={6}>
                  <DateInput
                    name="start_datetime"
                    label="Sale Start Date And Time"
                    includeTime
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateInput
                    name="end_datetime"
                    label="Sale End Date And Time"
                    includeTime
                    disabled={readOnly}
                  />
                </Grid>
              </ForClearingSale>

              <ForSaleType
                selectedSaleType={selectedSaleType}
                exceptSaleType={[SaleTypes.CLEARING, SaleTypes.SUNDRY_SALE]}
              >
                <Grid item xs={12}>
                  <SelectField
                    label="Pricing Type"
                    name="pricing_type_id"
                    options={pricingTypesOptions}
                    required
                    disabled={readOnly}
                  />
                </Grid>

                <ForPaddock selectedSaleType={selectedSaleType}>
                  <Grid item xs={12}>
                    <StateAwareBusinessPICSelector
                      label="Vendor"
                      businessFieldName="default_vendor_id"
                      propertyFieldName="default_vendor_property_id"
                      formikProps={formikProps}
                      error={
                        (formikProps.touched.default_vendor_property_id &&
                          formikProps.errors.default_vendor_property_id) ||
                        (formikProps.touched.default_vendor_id &&
                          formikProps.errors.default_vendor_id)
                      }
                      disabled={
                        readOnly ||
                        (isEditMode && isOverHooksSale) ||
                        !saleyardName
                      }
                      allowPropertyOnly={false}
                      saleyardName={saleyardName}
                    />
                  </Grid>
                </ForPaddock>
                <ForHooksOrPaddock selectedSaleType={selectedSaleType}>
                  <Grid item xs={12}>
                    <StateAwareBusinessPICSelector
                      optional={!isOverHooksSale}
                      label="Buyer"
                      businessFieldName="default_buyer_id"
                      propertyFieldName="default_property_id"
                      formikProps={formikProps}
                      error={
                        (formikProps.touched.default_property_id &&
                          formikProps.errors.default_property_id) ||
                        (formikProps.touched.default_buyer_id &&
                          formikProps.errors.default_buyer_id)
                      }
                      disabled={
                        readOnly ||
                        (isEditMode && isPaddockSale) ||
                        !saleyardName
                      }
                      allowPropertyOnly={false}
                      saleyardName={saleyardName}
                    />
                  </Grid>
                </ForHooksOrPaddock>
              </ForSaleType>

              <Grid item xs={12}>
                <Input name="sale_title" label="Title" disabled={readOnly} />
              </Grid>

              <Grid item xs={12}>
                <TextArea
                  name="notes"
                  label="Notes"
                  rows={2}
                  disabled={readOnly}
                />
              </Grid>
              <ForSaleType
                selectedSaleType={selectedSaleType}
                saleType={SaleTypes.ON_FARM_AUCTION}
              >
                <Grid item xs={12}>
                  <PICField
                    name="location_property_id"
                    label="Sale Location PIC"
                    addToBusiness={false}
                    showAllProperties
                  />
                </Grid>
              </ForSaleType>

              <ForLivestockAuction selectedSaleType={selectedSaleType}>
                <ForSaleyardAdmin>
                  {!isEditMode && (
                    <Grid item xs={12}>
                      <DeploymentSelectField
                        label="Deployments"
                        name="deploymentIds"
                        onChangeExtra={onAfterDeploymentIdsChanged}
                        isMulti
                        disabled={readOnly}
                      />
                    </Grid>
                  )}
                </ForSaleyardAdmin>
                <ForNotExternalAgentSale selectedSaleType={selectedSaleType}>
                  <Grid item xs={12}>
                    <CheckBox
                      name="manual_vendor_numbers"
                      label="Manual Vendor Numbering"
                      disabled={readOnly || isEditMode}
                    />
                  </Grid>
                </ForNotExternalAgentSale>
                {isEditMode ? (
                  <Grid item xs={12}>
                    <CheckBox
                      name="is_sale_locked"
                      label="Is Sale Locked?"
                      disabled={readOnly}
                    />
                    <LockIcon status={is_sale_locked} />
                  </Grid>
                ) : null}
              </ForLivestockAuction>
              <ForClearingSale selectedSaleType={selectedSaleType}>
                <Grid item xs={12}>
                  <PercentageInput
                    label="Default Buyers Premium"
                    name="default_buyers_premium"
                    required
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <IntegerPriceInput
                    label="Default Listing Fee"
                    name="default_listing_fee_cents"
                    required
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PercentageInput
                    label="Default Vendor Commission"
                    name="default_vendor_commission"
                    required
                    disabled={readOnly}
                  />
                </Grid>
                {isEditMode && isSaleLotWarningVisble && (
                  <Row fullWidth alignCenter justifyCenter>
                    <ErrorMessage>
                      Existing Sale Lots created will not be updated and will
                      need to be manually adjusted
                    </ErrorMessage>
                  </Row>
                )}
              </ForClearingSale>
              <ForLivestockAuctionAndClearingSale
                selectedSaleType={selectedSaleType}
              >
                <ForNotExternalAgentSale selectedSaleType={selectedSaleType}>
                  <Grid item xs={12}>
                    <CheckBox
                      dataTour="useRegisteredBidders"
                      name="using_registered_bidders"
                      label="Use Registered Bidders"
                      disabled={readOnly}
                    />
                  </Grid>
                </ForNotExternalAgentSale>
              </ForLivestockAuctionAndClearingSale>

              {hasCanBlockNonAdminChangingWeightsPermission && (
                <Grid item xs={12}>
                  <CheckBox
                    dataTour="blockNonAdminChangingWeights"
                    name="block_non_admin_changing_weights"
                    label="Block Non Admin Users Changing Weights"
                    disabled={readOnly}
                    tooltip="When this is enabled non admin users will not be able to change weights for sale lots or scans. They will however still be able to add them if the sale lot/scan did not originally have a weight assigned to it and this will not effect weights added from scales."
                  />
                </Grid>
              )}

              <ForSaleType
                selectedSaleType={selectedSaleType}
                exceptSaleType={[SaleTypes.SUNDRY_SALE]}
              >
                <Grid item xs={12}>
                  <DeploymentSaleInterfaceOptionsForm
                    namespace="deployment_sales"
                    isCreateMode={!isEditMode}
                    deploymentIds={deploymentIds}
                    readOnly={readOnly}
                  />
                </Grid>
              </ForSaleType>
            </Grid>
          </Column>
        </DialogContent>
        <DialogActions>
          {handleDelete && (
            <DeleteButton
              data-tour="delete"
              type="button"
              onClick={handleDelete}
            >
              Delete
            </DeleteButton>
          )}
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
          <Button data-tour="submit" type="submit" disabled={!isSubmitEnabled}>
            {isEditMode ? "Save" : "Create"}
          </Button>
        </DialogActions>
      </FullPageForm>
    </ZoomyDialog>
  );
};

SaleForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  saleTypes: PropTypes.array,
  saleyards: PropTypes.array,
};

export default SaleForm;
