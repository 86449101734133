import React from "react";

import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { closeConfirmModal, openConfirmModal } from "actions";

import { SundryTemplateAction } from "actions/sundryTemplates";

import AuditLogLink from "components/AuditLog/AuditLogLink";
import { Button, DeleteButton, SecondaryButton } from "components/Form";
import { useSubmitHandler } from "components/Form/FormikControls";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";
import { SundryTemplateForm } from "components/SundryTemplate/Form";

import { AuditLogTypes } from "constants/auditLog";
import { GstMethod } from "constants/billing";
import {
  DeploymentPermissions,
  SaleyardPermissions,
  SundryTemplatePermissions,
} from "constants/permissions";

import { deepObjectChanges } from "lib/compare";
import { hasPermission } from "lib/permissions";

import { getSundryTemplateById } from "selectors";

import { useHasSaleyardOrDeploymentPermission } from "hooks";

import { SundryTemplateValidationSchema } from "./validationSchema";

export function Footer(props) {
  const { onClose, id } = props;
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);

  const sundryTemplate = useSelector(getSundryTemplateById(id));
  const hasCreatePermission = useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.canAddSundryTemplate,
    SaleyardPermissions.canAddSundryTemplate,
  );

  const hasEditPermission = hasPermission(
    sundryTemplate,
    SundryTemplatePermissions.update,
  );
  const hasSavePermission = id ? hasEditPermission : hasCreatePermission;
  const hasDeletePermission = hasPermission(
    sundryTemplate,
    SundryTemplatePermissions.delete,
  );

  useSubmitHandler(true, setIsSubmitEnabled);
  const dispatch = useDispatch();
  const onClickConfirmDelete = () => {
    dispatch(SundryTemplateAction.delete(id));
    dispatch(closeConfirmModal());
    onClose();
  };

  function onClickCancelDelete() {
    dispatch(closeConfirmModal());
  }

  function onClickDelete() {
    dispatch(
      openConfirmModal({
        title: "Are you sure?",
        message: "Are you sure you want to delete the Sundry Template?",
        actions: [
          {
            label: "Cancel",
            onClick: onClickCancelDelete,
            secondary: true,
          },
          {
            label: "Delete Sundry Template",
            onClick: onClickConfirmDelete,
            secondary: false,
          },
        ],
      }),
    );
  }

  return (
    <>
      <SecondaryButton type="button" onClick={onClose}>
        Close
      </SecondaryButton>
      {sundryTemplate && (
        <DeleteButton
          type="button"
          disabled={!isSubmitEnabled || !hasDeletePermission}
          onClick={onClickDelete}
        >
          Delete
        </DeleteButton>
      )}
      <Button type="submit" disabled={!isSubmitEnabled || !hasSavePermission}>
        Save
      </Button>
    </>
  );
}

export function SundryTemplateModal(props) {
  const { id, onClose, formDefaults } = props;
  const dispatch = useDispatch();
  const sundryTemplate = useSelector(getSundryTemplateById(id));

  const initialValues = {
    name: "",
    glCode: "",
    gstMethod: GstMethod.GST_EXCLUSIVE,
    note: "",
    taxType: "",
    paidFromType: "",
    paidToType: "",
    ...formDefaults,
    ...sundryTemplate,
  };

  function onSubmit(values) {
    if (values.id) {
      dispatch(
        SundryTemplateAction.update(
          { id, ...deepObjectChanges(initialValues, values) },
          { changeReason: "Updated from Edit Sundry Template modal" },
        ),
      );
    } else {
      dispatch(SundryTemplateAction.create(values));
    }

    onClose();
  }

  return (
    <ZoomyDialog open onClose={onClose} maxWidth="md">
      <DialogTitle onClose={onClose}>
        {id && (
          <AuditLogLink
            auditLogType={AuditLogTypes.SUNDRY_TEMPLATE}
            dataId={id}
            returnTo={window.location.hash}
          />
        )}
        &nbsp;{id ? "Edit" : "Create"} Sundry Template
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={SundryTemplateValidationSchema}
      >
        <Form data-tour="sundry-template-form">
          <DialogContent dividers>
            <SundryTemplateForm id={id} />
          </DialogContent>
          <DialogActions>
            <Footer id={id} onClose={onClose} />
          </DialogActions>
        </Form>
      </Formik>
    </ZoomyDialog>
  );
}
