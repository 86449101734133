import React from "react";

import PropTypes from "prop-types";
import { withTheme } from "styled-components";

const NoSaleLogo = ({ color, theme, dark = false }) => {
  const logoColor = color || theme.colors.primary;
  const altColor = dark ? theme.colors.black : theme.colors.white;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3318.92 3318.92"
      width="33px"
      height="33px"
    >
      <defs />
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_2519420898688">
          <path
            fill={altColor}
            stroke="#427DB3"
            strokeWidth="105.83"
            strokeMiterlimit="22.9256"
            d="M1384.39 159.82c-1989.07,374.51 -1457.83,3378.41 568.4,3012.62 1955.31,-352.98 1441.4,-3391 -568.4,-3012.62z"
          />
          <path
            fill={logoColor}
            d="M1757.39 2255.96c89.32,-3.97 150.35,-27.07 217.72,-75.68 16.95,-12.23 48.14,-44.22 60.86,-61.57 11.24,-15.32 18.82,-24.97 28.3,-43.74 56.81,-112.36 49.37,-238.47 -19.35,-343.02 -18.39,-27.97 -44.32,-54.92 -70.05,-75.82 -18.55,-15.07 -34.86,-25.69 -59.39,-37.88 -74.45,-37 -369.27,-107.13 -406.91,-127.84 -62.86,-34.6 -59.55,-121.63 -4.58,-155.79 26.45,-16.43 49.08,-13.99 82.9,-13.99 32.41,0 64.83,0.09 97.24,0.09 64.29,0 117.23,-5.35 168.16,25.85 10.39,6.36 19.99,13.99 35.29,14.01 15.66,0.01 28.37,-6.78 35.23,-14.18 16.61,-17.93 31.99,-31.79 49.07,-48.14l48.82 -48.38c50.73,-48.63 11.93,-80.48 -36.52,-110.81 -19.57,-12.24 -41.03,-23.15 -64.49,-32.8 -48.29,-19.87 -104.53,-31.17 -162.19,-32.23 0,-48.26 1.83,-100.41 -0.21,-148.2 -0.94,-21.79 -13.88,-37.78 -28.88,-44.3 -15.39,-6.69 -123.33,-6.63 -138.9,0.14 -14.77,6.43 -28.07,22.73 -29.07,44.16 -2.2,47.33 0,100.3 0,148.28 -94.07,0.14 -181.05,33.38 -244.9,97.22 -4.38,4.39 -8.03,8.42 -11.91,13.31 -10.7,13.48 -20.19,22.1 -31.7,40.31 -82,129.72 -74.68,285.13 23.51,399.39 10.28,11.97 37.74,38.97 49.39,47.8 71.8,54.42 112.28,62.79 202.89,88.86l225.67 66c32.71,9.47 44.36,15.82 62.03,35.25 30.4,33.43 30.01,100.95 -21.45,133.45 -26.66,16.83 -48.68,14.8 -83.02,14.8 -32.42,0 -64.83,-0.17 -97.24,-0.16 -65.89,0 -115.81,5.76 -168.28,-26.59 -25.81,-15.91 -45.06,-20.86 -69.77,0.53 -20.29,17.56 -32.82,33.22 -48.91,48.37 -46.68,43.96 -113.18,87.67 -42.63,139.37 74.09,54.29 160.21,82.6 256.22,84.56 0,48.49 -1.45,99.4 0.18,147.6 2.02,59.91 64.53,49.15 98.4,49.06 33.31,-0.1 95.99,10.94 98.5,-49.06 2,-47.66 -0.03,-100.03 -0.03,-148.23z"
          />
          <line
            fill={logoColor}
            stroke="#427DB3"
            strokeWidth="105.83"
            strokeMiterlimit="22.9256"
            x1="391.99"
            y1="2616.51"
            x2="2767.44"
            y2="581.84"
          />
        </g>
      </g>
    </svg>
  );
};

NoSaleLogo.propTypes = {
  color: PropTypes.string,
  theme: PropTypes.object.isRequired,
  dark: PropTypes.bool,
};

export default withTheme(NoSaleLogo);
