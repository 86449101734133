import React from "react";

import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import startCase from "lodash/startCase";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

import { printSaleLotWeightSheet } from "actions";

import FontAwesomeButton, {
  ButtonIcon,
} from "components/Button/FontAwesomeButton";
import StatusCard from "components/Card/StatusCard";
import {
  Chip,
  MarkChips,
  MoneyChip,
  AverageWeightChip,
  TotalWeightChip,
  SpeciesChippies,
} from "components/Chip";
import { Row } from "components/Layout";

import { statusColors } from "constants/theme";

import { formatPercentage, formatWeightKg } from "lib";

import { getCombinedLotNumber } from "lib/saleLot";
import { getScanStatusColour } from "lib/scans";

import { Info, Label, Text } from "./Elements";

const LotLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 1rem;
`;

const HeadScanContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
`;

const SelectableStatusCard = styled(StatusCard)`
  width: 100%;
  cursor: pointer;
  box-shadow: ${({ selected }) =>
    selected ? `boxShadow: 0 0 1px 2px #4A90E2` : `none`};
`;

function CompactLotCardComponent(props) {
  const {
    age,
    averageWeightFormatted,
    breed,
    buyer_name,
    buyer_way_name,
    buyer_id,
    category,
    consignmentCode,
    grade,
    id,
    lotNumberCombined,
    marks,
    onClick,
    penName,
    price_unit,
    price,
    quantity,
    sale_round,
    scannedPercentage,
    scannedStatus,
    sex,
    status,
    selected,
    title,
    total_mass_grams,
    totalWeightFormatted,
    showPrint = false,
  } = props;

  const lotNumber = lotNumberCombined || getCombinedLotNumber(props);

  const dispatch = useDispatch();

  function onClickPrint() {
    dispatch(printSaleLotWeightSheet(id, true));
  }

  const possibleChippies = [
    buyer_id && `${buyer_name} ${buyer_way_name || ""}`,
    total_mass_grams > 0 && formatWeightKg(total_mass_grams),
  ].filter(Boolean);

  return (
    <SelectableStatusCard
      data-tour={`statusCard.${lotNumber}`}
      onClick={onClick}
      selected={selected}
      status={status}
    >
      <LotLayout>
        <Info>
          <Label>Pen</Label>
          <Text>{penName}</Text>
        </Info>

        <Info>
          <Text>{title}</Text>
        </Info>
        <Info style={{ marginLeft: "auto" }}>
          <HeadScanContainer>
            <Label>{consignmentCode && "Code"}</Label>
            <Label>Head</Label>
            <Label>Scan</Label>
            <Text>{consignmentCode}</Text>
            <Text align="right">{quantity}</Text>
            <Text color={getScanStatusColour(scannedStatus)}>
              {formatPercentage(scannedPercentage)}
            </Text>
          </HeadScanContainer>
        </Info>
      </LotLayout>

      <Row flexWrap>
        <Chip backgroundColor={statusColors[status]}>{status}</Chip>
        <Chip>Round: {startCase(sale_round?.name)}</Chip>
        <Chip>Lot #{lotNumber}</Chip>
        {possibleChippies.map(chipText => (
          <Chip key={chipText}>{chipText}</Chip>
        ))}
        <SpeciesChippies
          ageName={age?.name}
          breedName={breed?.name}
          categoryName={category?.name}
          gradeName={grade?.name}
          sexName={sex?.name}
        />

        <MarkChips marks={marks} />

        {price > 0 && (
          <MoneyChip>
            {price} {price_unit}
          </MoneyChip>
        )}
        {totalWeightFormatted && (
          <TotalWeightChip>Tot Wt {totalWeightFormatted} Kg</TotalWeightChip>
        )}

        {averageWeightFormatted && (
          <AverageWeightChip>
            Avg. Wt. {averageWeightFormatted} Kg
          </AverageWeightChip>
        )}
      </Row>
      {showPrint && (
        <Row justifyCenter>
          <FontAwesomeButton onClick={onClickPrint}>
            <ButtonIcon icon={faPrint} />
            &nbsp;Print
          </FontAwesomeButton>
        </Row>
      )}
    </SelectableStatusCard>
  );
}

export const CompactLotCard = React.memo(CompactLotCardComponent, (a, b) =>
  [
    "age",
    "averageWeightFormatted",
    "breed",
    "buyer_name",
    "buyer_way_name",
    "buyer_id",
    "category",
    "consignmentCode",
    "grade",
    "id",
    "lot_number",
    "lot_number_suffix",
    "marks",
    "penName",
    "price_unit",
    "price",
    "quantity",
    "sale_round",
    "scannedPercentage",
    "scannedStatus",
    "sex",
    "status",
    "selected",
    "title",
    "total_mass_grams",
    "totalWeightFormatted",
  ].every(key => a[key] === b[key]),
);
