export const BillingDocumentPermissions = {
  read: "IntegrationBusinessPermissions.read",
  update: "IntegrationBusinessPermissions.update",
  delete: "IntegrationBusinessPermissions.delete",
};

export const LivestockSalePermissions = {
  read: "LivestockSalePermissions.read",
  update: "LivestockSalePermissions.update",
  delete: "LivestockSalePermissions.delete",
  canForceConfirmSales: "LivestockSalePermissions.can_force_confirm_sales",
  canAddReceivalLot: "LivestockSalePermissions.can_add_receival_lot",
  canAddPenScanLot: "LivestockSalePermissions.can_add_pen_scan_lot",
  canAddWeighLot: "LivestockSalePermissions.can_add_weigh_lot",
};

export const SaleLotPermissions = {
  read: "SaleLotPermissions.read",
  update: "SaleLotPermissions.update",
  delete: "SaleLotPermissions.delete",
};

export const SaleyardPermissions = {
  canAddAttachmentWithoutImage:
    "SaleyardPermissions.can_add_attachment_without_image",
  canAddRuleBook: "SaleyardPermissions.can_add_rule_book",
  canAddSaleyardAdmin: "SaleyardPermissions.can_add_saleyard_admin",
  canAddSavedView: "SaleyardPermissions.can_add_saved_view",
  canAddSundryTemplate: "SaleyardPermissions.can_add_sundry_template",
  canBulkUpdateSaleLotConsignment:
    "SaleyardPermissions.can_bulk_update_sale_lot_consignment",
  canCreateCheckpoint: "SaleyardPermissions.can_create_checkpoint",
  canCreateLedgerAccount: "SaleyardPermissions.can_create_ledger_account",
  canBlockNonAdminChangingWeights:
    "SaleyardPermissions.can_block_non_admin_changing_weights",
  canExportBusinesses: "SaleyardPermissions.can_export_businesses",
  canForceSell: "SaleyardPermissions.can_force_sell",
  canForceTake: "SaleyardPermissions.can_force_take",
  canSendVendorReportEmail: "SaleyardPermissions.can_send_vendor_report_email",
  featureAutoIncrement: "SaleyardPermissions.feature_auto_increment_pen_number",
  featureAutoIncrementWeighbridge:
    "SaleyardPermissions.feature_auto_increment_weighbridge_pen_number",
  featureAllowRememberLastDraftingAttributes:
    "SaleyardPermissions.feature_allow_remember_last_drafting_attributes",
  featureBidders: "SaleyardPermissions.feature_bidders",
  featureBilling: "SaleyardPermissions.feature_billing",
  featureBulkWeigh: "SaleyardPermissions.feature_bulk_weigh",
  featureBusinessAlternatives:
    "SaleyardPermissions.feature_business_alternatives",
  featureBusinessFinance: "SaleyardPermissions.feature_business_finance",
  featureEditWeightOnEid: "SaleyardPermissions.feature_edit_weight_on_eid",
  featureEmailList: "SaleyardPermissions.feature_email_list",
  featureEnvds: "SaleyardPermissions.feature_envds",
  featureQuickCreateLivestock:
    "SaleyardPermissions.feature_quick_create_livestock",
  featureMultiSaleReports: "SaleyardPermissions.feature_multi_sale_reports",
  featureNlis: "SaleyardPermissions.feature_nlis",
  featurePenScanLots: "SaleyardPermissions.feature_pen_scan_lots",
  featureReceivalLots: "SaleyardPermissions.feature_receival_lots",
  featureRuleBook: "SaleyardPermissions.feature_rule_book",
  featureSingleWeigh: "SaleyardPermissions.feature_single_weigh",
  featureSundryTemplates: "SaleyardPermissions.feature_sundry_templates",
  featureUserManagement: "SaleyardPermissions.feature_user_management",
  featureWatcher: "SaleyardPermissions.feature_watcher",
  featureWeighLots: "SaleyardPermissions.feature_weigh_lots",
  featureWeightRange: "SaleyardPermissions.feature_weight_range",
  featureForceERPRefresh: "SaleyardPermissions.feature_force_erp_refresh",
  featureMLASupplementaryData:
    "SaleyardPermissions.feature_mla_supplementary_data",
  read: "SaleyardPermissions.read",
};

export const SaleyardAdminPermissions = {
  read: "SaleyardAdminPermissions.read",
  update: "SaleyardAdminPermissions.update",
  delete: "SaleyardAdminPermissions.delete",
  canAddSavedView: "SaleyardAdminPermissions.can_add_saved_view",
};

export const NominationPermissions = {
  read: "NominationPermissions.read",
  update: "NominationPermissions.update",
  delete: "NominationPermissions.delete",
};

export const DeploymentPermissions = {
  canAddAttachmentWithoutImage:
    "DeploymentPermissions.can_add_attachment_without_image",
  canAddConsignment: "DeploymentPermissions.can_add_consignment",
  canAddLivestockAgent: "DeploymentPermissions.can_add_livestock_agent",
  canAddRuleBook: "DeploymentPermissions.can_add_rule_book",
  canAddSavedView: "DeploymentPermissions.can_add_saved_view",
  canAddSundryTemplate: "DeploymentPermissions.can_add_sundry_template",
  canCreateBillingRun: "DeploymentPermissions.can_add_billing_run",
  canCreateCheckpoint: "DeploymentPermissions.can_create_checkpoint",
  canCreateLedgerAccount: "DeploymentPermissions.can_create_ledger_account",
  canCreateEContract: "DeploymentPermissions.can_create_econtract",
  canExportBusinesses: "DeploymentPermissions.can_export_businesses",
  canLinkExternalIntegration:
    "DeploymentPermissions.can_link_external_integration",
  canLockNominations: "DeploymentPermissions.can_lock_nominations",
  canNominateFor: "DeploymentPermissions.can_nominate_for",
  canSendVendorReportEmail:
    "DeploymentPermissions.can_send_vendor_report_email",
  featureAges: "DeploymentPermissions.feature_ages",
  featureAttachments: "DeploymentPermissions.feature_attachments",
  featureAuction: "DeploymentPermissions.feature_auction",
  featureAutoIncrement:
    "DeploymentPermissions.feature_auto_increment_pen_number",
  featureAutoIncrementWeighbridge:
    "DeploymentPermissions.feature_auto_increment_weighbridge_pen_number",
  featureAllowRememberLastDraftingAttributes:
    "DeploymentPermissions.feature_allow_remember_last_drafting_attributes",
  featureBilling: "DeploymentPermissions.feature_billing",
  featureBillingRun: "DeploymentPermissions.feature_billing_run",
  featureBreeds: "DeploymentPermissions.feature_breeds",
  featureBulkBillingExport: "DeploymentPermissions.feature_bulk_billing_export",
  featureBulkWeigh: "DeploymentPermissions.feature_bulk_weigh",
  featureBusinessAlternatives:
    "DeploymentPermissions.feature_business_alternatives",
  featureBusinessFinance: "DeploymentPermissions.feature_business_finance",
  featureBusinessRelations: "DeploymentPermissions.feature_business_relations",
  featureBuyers: "DeploymentPermissions.feature_buyers",
  featureConsignments: "DeploymentPermissions.feature_consignments",
  featureDentition: "DeploymentPermissions.feature_dentition",
  featureEContracts: "DeploymentPermissions.feature_econtracts",
  featureEditDeployment: "DeploymentPermissions.feature_edit_deployment",
  featureEmailList: "DeploymentPermissions.feature_email_list",
  featureExternalIntegrations:
    "DeploymentPermissions.feature_external_integrations",
  featureGSTOverride: "DeploymentPermissions.feature_gst_override",
  featureLabels: "DeploymentPermissions.feature_labels",
  featureManualCharges: "DeploymentPermissions.feature_manual_charges",
  featureMarks: "DeploymentPermissions.feature_marks",
  featureMultiSaleReports: "DeploymentPermissions.feature_multi_sale_reports",
  featureMyobIntegration: "DeploymentPermissions.feature_myob_exports",
  featureMyobClassicIntegration:
    "DeploymentPermissions.feature_myob_classic_exports",
  featureNominations: "DeploymentPermissions.feature_nominations",
  featurePens: "DeploymentPermissions.feature_pens",
  featurePremiumSaleStatistics:
    "DeploymentPermissions.feature_premium_sale_statistics",
  featureProducts: "DeploymentPermissions.feature_products",
  featureQuickSell: "DeploymentPermissions.feature_quick_sell",
  featureReports: "DeploymentPermissions.feature_reports",
  featureRetainedProceeds: "DeploymentPermissions.feature_retained_proceeds",
  featureRuleBook: "DeploymentPermissions.feature_rule_book",
  featureSaleLots: "DeploymentPermissions.feature_sale_lots",
  featureSaleStatistics: "DeploymentPermissions.feature_sale_statistics",
  featureScanning: "DeploymentPermissions.feature_scanning",
  featureScans: "DeploymentPermissions.feature_scans",
  featureSexes: "DeploymentPermissions.feature_sexes",
  featureSundryTemplates: "DeploymentPermissions.feature_sundry_templates",
  featureSyncAllAlternativeBusinessFields:
    "DeploymentPermissions.feature_sync_all_alternative_business_fields",
  featureUserManagement: "DeploymentPermissions.feature_user_management",
  featureVendorCommissions: "DeploymentPermissions.feature_vendor_commissions",
  featureInterest: "DeploymentPermissions.feature_interest",
  featureVendorSplits: "DeploymentPermissions.feature_vendor_splits",
  featureTradingTerms: "DeploymentPermissions.feature_trading_terms",
  featurePercentageVendorSplits:
    "DeploymentPermissions.feature_percentage_vendor_splits",
  featureWeightRange: "DeploymentPermissions.feature_weight_range",
  featureXeroIntegration: "DeploymentPermissions.feature_xero_integration",
  featureFinancialSaleSubTypes:
    "DeploymentPermissions.feature_financial_sale_sub_types",
  featureForceERPRefresh: "DeploymentPermissions.feature_force_erp_refresh",
  read: "DeploymentPermissions.read",
};

export const LivestockAgentPermissions = {
  read: "LivestockAgentPermissions.read",
  update: "LivestockAgentPermissions.update",
  delete: "LivestockAgentPermissions.delete",
  canAddSavedView: "LivestockAgentPermissions.can_add_saved_view",
};

export const IntegrationCredentialPermissions = {
  read: "IntegrationCredentialPermissions.read",
  update: "IntegrationCredentialPermissions.update",
  delete: "IntegrationCredentialPermissions.delete",
};

export const IntegrationBusinessPermissions = {
  read: "IntegrationBusinessPermissions.read",
  update: "IntegrationBusinessPermissions.update",
  delete: "IntegrationBusinessPermissions.delete",
};

export const MLASupplementaryDataPermissions = {
  read: "MLASupplementaryDataPermissions.read",
  update: "MLASupplementaryDataPermissions.update",
  delete: "MLASupplementaryDataPermissions.delete",
};

export const RuleBookPermissions = {
  read: "RuleBookPermissions.read",
  update: "RuleBookPermissions.update",
  delete: "RuleBookPermissions.delete",
};

export const ConsignmentPermissions = {
  read: "ConsignmentPermissions.read",
  update: "ConsignmentPermissions.update",
  delete: "ConsignmentPermissions.delete",
  canAddSaleLot: "ConsignmentPermissions.can_add_sale_lot",
};

export const ReceivalLotPermissions = {
  read: "ReceivalLotPermissions.read",
  update: "ReceivalLotPermissions.update",
  delete: "ReceivalLotPermissions.delete",
};

export const PenScanLotPermissions = {
  read: "PenScanLotPermissions.read",
  update: "PenScanLotPermissions.update",
  delete: "PenScanLotPermissions.delete",
};

export const WeighLotPermissions = {
  read: "WeighLotPermissions.read",
  update: "WeighLotPermissions.update",
  delete: "WeighLotPermissions.delete",
};

export const LedgerAccountPermissions = {
  read: "LedgerAccountPermissions.read",
  update: "LedgerAccountPermissions.update",
  delete: "LedgerAccountPermissions.delete",
};

export const EContractPermissions = {
  read: "EContractPermissions.read",
  accept: "EContractPermissions.accept",
  void: "EContractPermissions.void",
};

export const SundryTemplatePermissions = {
  read: "SundryTemplatePermissions.read",
  update: "SundryTemplatePermissions.update",
  delete: "SundryTemplatePermissions.delete",
};

export const OfflineConsignmentPermissions = [
  ConsignmentPermissions.canAddSaleLot,
  ConsignmentPermissions.delete,
  ConsignmentPermissions.read,
  ConsignmentPermissions.update,
];
