import React from "react";

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import styled from "styled-components/macro";

import Clickable from "components/Button/Clickable";
import { Row } from "components/Layout";

const PageButton = styled(Clickable)`
  padding: 12px;
  font-weight: bold;
  ${({ theme, selected }) => selected && `color: ${theme.colors.primary}`};
  transition: ${({ theme }) => theme.transitions[0]};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Ellipsis = styled.div`
  padding: 12px 10px;
  font-weight: bold;
`;

const Paginator = ({ page = 1, numPages = 1, setPage }) => {
  const pageNumbers = [
    ...new Set([1, page - 1, page, page + 1, numPages]),
  ].filter(num => num > 0 && num <= numPages);

  const pageButtons = [];
  pageNumbers.forEach((num, i, pageNumbers) => {
    pageButtons.push(
      <PageButton
        type="button"
        key={num}
        onClick={() => setPage(num)}
        selected={num === page}
      >
        {num}
      </PageButton>,
    );
    if (num !== numPages && num + 1 !== pageNumbers[i + 1]) {
      pageButtons.push(<Ellipsis key={num + 1}>...</Ellipsis>);
    }
  });

  return (
    <Row>
      <PageButton onClick={() => setPage(page - 1)} type="button">
        <FontAwesomeIcon icon={faChevronLeft} />
      </PageButton>
      {pageButtons}
      <PageButton onClick={() => setPage(page + 1)} type="button">
        <FontAwesomeIcon icon={faChevronRight} />
      </PageButton>
    </Row>
  );
};

Paginator.propTypes = {
  page: PropTypes.number,
  numPages: PropTypes.number,
  setPage: PropTypes.func.isRequired,
};

export default Paginator;
