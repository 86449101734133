import React from "react";

import styled from "styled-components/macro";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row } from "components/Layout";
import { ExternalFileLink } from "components/Link";
import { extractFileExtensionFromURL } from "lib";
import { RESIZABLE_IMAGE_EXTENSIONS } from "constants/files";

const DeletableWrapper = styled(Row)`
  position: relative;
`;

const ConstrainedImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const MediaWrapper = styled.div`
  min-height: 120px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteButton = styled(FontAwesomeIcon).attrs({ icon: faTimesCircle })`
  color: ${({ theme }) => theme.colors.deleteRed};
  font-size: 32px;
  position: absolute;
  top: -16px;
  right: -10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.3);
  }
`;

interface DeletableProps {
  children: React.ReactNode;
  handleDelete: () => void;
}

const Deletable = ({ children, handleDelete }: DeletableProps) => (
  <DeletableWrapper justifyCenter alignCenter>
    <DeleteButton onClick={handleDelete} />
    {children}
  </DeletableWrapper>
);

interface ThumbnailProps {
  file: File;
  setPreview: (id: number) => void;
}
export const Thumbnail = ({ file, setPreview }: ThumbnailProps) => {
  const onClick = () => setPreview(file.id);

  const fileExtension = extractFileExtensionFromURL(file.image_url);
  if (RESIZABLE_IMAGE_EXTENSIONS.includes(fileExtension)) {
    return (
      <MediaWrapper onClick={onClick}>
        <ConstrainedImage
          key={file.id}
          alt=""
          src={file.thumbnail_url || file.image_url}
        />
      </MediaWrapper>
    );
  }
  return (
    <MediaWrapper>
      <ExternalFileLink imageUrl={file.image_url} />
    </MediaWrapper>
  );
};

interface DeletableThumbnailProps extends ThumbnailProps {
  handleDelete: () => void;
  readOnly: boolean;
}
export const DeletableThumbnail = ({
  file,
  setPreview,
  handleDelete,
  readOnly,
}: DeletableThumbnailProps) => {
  if (readOnly) {
    return <Thumbnail file={file} setPreview={setPreview} />;
  }
  return (
    <Deletable handleDelete={handleDelete}>
      <Thumbnail file={file} setPreview={setPreview} />
    </Deletable>
  );
};
