import reduceReducers from "reduce-reducers";

import { MLA_SUPPLEMENTARY_DATA } from "constants/actionTypes";

import {
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  bulkUpdateCases,
} from "lib/reducers";
import { deserializeMLASupplementaryData } from "lib/serializers/mlaSupplementaryData";

const update = apiModelOfflineUpdateReducer(MLA_SUPPLEMENTARY_DATA, {
  deserializer: deserializeMLASupplementaryData,
});

const fetch = apiModelOfflineFetchReducer(MLA_SUPPLEMENTARY_DATA, {
  deserializer: deserializeMLASupplementaryData,
  clearOnRequest: true,
});

function bulkReducer(state, action) {
  switch (action.type) {
    case MLA_SUPPLEMENTARY_DATA.UPDATE_BULK.REQUEST:
    case MLA_SUPPLEMENTARY_DATA.UPDATE_BULK.SUCCESS:
    case MLA_SUPPLEMENTARY_DATA.UPDATE_BULK.FROM_SOCKET: {
      return bulkUpdateCases(
        state,
        action,
        "byId",
        deserializeMLASupplementaryData,
      );
    }
    default:
      return state;
  }
}

const mlaSupplementaryData = reduceReducers(update, bulkReducer, fetch);

export default mlaSupplementaryData;
