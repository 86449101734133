/* eslint-disable react/jsx-indent */
import React from "react";

import {
  faCaretRight,
  faCaretUp,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faRepeat } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { IconTextButton } from "components/Button";
import { Row } from "components/Layout";
import {
  expandedLineHeight,
  expandedPadding,
} from "components/SearchableSelector/ListItem";

import { LOAD_MORE_SALES } from "constants/sale";
import { colors } from "constants/theme";

import { EMPTY_TRUTHY_FUNCTION, EMPTY_OBJECT } from "lib";

import { allFieldsEqual } from "lib/compare";

import { List, ListItem } from ".";

export const LoadSalesWrapper = styled.div`
  text-align: center;
  height: inherit;
  padding-top: 5px;
  display: flex;
  justify-content: center;
`;

class SearchableSelector extends React.Component {
  state = {
    displayDropdown: false,
    filter: "",
    showSearch: false,
    extendedItem: "",
    visibleMetadataId: null,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    const {
      onChange,
      defaultToFirst = false,
      selectedOption,
      options,
    } = this.props;
    // if no selection options, set to first in list
    defaultToFirst &&
      !selectedOption &&
      options &&
      options.length &&
      onChange(options[0].id);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { props, state } = this;
    return (
      allFieldsEqual(props, nextProps, ["options"]) ||
      allFieldsEqual(state, nextState, [
        "displayDropdown",
        "filter",
        "showSearch",
        "extendedItem",
        "visibleMetadataId",
      ])
    );
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  static getDerivedStateFromProps(props) {
    const { options, searchableAfter = 9 } = props;
    if (searchableAfter !== -1) {
      return {
        showSearch: options.length >= searchableAfter,
      };
    }
    return null;
  }

  setRef = (node, refName) => {
    this[refName] = node;
  };

  handleExtendClick = extendedItem => {
    this.setState({ extendedItem });
  };

  toggleMetadata = visibleMetadataId => {
    const { visibleMetadataId: existingValue } = this.state;
    if (visibleMetadataId === existingValue) {
      this.setState({ visibleMetadataId: null });
    } else {
      this.setState({ visibleMetadataId });
    }
    if (this.listRef) {
      this.listRef.recomputeRowHeights();
    }
  };

  handleOptionClick = optionId => {
    const { onChange } = this.props;
    if (optionId !== LOAD_MORE_SALES) {
      this.collapseDropdown();
      onChange(optionId);
    } else {
      onChange();
    }
  };

  handleTopClick = () => {
    const { displayDropdown } = this.state;
    const { disabled = false } = this.props;
    if (disabled) {
      return;
    }
    if (displayDropdown) {
      this.collapseDropdown();
    } else {
      this.expandDropdown();
    }
  };

  handleClickOutside = event => {
    const { displayDropdown } = this.state;
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      displayDropdown
    ) {
      this.collapseDropdown();
    }
  };

  expandDropdown = () => {
    this.setState({ displayDropdown: true });
    if (this.listRef) {
      this.listRef.recomputeRowHeights();
    }
  };

  collapseDropdown = () => {
    this.setState({
      displayDropdown: false,
      filter: "",
      extendedItem: "",
      visibleMetadataId: null,
    });
  };

  handleInputChange = event => {
    const {
      inputTransformationFunction,
      allSalesLoaded = false,
      loadSales = null,
    } = this.props;

    let filter = event.target.value;
    if (inputTransformationFunction) {
      filter = inputTransformationFunction(filter);
    }
    if (!allSalesLoaded && loadSales) {
      loadSales();
    }
    this.setState({ filter });
  };

  handleInputKeydown = (event, filteredOptions) => {
    const {
      onChange,
      itemValidationFunction = EMPTY_TRUTHY_FUNCTION,
      addItemFunction,
    } = this.props;
    const { showSearch, filter } = this.state;

    if (showSearch && event.keyCode === 13 && filteredOptions.length) {
      // Handling to add a new item
      if (filteredOptions[0].id === -2 && addItemFunction) {
        if (itemValidationFunction(filter)) {
          this.handleAddItem(filter);
        }
        event.preventDefault();
      } else {
        onChange(filteredOptions[0].id);
        this.setState({ displayDropdown: false, filter: "" });
      }
    }
  };

  handleAddItem = itemName => {
    const { addItemFunction } = this.props;
    addItemFunction(itemName);
    this.collapseDropdown();
  };

  clearInput = () => {
    this.setState({ filter: "" });
  };

  render() {
    const {
      addItemFunction,
      border = false,
      dark = false,
      large = false,
      dataTour,
      disabled = false,
      maxDropdownHeight = 420,
      topRowStyle = EMPTY_OBJECT,
      searchRowStyle = EMPTY_OBJECT,
      listStyle = EMPTY_OBJECT,
      rowStyle = EMPTY_OBJECT,
      displayIconStyle = EMPTY_OBJECT,
      placeholderSearch = "Type to search",
      itemValidationFunction = EMPTY_TRUTHY_FUNCTION,
      itemName = "Item",
      inputMaxLength = null,
      options: originalOptions,
      selectedOption,
      includeBlankOption = false,
      blankOption,
      error = false,
      renderFooter,
      renderMultiFooter,
      wideDropdown,
      placeholderText,
      showCheckPoints,
    } = this.props;
    const {
      filter,
      displayDropdown,
      showSearch,
      extendedItem,
      visibleMetadataId,
    } = this.state;

    const options = [...originalOptions];

    const isMulti = options.length && options[0].data;

    if (includeBlankOption || blankOption) {
      if (
        options.length === 0 ||
        (options.length > 0 && options[0].id !== -1)
      ) {
        options.unshift(blankOption || { id: -1, name: "---" });
      }
    }

    let displayedOption =
      options.find(option => option.id === selectedOption) || options[0];

    if (isMulti) {
      // iterate through nested data options to find the selected
      options.forEach(option => {
        const selectedMultiItem = option.data.find(
          data => data.id === selectedOption,
        );
        displayedOption = selectedMultiItem || displayedOption;
      });
    }
    const filterLowered = filter.toLowerCase();
    const filteredOptions = options.filter(
      opt =>
        (opt.name && opt.name.toLowerCase().includes(filterLowered)) ||
        (opt.preText && opt.preText.toLowerCase().includes(filterLowered)) ||
        (opt.metadata &&
          opt.metadata.length > 0 &&
          opt.metadata
            .map(ei => ei.value.toLowerCase())
            .find(val => val.includes(filterLowered))) ||
        opt.id === LOAD_MORE_SALES,
    );

    const rowHeight = large ? 48 : 36;

    // Add custom item when there it's not match and it's allowed to do it
    if (addItemFunction && filteredOptions.length === 0) {
      filteredOptions.push({ id: -2, name: `Add this ${itemName}` });
    }

    // If we're showing extra info for a row, tell the virtualized wrapper
    // which index, and how much extra height the row has.
    const metadataIndex = filteredOptions.findIndex(
      item => item.id === visibleMetadataId,
    );

    // Line height is multiplied by information bits, plus vertical padding.
    const metadataHeight =
      visibleMetadataId && filteredOptions[metadataIndex]
        ? filteredOptions[metadataIndex].metadata.length * expandedLineHeight +
          expandedPadding * 2 +
          rowHeight
        : null;

    let potentialListHeight = filteredOptions.length * rowHeight;
    if (showSearch) {
      potentialListHeight += rowHeight;
    }
    if (renderMultiFooter) {
      potentialListHeight += rowHeight;
    }
    if (metadataHeight) {
      potentialListHeight += metadataHeight - rowHeight;
    }

    let dropdownHeight = 0;
    if (displayDropdown) {
      dropdownHeight = Math.min(potentialListHeight, maxDropdownHeight);
    }

    let optionListHeight = dropdownHeight;
    if (showSearch) {
      optionListHeight -= rowHeight;
    }
    if (renderMultiFooter) {
      optionListHeight -= rowHeight;
    }

    let extraRows = 0;
    if (showSearch) {
      extraRows += 1;
    }
    if (renderMultiFooter) {
      extraRows += 1;
    }
    // Only get the components if not multi level
    const optionComponents = !isMulti
      ? filteredOptions.map(
          ({ id, name, preText, metadata, dataTour = null }) => {
            if (id === -2 && addItemFunction) {
              const isValidated = itemValidationFunction(filter);
              return (
                <ListItem
                  style={{
                    ...rowStyle,
                    color: "#427DB3",
                    fontSize: "14px",
                  }}
                  rowHeight={rowHeight}
                  dark={dark || undefined}
                  large={large || undefined}
                  onClick={() => this.handleAddItem(filter)}
                  key={`option-${id}`}
                  disabled={!isValidated}
                  name={name}
                  dataTour={dataTour || id}
                  preText={preText}
                  metadata={metadata}
                  expanded={visibleMetadataId === id}
                  toggleMetadata={() => {
                    this.toggleMetadata(id);
                  }}
                  itemName={itemName}
                />
              );
            } else if (id === LOAD_MORE_SALES) {
              return (
                <LoadSalesWrapper key={`option-${id}`}>
                  <IconTextButton
                    dataTour="loadMoreSales"
                    icon={faRepeat}
                    iconSize={25}
                    textSize={16}
                    onClick={() => this.handleOptionClick(LOAD_MORE_SALES)}
                    tooltip="We only load 3 months of sales. Click to fetch all."
                  >
                    {name}
                  </IconTextButton>
                </LoadSalesWrapper>
              );
            } else {
              const style = { ...rowStyle };
              if (showCheckPoints) {
                // BAU-1961 Hide this for now
                // const hasChanges = Boolean(
                //   sale.has_compliance_changes || sale.has_commercial_changes,
                // );
                const hasChanges = false;
                style.background = hasChanges ? colors.error : "inherit";
              }

              return (
                <ListItem
                  key={`option-${id}`}
                  style={style}
                  rowHeight={rowHeight}
                  expandedHeight={metadataHeight}
                  dark={dark}
                  large={large}
                  onClick={() => this.handleOptionClick(id)}
                  name={name}
                  dataTour={dataTour || id}
                  preText={preText}
                  metadata={metadata}
                  expanded={visibleMetadataId === id}
                  toggleMetadata={() => this.toggleMetadata(id)}
                />
              );
            }
          },
        )
      : [];

    return (
      <div
        ref={node => this.setRef(node, "wrapperRef")}
        style={{ position: "relative", height: "100%" }}
        id="searchable-selector"
        data-tour={dataTour}
      >
        <TopRow
          dark={dark || disabled || undefined}
          border={border || undefined}
          error={error || undefined}
          large={large || undefined}
          style={{
            ...topRowStyle,
            borderBottom: displayDropdown ? "none" : undefined,
          }}
          onClick={this.handleTopClick}
        >
          <TopRowText data-tour={displayedOption && displayedOption.name}>
            {placeholderText || (displayedOption && displayedOption.name)}{" "}
          </TopRowText>
          <FaIcon icon={faCaretUp} yflip="show" style={displayIconStyle} />
        </TopRow>

        {isMulti
          ? displayDropdown && (
              <MultiDropdownWrapper
                displayDropdown={displayDropdown}
                maxDropdownHeight={maxDropdownHeight}
                border={border || undefined}
              >
                {options
                  .filter(o => o.data.length > 0)
                  .map((o, index) => {
                    let listHeight = Math.min(
                      o.data.length * rowHeight,
                      maxDropdownHeight,
                    );
                    if (listHeight === maxDropdownHeight) {
                      listHeight -= index * rowHeight;
                    }

                    const expanded = extendedItem === o.name;

                    const subListHeight = Math.min(
                      o.data.length * rowHeight,
                      maxDropdownHeight,
                    );
                    const subListStyle = {
                      ...listStyle,
                      height: `${subListHeight}px`,
                    };

                    return (
                      <MultiSelectWrapper key={index} border>
                        <GroupHeader
                          style={{ ...rowStyle, height: rowHeight }}
                          onClick={() => this.handleExtendClick(o.name)}
                          last={options.length - 1 === index}
                          expanded={expanded}
                        >
                          <LimittedWidthTitle>{o.name}</LimittedWidthTitle>
                          <FontAwesomeIcon icon={faCaretRight} />
                        </GroupHeader>
                        {expanded ? (
                          <MultiListContainer>
                            <List
                              listStyle={subListStyle}
                              border={border}
                              options={o.data}
                              optionComponents={o.data.map(data => (
                                <ListItem
                                  dataTour={o.id}
                                  onClick={() =>
                                    this.handleOptionClick(data.id)
                                  }
                                  key={`option-${data.id}`}
                                  style={{
                                    ...rowStyle,
                                    backgroundColor: "#FFF",
                                  }}
                                  rowHeight={rowHeight}
                                  dark={dark}
                                  large={large}
                                  name={data.shortName || data.name}
                                  isMulti
                                />
                              ))}
                              rowStyle={rowStyle}
                              optionListHeight={listHeight}
                              rowHeight={rowHeight}
                              renderFooter={renderFooter}
                              isMulti
                            />
                          </MultiListContainer>
                        ) : null}
                      </MultiSelectWrapper>
                    );
                  })}
                {renderMultiFooter && renderMultiFooter(rowHeight, isMulti)}
              </MultiDropdownWrapper>
            )
          : displayDropdown && (
              <DropdownWrapper
                displayDropdown={displayDropdown}
                dropdownHeight={dropdownHeight}
                rowHeight={rowHeight}
                dark={dark || undefined}
                border={border || undefined}
                error={error || undefined}
                wideDropdown={wideDropdown}
                data-tour="dropDown"
              >
                {showSearch && (
                  <SearchRow
                    dark={dark || undefined}
                    large={large || undefined}
                    style={searchRowStyle}
                    rowHeight={rowHeight}
                  >
                    <input
                      style={{ maxWidth: "100%" }}
                      placeholder={placeholderSearch}
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                      value={filter}
                      onChange={this.handleInputChange}
                      onKeyDown={e =>
                        this.handleInputKeydown(e, filteredOptions)
                      }
                      maxLength={inputMaxLength}
                    />
                    <FaIcon dark={dark || undefined} icon={faSearch} />
                    {filter && (
                      <CloseIcon onClick={this.clearInput}>
                        <FaIcon icon={faTimes} style={{ fontSize: 14 }} />
                      </CloseIcon>
                    )}
                  </SearchRow>
                )}
                <List
                  listStyle={listStyle}
                  border={border}
                  options={options}
                  optionListHeight={optionListHeight}
                  optionComponents={optionComponents}
                  rowHeight={rowHeight}
                  renderFooter={renderFooter}
                  displayDropdown={displayDropdown}
                  expandedHeight={metadataHeight}
                  expandedIndex={metadataIndex}
                  setRef={() => node => {
                    this.setRef(node, "listRef");
                  }}
                />
                <FooterWrapper
                  dropdownHeight={dropdownHeight}
                  rowHeight={rowHeight}
                  extraRows={extraRows}
                >
                  {renderMultiFooter && renderMultiFooter(rowHeight, isMulti)}
                </FooterWrapper>
              </DropdownWrapper>
            )}
      </div>
    );
  }
}

SearchableSelector.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired,
          name: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  addItemFunction: PropTypes.func, // callback for the "Add new PIC"/"Add new item" option at the bottom. not shown if no function passed
  border: PropTypes.bool,
  dark: PropTypes.bool,
  large: PropTypes.bool,
  searchableAfter: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
  maxDropdownHeight: PropTypes.number,
  topRowStyle: PropTypes.object,
  searchRowStyle: PropTypes.object,
  rowStyle: PropTypes.object,
  listStyle: PropTypes.object,
  displayIconStyle: PropTypes.object,
  placeholderSearch: PropTypes.string,
  inputMaxLength: PropTypes.number,
  itemName: PropTypes.string, // the item/option name. to be used in "Add this item" or "This item is invalid". defaults to "item", would be "PIC" for form you're working on
  itemValidationFunction: PropTypes.func, // callback to validate the entered value
  onChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  includeBlankOption: PropTypes.bool,
  blankOption: PropTypes.object,
  error: PropTypes.bool,
  defaultToFirst: PropTypes.bool,
  inputTransformationFunction: PropTypes.func,
  renderFooter: PropTypes.func,
  renderMultiFooter: PropTypes.func,
  disabled: PropTypes.bool,
  placeholderText: PropTypes.string,
  allSalesLoaded: PropTypes.bool,
  loadSales: PropTypes.func,
};

export default SearchableSelector;

const DropdownWrapper = styled.div`
  position: absolute;
  background-color: ${({ dark, border }) =>
    dark ? "#717171" : border ? "#FFF" : "#f9f9f9"};
  z-index: 1101;
  height: ${({ dropdownHeight }) => dropdownHeight || 0}px;
  transition: ${({ theme }) => theme.transitions[0]};
  overflow-y: hidden;
  left: 0;
  right: 0;
  ${({ wideDropdown }) =>
    wideDropdown ? "width: 80vw; max-width: 400px; min-width: 200px;" : ""}
  ${({ border, theme, error }) => {
    if (border) {
      const borderColor = error ? theme.colors.inputError : theme.colors.gray78;
      return `border: 1px solid ${borderColor};`;
    }
  }};
`;

const MultiDropdownWrapper = styled.div`
  position: absolute;
  background-color: transparent;
  ${({ displayDropdown }) =>
    displayDropdown ? "z-index: 100;" : "display: none;"};
  height: ${({ displayDropdown, maxDropdownHeight }) =>
    displayDropdown ? maxDropdownHeight : 0}px;
  transition: ${({ theme }) => theme.transitions[0]};
  left: 0;
  right: 0;
  width: 80vw;
  max-width: 500px;
`;

const TopRow = styled(Row)`
  align-items: center;
  justify-content: space-between;
  position: relative;
  max-width: 40vw;
  height: 100%;
  ${({ large }) => large && "font-size: 18px;"};
  padding: ${({ large }) => (large ? "0 24px 0 36px;" : "0 12px;")};
  ${({ dark }) => dark && "color: #c8c8c8"};
  ${({ border, theme, error }) => {
    if (border) {
      const borderColor = error ? theme.colors.inputError : theme.colors.gray78;
      return `border: 1px solid ${borderColor}; background-color: #fff;`;
    }
  }};
`;

const TopRowText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SearchRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${({ rowHeight }) => rowHeight}px;
  border-bottom: 1px solid ${({ dark }) => (dark ? "#666" : "#f2f2f2")};
  padding: ${({ large }) => (large ? "0 24px 0 36px;" : "0 24px 0 12px;")};
  input {
    flex-grow: 1;
    border: none;
    background-color: transparent;
    color: ${({ dark }) => (dark ? "#c8c8c8" : "inherit")};
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: ${({ dark }) => (dark ? "#bababa" : "#ccc")};
    }
  }
`;

const FaIcon = styled(FontAwesomeIcon)`
  transition: ${({ theme }) => theme.transitions[0]};
  transform: scale(1, ${({ yflip }) => (yflip ? -1 : 1)});
  ${({ dark }) => dark && "color: #bababa;"};
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 9px;
  right: 48px;
  cursor: pointer;
`;

const MultiSelectWrapper = styled.div`
  position: relative;
  width: 80vw;
  max-width: 500px;
`;

const MultiListContainer = styled.div`
  position: absolute;
  width: 40vw;
  top: 0;
  right: 0;
  z-index: 1000;
  max-width: 200px;
`;

const GroupHeader = styled.div`
  width: 40vw;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: ${({ theme }) => ` 1px solid ${theme.colors.gray78}`};
  ${({ last }) => (last ? "border-top: none;" : "")}
  padding: 0 ${({ theme }) => theme.space[2]}px;
  background-color: ${({ expanded, theme }) =>
    expanded ? theme.colors.gray95 : theme.colors.white};
`;

const FooterWrapper = styled.div`
  margin-top: ${({ dropdownHeight, rowHeight, extraRows }) =>
    extraRows ? dropdownHeight - extraRows * rowHeight : dropdownHeight}px;
  transition: ${({ theme }) => theme.transitions[0]};
`;

const LimittedWidthTitle = styled.span`
  max-width: 80%;
  overflow: hidden;
`;
