import React, { useMemo } from "react";

import { sortBy } from "lodash";
import { intersection } from "lodash/array";
import { useDispatch, useSelector } from "react-redux";

import { updateFileBuyerMapping, updateFileVendorMapping } from "actions";

import { AutoCompleteV2 } from "components/AgGrid/AutoComplete";
import {
  filterResults,
  textToSearchTokens,
} from "components/BusinessPICSelector/Search";
import { BaseTable } from "components/Importer/BaseTable";

import { valueExistsRenderer } from "lib/agGrid/renderers";

import { getBusinesses, getKeywordLookups } from "selectors";

import { getRowId, onCellEditRequestFactory } from "./genericImport";

function businessImportAutoCompleteV2CellEditorParams(params) {
  const options = params.context.businesses || {};
  const keywordLookup = params.context.businessKeywordLookup;
  return {
    filterOptions: (values, filterState) => {
      const searchTokens = textToSearchTokens(filterState.inputValue);
      const matches = Object.values(
        filterResults(searchTokens, keywordLookup, {}),
      ).map(result => result.business.id);

      return intersection(matches, values);
    },
    values: sortBy(Object.values(options), "name").map(option => option.id),
    isClearable: true,
  };
}

function businessNameFormatter(params) {
  const options = params.context.businesses || {};
  return (
    Object.values(options).find(option => {
      return option.id === params.value;
    })?.name || ""
  );
}

const ColumnId = {
  FileVendorId: "0771171c-519d-4103-97d1-377a8d061715",
  FileVendorIsMapped: "61832dae-03b4-4cb3-99ec-f6ef7c775d6f",
  FileBuyerId: "07e717c0-6972-476f-8764-935c2fd203fe",
  FileBuyerIsMapped: "731f0123-40cd-461a-a6fe-074672db85f3",
};

export const VendorBusinessColumns = [
  {
    headerName: "Business in File",
    children: [
      {
        colId: ColumnId.FileVendorIsMapped,
        headerName: "Is Mapped",
        field: "fileData.vendorId",
        cellRenderer: valueExistsRenderer,
        sortable: true,
      },
      { headerName: "Short Code", field: "fileData.shortCode", sortable: true },
      {
        headerName: "Vendor Name",
        field: "fileData.vendorName",
        sortable: true,
      },
    ],
  },
  {
    headerName: "Business in AgriNous",
    children: [
      {
        cellEditor: AutoCompleteV2,
        cellEditorParams: businessImportAutoCompleteV2CellEditorParams,
        colId: ColumnId.FileVendorId,
        editable: true,
        field: "fileData.vendorId",
        headerName: "Name",
        valueFormatter: businessNameFormatter,
      },
      {
        headerName: "Agent Reference",
        field: "agrinousBusiness.shortCode",
      },
      {
        headerName: "Saleyard Reference",
        field: "agrinousBusiness.shortCodeSaleyard",
      },
    ],
  },
];

const initialVendorTableColumnState = {
  state: [{ colId: ColumnId.FileVendorIsMapped, sort: "asc" }],
  applyOrder: true,
};

function onVendorBusinessGridReady(params) {
  const { columnApi } = params;
  columnApi.applyColumnState(initialVendorTableColumnState);
}

export function MapVendorTable({ importName, type }) {
  const vendors = useSelector(state => state.importers?.[importName].vendors);
  const businesses = useSelector(getBusinesses);
  const businessKeywordLookup = useSelector(getKeywordLookups).businesses;
  const dispatch = useDispatch();

  const data = useMemo(
    () =>
      vendors.map(fileData => ({
        fileData,
        agrinousBusiness: businesses[fileData.vendorId],
      })),
    [vendors, businesses],
  );
  return (
    <BaseTable
      columns={VendorBusinessColumns}
      data={data}
      context={{ businesses, businessKeywordLookup }}
      getRowId={getRowId}
      onCellEditRequest={onCellEditRequestFactory(
        ColumnId.FileVendorId,
        updateFileVendorMapping,
        "vendorId",
        dispatch,
        type,
      )}
      readOnlyEdit
      onGridReady={onVendorBusinessGridReady}
      defaultColDefOverride={{}}
    />
  );
}

export const BuyerBusinessColumns = [
  {
    headerName: "Business in File",
    children: [
      {
        colId: ColumnId.FileBuyerIsMapped,
        headerName: "Is Mapped",
        field: "fileData.buyerId",
        cellRenderer: valueExistsRenderer,
        sortable: true,
      },
      { headerName: "Short Code", field: "fileData.shortCode", sortable: true },
      {
        headerName: "Buyer Name",
        field: "fileData.buyerName",
        sortable: true,
      },
    ],
  },
  {
    headerName: "Business in AgriNous",
    children: [
      {
        cellEditor: AutoCompleteV2,
        cellEditorParams: businessImportAutoCompleteV2CellEditorParams,
        colId: ColumnId.FileBuyerId,
        editable: true,
        field: "fileData.buyerId",
        headerName: "Name",
        valueFormatter: businessNameFormatter,
      },
      {
        headerName: "Agent Reference",
        field: "agrinousBusiness.shortCode",
      },
      {
        headerName: "Saleyard Reference",
        field: "agrinousBusiness.shortCodeSaleyard",
      },
    ],
  },
];

const initialBuyerTableColumnState = {
  state: [{ colId: ColumnId.FileBuyerIsMapped, sort: "asc" }],
  applyOrder: true,
};

function onBuyerBusinessGridReady(params) {
  const { columnApi } = params;
  columnApi.applyColumnState(initialBuyerTableColumnState);
}

export function MapBuyerTable({ importName, type }) {
  const buyers = useSelector(state => state.importers?.[importName].buyers);
  const businesses = useSelector(getBusinesses);
  const businessKeywordLookup = useSelector(getKeywordLookups).businesses;
  const dispatch = useDispatch();

  const data = useMemo(
    () =>
      buyers.map(fileData => ({
        fileData,
        agrinousBusiness: businesses[fileData.buyerId],
      })),
    [buyers, businesses],
  );
  return (
    <BaseTable
      columns={BuyerBusinessColumns}
      data={data}
      context={{ businesses, businessKeywordLookup }}
      getRowId={getRowId}
      immutableData
      onCellEditRequest={onCellEditRequestFactory(
        ColumnId.FileBuyerId,
        updateFileBuyerMapping,
        "buyerId",
        dispatch,
        type,
      )}
      readOnlyEdit
      onGridReady={onBuyerBusinessGridReady}
      defaultColDefOverride={{}}
    />
  );
}
