import { startCase } from "lodash";
import { createSelector } from "reselect";

import { Species } from "constants/species";

import { resolveValue } from "lib/mlaSupplementaryData";
import { isPrimeSaleSubtype, isStoreSaleSubtype } from "lib/sale";

import { getCurrentSpeciesId } from "./currentSale";
import { getMLASupplementaryData, getSaleLots, getSpecies } from "./root";
import { getCurrentSaleSubType } from "./saleSubTypes";

const requiredMLAFields = (speciesId, saleSubType) => {
  const requiredFields = ["breedId", "ageId", "sexId"];
  if (speciesId === Species.CATTLE) {
    requiredFields.push("muscleScore", "fatScore");
    if (isPrimeSaleSubtype(saleSubType)) {
      requiredFields.push("dressingRangeId");
    }
    if (isStoreSaleSubtype(saleSubType)) {
      requiredFields.push("estCarcassWeight");
    }
  } else if (speciesId === Species.SHEEP) {
    requiredFields.push("joinedStart", "joinedEnd");
    if (isPrimeSaleSubtype(saleSubType)) {
      requiredFields.push("shearing");
    }
    if (isStoreSaleSubtype(saleSubType)) {
      requiredFields.push("frameStructure");
    }
  }
  return requiredFields;
};

export const getRequiredMLAFieldsBySubTypeAndSpecies = createSelector(
  [getCurrentSaleSubType, getCurrentSpeciesId, getSpecies],
  (currentSaleSubType, currentSpeciesId, speciesLookup) => {
    const species = speciesLookup[currentSpeciesId] || {};
    return requiredMLAFields(species.id, currentSaleSubType);
  },
);

const transformSaleLotIntoEmptyMLAObject = saleLot => ({
  id: saleLot.id,
  created: saleLot.created,
  lastModified: saleLot.last_modified,
  ageId: saleLot.age_id,
  breedId: saleLot.breed_id,
  sexId: saleLot.sex_id,
  quantity: saleLot.quantity,
  auctionPenId: saleLot.auction_pen_id,
  dressingRangeId: saleLot.dressing_range_id,
  estimatedAverageWeightId: saleLot.estimated_average_weight_id,
  estimatedAverageMassGrams: saleLot.estimated_average_mass_grams,
  totalMassGrams: saleLot.total_mass_grams,
  totalPriceCents: saleLot.total_price_cents,
  pricingTypeId: saleLot.pricing_type_id,
  draftingAttributes: saleLot.draftingAttributes,
});

const generateMLAWarnings = (suppData, requiredFields) => {
  return requiredFields.reduce((warnings, field) => {
    const value = resolveValue(suppData, field, "");
    if (!value) {
      warnings.push(startCase(field));
    }
    return warnings;
  }, []);
};

export const selectMLAFieldWarningsBySaleLotId = createSelector(
  [
    getMLASupplementaryData,
    getRequiredMLAFieldsBySubTypeAndSpecies,
    getSaleLots,
  ],
  (mlaSupplementaryData, requiredMLAFields, saleLots) => {
    const warningsBySaleLotId = Object.entries(saleLots).reduce(
      (acc, [saleLotId, saleLot]) => {
        const suppData = mlaSupplementaryData[saleLotId] || {
          saleLot: transformSaleLotIntoEmptyMLAObject(saleLot),
        };
        acc[saleLotId] = generateMLAWarnings(suppData, requiredMLAFields);
        return acc;
      },
      {},
    );

    warningsBySaleLotId.null = requiredMLAFields.map(field => startCase(field));
    return warningsBySaleLotId;
  },
);
