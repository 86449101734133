import { getIn } from "formik";
import { withNamespaces } from "components/Form/FormikControls/lib";

export const EXCLUDE_FROM_UPDATE_COUNT = "EXCLUDE_FROM_UPDATE_COUNT";

export function resolveValue(
  mlaDataModel: any,
  fieldName: string,
  emptyValue: any,
): string {
  // If the mla data model doesn't exist we should return nothing early
  if (!mlaDataModel) {
    return emptyValue;
  }
  // Simple - root of MLA to root of sale lot.
  if (["ageId", "breedId", "sexId"].includes(fieldName)) {
    return (
      mlaDataModel[fieldName] ||
      getIn(mlaDataModel, withNamespaces(["saleLot"], fieldName))
    );
  }

  // A  little less simple - root of mla to draft attributes on sale lot.
  if (
    [
      "shearing",
      "shearingStatus",
      "frameStructure",
      "joinedStart",
      "joinedEnd",
      "estCarcassWeight",
    ].includes(fieldName)
  ) {
    return (
      mlaDataModel[fieldName] ||
      getIn(
        mlaDataModel,
        withNamespaces(["saleLot", "draftingAttributes"], fieldName),
      )
    );
  }

  // Muscle and fat scores have multiple fields - find the most common.
  if (["muscleScore", "fatScore"].includes(fieldName)) {
    const mlaScore = mlaDataModel[fieldName];
    if (mlaScore) {
      // We have an override - that's our value.
      return mlaDataModel[fieldName];
    }
    // Find the most common value underneath - they're stored as, eg,
    // fatScore1: number of animals with a fat score of 1
    // fatScore2 - number of animals with a fat score of 2
    // ...
    const resolvedValue = [1, 2, 3, 4, 5, 6].reduce(
      ([score, highestFieldNumber], fieldNumber) => {
        const saleLotValue = getIn(
          mlaDataModel,
          withNamespaces(
            ["saleLot", "draftingAttributes"],
            `${fieldName}${fieldNumber}`,
          ),
        );
        if (score === null || (saleLotValue && saleLotValue > score)) {
          return [fieldNumber, saleLotValue];
        }
        return [highestFieldNumber, fieldNumber];
      },
      [null, null],
    );
    if (resolvedValue[0]) {
      return resolvedValue[0];
    }
    return null;
  }

  // Dressing range an underlying check
  // The MLA value and the Sale Lot root entries are an ID referencing a value.
  // the draftingAttributes value is a VALUE.
  // In the feed, if a specific value has been entered for the attribute, that gets sent.
  // So we will NOT be overriding/setting it.
  // Hence - this value does not count toward a value you will be updating!
  // Return the SELECTED value to result in not saying it will be updated.

  if (fieldName === "dressingRangeId") {
    const draftingAttributesValue = getIn(
      mlaDataModel,
      withNamespaces(["saleLot", "draftingAttributes"], "dressingPercent"),
    );
    if (draftingAttributesValue) {
      return EXCLUDE_FROM_UPDATE_COUNT;
    }
    return (
      getIn(mlaDataModel, fieldName) ||
      getIn(mlaDataModel, withNamespaces(["saleLot"], fieldName))
    );
  }

  // Estimated Live Weight may fall upward to different values
  // It also has an underlying relevance - we may have an ACTUAL live weight!
  // The data feed will send ....
  // The actual live weight *
  // estimated_average_mass_grams *
  // min_mass_grams / max_mass_grams *
  // The MLA data
  // The Sale Lot's estimated weight.
  //
  // In the specific (*) values - this is better data than a broad sweep of MLA overrides
  // so we will not be "overwriting" the data.
  // In the last two instance (MLA data, Sale Lots estimated) we revert to the same considerations as other fields
  if (fieldName === "estimatedAverageWeightId") {
    // Do any of the * values exist?
    if (
      getIn(mlaDataModel, withNamespaces(["saleLot"], "totalMassGrams")) ||
      getIn(
        mlaDataModel,
        withNamespaces(["saleLot"], "estimatedAverageMassGrams"),
      ) ||
      (getIn(
        mlaDataModel,
        withNamespaces(["saleLot", "draftingAttributes"], "maxMassGrams"),
      ) &&
        getIn(
          mlaDataModel,
          withNamespaces(["saleLot", "draftingAttributes"], "maxMassGrams"),
        ))
    ) {
      return EXCLUDE_FROM_UPDATE_COUNT;
    }
    // Fallback to flat comparing mla to sale lot data.
    return (
      mlaDataModel[fieldName] ||
      getIn(mlaDataModel, withNamespaces(["saleLot"], fieldName))
    );
  }

  if (fieldName === "dressingRangeId") {
    const draftingAttributesValue = getIn(
      mlaDataModel,
      withNamespaces(["saleLot", "draftingAttributes"], "dressingPercent"),
    );
    if (draftingAttributesValue) {
      return EXCLUDE_FROM_UPDATE_COUNT;
    }
    //
    return (
      getIn(mlaDataModel, fieldName) ||
      getIn(mlaDataModel, withNamespaces(["saleLot"], fieldName))
    );
  }

  if (["totalPriceCents", "pricingTypeId"].includes(fieldName)) {
    return getIn(mlaDataModel, withNamespaces(["saleLot"], fieldName));
  }

  // This could be a raise - it's an unknown field.

  return emptyValue;
}
