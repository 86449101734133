import React from "react";

import { faSync } from "@fortawesome/free-solid-svg-icons";
import {
  faCopy,
  faFileEdit,
  faSignOut,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import {
  closeConfirmModal,
  MasterRuleBookAction,
  openConfirmModal,
  openDispatchingModal,
  RuleAction,
  RuleBookAction,
} from "actions";

import { ColumnType } from "components/AgGrid/constants";
import { MultiButton, SlimButton } from "components/Button";

import { ACTION_COLUMN_ID, ACTION_COLUMN_NAME } from "constants/aggrid";
import {
  LedgerAccountColId,
  LedgerAccountColumnDef,
} from "constants/columnDefinitions/ledgerAccount";
import { MasterRuleColumnDefinitions } from "constants/columnDefinitions/masterRule";
import { RuleColumnDefinitions } from "constants/columnDefinitions/rule";
import { ModalTypes } from "constants/navigation";
import { RuleBookPermissions } from "constants/permissions";

import { createNamespacedColumnDefinitions, getters } from "lib/agGrid";
import { valueExistsRenderer } from "lib/agGrid/renderers";
import { RuleSyncStatusRenderer } from "lib/agGrid/renderers/statusRenderer";
import { openModalLink } from "lib/navigation";
import { hasPermission } from "lib/permissions";
import { pluralize } from "lib/pluralize";

import {
  getIsStaff,
  getRuleBooks,
  selectMasterRulesByMasterRuleBookIdLookup,
  selectRuleBookIdByRuleIdLookup,
  selectRulesByRuleBookIdLookup,
} from "selectors";

const { [LedgerAccountColId.ACTIONS]: _ignored, ...LedgerAccountColumns } =
  LedgerAccountColumnDef;

export const ruleColumnDefs = [
  {
    headerName: "Rule",
    children: Object.values(RuleColumnDefinitions),
  },
  {
    headerName: "Master Rule",
    children: Object.values(MasterRuleColumnDefinitions),
  },
  {
    headerName: "Ledger Account",
    children: Object.values(
      createNamespacedColumnDefinitions(LedgerAccountColumns, "ledgerAccount"),
    ),
  },
  {
    headerName: "Status",
    colId: "masterRuleStatus",
    field: "masterRuleStatus",
    cellRenderer: RuleSyncStatusRenderer,
    tooltipValueGetter: getters.ruleSyncTooltipGetter,
  },
  {
    headerName: "Customised",
    colId: "isCustomised",
    field: "isCustomised",
    cellRenderer: valueExistsRenderer,
  },

  {
    colId: ACTION_COLUMN_ID,
    field: "rule.id",
    headerName: ACTION_COLUMN_NAME,
    width: 160,
    type: ColumnType.STRING,
    cellRenderer: RuleActions,
    pinned: "right",
  },
];

function RuleActions({ data }) {
  const dispatch = useDispatch();

  const isStaff = useSelector(getIsStaff);
  const ruleBookIdByRuleIdLookup = useSelector(selectRuleBookIdByRuleIdLookup);
  const ruleBookByIdLookup = useSelector(getRuleBooks);
  const masterRulesByMasterRuleBookIdLookup = useSelector(
    selectMasterRulesByMasterRuleBookIdLookup,
  );
  const rulesByRuleBookId = useSelector(selectRulesByRuleBookIdLookup);

  if (!data) {
    return null;
  }
  const { rule = {}, masterRule = {}, masterRuleBook } = data;

  const ruleId = rule.id;

  const ruleBookId = ruleBookIdByRuleIdLookup[ruleId];

  const ruleBook = ruleBookByIdLookup[ruleBookId];

  const readOnly = !hasPermission(ruleBook, RuleBookPermissions.update);

  const rules = rulesByRuleBookId[ruleBookId] || [];

  const masterRules =
    masterRulesByMasterRuleBookIdLookup[masterRule.rule_book_id] || [];

  // the number of other rules in this rule book, which are from the same master rule book as the selected rule
  const ruleBookManagedRuleCount =
    rules.filter(rule =>
      masterRules.find(masterRule => masterRule.id === rule.master_rule_id),
    ).length || 0;

  const isManagedRule = Boolean(data.rule.master_rule_id);

  const masterRuleBookName = masterRuleBook?.name;

  function onClickEditMasterRule() {
    openModalLink(ModalTypes.EditMasterRule, {
      masterRuleId: data.masterRule.id,
    });
  }

  function onClickEdit() {
    dispatch(
      openDispatchingModal(
        ModalTypes.EditRule,
        window.location.hash,
        { ruleId },
        { ruleId },
      ),
    );
  }

  function onClickCopy() {
    dispatch(RuleAction.copy(ruleId));
  }

  function onClickConfirmDelete() {
    dispatch(RuleAction.delete(ruleId));
    dispatch(closeConfirmModal());
  }

  function onClickCancelDelete() {
    dispatch(closeConfirmModal());
  }

  function onClickDelete() {
    dispatch(
      openConfirmModal({
        title: "Are you sure?",
        message: "Are you sure you want to delete the Rule?",
        actions: [
          {
            label: "Cancel",
            onClick: onClickCancelDelete,
            secondary: true,
          },
          {
            label: "Delete Rule",
            onClick: onClickConfirmDelete,
            secondary: false,
          },
        ],
      }),
    );
  }

  function onClickConfirmUnsubscribe() {
    dispatch(
      RuleBookAction.unsubscribeFromMasterRuleBook(
        rule.rule_book_id,
        masterRule.rule_book_id,
      ),
    );

    dispatch(closeConfirmModal());
  }

  function onClickCancelUnsubscribe() {
    dispatch(closeConfirmModal());
  }

  function onClickUnsubscribe() {
    dispatch(
      openConfirmModal({
        title: "Are you sure?",
        message: `Are you sure you want to remove this and ${ruleBookManagedRuleCount} other ${pluralize(
          "rule",
          ruleBookManagedRuleCount,
        )} from the "${masterRuleBookName}" Master Rule Book?`,
        actions: [
          {
            label: "Cancel",
            onClick: onClickCancelUnsubscribe,
            secondary: true,
          },
          {
            label: "Unsubscribe from Master Rule Book",
            onClick: onClickConfirmUnsubscribe,
            secondary: false,
          },
        ],
      }),
    );
  }

  function onClickUpdateRulebook() {
    dispatch(
      MasterRuleBookAction.updateManagedRule(
        masterRule.rule_book_id,
        rule.rule_book_id,
        rule.id,
      ),
    );
  }

  const buttons = [
    {
      dataTour: `edit:${rule.name}`,
      default: true,
      icon: faFileEdit,
      isDisabled: readOnly,
      onClick: onClickEdit,
      title: isManagedRule ? "Customise" : "Edit",
    },
    {
      dataTour: "copyRule",
      default: false,
      icon: faCopy,
      isDisabled: readOnly || isManagedRule,
      onClick: onClickCopy,
      title: "Copy",
    },
    {
      dataTour: "deleteRule",
      default: false,
      icon: faTrash,
      isDisabled: readOnly || isManagedRule,
      onClick: onClickDelete,
      title: "Delete",
    },
    {
      dataTour: "unsubscribe",
      default: false,
      icon: faSignOut,
      isDisabled: readOnly || !isManagedRule,
      onClick: onClickUnsubscribe,
      title: "Unsubscribe",
    },
    {
      dataTour: "updateRuleBook",
      default: false,
      icon: faSync,
      isDisabled: readOnly || !isManagedRule,
      onClick: onClickUpdateRulebook,
      title: "Update",
    },
    masterRule &&
      isStaff && {
        dataTour: "editMasterRule",
        default: false,
        icon: faFileEdit,
        isDisabled: readOnly || !isManagedRule,
        onClick: onClickEditMasterRule,
        title: `Edit Master Rule`,
      },
  ].filter(Boolean);

  return <MultiButton buttons={buttons} ButtonComponent={SlimButton} />;
}
