import React, { useMemo } from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik, getIn, useFormikContext } from "formik";
import { isNil } from "lodash";
import omitBy from "lodash/omitBy";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import {
  ManualAdjustmentAction,
  openDispatchingModal,
  setModalContext,
} from "actions";

import AuditLogLink from "components/AuditLog/AuditLogLink";
import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { Button, DeleteButton, SecondaryButton } from "components/Form";
import {
  BusinessFieldWithFilter,
  ManualChargeLabelSelectorField,
} from "components/Form/Fields";
import {
  CheckBox,
  Input,
  SelectField,
  TextArea,
} from "components/Form/FormikControls";
import { HelpText } from "components/Form/FormikControls/HelpText";
import { Row } from "components/Layout";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";
import { SundryPricingForm } from "components/Sundry/Form/SundryPricingForm";
import { SmallText } from "components/Text";

import { AuditLogTypes } from "constants/auditLog";
import {
  BaseTaxTypeOptions,
  GstMethod,
  PricingMethod,
} from "constants/billing";
import { BUYER, IN_SALE, VENDOR } from "constants/businesses";
import { BusinessModalSection, ModalTypes } from "constants/navigation";
import {
  MANUAL_TEMPLATE_ID,
  SundryPartyType,
  SundryPartyTypeLookup,
  SundryTemplateLabel,
} from "constants/sundryTemplates";

import { EMPTY_OBJECT, shallowObjectChanges } from "lib";

import { getLivestockSaleId } from "lib/navigation";
import { getSundryLabelByTemplateAndValue } from "lib/sundrys";

import {
  getActiveLivestockAgentDeployment,
  getBusinessById,
  getContextByModalType,
  getIsLivestockAgent,
  getManualAdjustments,
  getShouldChargeGSTByBusinessId,
  selectActiveLivestockAgentDeploymentMasterBusinessId,
  selectMyLedgerAccountOptionsForLedgerEntryForm,
} from "selectors";

import {
  getSundryTemplateById,
  selectSundryTemplateOptions,
} from "selectors/sundryTemplate";

import { useBoolean } from "hooks";

const validationSchema = Yup.object().shape({
  sundryTemplateId: Yup.string().required("Required!"),
  toBusinessId: Yup.string()
    .nullable()
    .required("Required")
    .test("valid", "Required", val => val !== "-1"),
  fromBusinessId: Yup.string()
    .nullable()
    .required("Required")
    .test("valid", "Required", val => val !== "-1"),
  note: Yup.string().required("Required"),
  glCode: Yup.string().when("sundryTemplateId", {
    is: value => value !== MANUAL_TEMPLATE_ID.toString(),
    then: schema => schema.required("Required!"),
    otherwise: schema => schema.nullable(),
  }),
  taxType: Yup.string().when("sundryTemplateId", {
    is: value => value !== MANUAL_TEMPLATE_ID.toString(),
    then: schema => schema.required("Required!"),
    otherwise: schema => schema.nullable(),
  }),
  taxAmount: Yup.string().nullable().required("Required"),
  totalInc: Yup.string().nullable().required("Required"),
  subtotal: Yup.string().nullable().required("Required"),
});

const isGstApplicableMap = {
  true: "Applicable",
  false: "Not Applicable",
  undefined: "Unknown",
  null: "Unknown",
};

function SundryForm(props) {
  const { id, onClose, readOnlyFields = [], namespace: ns = "" } = props;
  const isEdit = Boolean(id);
  const dispatch = useDispatch();
  const { values } = useFormikContext();
  const fieldValues = getIn(values, ns);
  const {
    fromBusinessId,
    toBusinessId,
    sundryTemplateId,
    totalInc,
    createAnother,
  } = fieldValues;

  const activeSundryTemplate = useSelector(
    getSundryTemplateById(sundryTemplateId),
  );
  const isManual = sundryTemplateId === MANUAL_TEMPLATE_ID;
  const ledgerAccountOptions = useSelector(
    selectMyLedgerAccountOptionsForLedgerEntryForm,
  );
  const [isShowingConfirmDelete, showConfirmDelete, hideConfirmDelete] =
    useBoolean(false);

  const shouldFromBusinessChargeGST = useSelector(
    getShouldChargeGSTByBusinessId(fromBusinessId),
  );

  const fromBusiness = useSelector(getBusinessById(fromBusinessId));
  const toBusiness = useSelector(getBusinessById(toBusinessId));

  const fromBusinessGSTStatus = isGstApplicableMap[shouldFromBusinessChargeGST];

  const onConfirmDelete = () => {
    id && dispatch(ManualAdjustmentAction.delete(id));
    onClose();
  };

  const returnTo = window.location.hash;

  function openEditFromBusiness() {
    dispatch(setModalContext(ModalTypes.ManualAdjustmentForm, fieldValues));
    dispatch(
      openDispatchingModal(ModalTypes.EditBusiness, returnTo, fieldValues, {
        businessId: fromBusinessId,
        defaultTab: BusinessModalSection.FINANCE_AND_ACCOUNTING,
      }),
    );
  }

  function openEditToBusiness() {
    dispatch(setModalContext(ModalTypes.ManualAdjustmentForm, fieldValues));
    dispatch(
      openDispatchingModal(ModalTypes.EditBusiness, returnTo, fieldValues, {
        businessId: toBusinessId,
        defaultTab: BusinessModalSection.FINANCE_AND_ACCOUNTING,
      }),
    );
  }

  const sundryTemplateOptions = useSelector(selectSundryTemplateOptions);

  const isTemplateInUse = Object.values(SundryTemplateLabel).includes(
    activeSundryTemplate.templateLabel,
  );

  const renderHelpText = Boolean(
    fromBusinessId && toBusinessId && totalInc && isTemplateInUse,
  );

  const fromBusinessLabel = isManual ? "Billing From" : "Paid To";
  const toBusinessLabel = isManual ? "Billing To" : "Paid From";

  const getBusinessFilters = businessFilter => {
    // If the sundry template is filtering on Buyers or Vendors, assume they
    // want to also filter for in sale.
    const businessFilters = [BUYER, VENDOR].includes(businessFilter)
      ? [businessFilter, IN_SALE]
      : [businessFilter];
    return businessFilters.filter(Boolean);
  };

  const defaultToBusinessFilter = getBusinessFilters(
    activeSundryTemplate?.paidFromSearchFilter,
  );

  const defaultFromBusinessFilter = getBusinessFilters(
    activeSundryTemplate?.paidToSearchFilter,
  );

  return (
    <Form>
      <DialogTitle onClose={onClose}>
        {id && (
          <AuditLogLink
            auditLogType={AuditLogTypes.MANUAL_ADJUSTMENT}
            dataId={id}
            returnTo={window.location.hash}
          />
        )}
        &nbsp;{isEdit ? "Edit Sundry" : "Create Sundry"}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectField
              autoFocus
              required
              name="sundryTemplateId"
              options={sundryTemplateOptions}
              label="Type"
              onChangeExtra={sundryTemplateId => {
                const context = {
                  sundryTemplateId,
                  createAnother,
                };

                dispatch(
                  setModalContext(ModalTypes.ManualAdjustmentForm, context),
                );
              }}
              disabled={isEdit}
            />
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={9}>
              <BusinessFieldWithFilter
                label={fromBusinessLabel}
                name="fromBusinessId"
                disabled={readOnlyFields.includes("fromBusinessId")}
                defaultBusinessFilter={defaultFromBusinessFilter}
              />
              <SmallText>GST Status - {fromBusinessGSTStatus}</SmallText>
            </Grid>
            <Grid item xs={3} container alignItems="center">
              <SecondaryButton
                type="button"
                onClick={openEditFromBusiness}
                disabled={!fromBusinessId}
              >
                Edit Business
              </SecondaryButton>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={9}>
              <BusinessFieldWithFilter
                label={toBusinessLabel}
                name="toBusinessId"
                disabled={readOnlyFields.includes("toBusinessId")}
                defaultBusinessFilter={defaultToBusinessFilter}
              />

              <SmallText>&nbsp;</SmallText>
            </Grid>
            <Grid item xs={3} container alignItems="center">
              <SecondaryButton
                type="button"
                onClick={openEditToBusiness}
                disabled={!toBusinessId}
              >
                Edit Business
              </SecondaryButton>
            </Grid>
          </Grid>

          <SundryPricingForm readOnlyFields={readOnlyFields} />
          {renderHelpText && (
            <Grid item xs={12}>
              <HelpText>
                {getSundryLabelByTemplateAndValue(
                  activeSundryTemplate,
                  totalInc,
                  toBusiness?.name,
                  fromBusiness?.name,
                )}
              </HelpText>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextArea
              label="Description"
              name="note"
              readOnly={readOnlyFields.includes("note")}
              tooltip="This will appear on RCTIs and/or Invoices"
              required={isTemplateInUse}
            />
          </Grid>
          {!isManual && (
            <>
              <Grid item xs={6}>
                <SelectField
                  label="GL Code"
                  name="glCode"
                  readOnly={readOnlyFields.includes("glCode")}
                  options={ledgerAccountOptions}
                  menuPortalTarget={document.body}
                  required={isTemplateInUse}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectField
                  label="Tax Type"
                  name="taxType"
                  readOnly={readOnlyFields.includes("taxType")}
                  options={BaseTaxTypeOptions}
                  menuPortalTarget={document.body}
                  required={isTemplateInUse}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Input
              label="External Ref/s"
              name="billingReference"
              maxLength={255}
            />
          </Grid>

          <Grid item xs={6}>
            <ManualChargeLabelSelectorField
              label="Labels"
              name="labels"
              disabled={readOnlyFields.includes("labels")}
            />
          </Grid>
        </Grid>
        {!isEdit && (
          <Row justifyStart alignEnd>
            <CheckBox name="createAnother" label="Add Another On Submit" />
          </Row>
        )}
      </DialogContent>
      <DialogActions>
        {id && (
          <DeleteButton type="button" onClick={showConfirmDelete}>
            Delete
          </DeleteButton>
        )}

        <SecondaryButton type="button" onClick={onClose}>
          Cancel
        </SecondaryButton>
        <Button data-tour="adjust" type="submit">
          Submit
        </Button>
      </DialogActions>
      <ConfirmDialog
        title={createModalTitle("this Sundry")}
        isOpen={isShowingConfirmDelete}
        onCancel={hideConfirmDelete}
        onDelete={onConfirmDelete}
      />
    </Form>
  );
}

export const Modal = ({
  id,
  fromBusinessId,
  toBusinessId,
  labels,
  gstMethod,
  readOnlyFields,
  note = undefined,
  totalAmountCents = undefined,
  onClose,
}) => {
  const defaults = {
    fromBusinessId,
    toBusinessId,
    labels,
    note,
    totalAmountCents,
    gstMethod,
    pricingMethod: PricingMethod.GROSS_PRICE,
  };
  const handleClose = () => {
    dispatch(setModalContext(ModalTypes.ManualAdjustmentForm, null));
    onClose();
  };

  const manualAdjustment =
    useSelector(getManualAdjustments)?.[id] || EMPTY_OBJECT;

  const context =
    useSelector(getContextByModalType(ModalTypes.ManualAdjustmentForm)) || {};

  const activeSundryTemplate = useSelector(
    getSundryTemplateById(
      manualAdjustment.sundryTemplateId || context.sundryTemplateId,
    ),
  );

  const deploymentMasterBusinessId = useSelector(
    selectActiveLivestockAgentDeploymentMasterBusinessId,
  );

  const defaultsFromTemplate = {
    fromBusinessId: activeSundryTemplate.paidToBusinessId, // Billed From
    toBusinessId: activeSundryTemplate.paidFromBusinessId, // Billed To
    glCode: activeSundryTemplate.glCode,
    gstMethod: activeSundryTemplate.gstMethod,
    taxType: activeSundryTemplate.taxType,
    note: activeSundryTemplate.note,
    labels: activeSundryTemplate.labels,
  };

  const { fromType, toType } =
    SundryPartyTypeLookup[activeSundryTemplate?.label] || EMPTY_OBJECT;

  const hardCodedFromTemplate = {};
  if (fromType === SundryPartyType.DEPLOYMENT) {
    hardCodedFromTemplate.toBusinessId = deploymentMasterBusinessId;
  }
  if (toType === SundryPartyType.DEPLOYMENT) {
    hardCodedFromTemplate.fromBusinessId = deploymentMasterBusinessId;
  }

  const sundryTemplateId = useMemo(() => {
    // If we have an active template, use it.
    if (activeSundryTemplate?.id) {
      return activeSundryTemplate.id;
    }
    // If we have some context about manual labels, set to manual
    if (defaults.labels || manualAdjustment.labels) {
      return MANUAL_TEMPLATE_ID;
    }
  }, [activeSundryTemplate.id, defaults.labels, manualAdjustment.labels]);

  const initialValues = {
    // Variable names danced so that we can make use shared common in usePricingFormContext.
    fromBusinessId: null,
    toBusinessId: null,
    taxAmount: manualAdjustment.gstCents,
    subtotal: manualAdjustment.subtotalCents,
    totalInc: manualAdjustment.totalCents,
    gstMethod: GstMethod.GST_INCLUSIVE,
    ...omitBy(defaultsFromTemplate, isNil),
    ...omitBy(defaults, isNil),
    ...manualAdjustment,
    sundryTemplateId,
    ...context,
    ...hardCodedFromTemplate,
  };

  const deploymentId = useSelector(state =>
    getIsLivestockAgent(state)
      ? getActiveLivestockAgentDeployment(state).id
      : null,
  );

  const dispatch = useDispatch();

  const onSubmit = rawValues => {
    // Variable names danced so that we can make use shared common in usePricingFormContext.
    const values = {
      fromBusinessId: rawValues.fromBusinessId,
      toBusinessId: rawValues.toBusinessId,
      note: rawValues.note,
      subtotalCents: rawValues.subtotal,
      gstMethod: rawValues.gstMethod,
      gstCents: rawValues.taxAmount,
      glCode: rawValues.glCode,
      taxType: rawValues.taxType,
      billingReference: rawValues.billingReference,
      sundryTemplateId:
        rawValues.sundryTemplateId === MANUAL_TEMPLATE_ID
          ? null
          : rawValues.sundryTemplateId,
      labels: rawValues.labels,
    };

    const { createAnother } = rawValues;

    if (id) {
      const payload = {
        id,
        ...shallowObjectChanges(values, manualAdjustment),
      };

      dispatch(ManualAdjustmentAction.update(payload));
    } else {
      const payload = {
        ...values,
        id: uuidv4(),
        livestockSaleId: getLivestockSaleId(),
        deploymentId,
      };
      dispatch(ManualAdjustmentAction.create(payload));
    }

    if (createAnother) {
      const newContext = {
        sundryTemplateId: rawValues.sundryTemplateId,
        labels: values.labels,
        key: uuidv4(),
        createAnother: true,
      };
      dispatch(setModalContext(ModalTypes.ManualAdjustmentForm, newContext));
    } else {
      handleClose();
    }
  };

  return (
    <ZoomyDialog open onClose={handleClose}>
      <div key={`${initialValues.sundryTemplateId}-${context.key}`}>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <SundryForm
            id={id}
            readOnlyFields={[
              ...(readOnlyFields || []),
              ...Object.keys(hardCodedFromTemplate),
            ]}
            onClose={handleClose}
          />
        </Formik>
      </div>
    </ZoomyDialog>
  );
};
