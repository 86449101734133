import React from "react";

import { Checkbox, Grid, CheckboxProps, GridSize } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { SlimButton } from "components/Button";
import { Row } from "components/Layout";

import { CheckboxContainerTypes } from "constants/checkboxContainerTypes";

import { useIsExtraSmallMobile } from "hooks";

import styled from "styled-components/macro";
import { useGetAuctionPenCheckBoxValues } from "./hooks/useGetAuctionPenCheckBoxValues";
import {
  CheckBoxComponentContainerHeaderProps,
  CheckBoxComponentContainerProps,
} from "./types";

const CheckBoxContainerValuesMap = {
  [CheckboxContainerTypes.AUCTION_PEN]: useGetAuctionPenCheckBoxValues,
  [CheckboxContainerTypes.MLA]: useGetAuctionPenCheckBoxValues, // This is fine using the auction pen function for now. But will probably change in the future
};

const InfoText = styled.span`
  color: ${({ warning, theme }) => warning && theme.colors.error};
`;

const ShowSelectButton = styled(SlimButton)`
  font-size: 12px;
  font-weight: normal;
  border-radius: 6px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  background: rgba(66, 125, 179, 0.17);
  &:active {
    background: rgba(66, 125, 179, 0.2);
  }
  &:hover:enabled {
    background: rgba(66, 125, 179, 0.2);
  }
`;

const NoRippleMuiCheckbox = (props: CheckboxProps) => {
  const useStyles = makeStyles(() => ({
    root: {
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
  }));

  const classes = useStyles();

  return <Checkbox {...props} disableRipple classes={{ root: classes.root }} />;
};

export const CheckBoxComponentContainer = ({
  showSelect = false,
  checked = false,
  indeterminate = false,
  disabled = false,
  onChange = null,
  checkBoxClassName = "",
  childClassName = "",
  containerClassName = "",
  justifyChildContainer = null,
  info = null,
  warning = null,
  children,
}: CheckBoxComponentContainerProps) => {
  const isExtraSmallMobile: Boolean = useIsExtraSmallMobile();

  let checkboxGridXs: GridSize = 1;
  let infoGridXs: GridSize = null;
  let childGridXs: GridSize = 12;

  if (showSelect) {
    if (isExtraSmallMobile) {
      checkboxGridXs = 2;
      if (info) {
        infoGridXs = 7;
        childGridXs = 3;
      } else {
        childGridXs = 10;
      }
    } else if (info) {
      checkboxGridXs = 1;
      infoGridXs = 8;
      childGridXs = 3;
    } else {
      childGridXs = 11;
    }
  }

  return (
    <Grid item container xs={12} className={containerClassName}>
      {showSelect && (
        <>
          <Grid
            item
            xs={checkboxGridXs}
            container
            justifyContent="center"
            alignItems="center"
            className={checkBoxClassName}
          >
            <Row>
              <NoRippleMuiCheckbox
                color="primary"
                checked={checked}
                indeterminate={indeterminate}
                onChange={onChange}
                disabled={disabled}
              />
            </Row>
          </Grid>
          {info && (
            <Grid
              item
              xs={infoGridXs}
              container
              justifyContent="flex-start"
              alignItems="center"
            >
              <Row className="text-12 items-center">
                <InfoText>{info}</InfoText>&nbsp;
                <InfoText warning>{warning}</InfoText>
              </Row>
            </Grid>
          )}
        </>
      )}
      <Grid
        item
        xs={childGridXs}
        container={!!justifyChildContainer}
        justifyContent={justifyChildContainer}
        alignItems="center"
      >
        <div className={childClassName}>{children}</div>
      </Grid>
    </Grid>
  );
};

export const CheckBoxComponentContainerHeader = ({
  showSelect = false,
  setShowSelect = null,
  containerType = null,
  containerValuesArgs = null,
}: CheckBoxComponentContainerHeaderProps) => {
  const { checked, indeterminate, disabled, onChange, warning, info } =
    CheckBoxContainerValuesMap[containerType](containerValuesArgs) || {};
  return (
    <CheckBoxComponentContainer
      indeterminate={indeterminate}
      checked={checked}
      disabled={disabled}
      showSelect={showSelect}
      onChange={onChange}
      justifyChildContainer="flex-end"
      info={info}
      warning={warning}
      containerClassName="min-h-45"
    >
      <ShowSelectButton onClick={() => setShowSelect(!showSelect)}>
        {showSelect ? "Hide Select" : "Show Select"}
      </ShowSelectButton>
    </CheckBoxComponentContainer>
  );
};
