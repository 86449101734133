import React from "react";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { Clickable } from "components/Button";
import { Column, Row } from "components/Layout";
import { ModalBg } from "components/Modal";

import { EMPTY_OBJECT, EMPTY_OBJECT_FUNCTION } from "lib";

const styles = {
  backgroundModal: {
    backgroundColor: "rgba(102,102,102,0.5)",
  },
  contentModal: {
    height: "auto",
    maxHeight: "100%",
    maxWidth: "552px",
    padding: "12px",
    backgroundColor: "#fff",
    overflowY: "auto",
  },
};

const Title = styled.span`
  color: #4a4a4a;
  font-size: ${({ theme }) => theme.fontSizes.epsilon}px;
  font-weight: bold;
  line-height: 36px;
`;

const Header = styled(Row)`
  background-color: #e7e7e7;
  height: 48px;
  margin: -12px -12px 12px -12px;
  padding: 12px;
  border-bottom: 1px solid #ccc;
`;

const FaBlue = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 12px;
`;

const BackButton = styled(Clickable)`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

export class FormModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction = event => {
    if (event.keyCode === 27) {
      const { closeSelf } = this.props;
      closeSelf();
    }
  };

  render() {
    const {
      closeSelf = EMPTY_OBJECT_FUNCTION,
      title,
      children,
      style = EMPTY_OBJECT,
      HeaderContent,
      headerBackText = "Back",
      contentStyles,
    } = this.props;

    return (
      <ModalBg
        closeSelf={closeSelf}
        backgroundStyle={{
          ...styles.backgroundModal,
          ...style,
        }}
        contentStyle={{ ...styles.contentModal, ...contentStyles }}
      >
        {!!HeaderContent && (
          <Header justifyBetween alignCenter>
            <BackButton onClick={closeSelf}>
              <FaBlue icon={faChevronLeft} />
              {headerBackText}
            </BackButton>
            {HeaderContent()}
          </Header>
        )}
        <Column fullHeight>
          <Row
            justifyBetween
            style={{ marginLeft: 12, marginRight: 12, marginTop: 6 }}
          >
            {title && <Title>{title}</Title>}
            {!HeaderContent && (
              <Clickable onClick={closeSelf}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ fontSize: 20, color: "#4a4a4a" }}
                />
              </Clickable>
            )}
          </Row>
          {children}
        </Column>
      </ModalBg>
    );
  }
}

FormModal.propTypes = {
  closeSelf: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  style: PropTypes.object,
  contentStyles: PropTypes.object,
  HeaderContent: PropTypes.func,
  headerBackText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
