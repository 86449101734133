import React, { useMemo } from "react";

import Grid from "@material-ui/core/Grid";
import { getIn, useFormikContext } from "formik";
import { useSelector } from "react-redux";

import { AdjustWeightPermissionWarning } from "components/ErrorMessage";
import {
  ControlledLifeCycleInput,
  DecimalNumberInputProps,
  Input,
  OptionTogglerField,
  withNamespace,
} from "components/Form/FormikControls";

import { PricingTypes } from "constants/pricingTypes";
import { SaleTypes } from "constants/sale";

import { ForNotClearingSale } from "containers/ForSaleType";

import { formatDecimal } from "lib";

import { getCurrentSaleType, getSaleLotById } from "selectors";

import { useTranslatedSaleTypeText } from "hooks";
import { useBlockChangingWeights } from "hooks/useBlockChangingWeights";

const PricingTypeForm = ({
  namespace: ns,
  quantity,
  pricingTypeId,
  readOnly,
  hideFields = {},
}) => {
  const { values } = useFormikContext();
  const unitPrice = getIn(values, withNamespace(ns, "unit_price"));
  const totalMassGrams = getIn(values, withNamespace(ns, "total_mass_grams"));
  const pricePerHeadSaleTypeText = useTranslatedSaleTypeText("Price per Head");
  const avgHeadSaleTypeText = useTranslatedSaleTypeText("Avg/Head");

  const hiddenFields = useMemo(() => ({ ...hideFields }), [hideFields]);

  const {
    unit_price: hideUnitPrice = false,
    quantity_delivered: hideQuantityDelivered = false,
    total: hideTotal = false,
    average: hideAverage = false,
    total_mass_grams: hideTotalMassGrams = false,
    averageWeight: hideAverageWeight = false,
  } = hiddenFields;

  const originalValues = useSelector(getSaleLotById(values.id)) || {};

  const cannotChangeWeight = useBlockChangingWeights(
    !!originalValues.total_mass_grams,
  );

  let total;
  let average;
  switch (pricingTypeId) {
    case PricingTypes.PER_HEAD:
      total = quantity === null ? "N/A" : formatDecimal(quantity * unitPrice);
      return (
        <>
          {!hideUnitPrice && (
            <Grid xs={12} sm item>
              <Input
                label={pricePerHeadSaleTypeText}
                name="unit_price"
                type="number"
                decimal
                beforeSymbol="$"
                disabled={readOnly}
              />
            </Grid>
          )}

          {!hideQuantityDelivered && (
            <ForNotClearingSale>
              <Grid xs={12} sm item>
                <Input
                  label="Hd Delivered"
                  name="quantity_delivered"
                  type="number"
                  disabled={readOnly}
                />
              </Grid>
            </ForNotClearingSale>
          )}
          {!hideTotal && (
            <Grid xs={12} sm item>
              <Input
                label="Total"
                name="total"
                align="right"
                overrideValue={total}
                beforeSymbol="$"
                disabled
              />
            </Grid>
          )}
        </>
      );
    case PricingTypes.GROSS:
      average = quantity === null ? "N/A" : formatDecimal(unitPrice / quantity);
      return (
        <>
          {!hideUnitPrice && (
            <Grid xs={12} sm item>
              <Input
                label="Price"
                name="unit_price"
                type="number"
                beforeSymbol="$"
                decimal
                disabled={readOnly}
              />
            </Grid>
          )}
          {!hideQuantityDelivered && (
            <ForNotClearingSale>
              <Grid xs={12} sm item>
                <Input
                  label="Hd Delivered"
                  name="quantity_delivered"
                  type="number"
                  disabled={readOnly}
                />
              </Grid>
            </ForNotClearingSale>
          )}
          {!hideAverage && (
            <Grid xs={12} sm item>
              <Input
                label={avgHeadSaleTypeText}
                name="average"
                align="right"
                overrideValue={average}
                beforeSymbol="$"
                disabled
              />
            </Grid>
          )}
        </>
      );
    case PricingTypes.PER_KILO:
      // Convert cents/gram to $/Kg
      total = (+unitPrice * totalMassGrams) / 100 / 1000;
      average = quantity === null ? "N/A" : formatDecimal(total / quantity);
      total = formatDecimal(total);
      const averageWeight =
        quantity === null
          ? "N/A"
          : formatDecimal(totalMassGrams / 1000 / quantity);

      return (
        <>
          {!hideUnitPrice && (
            <Grid xs={12} sm={4} item>
              <Input
                decimal
                label="Cents / kg"
                name="unit_price"
                type="number"
                afterSymbol="¢"
                disabled={readOnly}
              />
            </Grid>
          )}
          {!hideTotalMassGrams && (
            <Grid xs={12} sm={4} item>
              <ControlledLifeCycleInput
                afterSymbol="kg"
                decimal
                decimalPlaces={2}
                disabled={cannotChangeWeight || readOnly}
                fieldDecimalPlaces={0}
                fieldMultiplier={1000}
                inputMode="number"
                label="Weight (kg)"
                multiplier={0.001}
                name="total_mass_grams"
                type="text"
                {...DecimalNumberInputProps}
              />
            </Grid>
          )}
          {!hideAverageWeight && (
            <Grid xs={12} sm={4} item>
              <Input
                label="Average kg"
                name="averageWeight"
                align="right"
                overrideValue={averageWeight}
                afterSymbol="kg"
                disabled
              />
            </Grid>
          )}
          {cannotChangeWeight && (
            <Grid xs={12} container item justifyContent="center">
              <AdjustWeightPermissionWarning />
            </Grid>
          )}
          {!hideQuantityDelivered && (
            <Grid xs={12} sm={4} item>
              <Input
                label="Hd Delivered"
                name="quantity_delivered"
                type="number"
                disabled={readOnly}
              />
            </Grid>
          )}
          {!hideTotal && (
            <Grid xs={6} sm={4} item>
              <Input
                label="Total"
                name="total"
                overrideValue={total}
                align="right"
                beforeSymbol="$"
                disabled
              />
            </Grid>
          )}
          {!hideAverage && (
            <Grid xs={6} sm={4} item>
              <Input
                label="Avg/Head"
                name="average"
                type="number"
                overrideValue={average}
                beforeSymbol="$"
                disabled
              />
            </Grid>
          )}
        </>
      );
    default:
      return null;
  }
};

export function PricingType({
  namespace: ns = null,
  quantity,
  readOnly = false,
  skipTab = false,
  hideFields = {},
}) {
  const { values } = useFormikContext();
  const pricingTypeId = getIn(values, withNamespace(ns, "pricing_type_id"));

  const saleType = useSelector(getCurrentSaleType);

  return (
    <Grid container spacing={1}>
      <Grid xs={12} item>
        <OptionTogglerField
          name="pricing_type_id"
          options={
            saleType === SaleTypes.CLEARING
              ? PricingTypes.forClearingSales()
              : PricingTypes.all()
          }
          disabled={readOnly}
          skipTab={skipTab}
        />
      </Grid>
      <Grid item container spacing={2}>
        <PricingTypeForm
          namespace={ns}
          pricingTypeId={pricingTypeId}
          quantity={quantity || values.quantity}
          readOnly={readOnly}
          hideFields={hideFields}
        />
      </Grid>
    </Grid>
  );
}
