import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { BillingDocumentAction, bulkUpdateBillingDocuments } from "actions";

import { AuditLogTypes } from "constants/auditLog";
import { BillingDocumentStatusName } from "constants/billingDocuments";

import { openAuditLogModal, openEditBusinessModal } from "lib/navigation";

import { getActiveBillingRunId, getIsReadOnlyByBillingRunId } from "selectors";

export const useBillingDocumentAggridGetContextMenuItems = () => {
  const dispatch = useDispatch();

  const setDocumentStatus = useCallback(
    (billingDocumentId, status) => {
      dispatch(
        BillingDocumentAction.update({
          id: billingDocumentId,
          status,
        }),
      );
    },
    [dispatch],
  );
  const activeBillingRunId = useSelector(getActiveBillingRunId);
  const isReadOnly = useSelector(
    getIsReadOnlyByBillingRunId(activeBillingRunId),
  );

  const getContextMenuItems = useCallback(
    params => {
      const contextMenu = Array.isArray(params.defaultItems)
        ? [...params.defaultItems]
        : [];

      const { billingDocument, business } = params.node.data || {};

      if (billingDocument) {
        contextMenu.push({
          name: `Set Status`,
          disabled: isReadOnly,
          subMenu: Object.entries(BillingDocumentStatusName).map(
            ([status, name]) => ({
              disabled: isReadOnly || status === billingDocument.status,
              name,
              action: () => setDocumentStatus(billingDocument.id, status),
            }),
          ),
        });
      }

      const selectedRows = params.api.getSelectedRows();
      contextMenu.push({
        name: `Bulk Set Status (${selectedRows.length})`,
        disabled: isReadOnly || !(selectedRows.length > 1),
        subMenu: Object.entries(BillingDocumentStatusName).map(
          ([status, name]) => ({
            name,
            action: () => {
              dispatch(
                bulkUpdateBillingDocuments(
                  selectedRows.map(row => ({
                    id: row.billingDocument.id,
                    status,
                  })),
                ),
              );
            },
          }),
        ),
      });
      if (business) {
        contextMenu.push({
          name: "Edit Business",
          action: () => openEditBusinessModal(business.id),
        });
      }

      if (billingDocument) {
        contextMenu.push({
          name: "View History",
          action: () =>
            openAuditLogModal(
              AuditLogTypes.BILLING_DOCUMENT,
              billingDocument.id,
            ),
        });
      }

      return contextMenu;
    },
    [dispatch, isReadOnly, setDocumentStatus],
  );
  return getContextMenuItems;
};
