import React, { useMemo } from "react";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { SaleAction } from "actions";

import Header, { HeaderDropdown } from "components/Header";
import { Column, PaddedIcon, Row } from "components/Layout";
import OnlineStatus from "components/OnlineStatus";
import RolePicker from "components/RolePicker";
import { StaffActions } from "components/StaffActions";
import Subtitle from "components/Text/Subtitle";
import { UpdateStatus } from "components/UpdateStatus";

import { LOAD_MORE_SALES } from "constants/sale";
import { Settings } from "constants/settings";
import { appUpdateStatus } from "constants/system";
import { userTypes } from "constants/users";

import { ForHooksOrPaddock, ForLivestockAuction } from "containers/ForSaleType";
import { ForStaff } from "containers/ForUserType";

import { allFieldsEqual } from "lib/compare";

import {
  getAppUpdateStatus,
  getCurrentSale,
  getDashboardFetchingStatus,
  getOnlineStatus,
  getSetting,
  selectIsUserOfType,
  selectSaleOptions,
  selectShowCheckPoints,
} from "selectors";

function RightColumnComponent() {
  const onlineState = useSelector(getOnlineStatus);
  const updateState = useSelector(getAppUpdateStatus);
  const isFetchingDashboardData = useSelector(getDashboardFetchingStatus);

  return (
    <>
      <ForStaff>
        <StaffActions />
      </ForStaff>
      <RolePicker />
      <Column>
        <OnlineStatus
          status={onlineState.status}
          pendingChangesCount={onlineState.changesOutboxSize}
          isFetching={isFetchingDashboardData}
        />
        {![appUpdateStatus.INVISIBLE, appUpdateStatus.UP_TO_DATE].includes(
          updateState,
        ) && <UpdateStatus />}
      </Column>
    </>
  );
}

const SaleSubtitle = styled(Subtitle)(
  ({ theme }) => `
  margin: -${theme.space[2]}px 0 ${theme.space[1]}px ${theme.space[1]}px;
`,
);

const RightColumn = React.memo(RightColumnComponent);

function SaleyardHeader(props) {
  const {
    isMobile,
    onClickHamburgerMenu,
    showSaleSelector,
    newSale,
    goToSale,
    saleId,
  } = props;

  const defaultSaleOptions = useSelector(selectSaleOptions);
  const allSalesLoaded = useSelector(getSetting(Settings.allSalesLoaded));
  const isSaleWatcher = useSelector(
    selectIsUserOfType([userTypes.SALE_WATCHER]),
  );

  const saleOptions = useMemo(() => {
    const options = [...defaultSaleOptions];

    // If not all sales are loaded, and we aren't a salewatcher, add a button/option to load all sales.
    if (
      !options.find(sale => sale.id === LOAD_MORE_SALES) &&
      !isSaleWatcher &&
      !allSalesLoaded
    ) {
      options.push({
        id: LOAD_MORE_SALES,
        name: LOAD_MORE_SALES,
      });
    }

    // If all sales are loaded, remove the option to load all sales...
    if (allSalesLoaded) {
      return options.filter(obj => obj.id !== LOAD_MORE_SALES);
    }

    return options;
  }, [allSalesLoaded, defaultSaleOptions, isSaleWatcher]);

  const livestockSale = useSelector(getCurrentSale);
  const stickySale = useSelector(getSetting(Settings.stickySale));

  const dispatch = useDispatch();

  const onChangeSale = saleId => {
    if (saleId) {
      goToSale(saleId);
    } else {
      dispatch(SaleAction.request({ fetchAll: true }));
    }
  };

  const showCheckPoints = useSelector(selectShowCheckPoints);

  return (
    <Header isMobile={isMobile}>
      {isMobile && (
        <PaddedIcon onClick={onClickHamburgerMenu}>
          <WrapperMenuButton data-tour="menubar" icon={faBars} />
        </PaddedIcon>
      )}
      <FullHeightRow justifyBetween flexGrow alignCenter>
        <IndentedHeaderRow flexGrow isMobile={isMobile}>
          {showSaleSelector && (
            <Column>
              <HeaderDropdown
                dataTour="saleSelector"
                onAddSale={newSale}
                onChange={onChangeSale}
                options={saleOptions}
                selectedOption={parseInt(saleId, 10)}
                isMobile={isMobile}
                wideDropdown
                showCheckPoints={showCheckPoints}
                placeholderText={
                  stickySale && livestockSale
                    ? undefined // Dont show placeholder text if sticky sale and livestock sale exist
                    : "Select A Sale To Work On"
                }
                allSalesLoaded={allSalesLoaded}
                loadSales={() =>
                  dispatch(SaleAction.request({ fetchAll: true }))
                }
              />
              {livestockSale && (
                <>
                  <ForLivestockAuction>
                    <SaleSubtitle data-tour="saleSubTitle">
                      {livestockSale.sale_code}: {livestockSale.sale_type} |{" "}
                      {livestockSale.saleyard_name}
                    </SaleSubtitle>
                  </ForLivestockAuction>

                  <ForHooksOrPaddock>
                    <SaleSubtitle>
                      {livestockSale.sale_code}: {livestockSale.sale_type}
                    </SaleSubtitle>
                  </ForHooksOrPaddock>
                </>
              )}
            </Column>
          )}
        </IndentedHeaderRow>
        <RightColumn />
      </FullHeightRow>
    </Header>
  );
}

export default React.memo(SaleyardHeader, (oldProps, newProps) =>
  allFieldsEqual(oldProps, newProps, [
    "goToSale",
    "isMobile",
    "showSaleSelector",
    "saleId",
    "pendingChangesCount",
    "isFetchingDashboardData",
  ]),
);

const WrapperMenuButton = styled(FontAwesomeIcon)`
  font-size: 24px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`;

const FullHeightRow = styled(Row)`
  height: 100%;
`;

const IndentedHeaderRow = styled(Row)`
  margin: ${({ isMobile }) => `${isMobile ? "inherit" : "0 18px"}`};
`;
