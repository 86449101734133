import React, { useEffect, useMemo } from "react";

import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { Formik, getIn, useFormikContext } from "formik";
import isEmpty from "lodash/isEmpty";
import omitBy from "lodash/omitBy";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import { setSetting, updateConsignmentMega } from "actions";

import { suggestedValueMap } from "components/AttachmentForm/constants";
import { MultiButton, SlimButton } from "components/Button";
import { AddPIC, BottomSticky, Button, SecondaryButton } from "components/Form";
import { PICField } from "components/Form/Fields/PICField";
import { Input, SelectField } from "components/Form/FormikControls";
import { BodyArea, ErrorArea } from "components/Form/Grid";
import { Row } from "components/Layout";
import { SmallText } from "components/Text";

import {
  DeclarationTypes,
  EXPIRED_DECLARATION_VERSIONS,
} from "constants/documentTypes";
import { ConsignmentModalSection } from "constants/navigation";
import { SaleTypes } from "constants/sale";
import { Settings } from "constants/settings";
import { Species } from "constants/species";
import { colors } from "constants/theme";

import { ForSaleyardAdmin } from "containers/ForUserType";

import { consignmentOptionFilter } from "lib/filters";
import {
  getEditConsignmentHashRoute,
  openEditConsignmentModal,
} from "lib/navigation";
import { NVDValidator, saleyardAuctionNVDValidator } from "lib/validators";

import {
  getAgencyById,
  getAgencyIdByDeploymentSaleId,
  getBusinesses,
  getConsignmentById,
  getConsignmentOptionsByDeploymentSaleId,
  getConsignments,
  getCurrentSale,
  getProperties,
  getSelectableBranches,
  selectHasNvdUploadByConsignmentIdLookup,
  selectLastAddedConsignment,
  selectPropertyIdByPICLookup,
} from "selectors";

import {
  useHasAddAttachmentWithoutImagePermission,
  useMountEffect,
} from "hooks";

import {
  AddButton,
  CornerCloseButton,
  EditButton,
  FullWidthGridRow,
  GridRow,
  QuestionnaireRow,
  StyledForm,
} from "./Components";
import { NVD_OPTIONS } from "./NVDOptionToggles";
import {
  BC0720,
  C0413,
  C0720,
  E0413,
  E0720,
  G0720,
  S0413,
  S0720,
  G0524,
} from "./NVDVersionForms";
import { declarationVersions } from "./speciesDeclarations";

const defaultSheepDeclaration = {
  fed_feed_containing_animal_fats: false,
  owned_since_birth: undefined,
  owned_since_birth_duration: undefined,
  qa_accredited: false,
  treated_for_scabby_mouth: false,
  veterinary_treatment: false,
  consumed_material_in_withholding_period: false,
  additional_information: "",
  additional_information_suggested: "",
  post_breeder_tags: null,
};

const defaultCattleDeclaration = {
  treated_with_hgp: false,
  fed_feed_containing_animal_fats: false,
  owned_since_birth: undefined,
  owned_since_birth_duration: undefined,
  fed_byproduct_stockfeeds: false,
  chemical_residue_restrictions: false,
  veterinary_treatment: false,
  consumed_material_in_withholding_period: false,
  spray_drift_risk: false,
  additional_information: "",
  additional_information_suggested: "",
};

const defaultEUCattleDeclaration = {
  fed_feed_containing_animal_fats: false,
  owned_since_birth: undefined,
  owned_since_birth_duration: undefined,
  fed_byproduct_stockfeeds: false,
  chemical_residue_restrictions: false,
  veterinary_treatment: false,
  consumed_material_in_withholding_period: false,
  spray_drift_risk: false,
  additional_information: "",
  additional_information_suggested: "",
};

const defaultBobbyCalfDeclaration = {
  qa_accredited: false,
  owned_since_birth: undefined,
  owned_since_birth_duration: undefined,
  consumed_material_in_withholding_period: false,
  veterinary_treatment: false,
  veterinary_treatment_milk: false,
  chemical_residue_restrictions: false,
  additional_information: "",
  additional_information_suggested: "",
};

// TODO Fully deprecate this after NOV 2025 and use the updated one below
// For now, we need to keep this for the G0720 form
const defaultGoatDeclaration = {
  // Question 1
  owned_since_birth: undefined,
  owned_since_birth_duration: undefined,
  // Question 2
  feedlot_or_treatment: false,
  // Question 3
  veterinary_treatment: false,
  // Question 4
  consumed_material_in_withholding_period: false,
  // Question 5
  fed_feed_containing_animal_fats: false,
  additional_information: "",
  additional_information_suggested: "",
};

const defaultUpdatedGoatDeclaration = {
  // Question 1
  owned_since_birth: undefined,
  owned_since_birth_duration: undefined,
  // Question 2
  veterinary_treatment: false,
  // Question 3
  consumed_material_in_withholding_period: false,
  // Question 4
  fed_feed_containing_animal_fats: false,
  additional_information: "",
  additional_information_suggested: "",
};

// Create an initial state that contains all the default values, this way we dont need to re-initialise the form if the defaults change.
// This will only work well if the defaults are the same for all fields between nvd versions and species and will have to be handled differently
// if for example there was a different default value for 'additional_information' between EU and normal cattle.
const DEFAULT_DECLARATION = {
  ...defaultSheepDeclaration,
  ...defaultCattleDeclaration,
  ...defaultEUCattleDeclaration,
  ...defaultBobbyCalfDeclaration,
  ...defaultGoatDeclaration,
  ...defaultUpdatedGoatDeclaration,
};

const defaultDeclarations = {
  S0413: {
    VERSION: DeclarationTypes.S0413,
    ...defaultSheepDeclaration,
  },
  S0720: {
    VERSION: DeclarationTypes.S0720,
    ...defaultSheepDeclaration,
  },
  C0413: {
    VERSION: DeclarationTypes.C0413,
    ...defaultCattleDeclaration,
  },
  C0720: {
    VERSION: DeclarationTypes.C0720,
    ...defaultCattleDeclaration,
  },

  E0413: {
    VERSION: DeclarationTypes.E0413,
    ...defaultEUCattleDeclaration,
  },
  E0720: {
    VERSION: DeclarationTypes.E0720,
    ...defaultEUCattleDeclaration,
  },
  BC0720: {
    VERSION: DeclarationTypes.BC0720,
    ...defaultBobbyCalfDeclaration,
  },
  G0720: {
    VERSION: DeclarationTypes.G0720,
    ...defaultGoatDeclaration,
  },
  G0524: {
    VERSION: DeclarationTypes.G0524,
    ...defaultUpdatedGoatDeclaration,
  },
};

const defaultValues = {
  id: null,
  vendor: { branchId: null },
  LPA: "",
  MSA: "",
  NVD: "",
  additional_properties: [],
  declaration: DEFAULT_DECLARATION,
  vendor_property_id: null,
};

export const getValidationSchema = isSaleyardAuction =>
  Yup.lazy(values =>
    Yup.object().shape({
      id: Yup.string().required("Please select a consignment").nullable(),
      NVD: isSaleyardAuction
        ? saleyardAuctionNVDValidator.required("Please enter an NVD number")
        : NVDValidator.required("Please enter an NVD number"),
      vendor_property_id: Yup.string().nullable().typeError("Invalid PIC"),
      declaration: Yup.object().shape({
        owned_since_birth: Yup.string()
          .nullable()
          .test({
            name: "owned-since-birth-test",
            test: v => v !== "undefined",
            message: "This question must be completed",
          }),
        owned_since_birth_duration: Yup.string()
          .nullable()
          .test("Duration required when answer is no", "Required", value => {
            const { owned_since_birth } = values.declaration;
            if (owned_since_birth === NVD_OPTIONS.NO) {
              return value && value !== "undefined";
            }
            return true;
          }),
      }),
    }),
  );

const isNVDFormValid = formikProps => {
  // errors on additional_properties are just for warnings, we keep it in
  // the validation object for convenience, but this makes the isValid
  // wrong for our case.
  const errorsWeCareAbout = omitBy(
    formikProps.errors,
    (value, key) => key === "additional_properties",
  );
  return isEmpty(errorsWeCareAbout);
};

const getDefaultVersion = (speciesId, consignment) => {
  if (
    consignment &&
    consignment.declaration &&
    consignment.declaration.VERSION
  ) {
    return consignment.declaration.VERSION;
  } else {
    return speciesId === Species.CATTLE
      ? DeclarationTypes.C0720
      : DeclarationTypes.S0720;
  }
};

const getAllowedFieldsForDeclarationVersion = version =>
  Object.keys(defaultDeclarations[version]).filter(
    k => k !== "additional_information_suggested",
  );

const serialiseDeclarationByType = declaration => {
  // When the declaration type is changed, so are the expected fields, but they do still
  // remain in formik values.
  // Before submitting, strip out anything in the declaration that isn't relevant.
  const allowedFields = getAllowedFieldsForDeclarationVersion(
    declaration.VERSION,
  );
  return allowedFields.reduce((dec, field) => {
    dec[field] = declaration[field];
    return dec;
  }, {});
};

const buildAdditionalInfoFromProperty = property =>
  [
    property?.msaNumber && `MSA: ${property.msaNumber}`,
    property?.lpaNumber && `LPA: ${property.lpaNumber}`,
  ]
    .filter(Boolean)
    .join(" ");

function FormComponent({
  deploymentSaleId,
  selectedAttachment,
  handleGoBack,
  DocumentSelector,
}) {
  const formikProps = useFormikContext();
  const {
    errors,
    initialValues,
    values,
    setFieldValue,
    setFieldTouched,
    touched,
    handleSubmit,
  } = formikProps;
  const initialConsignmentId = getIn(initialValues, "id");
  const consignmentId = getIn(values, "id");

  const hasAddAttachmentWithoutImagePermission =
    useHasAddAttachmentWithoutImagePermission();

  const selectedConsignment =
    useSelector(getConsignmentById(consignmentId)) || {};

  const location = useLocation();

  const consignmentsLookup = useSelector(getConsignments);

  const lastAddedConsignment = useSelector(selectLastAddedConsignment);

  const businessesLookup = useSelector(getBusinesses);

  const lastAddedVendorId = lastAddedConsignment?.vendor_id;
  const lastAddedBrands = businessesLookup[lastAddedVendorId]?.brands || "";

  const consignmentOptions = useSelector(
    getConsignmentOptionsByDeploymentSaleId(deploymentSaleId),
  );

  const hasNvdUploadByConsignmentId = useSelector(
    selectHasNvdUploadByConsignmentIdLookup,
  );

  const branches = useSelector(getSelectableBranches);
  const speciesId = useSelector(state => getCurrentSale(state).species_id);

  // Only show if it was already selected or doesn't have an NVD attached
  const filteredConsignmentOptions = consignmentOptions.filter(
    option =>
      initialConsignmentId === option.value ||
      !hasNvdUploadByConsignmentId[option.value],
  );

  const branchSelectOptions = useMemo(
    () =>
      branches.map(branch => ({
        value: branch.id,
        label: branch.name,
      })),
    [branches],
  );

  const agencyId = useSelector(getAgencyIdByDeploymentSaleId(deploymentSaleId));

  const agency = useSelector(getAgencyById(agencyId)) || {};

  const submitDisabled =
    formikProps.submitCount > 0 && !isNVDFormValid(formikProps);

  // Warning for missing additional PIC's cannot be done using
  // formik/yup validation because it will stop submission.  So put it in here.
  const additionalPicErrors = [];
  if (
    formikProps.values.declaration?.owned_since_birth === NVD_OPTIONS.NO &&
    formikProps.values.declaration?.VERSION === DeclarationTypes.S0413 &&
    formikProps.values.additional_properties.length === 0
  ) {
    additionalPicErrors.push(
      'One or more additional PICs are required when question 3 is answered "No"',
    );
  }

  const declarationVersion = formikProps.values.declaration.VERSION;

  if (formikProps.status?.addPicFieldWarning) {
    additionalPicErrors.push("One or more additional PICs are invalid.");
  }

  const dispatch = useDispatch();

  //  As we don't have a callback after add on the modal popup, check for
  // a value stashed in settings after hitting create.
  // We want to clear the previous value on load, and after selecting one.
  useMountEffect(() => {
    dispatch(setSetting(Settings.lastAddedConsignmentId, null));
  });
  useEffect(() => {
    dispatch(setSetting(Settings.lastAddedConsignmentId, null));
  }, [values.id, dispatch]);

  useEffect(() => {
    if (!isEmpty(lastAddedConsignment) && !values.id) {
      setFieldValue("id", lastAddedConsignment.id);
      setFieldValue("NVD", lastAddedConsignment.NVD);
      setFieldValue(
        "vendor_property_id",
        lastAddedConsignment.vendor_property_id,
      );
      setFieldValue("brands", lastAddedBrands);
    }
  }, [setFieldValue, values.id, lastAddedConsignment, lastAddedBrands]);

  const onConsignmentSelected = nextConsignmentId => {
    const nextConsignment = consignmentsLookup[nextConsignmentId];

    const touchedFields = Object.entries(touched)
      .filter(
        ([_ignored, objectOrIsTouched]) =>
          typeof objectOrIsTouched === "boolean" && objectOrIsTouched,
      )
      .map(([fieldName]) => fieldName);

    if (!touchedFields.includes("NVD")) {
      setFieldValue("NVD", nextConsignment?.NVD);
    }

    if (!touchedFields.includes("brands")) {
      setFieldValue(
        "brands",
        businessesLookup[nextConsignment.vendor_id]?.brands,
      );
    }

    // Always update the Vendor PIC as the next business may not have that PIC
    // associated with them and will need to be added/created as a new temp PIC
    setFieldValue(
      "vendor_property_id",
      getIn(nextConsignment, "vendor_property_id"),
    );

    if (!touchedFields.includes("additional_properties")) {
      setFieldValue(
        "additional_properties",
        getIn(nextConsignment, "additional_properties").map(
          property => property.PIC,
        ),
      );
    }

    const additionalInformationSuggested =
      [
        selectedAttachment?.draftDeclaration?.additionalInformation,
        buildAdditionalInfoFromProperty(nextConsignment.vendor_property),
      ]
        .filter(Boolean)
        .join(" ") || undefined;

    setFieldValue("declaration", {
      additional_information_suggested: additionalInformationSuggested,
      VERSION: getDefaultVersion(speciesId, nextConsignment),
      ...(nextConsignment.declaration || defaultValues.declaration),
    });
  };

  const useAllSuggested = () => {
    // If we have a consignment id, set this first, as it affects the other controls.
    const { suggestedId } = formikProps.values;
    const { id } = formikProps.values;
    if (id !== suggestedId) {
      setFieldValue("id", suggestedId);
    }

    Object.entries(suggestedValueMap)
      .filter(valueMap => valueMap[0] !== "id")
      .forEach(([fieldName, suggestedFieldName]) => {
        const suggestedValue = getIn(formikProps.values, suggestedFieldName);
        const value = getIn(formikProps.values, fieldName);
        // Checking for value undefined should mean we don't set values on a declaration that aren't there
        // (cattle on a sheep dec, etc)
        if (value !== suggestedValue) {
          setFieldValue(fieldName, suggestedValue).then(() => {
            setFieldTouched(fieldName);
          });
        } else {
          // Show that we've fiddled with it so validation does things.
          setFieldTouched(fieldName);
        }
      });
    // Last, always set id to touched - again - validation
    setFieldTouched("id");
  };

  const attachmentButtons = [
    {
      title: "Submit",
      dataTour: "submit",
      onClick: handleSubmit,
      default: true,
    },

    {
      title: "Submit And Create New Consignment",
      onClick: () => {
        handleSubmit();
        if (isEmpty(errors)) {
          openEditConsignmentModal();
        }
      },
      icon: faPlus,
      dataTour: "submitAndCreateNewConsignment",
      iconColor: "black",
    },
  ];

  return (
    <StyledForm>
      <CornerCloseButton handleClose={handleGoBack} />
      {selectedAttachment.envd ? (
        <h3>
          Suggested values loaded from eNVD{" "}
          {selectedAttachment.envd.consignmentNumber}
        </h3>
      ) : null}
      <ForSaleyardAdmin>
        <h3>Agency: {agency.name}</h3>
      </ForSaleyardAdmin>
      <QuestionnaireRow>
        <DocumentSelector />
      </QuestionnaireRow>
      {declarationVersions[speciesId]?.length > 1 && (
        <QuestionnaireRow>
          <SelectField
            name="declaration.VERSION"
            options={declarationVersions[speciesId]}
            label="Version"
            suggestedValueFieldName={suggestedValueMap["declaration.VERSION"]}
          />
          {EXPIRED_DECLARATION_VERSIONS.includes(declarationVersion) && (
            <ErrorArea large>
              That declaration version is no longer accepted.
              <br />
              <a
                href="https://www.integritysystems.com.au/on-farm-assurance/national-vendor-declaration-nvd/nvd-0720-changes/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here for more information.
              </a>
            </ErrorArea>
          )}
        </QuestionnaireRow>
      )}
      <QuestionnaireRow>
        <Row>
          <SelectField
            name="id"
            options={filteredConsignmentOptions}
            label="Owner / Vendor"
            onChangeExtra={onConsignmentSelected}
            suggestedValueFieldName={suggestedValueMap.id}
            filterOption={consignmentOptionFilter}
          />
          {formikProps.values.id ? (
            <EditButton
              to={`#${getEditConsignmentHashRoute(
                selectedConsignment.id,
                null,
                null,
                false,
                location.search,
              )}`}
            />
          ) : (
            <AddButton
              to={`#${getEditConsignmentHashRoute(
                "",
                ConsignmentModalSection.GENERAL,
                false,
                false,
                location.search,
                null,
                agencyId,
              )}`}
            />
          )}
        </Row>
        {selectedAttachment.envd ? (
          <GridRow>
            <GridRow>
              <SmallText justifyContent="flex-end">From PIC:</SmallText>
              <SmallText>{selectedAttachment.envd.fromPIC}</SmallText>
            </GridRow>

            <GridRow>
              <SmallText justifyContent="flex-end">Owner:</SmallText>
              <SmallText>{selectedAttachment.envd.ownerName}</SmallText>
            </GridRow>

            <GridRow>
              <SmallText justifyContent="flex-end">Declaration:</SmallText>
              <SmallText>{selectedAttachment.envd.declarationName}</SmallText>
            </GridRow>

            <GridRow>
              <SmallText justifyContent="flex-end">Head:</SmallText>
              <SmallText>{selectedAttachment.envd.quantity}</SmallText>
            </GridRow>
          </GridRow>
        ) : null}
      </QuestionnaireRow>

      {branches.length > 0 && (
        <QuestionnaireRow>
          <SelectField
            name="vendor.branchId"
            options={branchSelectOptions}
            label="Branch"
          />
        </QuestionnaireRow>
      )}
      <QuestionnaireRow>
        <Input
          name="NVD"
          label="NVD Number"
          maxLength={9}
          suggestedValueFieldName={suggestedValueMap.NVD}
        />
      </QuestionnaireRow>
      <QuestionnaireRow>
        <PICField
          name="vendor_property_id"
          label="PIC"
          businessId={selectedConsignment.vendor_id}
          suggestedValueFieldName={suggestedValueMap.vendor_property_id}
          key={selectedConsignment.vendor_id}
        />
      </QuestionnaireRow>
      <QuestionnaireRow>
        <BodyArea>
          <AddPIC label="additional_properties" formikProps={formikProps} />
        </BodyArea>
        <ErrorArea color={colors.warning}>
          {additionalPicErrors.map((err, idx) => (
            <span key={`pic-err-${idx}`}>
              {err}
              <br />
            </span>
          ))}
        </ErrorArea>
      </QuestionnaireRow>
      <QuestionnaireRow>
        <Input name="brands" label="Brands" />
      </QuestionnaireRow>
      <QuestionnaireRow>
        <Input
          name="vendor_property.name"
          label="Property/place where the journey commenced"
          disabled
        />
      </QuestionnaireRow>
      {formikProps.values.declaration.VERSION === DeclarationTypes.C0413 && (
        <C0413 formikProps={formikProps} />
      )}
      {formikProps.values.declaration.VERSION === DeclarationTypes.C0720 && (
        <C0720 formikProps={formikProps} />
      )}
      {formikProps.values.declaration.VERSION === DeclarationTypes.S0413 && (
        <S0413 formikProps={formikProps} />
      )}
      {formikProps.values.declaration.VERSION === DeclarationTypes.S0720 && (
        <S0720 formikProps={formikProps} />
      )}
      {formikProps.values.declaration.VERSION === DeclarationTypes.E0413 && (
        <E0413 formikProps={formikProps} />
      )}
      {formikProps.values.declaration.VERSION === DeclarationTypes.E0720 && (
        <E0720 formikProps={formikProps} />
      )}
      {formikProps.values.declaration.VERSION === DeclarationTypes.BC0720 && (
        <BC0720 formikProps={formikProps} />
      )}

      {formikProps.values.declaration.VERSION === DeclarationTypes.G0720 && (
        <G0720 formikProps={formikProps} />
      )}

      {formikProps.values.declaration.VERSION === DeclarationTypes.G0524 && (
        <G0524 formikProps={formikProps} />
      )}

      <BottomSticky>
        {selectedAttachment.envd ? (
          <FullWidthGridRow>
            <SlimButton
              fullWidth
              color="success"
              type="button"
              verticalMargin={2}
              onClick={useAllSuggested}
            >
              Use All eNVD Suggestions
            </SlimButton>
          </FullWidthGridRow>
        ) : null}

        <GridRow>
          <SecondaryButton
            data-tour="back"
            type="button"
            onClick={handleGoBack}
          >
            Back
          </SecondaryButton>
          {hasAddAttachmentWithoutImagePermission ? (
            <MultiButton
              buttons={attachmentButtons}
              disabled={submitDisabled}
              fullWidth
            />
          ) : (
            <Button data-tour="submit" type="submit" disabled={submitDisabled}>
              Submit
            </Button>
          )}
        </GridRow>
      </BottomSticky>
    </StyledForm>
  );
}

const DeclarationComponent = ({
  deploymentSaleId,
  documentType,
  DocumentSelector,
  getNextAttachment,
  handleGoBack,
  selectedAttachment,
  setConfirmBrandUpdateDialogProps,
}) => {
  const speciesId = useSelector(state => getCurrentSale(state).species_id);
  const dispatch = useDispatch();
  const saleType = useSelector(state => getCurrentSale(state).sale_type);
  const isSaleyardAuction = saleType === SaleTypes.SALEYARD_AUCTION;

  const consignmentsLookup = useSelector(getConsignments);
  const propertiesLookup = useSelector(getProperties);
  const businessLookup = useSelector(getBusinesses);

  const propertyIdByPICLookup = useSelector(selectPropertyIdByPICLookup);

  const consignment = useSelector(
    getConsignmentById(selectedAttachment?.consignment),
  );
  const {
    declaration = defaultValues.declaration,
    id,
    vendor_property,
    vendor_property_id,
    additional_properties = [],
    NVD,
    brands,
  } = consignment || {};

  // In the case of a brand that is entered on the consignment, vs a prefill available on a vendor:
  // - use a non-empty value if available (already entered) on the consignment
  // - use the default off the vendor if available.
  const vendorBrands = businessLookup?.[consignment?.vendor_id]?.brands || "";

  const consignmentInitialValues = {
    additional_properties: additional_properties.map(property => property.PIC),
    id,
    NVD,
    vendor_property_id,
    brands: brands || vendorBrands,
  };

  const initialValues = {
    ...defaultValues,
    ...consignmentInitialValues,
    declaration: {
      ...(declaration || defaultValues.declaration),
      additional_information_suggested:
        buildAdditionalInfoFromProperty(vendor_property) || undefined,
      VERSION: getDefaultVersion(speciesId, consignment),
    },
    selectedAttachment: {
      ...selectedAttachment,
      // Force these to undefined if they are null - otherwise formik's getIn pulls out selectedAttachment.XXX's value, which is null, and actually
      // a valid answer for some questions.
      envd: selectedAttachment.envd || undefined,
      draftDeclaration: selectedAttachment.draftDeclaration || undefined,
    },
    documentType,
  };

  if (selectedAttachment?.draftDeclaration?.additionalInformation) {
    initialValues.declaration.additional_information_suggested = `${
      initialValues.declaration.additional_information_suggested
        ? `${initialValues.declaration.additional_information_suggested}; `
        : ""
    }${selectedAttachment.draftDeclaration.additionalInformation}`;
  }

  if (selectedAttachment?.envd?.fromPIC) {
    // If we have a from pic try see if we have a suggested consignment
    initialValues.suggestedId = Object.values(consignmentsLookup).find(
      c =>
        propertiesLookup[c.vendor_property_id]?.PIC ===
        selectedAttachment.envd.fromPIC,
    )?.id;

    initialValues.suggestedVendorPropertyId =
      propertyIdByPICLookup[selectedAttachment.envd.fromPIC];
  }

  const onSubmit = values => {
    dispatch(
      updateConsignmentMega(
        {
          ...values,
          declaration: {
            ...serialiseDeclarationByType(values.declaration),
            envd_id: selectedAttachment.envd?.id || null,
          },
        },
        { changeReason: "Updated declaration" },
      ),
    );

    // If the user has entered a brand against this NVD, and it's different to what's stashed on the vendor,
    // offer to update the vendor, too.

    // The values.id is the consignment id being updated - this form isn't about updating vendors on consignments, so
    // the details of the consignment on the state are referenced to pull the vendors details out.
    const vendor = businessLookup[consignmentsLookup[values.id].vendor_id];

    if (values.brands && vendor.brands !== values.brands) {
      if (vendor.brands) {
        setConfirmBrandUpdateDialogProps({
          question: `Would you like to set "${values.brands}" as the default Brand for the Vendor "${vendor.name}"?`,
          message: `You have entered the Brand "${values.brands}".  The Vendor "${vendor.name}" currently has a default brand of "${vendor.brands}".`,
          vendorId: vendor.id,
          brands: values.brands,
        });
      } else {
        setConfirmBrandUpdateDialogProps({
          question: `Would you like to set "${values.brands}" as the default Brand for the Vendor "${vendor.name}"?`,
          message: `You have entered the Brand "${values.brands}".  The Vendor "${vendor.name}" has no current default brand.`,
          vendorId: vendor.id,
          brands: values.brands,
        });
      }
    }
    getNextAttachment();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema(isSaleyardAuction)}
      onSubmit={onSubmit}
    >
      <FormComponent
        deploymentSaleId={deploymentSaleId}
        selectedAttachment={selectedAttachment}
        handleGoBack={handleGoBack}
        DocumentSelector={DocumentSelector}
      />
    </Formik>
  );
};

export const Declaration = React.memo(DeclarationComponent);
