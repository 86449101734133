import React from "react";

import { faTruck } from "@fortawesome/pro-light-svg-icons";
import { useSelector } from "react-redux";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";

import { getBusinessById, getVendorIdByConsignmentId } from "selectors";

import { Chip } from "./BaseChip";

export const ConsignmentChip = ({ consignmentId }) => {
  const vendorId = useSelector(getVendorIdByConsignmentId(consignmentId));
  const vendor = useSelector(getBusinessById(vendorId));

  // consignments always have a vendor but not when consignments havent loaded yet
  if (!consignmentId || !vendor) {
    return null;
  }
  return (
    <Chip>
      {vendor.shortCode} &nbsp;
      <FaIcon icon={faTruck} />
    </Chip>
  );
};
