import React from "react";

import kebabCase from "lodash/kebabCase";
import { toast } from "react-toastify";

import Notification from "components/Notification";

export const UPDATE_TOAST_MESSAGE = "App update available";
export const UPDATE_TOAST_MESSAGE_LOGOUT =
  "We have made some important updates and had to log you out, please refresh and sign in again.";

// https://github.com/fkhadra/react-toastify#toast
const getOptions = options => ({
  hideProgressBar: true,
  closeButton: false,
  closeOnClick: false,
  autoClose: 800,
  position: toast.POSITION.TOP_RIGHT,
  ...options,
});

export default {
  // Keep the signature of the original toast object
  // Doing so you can pass additional options
  success(msg, options = {}) {
    return toast.success(
      ({ closeToast }) => (
        <Notification message={msg} type="success" dismiss={closeToast} />
      ),
      getOptions({
        toastId: kebabCase(msg),
        position: toast.POSITION.BOTTOM_LEFT,
        ...options,
      }),
    );
  },
  info(msg, options = {}) {
    return toast(
      ({ closeToast }) => (
        <Notification message={msg} type="info" dismiss={closeToast} />
      ),
      getOptions({
        toastId: kebabCase(msg),
        autoClose: false,
        ...options,
      }),
    );
  },
  warning(msg, options = {}) {
    return toast(
      ({ closeToast }) => (
        <Notification message={msg} type="warning" dismiss={closeToast} />
      ),
      getOptions({
        toastId: kebabCase(msg),
        ...options,
      }),
    );
  },
  syncing(msg, options = {}) {
    return toast(
      ({ closeToast }) => (
        <Notification message={msg} type="syncing" dismiss={closeToast} />
      ),
      getOptions({
        toastId: kebabCase(msg),
        ...options,
      }),
    );
  },
  refreshPrompt(msg, options = {}) {
    return toast(
      ({ closeToast }) => (
        <Notification message={msg} type="update" dismiss={closeToast} />
      ),
      {
        ...getOptions({
          autoClose: false,
          toastId: kebabCase(msg),
          ...options,
        }),
      },
    );
  },
  optionalRefreshPrompt(msg) {
    return toast(
      ({ closeToast }) => (
        <Notification
          message={msg}
          type="optionalUpdate"
          dismiss={closeToast}
        />
      ),
      {
        ...getOptions({ autoClose: false, toastId: kebabCase(msg) }),
      },
    );
  },
  error(msg, options = {}, dismissMessage, dismissAction) {
    return toast(
      ({ closeToast }) => (
        <Notification
          message={msg}
          type="error"
          dismiss={closeToast}
          dismissMessage={dismissMessage}
          dismissAction={dismissAction}
        />
      ),
      {
        ...getOptions({
          ...options,
          toastId: kebabCase(msg),
          autoClose: false,
        }),
      },
    );
  },
  dismiss(toastId) {
    return toast.dismiss(toastId);
  },
};
