import React from "react";

import { useSelector } from "react-redux";

import { getLabelById } from "selectors";

import { Chip } from "./BaseChip";

export const LabelChip = ({ labelId }) => {
  const label = useSelector(getLabelById(labelId));
  if (label) {
    return <Chip>{label.name}</Chip>;
  } else {
    return null;
  }
};
