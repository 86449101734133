import React from "react";

import { useSelector } from "react-redux";

import { getIsManuallyWeighedBySaleLotId } from "selectors";

import { Chip } from "./BaseChip";

export const ManuallyWeighedChip = React.memo(({ saleLotId }) => {
  const isManuallyWeighed = useSelector(
    getIsManuallyWeighedBySaleLotId(saleLotId),
  );

  if (isManuallyWeighed) {
    return (
      <Chip
        color
        backgroundColor="warning"
        tooltip="Some animal weights have been manually entered."
      >
        Manual Weights Found
      </Chip>
    );
  }

  return null;
});
export const WeightChip = ({ tooltip, children, dataTour }) => (
  <Chip dataTour={dataTour} backgroundColor="blueHighlight" tooltip={tooltip}>
    {children}
  </Chip>
);
export const TotalWeightChip = ({ children }) => (
  <Chip tooltip="Total weight in kg" backgroundColor="blueHighlight">
    {children}
  </Chip>
);
export const AverageWeightChip = ({ children }) => (
  <Chip tooltip="Average weight in kg" backgroundColor="blueHighlight">
    {children}
  </Chip>
);
