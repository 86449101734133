import { camelCase } from "lodash";

import { sortByKey } from "lib";

import { isCommercialUser } from "lib/reports";

export const REPORT_TYPE = {
  FAVOURITES: "favourites",
  AGENCIES: "agencies",
  AGENTS: "agents",
  BRANCHES: "branches",
  BUYERS: "buyers",
  CARDS: "cards",
  VENDORS: "vendors",
  STUD: "stud",
  SALE_FILES: "sale_files",
  NLIS: "nlis",
  SALE_EXPORT: "sale_export",
  SCANS: "scans",
  SALEYARD: "saleyard",
};

export const ALL_REPORT_TYPES = Object.values(REPORT_TYPE);

export const REPORT_RESTRICTIONS = {
  CONSIGNMENTS_COMPLETE: "consignments_complete",
  SALE_COMPLETE: "sale_complete",
  DENTITION_WEIGHT_COMPLETE: "dentition_weight_complete",
};

export const REPORT_DISPLAY_NAME = {
  [REPORT_TYPE.FAVOURITES]: "Favourites",
  [REPORT_TYPE.AGENCIES]: "Agencies",
  [REPORT_TYPE.AGENTS]: "Agents",
  [REPORT_TYPE.BRANCHES]: "Branches",
  [REPORT_TYPE.BUYERS]: "Buyers",
  [REPORT_TYPE.CARDS]: "Cards",
  [REPORT_TYPE.VENDORS]: "Vendors",
  [REPORT_TYPE.SALE_FILES]: "Sale Files",
  [REPORT_TYPE.NLIS]: "NLIS",
  [REPORT_TYPE.SALE_EXPORT]: "Sale Export",
  [REPORT_TYPE.SALEYARD]: "Saleyard",
  [REPORT_TYPE.STUD]: "Stud",
};

export const VENDOR_DETAILS_REPORT_NAME = "Vendor details report";
export const BUYER_PURCHASE_DETAILS_REPORT_NAME = "Buyer purchase check";
export const BUYER_DELIVERY_DETAILS_REPORT_NAME = "Buyer delivery details";

export const ReportQueryParam = {
  livestockSaleIdIn: "livestocksale-id__in",
  livestockSaleDateGte: "livestocksale-date__gte",
  livestockSaleDateLte: "livestocksale-date__lte",
  livestockSaleSaleTypeNameIn: "livestocksale-sale_type__name__in",
  livestockSaleSaleSubTypeIdIn: "livestocksale-sale_sub_type_id__in",
  startDate: "start_date",
  endDate: "end_date",
  businessId: "business_id",
  groupBys: "group_bys",
  speciesId: "species_id",
  onlySold: "only_sold",
  outputFormat: "output_format",
};

function makeQueryParamsCamelCase(params) {
  return params.reduce((paramMap, param) => {
    paramMap[param] = camelCase(param.replace(/[^a-zA-Z0-9_]/, "_")).replace(
      "livestocksale",
      "livestockSale",
    );
    return paramMap;
  }, {});
}

export const ReportDriver = {
  AGENT: "isAgentDriven",
  AGENCY: "isAgencyDriven",
  BRANCH_AND_AGENCY: "isBranchAndAgencyDriven",
  BUYER_AND_SALE_ROUND: "isBuyerAndSaleRoundDriven",
  BIDDER: "isBidderDriven",
  BUYER: "isBuyerDriven",
  BUYER_BUYER_WAY: "isBuyerBuyerWayDriven",
  BUYER_DESTINATION_PIC: "isBuyerDestinationPicDriven",
  BUYER_NAME_RANGE: "isBuyerNameRangeDriven",
  CONSIGNED_FROM: "isConsignedFromDriven",
  DEPLOYMENT: "isDeploymentDriven",
  MULTI_SALE: "isMultiSale",
  RULEBOOK: "isRulebookDriven",
  SALE_ROUND: "isSaleRoundDriven",
  SALE_ROUND_AND_AGENCY: "isSaleRoundAndAgencyDriven",
  VENDOR: "isVendorDriven",
};

export const Reports = {
  NVDImagesPDFView: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: true,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isRulebookDriven: false,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate:
      "/reports/saleyards/{saleyardName}/nvd-images/{livestockSaleId}/{buyerId}/",
    showToAgents: true,
    showToBuyers: true,
    showToSaleyardAdmins: true,
    showToVendors: false,
    queryMap: {
      user_role: "userRole",
      buyer_way: "buyerWay",
      static: "static",
      async: "async",
    },
    slug: "NVDImagesPDFView",
    title: "NVD & Attachments",
    showOnBuyerCard: true,
  },
  BuyerReconciliationPDFView: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: true,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isRulebookDriven: false,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate:
      "/reports/saleyards/{saleyardName}/buyer-reconciliation/{livestockSaleId}/{buyerId}/",
    showToAgents: true,
    showToBuyers: true,
    showToSaleyardAdmins: true,
    showToVendors: false,
    queryMap: {
      user_role: "userRole",
      buyer_way: "buyerWay",
      static: "static",
      async: "async",
    },
    slug: "BuyerReconciliationPDFView",
    title: "Buyer Reconciliation Report",
    showOnBuyerCard: true,
    getAllowRenderReport: isCommercialUser,
  },
  BuyerPurchaseDetailBySexPDFView: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: true,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isRulebookDriven: false,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate:
      "/reports/saleyards/{saleyardName}/buyer-purchase-detail-by-sex/{livestockSaleId}/{buyerId}/",
    showToAgents: true,
    showToBuyers: true,
    showToSaleyardAdmins: true,
    showToVendors: false,
    queryMap: {
      user_role: "userRole",
      buyer_way: "buyerWay",
      static: "static",
      async: "async",
    },
    slug: "BuyerPurchaseDetailBySexPDFView",
    title: "Buyer Purchase Detail (by Sex) ",
    showOnBuyerCard: true,
    getAllowRenderReport: isCommercialUser,
  },
  BuyerPurchaseDetailPDFView: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: true,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isRulebookDriven: false,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate:
      "/reports/saleyards/{saleyardName}/buyer-purchase-detail/{livestockSaleId}/{buyerId}/",
    showToAgents: true,
    showToBuyers: true,
    showToSaleyardAdmins: true,
    showToVendors: false,
    queryMap: {
      user_role: "userRole",
      buyer_way: "buyerWay",
      static: "static",
      async: "async",
    },
    slug: "BuyerPurchaseDetailPDFView",
    title: "Buyer Purchase Detail (by Round) ",
    showOnBuyerCard: true,
    getAllowRenderReport: isCommercialUser,
  },
  SaleOutwardMovementRecordPDFView: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: true,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isRulebookDriven: false,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate:
      "/reports/saleyards/{saleyardName}/sale-outward-movement-record/{livestockSaleId}/{buyerId}/",
    showToAgents: true,
    showToBuyers: true,
    showToSaleyardAdmins: true,
    showToVendors: false,
    queryMap: {
      async: "async",
      buyer_way: "buyerWay",
      static: "static",
      user_role: "userRole",
    },
    slug: "SaleOutwardMovementRecordPDFView",
    tab: "buyers",
    title: "Sale Outward Movement Record",
    showOnBuyerCard: true,
  },
  BuyerPurchaseCheckPageForBuyerPDFView: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: true,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isRulebookDriven: false,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate:
      "/reports/saleyards/{saleyardName}/buyer-purchase-check/{livestockSaleId}/{buyerId}/",
    showToAgents: true,
    showToBuyers: true,
    showToSaleyardAdmins: true,
    showToVendors: false,
    slug: "BuyerPurchaseCheckPageForBuyerPDFView",
    queryMap: {
      user_role: "userRole",
      buyer_way: "buyerWay",
      static: "static",
      async: "async",
    },
    tab: "buyers",
    title: "Buyer Purchase Check",
    showOnBuyerCard: true,
    getAllowRenderReport: isCommercialUser,
  },
  BuyerPurchaseCheckPageForBuyerPerBuyerWayPDFView: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: true,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isRulebookDriven: false,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate:
      "/reports/saleyards/{saleyardName}/buyer-purchase-check/{livestockSaleId}/{buyerId}/?page_per_buyer_way=True",
    showToAgents: true,
    showToBuyers: true,
    showToSaleyardAdmins: true,
    showToVendors: false,
    slug: "BuyerPurchaseCheckPageForBuyerPDFView",
    queryMap: {
      user_role: "userRole",
      buyer_way: "buyerWay",
      static: "static",
      async: "async",
    },
    tab: "buyers",
    title: "Buyer Purchase Check by Buyer Way",
    showOnBuyerCard: true,
    getAllowRenderReport: isCommercialUser,
  },
  BillingReport: {
    fileType: "csv",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate:
      "/reports/saleyards/{saleyardName}/billing-report/{livestockSaleId}/{rulebookId}/",
    showToAgents: true,
    showToBuyers: false,
    showToSaleyardAdmins: true,
    showToVendors: false,
    slug: "BillingReport",
    queryMap: { user_role: "userRole" },
    tab: "saleyard",
    title: "Billing Report",
  },
  ConsignmentBillingReportPdf: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate:
      "/reports/saleyards/{saleyardName}/consignment-billing-report-pdf/{livestockSaleId}/{rulebookId}/",
    showToAgents: true,
    showToBuyers: false,
    showToSaleyardAdmins: true,
    showToVendors: false,
    slug: "ConsignmentBillingReportPdf",
    queryMap: { user_role: "userRole" },
    tab: "saleyard",
    title: "Consignment Billing Report",
  },
  DeploymentBillingReportPdf: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate:
      "/reports/saleyards/{saleyardName}/deployment-billing-report-pdf/{livestockSaleId}/{rulebookId}/",
    showToAgents: true,
    showToBuyers: false,
    showToSaleyardAdmins: true,
    showToVendors: false,
    slug: "DeploymentBillingReportPdf",
    queryMap: { user_role: "userRole" },
    tab: "saleyard",
    title: "Deployment Billing Report",
  },
  LivestockSaleDeploymentBillingDataView: {
    fileType: "csv",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate:
      "reports/saleyards/{saleyardName}/billing-data/{livestockSaleId}/{rulebookId}/",
    showToAgents: false,
    showToBuyers: false,
    showToSaleyardAdmins: false,
    showToVendors: false,
    slug: "LivestockSaleDeploymentBillingDataView",
    queryMap: { user_role: "userRole" },
    tab: null,
    title: "Billing Data Export",
  },
  DebtorInsuranceTurnoverReportPdf: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isMultiSale: true,
    isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate: "/reports/debtor-insurance-turnover/",
    showToAgents: false,
    showToBuyers: false,
    showToSaleyardAdmins: false,
    showToVendors: false,
    slug: "DebtorInsuranceTurnoverReportPdf",
    queryMap: makeQueryParamsCamelCase([
      "user_role",
      "livestocksale-id__in",
      "livestocksale-date__gte",
      "livestocksale-date__lte",
      "livestocksale-sale_type__name__in",
      "livestocksale-sale_sub_type_id__in",
      "async",
      "static",
    ]),
    tab: null,
    title: "Debtor Insurance Turnover",
  },
  DetailedRelationshipReport: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isMultiSale: true,
    isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate: "/reports/relationships/detailed/",
    showToAgents: false,
    showToBuyers: false,
    showToSaleyardAdmins: false,
    showToVendors: false,
    slug: "DetailedRelationshipReport",
    queryMap: makeQueryParamsCamelCase([
      "user_role",
      "livestocksale-id__in",
      "livestocksale-date__gte",
      "livestocksale-date__lte",
      "livestocksale-sale_type__name__in",
      "livestocksale-sale_sub_type_id__in",
      // "async",
      // "static",
    ]),
    tab: null,
    title: "Detailed Relationships (PDF)",
  },
  DetailedRelationshipReportXls: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isMultiSale: true,
    isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate: "/reports/relationships/detailed/?output_format=xls",
    showToAgents: false,
    showToBuyers: false,
    showToSaleyardAdmins: false,
    showToVendors: false,
    slug: "DetailedRelationshipReportXls",
    queryMap: makeQueryParamsCamelCase([
      "user_role",
      "livestocksale-id__in",
      "livestocksale-date__gte",
      "livestocksale-date__lte",
      "livestocksale-sale_type__name__in",
      "livestocksale-sale_sub_type_id__in",
      // "async",
      // "static",
    ]),
    tab: null,
    title: "Detailed Relationships (XLS)",
  },
  RelationshipReportPdf: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isMultiSale: true,
    isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate: "/reports/relationships/",
    showToAgents: false,
    showToBuyers: false,
    showToSaleyardAdmins: false,
    showToVendors: false,
    slug: "RelationshipReportPdf",
    queryMap: makeQueryParamsCamelCase([
      "user_role",
      "livestocksale-id__in",
      "livestocksale-date__gte",
      "livestocksale-date__lte",
      "livestocksale-sale_type__name__in",
      "livestocksale-sale_sub_type_id__in",
      "async",
      "static",
    ]),
    tab: null,
    title: "Relationships",
  },
  TransitInsuranceReportPdf: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isMultiSale: true,
    isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate: "/reports/transit-insurance/",
    showToAgents: false,
    showToBuyers: false,
    showToSaleyardAdmins: false,
    showToVendors: false,
    slug: "TransitInsuranceReportPdf",
    queryMap: makeQueryParamsCamelCase([
      "user_role",
      "livestocksale-id__in",
      "livestocksale-date__gte",
      "livestocksale-date__lte",
      "livestocksale-sale_type__name__in",
      "livestocksale-sale_sub_type_id__in",
      "async",
      "static",
    ]),
    tab: null,
    title: "Transit Insurance",
  },

  FederalLevyReportPdf: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isMultiSale: true,
    isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate: "/reports/federal-levy-pdf/",
    showToAgents: false,
    showToBuyers: false,
    showToSaleyardAdmins: false,
    showToVendors: false,
    slug: "FederalLevyReportPdf",
    queryMap: makeQueryParamsCamelCase([
      "user_role",
      "livestocksale-id__in",
      "livestocksale-date__gte",
      "livestocksale-date__lte",
      "livestocksale-sale_type__name__in",
      "livestocksale-sale_sub_type_id__in",
      "async",
      "static",
    ]),
    tab: null,
    title: "Federal Levies (PDF)",
  },

  FederalLevyReportCsv: {
    fileType: "csv",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isMultiSale: true,
    isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate: "/reports/federal-levy-csv/",
    showToAgents: false,
    showToBuyers: false,
    showToSaleyardAdmins: false,
    showToVendors: false,
    slug: "FederalLevyReportCsv",
    queryMap: makeQueryParamsCamelCase([
      "user_role",
      "livestocksale-id__in",
      "livestocksale-date__gte",
      "livestocksale-date__lte",
      "livestocksale-sale_type__name__in",
      "livestocksale-sale_sub_type_id__in",
    ]),
    tab: null,
    title: "Federal Levies (CSV)",
  },
  VicStateLevyReportPdf: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isMultiSale: true,
    isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate: "/reports/vic-state-levy/",
    showToAgents: false,
    showToBuyers: false,
    showToSaleyardAdmins: false,
    showToVendors: false,
    slug: "VicStateLevyReportPdf",
    queryMap: makeQueryParamsCamelCase([
      "user_role",
      "livestocksale-id__in",
      "livestocksale-date__gte",
      "livestocksale-date__lte",
      "livestocksale-sale_type__name__in",
      "livestocksale-sale_sub_type_id__in",
      "async",
      "static",
    ]),
    tab: null,
    title: "Vic State Revenue Office Levy",
  },
  SASheepIndustryFundReport: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isMultiSale: true,
    isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate: "/reports/sa-sheep-industry-fund-report/",
    showToAgents: false,
    showToBuyers: false,
    showToSaleyardAdmins: false,
    showToVendors: false,
    slug: "SASheepIndustryFundReport",
    queryMap: makeQueryParamsCamelCase([
      "user_role",
      "livestocksale-id__in",
      "livestocksale-date__gte",
      "livestocksale-date__lte",
      "livestocksale-sale_type__name__in",
      "livestocksale-sale_sub_type_id__in",
      "async",
      "static",
    ]),
    tab: null,
    title: "SA Sheep Industry Fund",
  },

  BulkBillingReport: {
    fileType: "txt",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate:
      "/reports/saleyards/{saleyardName}/bulk-billing-report/{livestockSaleId}/{rulebookId}/",
    showToAgents: true,
    showToBuyers: false,
    showToSaleyardAdmins: false,
    showToVendors: false,
    slug: "BulkBillingReport",
    queryMap: { user_role: "userRole" },
    tab: "saleyard",
    title: "Bulk Billing Report",
  },
  IndividualBidderDetailReport: {
    fileType: "pdf",
    // isAgentDriven: false,
    // isAgencyDriven: false,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBidderDriven: true,
    isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate:
      "/reports/saleyards/{saleyardName}/bidder-detail-individual/{livestockSaleId}/{bidderId}/",
    showToAgents: true,
    showToBuyers: false,
    showToSaleyardAdmins: true,
    showToVendors: false,
    slug: "IndividualBidderDetailReport",
    queryMap: { user_role: "userRole" },
    tab: "saleyard",
    title: "Bidder Detail Report Individual",
  },
  IndividualBidderInvoiceReport: {
    fileType: "pdf",
    // isAgentDriven: false,
    isAgencyDriven: true,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    isBidderDriven: true,
    isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    pathTemplate:
      "/reports/saleyards/{saleyardName}/stud-buyer-tax-invoice/{livestockSaleId}/{buyerId}/{agencyShortName}/",
    showToAgents: true,
    showToBuyers: false,
    showToSaleyardAdmins: true,
    showToVendors: false,
    slug: "StudBuyerTaxInvoice",
    queryMap: { user_role: "userRole", buyer_way_id: "buyerWayId" },
    tab: "saleyard",
    title: "Stud Buyer Tax Invoice Individual",
  },
  DetailedFinancialStatementReport: {
    fileType: "pdf",
    // isAgentDriven: false,
    isAgencyDriven: true,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    // isBidderDriven: true,

    // isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isMultiSale: true,

    // isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    // pathTemplate: "/reports/detailed-financial-statement/{businessId}/",
    pathTemplate: "/reports/detailed-financial-statement/",
    showToAgents: true,
    showToBuyers: false,
    showToSaleyardAdmins: false,
    showToVendors: false,
    showBillingRunSummary: false,
    slug: "DetailedFinancialStatementReport",
    queryMap: makeQueryParamsCamelCase([
      "user_role",
      "start_date",
      "end_date",
      "business_id",
      "async",
      "static",
    ]),
    queryMapBulk: makeQueryParamsCamelCase([
      "business_id",
      "end_date",
      "start_date",
      "user_role",
    ]),
    tab: null,
    title: "Detailed Financial Statement",
  },
  FinancialStatementReport: {
    fileType: "pdf",
    // isAgentDriven: false,
    isAgencyDriven: true,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    // isBidderDriven: true,

    // isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isMultiSale: true,

    // isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    // pathTemplate: "/reports/financial-statement/{businessId}/",
    pathTemplate: "/reports/financial-statement/",
    showToAgents: true,
    showToBuyers: false,
    showToSaleyardAdmins: false,
    showToVendors: false,
    showBillingRunSummary: false,
    slug: "FinancialStatementReport",
    queryMap: makeQueryParamsCamelCase([
      "user_role",
      "start_date",
      "end_date",
      "business_id",
      "async",
      "static",
    ]),
    queryMapBulk: makeQueryParamsCamelCase([
      "business_id",
      "end_date",
      "start_date",
      "user_role",
    ]),
    tab: null,
    title: "Financial Statement",
  },

  FinancialStatementZipReport: {
    fileType: "zip",
    // isAgentDriven: false,
    isAgencyDriven: true,
    // isBranchAndAgencyDriven: false,
    // isBuyerAndSaleRoundDriven: false,
    // isBidderDriven: true,

    // isBuyerDriven: false,
    // isBuyerBuyerWayDriven: false,
    // isBuyerDestinationPicDriven: false,
    // isBuyerNameRangeDriven: false,
    // isConsignedFromDriven: false,
    // isDeploymentDriven: false,
    isMultiSale: true,

    // isRulebookDriven: true,
    // isSaleRoundDriven: false,
    // isSaleRoundAndAgencyDriven: false,
    // isVendorDriven: false,
    // pathTemplate: "/reports/financial-statement/{businessId}/",
    pathTemplate: "/reports/financial-statement-zip/",
    showToAgents: true,
    showToBuyers: false,
    showToSaleyardAdmins: false,
    showToVendors: false,
    showBillingRunSummary: false,
    slug: "FinancialStatementZipReport",
    queryMap: makeQueryParamsCamelCase([
      "user_role",
      "start_date",
      "end_date",
      "business_id",
      "async",
      "static",
    ]),
    queryMapBulk: makeQueryParamsCamelCase([
      "business_id",
      "end_date",
      "start_date",
      "user_role",
    ]),
    tab: null,
    title: "Financial Statement (Zip)",
  },

  BusinessSummaryReport: {
    // Default is pdf - but could be csv
    fileType: "pdf",
    isAgentDriven: false,
    isAgencyDriven: false,
    isBranchAndAgencyDriven: false,
    isBuyerAndSaleRoundDriven: false,
    isBidderDriven: false,

    isBuyerDriven: false,
    isBuyerBuyerWayDriven: false,
    isBuyerDestinationPicDriven: false,
    isBuyerNameRangeDriven: false,
    isConsignedFromDriven: false,
    isDeploymentDriven: false,
    isMultiSale: true,
    isRulebookDriven: false,
    isSaleRoundDriven: false,
    isSaleRoundAndAgencyDriven: false,
    isVendorDriven: false,

    pathTemplate: "/reports/business-summary/",
    showToAgents: true,
    showToBuyers: false,
    showToSaleyardAdmins: true,
    showToVendors: false,
    showBillingRunSummary: false,
    slug: "BusinessSummaryReport",
    queryMap: makeQueryParamsCamelCase([
      "user_role",
      "start_date",
      "end_date",
      "group_bys",
      "only_sold",
      "species_id",
      "output_format",
      "async",
      "static",
    ]),
    tab: null,
    title: "Business Summary Report",
  },
};

export const MultiSaleReportOptions = sortByKey(
  Object.values(Reports)
    .filter(report => report.isMultiSale)

    .map(report => ({
      label: report.title,
      value: report.slug,
    })),
  "label",
);

export const BulkCreateReportOptions = sortByKey(
  Object.values(Reports)
    .filter(report => report.queryMapBulk !== undefined)

    .map(report => ({
      label: report.title,
      value: report.slug,
    })),
  "label",
);
