import {
  BulkUpdateFieldsCollapseSection,
  BulkUpdateFieldsFormProps,
  BulkUpdateFieldsFormRowProps,
  BulkUpdateFieldsFormSetProps,
} from "components/BulkUpdateOptionalFieldsModal/types";
import React from "react";
import { Grid } from "@material-ui/core";
import { FormCollapse } from "components/Form";
import { useFieldValue, useSectionToggle } from "hooks";
import {
  CollapseTitle,
  getCheckboxFieldName,
} from "components/BulkUpdateOptionalFieldsModal/util";
import { CheckBox } from "components/Form/FormikControls";
import { Row } from "components/Layout";
import { SubtleBadge } from "components/Badge";
import { sortBy } from "lodash";
import { CombinedMissingFieldIndicator } from "components/MissingFieldsIndicator";

function BulkUpdateFieldsFormRow(
  props: BulkUpdateFieldsFormRowProps,
): React.JSX.Element {
  const { field, modelIds } = props;
  const {
    Component,
    componentProps = null,
    fieldName,
    UpdateInformationComponent,
  } = field;

  const isFieldEnabled =
    useFieldValue(getCheckboxFieldName(fieldName)) === true;

  return (
    <React.Fragment key={fieldName}>
      <Grid item xs={1} alignContent="center">
        <CheckBox name={getCheckboxFieldName(fieldName)} label="" />
      </Grid>
      <Grid item xs={11} spacing={1}>
        <Component
          name={fieldName}
          {...componentProps}
          // YUCK - some components are disabled, some are read only
          disabled={!isFieldEnabled}
          readOnly={!isFieldEnabled}
          modelIds={modelIds}
        />
        {UpdateInformationComponent ? (
          <UpdateInformationComponent field={field} modelIds={modelIds} />
        ) : null}
      </Grid>
    </React.Fragment>
  );
}

function BulkUpdateFieldsFormSet(
  props: BulkUpdateFieldsFormSetProps,
): React.JSX.Element {
  const { fields, modelIds } = props;

  return (
    <>
      {fields.map(field => (
        <BulkUpdateFieldsFormRow field={field} modelIds={modelIds} />
      ))}
    </>
  );
}

function SectionHeader({
  headerText,
  modelIds,
}: {
  headerText: string;
  modelIds: string[];
}): React.JSX.Element {
  return (
    <Row alignCenter flexWrap>
      <SubtleBadge>{headerText}</SubtleBadge>
      {headerText === CollapseTitle.GENERAL && (
        <CombinedMissingFieldIndicator modelIds={modelIds} />
      )}
    </Row>
  );
}

export function BulkUpdateFieldsForm(
  props: BulkUpdateFieldsFormProps,
): React.JSX.Element {
  const { fields, modelIds } = props;

  // Some fields are hoisted - they should display in the root.
  // Similarly - if they're not named as in a tab, they should display in the root too.
  const hoistedFields = sortBy(
    fields.filter(field => field.rootFieldOrder || !field.collapseTitle),
    "rootFieldOrder",
  );

  // Others (may) have tabbed fields.
  const tabbedFields = fields.reduce(
    (acc: BulkUpdateFieldsCollapseSection, field) => {
      if (field.collapseTitle) {
        if (!acc[field.collapseTitle]) {
          acc[field.collapseTitle] = [];
        }
        acc[field.collapseTitle].push(field);
      }
      return acc;
    },
    {} as BulkUpdateFieldsCollapseSection,
  );

  const [selectedSection, toggleSection] = useSectionToggle(null);

  return (
    <Grid container spacing={2}>
      <BulkUpdateFieldsFormSet fields={hoistedFields} modelIds={modelIds} />
      <Grid xs={12}>
        <hr />
        {Object.entries(tabbedFields).map(([sectionName, sectionFields]) => (
          <FormCollapse
            isOpen={selectedSection === sectionName}
            onToggle={() => toggleSection(sectionName)}
            header={
              <SectionHeader headerText={sectionName} modelIds={modelIds} />
            }
          >
            <Grid container spacing={2}>
              <BulkUpdateFieldsFormSet
                fields={sectionFields}
                modelIds={modelIds}
              />
            </Grid>
          </FormCollapse>
        ))}
      </Grid>
    </Grid>
  );
}
