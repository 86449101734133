import * as Sentry from "@sentry/react";
import { isEqual, omit } from "lodash";

import { DELETE_OFFLINE_ACTION } from "constants/actionTypes";
import { SentrySeverityWarning } from "constants/sentry";

import { isSentryActive } from "lib/sentry/config";
import {
  OfflineAction,
  ResultAction,
} from "@redux-offline/redux-offline/lib/types";
import { ActionPriority } from "constants/actions";

export type PrioritisedAction = { meta: { priority?: number } };

export function getActionPriority(action: PrioritisedAction): ActionPriority {
  if (action.meta.priority) {
    return action.meta.priority;
  }
  return ActionPriority.MEDIUM;
}

type ActionType = OfflineAction & DeleteAction & PrioritisedAction;

type ResultActionType = ResultAction & {
  meta: { offlineAction: OfflineAction };
};

function dequeue(
  currentQueue: OfflineAction[],
  commitOrRollbackAction: ResultActionType,
): OfflineAction[] {
  const newQueue = currentQueue.slice();

  if (
    !commitOrRollbackAction.meta ||
    !commitOrRollbackAction.meta.offlineAction
  ) {
    newQueue.splice(0, 1);
    return newQueue;
  }

  // Take the item which was
  const originalAction: OfflineAction =
    commitOrRollbackAction.meta.offlineAction;
  const { transaction } = originalAction.meta;

  const deleteIndex = currentQueue.findIndex(
    (offlineAction: OfflineAction) =>
      offlineAction.meta.transaction === transaction,
  );
  newQueue.splice(deleteIndex, 1);
  return newQueue;
}

function peek(offlineQueue: OfflineAction[]): OfflineAction {
  // Return the first item in the queue
  return offlineQueue[0];
}

interface DeleteAction {
  type: typeof DELETE_OFFLINE_ACTION;
  meta: {
    offline: {
      deleteTransaction: number;
    };
  };
}

const DISTINCT_ACTION_PATHS = [
  "meta.transaction",
  "meta.offline.commit.meta.offlineAction",
  "meta.offline.rollback.meta.offlineAction",
];

export function getIsEquivalentActionInQueue(
  currentQueue: ActionType[],
  action: ActionType,
) {
  const distinctAction = omit(action, DISTINCT_ACTION_PATHS);

  return currentQueue.some(
    (queuedAction: ActionType) =>
      // fail early if we can on just the action type
      queuedAction.type === action.type &&
      // The types are the same so we may
      isEqual(distinctAction, omit(queuedAction, DISTINCT_ACTION_PATHS)),
  );
}

function enqueue(currentQueue: ActionType[], action: ActionType): ActionType[] {
  if (action.type === DELETE_OFFLINE_ACTION) {
    const { deleteTransaction } = action.meta.offline;
    return currentQueue.filter(
      queuedAction => queuedAction.meta.transaction !== deleteTransaction,
    );
  }

  if (currentQueue.length > 100) {
    // Log a warning, stuffs about to get bad.
    // eslint-disable-next-line no-console
    console.warn(
      `Warning: Adding more actions to already large queue! Length: ${currentQueue.length}  Action: ${action}`,
    );
    // Send a Sentry error every 250 - the queue should generally be near 0.  Maybe one or two GET actions per
    // data type, and pending changes.  If they're offline and have 250 changes pending, they might have a bad
    // time anyway.
    if (currentQueue.length % 250 === 0) {
      // eslint-disable-next-line no-console
      console.error(
        `Queue size over limit - currently   ${currentQueue.length}`,
      );
      if (isSentryActive) {
        Sentry.captureMessage(
          `Queue size over limit - currently   ${currentQueue.length}`,
          {
            level: SentrySeverityWarning,
          },
        );
      }
    }
  }

  // There is no benefit to queueing the exact same action multiple times.  This should improve initial load.
  if (getIsEquivalentActionInQueue(currentQueue, action)) {
    // eslint-disable-next-line no-console
    console.warn(`Discarding duplicate action ${action.type}`);
    return currentQueue;
  }

  // Offline actions are 'bucketed' into their priority.
  // This allows us to insert them into the queue in a way that respects their priority, not just at the end.
  // Things such as mutative actions will be put into a higher priority bucket than non-mutatutive actions like GET requests.
  // Actions that are related to refreshing expired credentials should be put into a higher priority bucket than mutative actions.

  // Items are inserted into the last index of the bucket they belong to.
  const priority = getActionPriority(action);
  let insertIndex = currentQueue.findIndex(
    queuedAction => getActionPriority(queuedAction) < priority,
  );

  if (insertIndex === -1) {
    insertIndex = currentQueue.length;
  }

  const newQueue = currentQueue.slice();

  // Emulate the default behaviour of the offline commit, which adds the offlineAction to the meta of the commit and rollback actions.
  const queueAction: ActionType = {
    ...action,
    meta: {
      ...action.meta,
      offline: {
        ...action.meta.offline,
        commit: {
          ...action.meta.offline.commit,
          meta: {
            ...(action.meta.offline.commit.meta || null),
            offlineAction: action,
          },
        },
        rollback: {
          ...action.meta.offline.rollback,
          meta: {
            ...(action.meta.offline.rollback.meta || null),
            offlineAction: action,
          },
        },
      },
    },
  } as ActionType;
  newQueue.splice(insertIndex, 0, queueAction);

  return newQueue;
}

const queue = {
  peek,
  dequeue,
  enqueue,
};

export default queue;
