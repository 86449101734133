import {
  ALL_PRICING_TYPES,
  LivestockSalePricingTypeDisplayNameLkp,
} from "constants/pricingTypes";

import { getSaleExportSites } from "lib/sale";

import { ExportSites } from "./exportSites";
import { SaleTypes } from "./sale";

export const GlobalSearchBooleanFields = {
  HasArrived: "hasArrived",
  HasBuyerExceptions: "hasBuyerExceptions",
  HasConsignmentScans: "hasConsignmentScans",
  HasMissingNasFields: "HasMissingNasFields",
  HasOverflow: "hasOverflow",
  HasProgeny: "hasProgeny",
  HasVendorExceptions: "hasVendorExceptions",
  HasWeight: "hasWeight",
  IsBuyerBalanced: "isBuyerBalanced",
  IsCounted: "isCounted",
  IsDelivered: "isDelivered",
  IsEidCompliant: "isEidCompliant",
  IsInProgress: "isInProgress",
  IsSold: "isSold",
  IsPreSaleVendorBalanced: "isPreSaleVendorBalanced",
  IsPostSaleVendorBalanced: "IsPostSaleVendorBalanced",
  IsConsignmentVideoUploaded: "IsConsignmentVideoUploaded",
  IsSaleLotVideoUploaded: "IsSaleLotVideoUploaded",
  IsConsignmentImageUploaded: "IsConsignmentImageUploaded",
  IsSaleLotImageUploaded: "IsSaleLotImageUploaded",
};

export const GlobalIntegrationsBooleanFields = {
  IsAuctionsPlusCompliant: ExportSites.AUCTIONS_PLUS,
  IsStockLiveCompliant: ExportSites.STOCK_LIVE,
};

const ClearingSaleGlobalSearchBooleanFields = {
  HasBuyerExceptions: "hasBuyerExceptions",
  HasVendorExceptions: "hasVendorExceptions",
  IsSold: "isSold",
};

export const SaleSearchFields = {
  SaleId: "saleId",
  SaleType: "saleType",
  Saleyard: "saleyard",
  Status: "status",
  Species: "species",
  Date: "date",
  Title: "title",
};

export const GlobalSearchBooleanLabels = {
  [GlobalSearchBooleanFields.HasArrived]: "Has Arrived",
  [GlobalSearchBooleanFields.HasBuyerExceptions]: "Has Buyer Exceptions",
  [GlobalSearchBooleanFields.HasConsignmentScans]: "Has Consignment Scans",
  [GlobalSearchBooleanFields.HasMissingNasFields]: "Has Missing NAS Fields",
  [GlobalSearchBooleanFields.HasOverflow]: "Has Overflow",
  [GlobalSearchBooleanFields.HasProgeny]: "Has Progeny",
  [GlobalSearchBooleanFields.HasVendorExceptions]: "Has Vendor Exceptions",
  [GlobalSearchBooleanFields.HasWeight]: "Has Weight",
  [GlobalSearchBooleanFields.IsBuyerBalanced]: "Is Buyer Balanced",
  [GlobalSearchBooleanFields.IsCounted]: "Is Counted",
  [GlobalSearchBooleanFields.IsDelivered]: "Is Delivered",
  [GlobalSearchBooleanFields.IsEidCompliant]: "EID Compliant",
  [GlobalSearchBooleanFields.IsInProgress]: "In Progress",
  [GlobalSearchBooleanFields.IsSold]: "Sold",
  [GlobalSearchBooleanFields.IsPreSaleVendorBalanced]:
    "Is Vendor Pre Sale Balanced",
  [GlobalSearchBooleanFields.IsPostSaleVendorBalanced]:
    "Is Vendor Post Sale Balanced",
  [GlobalSearchBooleanFields.IsConsignmentVideoUploaded]:
    "Is Consignment Video Uploaded",
  [GlobalSearchBooleanFields.IsSaleLotVideoUploaded]:
    "Is Sale Lot Video Uploaded",
  [GlobalSearchBooleanFields.IsConsignmentImageUploaded]:
    "Is Consignment Image Uploaded",
  [GlobalSearchBooleanFields.IsSaleLotImageUploaded]:
    "Is Sale Lot Image Uploaded",
};

const InverseGlobalSearchBooleanLabels = {
  [GlobalSearchBooleanFields.HasArrived]: "Has Not Arrived",
  [GlobalSearchBooleanFields.HasBuyerExceptions]: "Has No Buyer Exceptions",
  [GlobalSearchBooleanFields.HasConsignmentScans]:
    "Doesn't Have Consignment Scans",
  [GlobalSearchBooleanFields.HasMissingNasFields]:
    "Doesn't Have Missing NAS Fields",
  [GlobalSearchBooleanFields.HasOverflow]: "Doesn't Have Overflow",
  [GlobalSearchBooleanFields.HasProgeny]: "Doesn't Have Progeny",
  [GlobalSearchBooleanFields.HasVendorExceptions]: "Has No Vendor Exceptions",
  [GlobalSearchBooleanFields.HasWeight]: "Has No Weight",
  [GlobalSearchBooleanFields.IsBuyerBalanced]: "Buyer Not Balanced",
  [GlobalSearchBooleanFields.IsCounted]: "Is Not Counted",
  [GlobalSearchBooleanFields.IsDelivered]: "Is Not Delivered",
  [GlobalSearchBooleanFields.IsEidCompliant]: "EIDs Not Compliant",
  [GlobalSearchBooleanFields.IsInProgress]: "Is Not In Progress",
  [GlobalSearchBooleanFields.IsSold]: "Is Not Sold",
  [GlobalSearchBooleanFields.IsPreSaleVendorBalanced]:
    "Vendor Not Pre Sale Balanced",
  [GlobalSearchBooleanFields.IsPostSaleVendorBalanced]:
    "Vendor Not Post Sale Balanced",
  [GlobalSearchBooleanFields.IsConsignmentVideoUploaded]:
    "Has No Consignment Videos Uploaded",
  [GlobalSearchBooleanFields.IsSaleLotVideoUploaded]:
    "Has No Sale Lot Videos Uploaded",
  [GlobalSearchBooleanFields.IsConsignmentImageUploaded]:
    "Has No Consignment Images Uploaded",
  [GlobalSearchBooleanFields.IsSaleLotImageUploaded]:
    "Has No Sale Lot Images Uploaded",
};

const GlobalIntegrationshBooleanLabels = {
  [GlobalIntegrationsBooleanFields.IsAuctionsPlusCompliant]: "Auctions Plus",
  [GlobalIntegrationsBooleanFields.IsStockLiveCompliant]: "Stock Live",
};

const InverseGlobalIntegrationshBooleanLabels = {
  [GlobalIntegrationsBooleanFields.IsAuctionsPlusCompliant]:
    "Not Auctions Plus",
  [GlobalIntegrationsBooleanFields.IsStockLiveCompliant]: "Not Stock Live",
};

const ClearingSaleGlobalSearchBooleanLabels = {
  [GlobalSearchBooleanFields.HasBuyerExceptions]: "Has Buyer Exceptions",
  [GlobalSearchBooleanFields.HasVendorExceptions]: "Has Vendor Exceptions",
  [GlobalSearchBooleanFields.IsSold]: "Sold",
};

const InverseClearingSaleGlobalSearchBooleanLabels = {
  [GlobalSearchBooleanFields.HasBuyerExceptions]: "Has No Buyer Exceptions",
  [GlobalSearchBooleanFields.HasVendorExceptions]: "Has No Vendor Exceptions",
  [GlobalSearchBooleanFields.IsSold]: "Is Not Sold",
};

export const GlobalSearchFields = {
  Agency: "agency",
  AuctionPen: "auctionPen",
  Buyer: "buyer",
  BuyerAndBuyerWay: "buyerHash",
  BuyerPic: "buyerPic",
  Checkpoint: "checkpoint",
  DeliveryPen: "deliveryPen",
  ExportSites: "ExportSites",
  Label: "label",
  Lane: "lane",
  Miscellaneous: "miscellaneous",
  Marks: "marks",
  OverflowPen: "overflowPen",
  PenScanLot: "penScanLot",
  PricingType: "pricingType",
  SaleRound: "saleRound",
  ReceivalLot: "receivalLot",
  ReceivalPen: "receivalPen",
  Scan: "scan",
  ThirdParty: "thirdParty",
  Vendor: "vendor",
  VendorNumber: "vendorNumber",
  VendorPic: "vendorPic",
  WeighLot: "weighLot",
  WeighLotScan: "weighLotScan",
  ...GlobalSearchBooleanFields,
  ...GlobalIntegrationsBooleanFields,
};
export const FieldToGroupLabelDescriptionMap = {
  [GlobalSearchFields.Agency]: "Agencies",
  [GlobalSearchFields.AuctionPen]: "Auction Pens",
  [GlobalSearchFields.Buyer]: "Buyers",
  [GlobalSearchFields.BuyerPic]: "Buyer PICs",
  [GlobalSearchFields.Checkpoint]: "Checkpoints ",
  [GlobalSearchFields.DeliveryPen]: "Delivery Pens",
  [GlobalSearchFields.ExportSites]: "Export Sites",
  [GlobalSearchFields.Label]: "Labels",
  [GlobalSearchFields.Lane]: "Lanes",
  [GlobalSearchFields.Miscellaneous]: "Misc.",
  [GlobalSearchFields.PenScanLot]: "Pen Scan Lot",
  [GlobalSearchFields.Marks]: "Marks",
  [GlobalSearchFields.OverflowPen]: "Overflow Pens",
  [GlobalSearchFields.PricingType]: "Pricing Types",
  [GlobalSearchFields.ReceivalLot]: "Receival Lot",
  [GlobalSearchFields.ReceivalPen]: "Receival Pens",
  [GlobalSearchFields.SaleRound]: "Sale Rounds",
  [GlobalSearchFields.Scan]: "Scans",
  [GlobalSearchFields.ThirdParty]: "Third Parties",
  [GlobalSearchFields.Vendor]: "Vendors",
  [GlobalSearchFields.VendorNumber]: "Vendor Numbers",
  [GlobalSearchFields.VendorPic]: "Vendor PICs",
  [GlobalSearchFields.BuyerAndBuyerWay]: "Buyer - Buyer Way: ",

  [SaleSearchFields.SaleId]: "Id",
  [SaleSearchFields.SaleType]: "Type",
  [SaleSearchFields.Saleyard]: "Saleyard",
  [SaleSearchFields.Status]: "Status",
  [SaleSearchFields.Species]: "Species",
  [SaleSearchFields.Date]: "Date",
  [SaleSearchFields.Title]: "Title",
};

export const FieldToLabelDescriptionMap = {
  [GlobalSearchFields.Agency]: "Agency: ",
  [GlobalSearchFields.AuctionPen]: "Auction Pen: ",
  [GlobalSearchFields.Buyer]: "Buyer: ",
  [GlobalSearchFields.BuyerPic]: "Buyer PIC: ",
  [GlobalSearchFields.Checkpoint]: "Checkpoint: ",
  [GlobalSearchFields.DeliveryPen]: "Delivery Pen: ",
  [GlobalSearchFields.ExportSites]: "ExportSites: ",
  [GlobalSearchFields.Label]: "Label: ",
  [GlobalSearchFields.Lane]: "Lane: ",
  [GlobalSearchFields.Marks]: "Marks: ",
  [GlobalSearchFields.OverflowPen]: "Overflow Pen: ",
  [GlobalSearchFields.PricingType]: "Pricing Type: ",
  [GlobalSearchFields.ReceivalPen]: "Receival Pen: ",
  [GlobalSearchFields.SaleRound]: "Sale Round: ",
  [GlobalSearchFields.Scan]: "Scan: ",
  [GlobalSearchFields.ThirdParty]: "Third Party: ",
  [GlobalSearchFields.Vendor]: "Vendor: ",
  [GlobalSearchFields.VendorNumber]: "Vendor Number: ",
  [GlobalSearchFields.VendorPic]: "Vendor PIC: ",
  [GlobalSearchFields.BuyerAndBuyerWay]: "Buyer - Buyer Way: ",
};

export const GlobalSearchBooleanOptions = (currentSale, searchField) => {
  const exportSites = getSaleExportSites(currentSale);
  const isClearingSale = currentSale.sale_type === SaleTypes.CLEARING;
  const isExportSitesSearch = searchField === GlobalSearchFields.ExportSites;

  const searchBooleanFields = isClearingSale
    ? ClearingSaleGlobalSearchBooleanFields
    : GlobalSearchBooleanFields;

  const searchBooleanLabels = isExportSitesSearch
    ? GlobalIntegrationshBooleanLabels
    : isClearingSale
      ? ClearingSaleGlobalSearchBooleanLabels
      : GlobalSearchBooleanLabels;

  const inverseSearchBooleanLabels = isExportSitesSearch
    ? InverseGlobalIntegrationshBooleanLabels
    : isClearingSale
      ? InverseClearingSaleGlobalSearchBooleanLabels
      : InverseGlobalSearchBooleanLabels;

  const searchFields = isExportSitesSearch
    ? GlobalIntegrationsBooleanFields
    : searchBooleanFields;

  return Object.values(searchFields)
    .filter(field => (isExportSitesSearch ? exportSites.includes(field) : true))
    .reduce((acc, field) => {
      acc.push({
        label: searchBooleanLabels[field],
        value: {
          field,
          value: true,
        },
        trueText: isExportSitesSearch ? "Is Compliant" : "Yes",
      });
      acc.push({
        label: inverseSearchBooleanLabels[field],
        value: {
          field,
          value: false,
        },
        falseText: isExportSitesSearch ? "Is Not Compliant" : "No",
      });
      return acc;
    }, []);
};

export const GlobalSearchPricingTypeOptions = ALL_PRICING_TYPES.map(
  pricingType => ({
    label: LivestockSalePricingTypeDisplayNameLkp[pricingType],
    value: {
      field: GlobalSearchFields.PricingType,
      value: pricingType,
    },
  }),
);

export const MiscellaneousLabel = "Miscellaneous";

export const GlobalSearchPlaceholder = "GlobalSearchPlaceholder";
