import {
  Autocomplete,
  getOptionAsValue,
  withNamespace,
} from "components/Form/FormikControls";
import React, { useMemo } from "react";
import { sortBy } from "lodash";
import { useSelector } from "react-redux";
import { getSaleTypes } from "selectors";

interface SaleTypeFieldProps {
  namespace?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

export const SaleTypeField = ({
  namespace: ns,
  label,
  name = "saleType",
  required = false,
}: SaleTypeFieldProps) => {
  const saleTypes = useSelector(getSaleTypes);

  const saleTypeOptions = useMemo(
    () =>
      [
        {
          value: null,
          label: "All",
        },
      ].concat(
        sortBy(
          saleTypes.map(saleType => ({
            label: saleType,
            value: saleType,
          })),
          "label",
        ),
      ),
    [saleTypes],
  );

  return (
    <Autocomplete
      name={withNamespace(ns, name)}
      options={saleTypeOptions}
      required={required}
      getOptionValue={getOptionAsValue}
      disableClearable
      label={label}
    />
  );
};
