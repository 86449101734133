import React, { memo, useCallback, useEffect, useState } from "react";

import { useFormikContext } from "formik";
import { get } from "lodash";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";

import { addPropertyToBusiness, BusinessAction, setSetting } from "actions";

import { FormRow } from "components/BusinessPICSelector/ClosedForm";
import { BusinessPICSelectorModal } from "components/BusinessPICSelector/Search";
import { Label } from "components/Form/FormikControls";
import { Error } from "components/Form/FormikControls/Error";
import { Column } from "components/Layout";
import { Text } from "components/Text";

import { businessRelations, UNKNOWN_BUSINESS_NAME } from "constants/businesses";
import { HotKey } from "constants/hotKey";
import { Settings } from "constants/settings";

import { setBusinessRoleAndInsuranceFlag } from "lib/businesses";

import {
  getBusinessById,
  getCurrentSaleyardId,
  getKeywordLookups,
  getProperties,
  getSettings,
  selectActivePropertyEnrichedBusinessByBusinessIdLookup,
  selectAllPropertyEnrichedBusinessByBusinessIdLookup,
} from "selectors";

import { usePrevious } from "hooks";
import { useBoolean } from "hooks/useBoolean";

const BusinessPICSelectorWrapper = styled.div`
  display: flex;
  min-height: 36px;
`;

const populateBusinessRoleFilterValue = (businessRoles, settings) => {
  return Array.isArray(businessRoles)
    ? businessRoles.reduce((acc, businessRole) => {
        acc[businessRole] =
          settings?.[Settings.businessPICSelectorAttrFilters[businessRole]];
        return acc;
      }, {})
    : undefined;
};

const BusinessPICSelectorComponent = ({
  businessRoles,
  buyerWayName,
  openInstance,
  searchPrefill,
  setOpen,
  businessFieldName,
  formikProps,
  propertyFieldName,
  setHasSelected,
  onAfterSelect,
  setClosed,
  saleyardName,
  innerRef,
  focusControl,
  allowPropertyOnly = true,
  allowBusinessOnly = true,
  label,
  disabled,
  clearable = false,
  open,
  toggleOpen,
  showPrefillSummary = true,
  openOnFocus = false,
}) => {
  const activeBusinesses = useSelector(
    selectActivePropertyEnrichedBusinessByBusinessIdLookup,
  );
  const allBusinesses = useSelector(
    selectAllPropertyEnrichedBusinessByBusinessIdLookup,
  );
  const properties = useSelector(getProperties);
  const settings = useSelector(getSettings);
  const keywordLookups = useSelector(getKeywordLookups);
  const saleyardId = useSelector(getCurrentSaleyardId);

  const [businessRoleFilterValue, setBusinessRoleFilterValue] = useState(
    populateBusinessRoleFilterValue(businessRoles, settings),
  );

  const dispatch = useDispatch();

  const previousSettings = usePrevious(settings);
  const previousSearchPrefill = usePrevious(searchPrefill);
  const previousOpenInstance = usePrevious(openInstance);

  const offlineLookup = useSelector(state => state.offlineTemp);

  const handleSetSetting = (setting, values) =>
    dispatch(setSetting(setting, values));

  const handleClear = e => {
    const { setValues, setTouched, touched, values } = formikProps;

    setValues(
      {
        ...values,
        [businessFieldName]: null,
        [propertyFieldName]: null,
      },
      false,
    ).then(() =>
      setTouched({
        ...touched,
        [businessFieldName]: true,
        [propertyFieldName]: true,
      }),
    );
    e.stopPropagation();
  };

  const handleSelection = (businessId, propertyId) => {
    const {
      setFieldTouched,
      setFieldValue,
      setValues,
      setTouched,
      touched,
      values,
    } = formikProps;

    // Special case for when we don't have a business id - treat
    // it like an add, so it will take the UNKOWN_BUSINESS_NAME path
    if (propertyId && !businessId) {
      const selectedProperty = properties[propertyId];
      handleAddBusinessPIC(null, selectedProperty.PIC, selectedProperty.name);
    }
    if (businessFieldName && propertyFieldName) {
      // Wait for validation to have completed before setting property field

      setValues(
        {
          ...values,
          [businessFieldName]: businessId,
          [propertyFieldName]: propertyId,
        },
        false,
      ).then(() =>
        setTouched({
          ...touched,
          [businessFieldName]: true,
          [propertyFieldName]: true,
        }),
      );
    } else if (businessFieldName) {
      // Explicitly don't run validation until the last step is complete
      setFieldValue(businessFieldName, businessId, false).then(() =>
        setFieldTouched(businessFieldName, true, true),
      );
    } else if (propertyFieldName) {
      // Explicitly don't run validation until the last step is complete
      setFieldValue(propertyFieldName, propertyId, false).then(() =>
        setFieldTouched(propertyFieldName, true, true),
      );
    }

    setHasSelected();
    setClosed();
    typeof onAfterSelect === "function" &&
      onAfterSelect(businessId, propertyId);
  };

  const handleAddBusinessPIC = (formBusinessName, PIC, propertyName) => {
    // Handles the actual creation of the business/linking of the PIC.
    // By rights, this could all be done on a single endpoint, being
    // a POST to the business, but this SETs the properties.  If we
    // are performing this action offline, and another user sets
    // another property, then that value would be lost.
    // As such, add the business, if needed, then if needed, add the
    // property.  If the property already exists, but isn't
    // linked to the business, then use the existing ID, and POST up.
    // Note that may also change the 'name' of the business property
    // link.

    // The business name falls back to the unknown business.  Basically,
    // PICs should not exist without related businesses.  So have a
    // placeholder here.
    const businessName = formBusinessName || UNKNOWN_BUSINESS_NAME;

    // First see if we already know about this business (by name, caseless)
    const existingBusiness = Object.values(activeBusinesses).find(
      business => business.name?.toUpperCase() === businessName.toUpperCase(),
    );
    let businessId;
    if (existingBusiness) {
      businessId = existingBusiness.id;
    } else {
      // Set the initial business type based on where it's being created.
      const values = {};
      setBusinessRoleAndInsuranceFlag(values, businessRoles);
      businessId = uuidv4();
      dispatch(
        BusinessAction.create(
          {
            id: businessId,
            name: businessName,
            ...values,
          },
          { saleyardName },
        ),
      );
    }

    // Do we already know about this PIC in general?  If so, don't worry
    // about the temp id stuff.
    let propertyId;
    if (PIC) {
      // Note this checks for those with the SAME name.
      const existingProperty = Object.values(properties).find(
        property => property.PIC === PIC && property.name === propertyName,
      );

      let needToSubmit = true;
      if (existingProperty) {
        propertyId = existingProperty.id;

        // Do we already have this property matched with this business?
        // If not, no submissions needed.
        needToSubmit =
          !existingBusiness ||
          !existingBusiness.properties.some(prop => prop.id === propertyId);
      } else {
        propertyId = uuidv4();
      }

      if (needToSubmit) {
        const tempProperty = {
          id: propertyId,
          PIC,
          name: propertyName,
        };
        dispatch(addPropertyToBusiness(tempProperty, businessId));
      }
    }
    // Then tell the parent that we're selected.
    handleSelection(businessId, propertyId);
  };

  const checkMappedIds = useCallback(() => {
    const { setFieldValue, values } = formikProps;
    // If the id changes "underneath" us in redux, follow that map
    // and update the values in the parent to match.
    const selectedBusinessId = values[businessFieldName];
    const selectedPropertyId = values[propertyFieldName];
    const actualBusinessId = offlineLookup[selectedBusinessId];
    if (actualBusinessId && actualBusinessId !== selectedBusinessId) {
      setFieldValue(businessFieldName, actualBusinessId);
    }
    const actualPropertyId = offlineLookup[selectedPropertyId];
    if (actualPropertyId && actualPropertyId !== selectedPropertyId) {
      setFieldValue(propertyFieldName, actualPropertyId);
    }
  }, [businessFieldName, formikProps, offlineLookup, propertyFieldName]);

  const checkFocus = useCallback(() => {
    if (!focusControl) {
      return;
    }

    const { focus, clearFocus } = focusControl;
    if (focus) {
      innerRef.current.focus();
      typeof clearFocus === "function" && clearFocus();
    }
  }, [innerRef, focusControl]);

  useEffect(() => {
    // component did mount
    checkMappedIds();
    checkFocus();
  }, [checkFocus, checkMappedIds]);

  useEffect(() => {
    // component did update

    checkMappedIds();
    checkFocus();

    const checkBusinessToggle = (settings, previousSettings) => {
      // When the toggle is changed it sets the setting businessPICSelectorAttrFilters.
      // We need to check if that setting doesn't match and if it doesn't we need to update businessRoleFilterValue
      // so that the toggle reflects the correct value.
      const updateState = Object.values(
        Settings.businessPICSelectorAttrFilters,
      ).find(
        attrFilter => previousSettings?.[attrFilter] !== settings[attrFilter],
      );

      if (updateState) {
        setBusinessRoleFilterValue(
          populateBusinessRoleFilterValue(businessRoles, settings),
        );
      }
    };
    checkBusinessToggle(settings, previousSettings);

    if (
      (!previousSearchPrefill && searchPrefill) ||
      // FIXME HACK this is used to force a re-open of the business pic selector after
      // the component has already mounted and the search prefill is present
      // (but hasn't changed)
      (searchPrefill && openInstance !== previousOpenInstance)
    ) {
      setOpen();
    }
  }, [
    businessRoles,
    checkMappedIds,
    checkFocus,
    openInstance,
    previousOpenInstance,
    previousSearchPrefill,
    previousSettings,
    searchPrefill,
    setOpen,
    settings,
  ]);

  useEffect(() => {
    // componentWillUnmount
    return () => {
      checkMappedIds();
    };
  }, [checkMappedIds]);

  const { values } = formikProps;

  // Handles the case where the accessor is a dot notation one (that formik supports) like "buyer_way.id"
  const selectedBusinessId = get(values, (businessFieldName || "").split("."));
  const selectedPropertyId = get(values, (propertyFieldName || "").split("."));

  const selectedBusiness =
    selectedBusinessId && allBusinesses[selectedBusinessId];
  const selectedProperty =
    selectedBusiness &&
    selectedPropertyId &&
    selectedBusiness.properties.find(
      property => property.id === selectedPropertyId,
    );

  const propertyPending =
    selectedBusiness && selectedPropertyId && !selectedProperty;

  return (
    <BusinessPICSelectorWrapper ref={innerRef} tabIndex={0}>
      {open ? (
        <BusinessPICSelectorModal
          buyerWayName={buyerWayName}
          searchPrefill={searchPrefill}
          selectedBusiness={selectedBusiness}
          selectedProperty={selectedProperty}
          keywordLookups={keywordLookups}
          handleSelection={handleSelection}
          handleAddBusinessPIC={handleAddBusinessPIC}
          allowBusinessOnly={allowBusinessOnly}
          allowPropertyOnly={allowPropertyOnly}
          toggleOpen={toggleOpen}
          label={label}
          businessRoles={businessRoles}
          businessRoleFilterValue={businessRoleFilterValue}
          saleyardId={saleyardId}
          setSetting={handleSetSetting} // this can be called in the pic selector component
          showProperty={Boolean(propertyFieldName)}
          showPrefillSummary={showPrefillSummary}
          openOnFocus={openOnFocus}
        />
      ) : (
        <FormRow
          businessFieldName={businessFieldName}
          business={selectedBusiness}
          property={selectedProperty}
          propertyPending={propertyPending}
          showProperty={Boolean(propertyFieldName)}
          handleClick={toggleOpen}
          disabled={disabled}
          onClear={handleClear}
          clearable={clearable}
        />
      )}
    </BusinessPICSelectorWrapper>
  );
};

const BusinessPICSelector = ({
  label = "Business",
  error,
  optional,
  tooltip = undefined,
  focusNextInput = undefined,
  openOnFocus = false,
  ...props
}) => {
  const [hasSelected, setHasSelected, clearHasSelected] = useBoolean(false);
  const [open, setOpen, setClosed, _ignored, toggleOpen] = useBoolean(
    Boolean(props.searchPrefill),
  );

  // the [Enter]/[Space] keys should open the business selector,
  //   - ONLY when the BusinessPICSelectorWrapper is focused
  //
  // if we have set the prop for openOnFocus - typing anything beside
  // [Tab]/[Shift] should open the select modal and accept input.
  //
  // https://www.npmjs.com/package/react-hotkeys-hook#focus-trap
  // "ref = useHotkeys()" --> only trigger the hotkey if the component is focused.
  const innerRef = useHotkeys(
    openOnFocus ? HotKey.ANY.keys : HotKey.ACTIVATE_ELEMENT.keys,
    () => {
      setOpen();
    },
    {
      ignoreEventWhen: e => {
        return HotKey.NAVIGATION_KEYS.keys.includes(e.key);
      },
    },
  );

  useEffect(() => {
    hasSelected && typeof focusNextInput === "function" && focusNextInput();
    clearHasSelected();
  }, [hasSelected, clearHasSelected, focusNextInput]);

  // FormikProps may be passed in (legacy from pre-hooks), but allow it to be hooked in
  // anyways.
  const ownFormikProps = useFormikContext();

  const { formikProps: argFormikProps, businessFieldName } = props;
  const formikProps = argFormikProps || ownFormikProps;

  const { values } = formikProps || ownFormikProps;

  const businessId = values[businessFieldName];

  const business = useSelector(getBusinessById(businessId));

  const businessRelationshipStockAgent = business?.relationships?.filter(
    relationship => relationship.relationType === businessRelations.STOCK_AGENT,
  )[0];

  const stockAgentBusiness = useSelector(
    getBusinessById(businessRelationshipStockAgent?.relatedToId),
  );

  return (
    // Named props go to the wrapper, other props to the BusinessPIC component
    <Column fullWidth>
      <Label required={!optional} error={!!error} tooltip={tooltip}>
        {label}
      </Label>
      <BusinessPICSelectorComponent
        innerRef={innerRef}
        label={label}
        open={open}
        setOpen={setOpen}
        setClosed={setClosed}
        toggleOpen={toggleOpen}
        setHasSelected={setHasSelected}
        clearHasSelected={clearHasSelected}
        openOnFocus={openOnFocus}
        formikProps={formikProps}
        {...props}
      />
      {props.showLinkedAgent && (
        <Text italic className="mt-8">
          Linked Agent: {stockAgentBusiness?.name || "-"}
        </Text>
      )}
      {error && <Error>{error}</Error>}
    </Column>
  );
};

export default memo(BusinessPICSelector);
