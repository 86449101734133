import { OFFLINE_SCHEDULE_RETRY } from "@redux-offline/redux-offline/lib/constants";
import { select, takeEvery } from "redux-saga/effects";

import { pluralize } from "lib/pluralize";
import toast from "lib/toast";

import { getChangesOutboxSize } from "selectors";

type RetryAction = {
  type: typeof OFFLINE_SCHEDULE_RETRY;
  payload: {
    delay: number;
  };
};

const NOTIFY_MS = 5000;
function* notifyOfflineEffect(action: RetryAction): Generator {
  // do not report offline at first retry (after NOTIFY_MS milliseconds), since this retry
  // might not be due to device offline, but some other internal stuff

  const state: State = (yield select()) as State;

  const changesOutboxSize: number = getChangesOutboxSize(state);

  if (action.payload.delay > NOTIFY_MS && changesOutboxSize > 0) {
    const message = `Device is currently experiencing connectivity issues.\n There ${
      changesOutboxSize === 1 ? "is" : "are"
    } ${changesOutboxSize} unsaved ${pluralize("change", changesOutboxSize)}.`;

    toast.info(message);
  }
}

function* rootSaga(): Generator {
  yield takeEvery<string>(OFFLINE_SCHEDULE_RETRY, notifyOfflineEffect);
}

export default rootSaga;
