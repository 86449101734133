import React from "react";

import { intersection } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { setSetting } from "actions";

import NestedCard from "components/Card/NestedCard";
import { CheckBoxComponentContainer } from "components/Checkbox/Checkbox";
import { PreventPropagation } from "components/Events";
import { LotCardBySaleLotId } from "components/LotCard/LotCard";

import { auctionPenStatuses, cards } from "constants/sale";
import { Settings } from "constants/settings";

import {
  getSaleLotIdsByAuctionPenId,
  getSetting,
  getStatusByAuctionPenId,
  selectAgencyIdBySaleLotId,
  selectSaleLotIdsByRoundIdLookup,
  selectIsWeighedBySaleLotIdLookup,
  selectSaleLotIdsByMarkOrder,
  selectFilteredSaleLotIds,
} from "selectors";

function SaleLotsGroup({
  auctionPenId,
  basePath,
  weighedOnly,
  showSelect = false,
}) {
  const saleLotIds = useSelector(getSaleLotIdsByAuctionPenId(auctionPenId));

  const dispatch = useDispatch();

  const globalSearchFilteredSaleLotIds = useSelector(selectFilteredSaleLotIds);

  const agencyIdBySaleLotId = useSelector(selectAgencyIdBySaleLotId);
  const agencyId = useSelector(getSetting(Settings.agencyId));

  const weighedOnlyFilterActive = typeof weighedOnly !== "undefined";
  const isWeighed = useSelector(selectIsWeighedBySaleLotIdLookup);

  const status = useSelector(getStatusByAuctionPenId(auctionPenId));
  // Not sold is the main flag here - a "NOT SOLD" pen = a "PENNED" lot status.
  // By definition of rendering a Pen card, the PENNED status is redundant.
  const overrideStatus = [
    auctionPenStatuses.NOT_PENNED,
    auctionPenStatuses.NOT_SOLD,
  ].includes(status)
    ? status
    : null;

  // We may get here by virtue of a null auction pen id - make sure we're only showing this round.
  const saleLotIdsByRoundIdLookup = useSelector(
    selectSaleLotIdsByRoundIdLookup,
  );
  const selectedRoundId = useSelector(getSetting(Settings.round));

  const markSortedSaleLotIds = intersection(
    useSelector(selectSaleLotIdsByMarkOrder),
    saleLotIds,
  );

  // Lint seems to not like logic in a single filter! :(
  const filteredSaleLotIds = markSortedSaleLotIds
    .filter(
      saleLotId =>
        agencyId === null || agencyIdBySaleLotId[saleLotId] === agencyId,
    )
    .filter(saleLotId =>
      saleLotIdsByRoundIdLookup[selectedRoundId].includes(saleLotId),
    )
    .filter(
      saleLotId =>
        !weighedOnlyFilterActive || isWeighed?.[saleLotId] === weighedOnly,
    );

  const selectedSaleLotIds = useSelector(
    getSetting(Settings.auctionPenSelectedSaleLotIds),
  );

  const setSelectedSaleLotIds = saleLotIds =>
    dispatch(setSetting(Settings.auctionPenSelectedSaleLotIds, saleLotIds));

  const onChange = saleLotId => {
    if (!selectedSaleLotIds.includes(saleLotId)) {
      setSelectedSaleLotIds(selectedSaleLotIds.concat(saleLotId));
    } else {
      setSelectedSaleLotIds(
        selectedSaleLotIds.filter(
          selectedSaleLotId => selectedSaleLotId !== saleLotId,
        ),
      );
    }
  };

  return (
    <PreventPropagation>
      <NestedCard smallSpacing data-tour="nestedLotCard">
        {filteredSaleLotIds.map((saleLotId, idx) => (
          <CheckBoxComponentContainer
            showSelect={showSelect}
            childClassName={idx !== 0 && "mt-12"}
            checked={selectedSaleLotIds.includes(saleLotId)}
            onChange={() => onChange(saleLotId)}
          >
            <LotCardBySaleLotId
              saleLotId={saleLotId}
              key={saleLotId}
              basePath={basePath}
              cardType={cards.SALE_LOT_CARD}
              showVendor
              showBuyer
              showBuyerWay
              showScanning
              overrideStatus={overrideStatus}
              faded={!globalSearchFilteredSaleLotIds.includes(saleLotId)}
            />
          </CheckBoxComponentContainer>
        ))}
      </NestedCard>
    </PreventPropagation>
  );
}

export default React.memo(SaleLotsGroup);
