import React from "react";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { Chip, ChipWrapper } from "components/Chip";

import { getStatus } from "constants/erpCodes";
import { NlisProgramName, WARNING_PROGRAMS } from "constants/nlis";

export const PicWarningChip = props => {
  const { programs } = props;
  return programs
    .filter(program => WARNING_PROGRAMS.includes(program.code))
    .map(({ code, status }) => (
      <ChipWrapper key={`${code}${status}`}>
        <Chip
          color
          backgroundColor="warning"
          tooltip={getStatus(`${code}${status}`)}
        >
          <FaIcon icon={faExclamationCircle} />
          &nbsp;&nbsp;
          {NlisProgramName[code]}
        </Chip>
      </ChipWrapper>
    ));
};
