import React from "react";

import { useSelector } from "react-redux";

import {
  getDraftingInformationById,
  getDraftingInformationIdsBySaleLotId,
} from "selectors";

import { Chip } from "./BaseChip";

export const DeviceChip = ({ tooltip, children }) => (
  <Chip backgroundColor="white" borderColor="gray13" tooltip={tooltip}>
    {children}
  </Chip>
);
export const DraftChip = ({ draftingInformationId }) => {
  const draftingInformation = useSelector(
    getDraftingInformationById(draftingInformationId),
  );
  return draftingInformation ? (
    <DeviceChip tooltip={draftingInformation.deviceId}>
      {draftingInformation.common_name || draftingInformation.deviceName}
      {draftingInformation.draftName && `: ${draftingInformation.draftName} `}
    </DeviceChip>
  ) : null;
};
export const DraftChips = React.memo(({ saleLotId }) => {
  const draftingInformationIds = useSelector(
    getDraftingInformationIdsBySaleLotId(saleLotId),
  );
  if (draftingInformationIds && draftingInformationIds.size) {
    return Array.from(draftingInformationIds).map(draftingInformationId => (
      <DraftChip
        draftingInformationId={draftingInformationId}
        key={draftingInformationId}
      />
    ));
  }
  return null;
});
