import React from "react";

import { useSelector } from "react-redux";

import { Autocomplete, getOptionAsValue } from "components/Form/FormikControls";

import { EMPTY_ARRAY } from "lib";

import { getCurrentSpeciesId } from "selectors";

import {
  SpeciesAttribute,
  useGetAttributeOptions,
} from "hooks/useGetAttributeOptions";

export const BreedField = ({
  name,
  dataTour = undefined,
  label = undefined,
  disabled = undefined,
  deploymentId = undefined,
  speciesId = undefined,
  showQuickCode = undefined,
  modelIds = EMPTY_ARRAY,
}) => {
  const saleSpeciesId = useSelector(getCurrentSpeciesId);

  const options = useGetAttributeOptions(
    SpeciesAttribute.BREED,
    speciesId || saleSpeciesId,
    deploymentId,
    showQuickCode,
  );

  return (
    <Autocomplete
      name={name}
      dataTour={dataTour}
      label={label}
      options={options}
      getOptionValue={getOptionAsValue}
      disabled={disabled}
      missingMLAFieldsWarningEnabled
      modelIds={modelIds}
    />
  );
};
