import React from "react";

import { faTruck, faBrush } from "@fortawesome/pro-light-svg-icons";
import { faPencil, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, makeStyles } from "@material-ui/core";
import { createFilterOptions } from "@material-ui/lab";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { setModalContext } from "actions";

import { IconButton } from "components/Button";
import { Chip } from "components/Chip";
import { Row } from "components/Layout";
import { SmallText } from "components/Text";

import { ScanLotStatusColourMap } from "constants/auctionPens";
import { ModalTypes } from "constants/navigation";

import { openModalLink } from "lib/navigation";
import { createListString } from "lib/textUtils";

import {
  getAuctionPens,
  getEidsByReceivalLotId,
  getReceivalLotById,
  getReceivalLotStatusById,
  getReceivalLots,
  selectReceivalLotsAsOptions,
} from "selectors";

import { useFieldValue } from "hooks";

import { Autocomplete, FILTER_LIMIT, defaultOnChange } from ".";

const SmallIconButton = styled(IconButton)`
  && {
    width: 8px;
    height: 8px;
    font-size: 12px;
  }
`;

function RenderOption({ option, props }) {
  const { selected } = props;
  const { inputValue, PICs = [], label } = option;

  const PICsString = createListString(PICs, 5);

  // if the option has inputValue, it's the "Add XXX" option
  if (inputValue) {
    return (
      <Row fullWidth justifyCenter>
        Add &quot;{inputValue}&quot;
      </Row>
    );
  }
  return (
    <Row>
      <Checkbox
        color="primary"
        edge="start"
        checked={selected}
        tabIndex={-1}
        disableRipple
      />
      <div>
        <div>{label}</div>
        <SmallText className="italic">{PICsString}</SmallText>
      </div>
    </Row>
  );
}
const getOptionValue = option => option.value;

const ReceivalLotChip = ({ receivalLotId, openModal }) => {
  const receivalLot = useSelector(getReceivalLotById(receivalLotId));
  const receivalLotStatus = useSelector(
    getReceivalLotStatusById(receivalLotId),
  );
  const auctionPenByIdLookup = useSelector(getAuctionPens);
  const scans = useSelector(getEidsByReceivalLotId(receivalLotId));
  const { values, setFieldValue } = useFormikContext();
  const { receivalLotIds } = values;

  const removeReceivalLot = () => {
    setFieldValue(
      "receivalLotIds",
      receivalLotIds.filter(rLotId => rLotId !== receivalLotId),
    );
  };

  const openReceivalLotModal = () =>
    typeof openModal === "function" && openModal(null, receivalLotId);

  return (
    <Chip
      borderLeftColor={ScanLotStatusColourMap[receivalLotStatus]}
      borderRadius={4}
    >
      <>
        {receivalLot.consignmentId && <FontAwesomeIcon icon={faTruck} />}
        &nbsp; {auctionPenByIdLookup[receivalLot.receivalPenId]?.start_pen}
        &nbsp; {scans.length} / {receivalLot.quantity} Hd &nbsp;
        <FontAwesomeIcon icon={faBrush} /> &nbsp;
        {receivalLot.mark}
        <SmallIconButton onClick={openReceivalLotModal}>
          <FontAwesomeIcon icon={faPencil} />
        </SmallIconButton>
        <SmallIconButton onClick={removeReceivalLot}>
          <FontAwesomeIcon icon={faTimes} />
        </SmallIconButton>
      </>
    </Chip>
  );
};

const useStyles = makeStyles(() => ({
  inputRoot: {
    height: `auto`,
  },
}));

export function ReceivalLotSelector({ modalType }) {
  const receivalLotOptions = useSelector(selectReceivalLotsAsOptions);
  const auctionPenByIdLookup = useSelector(getAuctionPens);
  const receivalLotByIdLookup = useSelector(getReceivalLots);

  const classes = useStyles();
  const consignmentId = useFieldValue("id");
  const dispatch = useDispatch();

  const { values, errors, touched } = useFormikContext();

  const openModal = (newValue = null, receivalLotId = null) => {
    if (modalType) {
      // set modalType modal state with form values we need to grab when we return
      dispatch(setModalContext(modalType, { values, errors, touched }));
    }
    if (newValue) {
      // if the option has inputValue, it's the "Add XXX" option
      // there should always only be at most 1 value with inputValue
      // so if it is there, then open the modal with it's values
      const hasInputValue = newValue.find(el => el?.inputValue);
      if (hasInputValue) {
        openModalLink(ModalTypes.EditReceivalLot, {
          receivalLotId: null,
          newPenName: hasInputValue.inputValue,
          consignmentId,
          hideConsignmentInput: true,
        });
      }
    } else {
      // open edit receival lot modal
      openModalLink(ModalTypes.EditReceivalLot, {
        receivalLotId,
        consignmentId,
      });
    }
  };

  const onChange = (e, newValue, props) => {
    defaultOnChange(e, newValue, props);
    openModal(newValue);
  };

  if (!modalType) {
    // without a passed in modal type - this component won't work correctly
    // because we need to have a modalType to store the original form values for
    return null;
  }

  const filter = createFilterOptions({
    matchFrom: "any",
    stringify: option => {
      return `${auctionPenByIdLookup[receivalLotByIdLookup[option.value].receivalPenId]?.start_pen}`;
    },
  });

  const receivalLotFilterOptions = (options, params) => {
    let filtered = filter(options, params).filter(o => !o.hide);

    if (filtered.length > FILTER_LIMIT) {
      filtered = [
        {
          disabled: true,
          label: `Showing ${FILTER_LIMIT} of ${filtered.length} options`,
        },
        ...filtered.slice(0, FILTER_LIMIT),
      ];
    }

    // Suggest the creation of a new value
    if (params.inputValue !== "") {
      filtered.push({
        inputValue: params.inputValue,
        label: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  return (
    <Autocomplete
      classes={classes}
      options={receivalLotOptions}
      onChange={onChange}
      name="receivalLotIds"
      handleHomeEndKeys
      label="Receival Lots"
      multiple
      id="size-small-filled-multi"
      getOptionValue={getOptionValue}
      filterOptions={receivalLotFilterOptions}
      renderOption={(option, props) => (
        <RenderOption option={option} props={{ ...props, modalType }} />
      )}
      renderTags={(values, getTagProps) =>
        values.map((option, index) => (
          <ReceivalLotChip
            receivalLotId={option.value}
            variant="outlined"
            label={option.title}
            size="small"
            {...getTagProps({ index })}
            openModal={openModal}
          />
        ))
      }
      groupBy={option => option.hasConsignment}
      disableCloseOnSelect
    />
  );
}

ReceivalLotSelector.propTypes = {
  modalType: PropTypes.string.isRequired,
};
